import React from 'react'
import { Table } from 'semantic-ui-react'
import { ROLES } from '../../../Utils/constants'

interface UsersTableHeaderProps {
    sortFieldOrder: string
    sortField: string
    searchField: string
    searchText: string
    role: string | null
    handleSearchByFieldName: (field_name: string, sort_order: string) => void
    handleSortByFieldName: (field_name: string, sort_order: string) => void
    changeCheckboxStatus: (e: any, id: string) => void
    isParentChecked: boolean
    config:any
}


const UserTableHeader = (props: UsersTableHeaderProps) => {

    const { sortFieldOrder, sortField, searchField, searchText, role, handleSearchByFieldName, handleSortByFieldName,isParentChecked, changeCheckboxStatus,config } = props
    const super_user: any = sessionStorage.getItem("super_user") === 'true'

    return (
      <Table.Header className={(role === ROLES.SUPER_ADMIN || super_user) ? 'users-table-sa t-header': 't-header'}>
        <Table.Row className='t-row'>
        <Table.HeaderCell textAlign="center" className="t-data headercheckbox" style={{ background: config?.client_config_fe?.button_color }} >
            {/* NAME */}
            <div className="ui fluid category">    
              <div className= {`parentcheckbox ${(role === ROLES.SUPER_ADMIN || super_user)  ? "sa-check": " " }`} >
                <input
                  type="checkbox"
                  value={"parent"}
                  onChange={(e) => changeCheckboxStatus(e, "p1")}
                  checked={isParentChecked}
                />
              </div>
            </div>
          </Table.HeaderCell> 
          <Table.HeaderCell textAlign="center" className='t-data' style={{ background: config?.client_config_fe?.button_color }}>
            {/* NAME */}
            <div className="ui fluid category">
              <i
                className={`pointer long arrow alternate ${
                  sortFieldOrder === "asc" && sortField === "user_name"
                    ? "down"
                    : "up"
                } icon`}
                onClick={() =>
                  handleSortByFieldName("user_name", sortFieldOrder)
                }
              ></i>
              <div className="ui icon input search-input">
                <input
                  type="text"
                  placeholder="NAME"
                  value={searchField === "user_name" ? searchText : ""}
                  onChange={(e) =>
                    handleSearchByFieldName("user_name", e.target.value)
                  }
                />
              </div>
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" className='t-data' style={{ background: config?.client_config_fe?.button_color }}>
            {/* EMAIL */}
            <div className="ui fluid category">
              <i
                className={`pointer long arrow alternate ${
                  sortFieldOrder === "asc" && sortField === "user_email"
                    ? "down"
                    : "up"
                } icon`}
                onClick={() =>
                  handleSortByFieldName("user_email", sortFieldOrder)
                }
              ></i>
              <div className="ui icon input search-input">
                <input
                  type="text"
                  placeholder="EMAIL"
                  value={searchField === "user_email" ? searchText : ""}
                  onChange={(e) =>
                    handleSearchByFieldName("user_email", e.target.value)
                  }
                />
              </div>
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" className='t-data' style={{ background: config?.client_config_fe?.button_color }}>
            {/* ROLE */}
            <div className="ui fluid category">
              <i
                className={`pointer long arrow alternate ${
                  sortFieldOrder === "asc" && sortField === "role_code"
                    ? "down"
                    : "up"
                } icon`}
                onClick={() =>
                  handleSortByFieldName("role_code", sortFieldOrder)
                }
              ></i>
              <div className="ui icon input search-input">
                <input
                  type="text"
                  placeholder="ROLE"
                  value={searchField === "role_code" ? searchText : ""}
                  onChange={(e) =>
                    handleSearchByFieldName("role_code", e.target.value)
                  }
                />
              </div>
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" className='t-data' style={{ background: config?.client_config_fe?.button_color }}>
            {/* OFFICES */}
            <div className="ui fluid category">
              <i
                className={`pointer long arrow alternate ${
                  sortFieldOrder === "asc" && sortField === "office_name"
                    ? "down"
                    : "up"
                } icon`}
                onClick={() =>
                  handleSortByFieldName("office_name", sortFieldOrder)
                }
              ></i>
              <div className="ui icon input search-input">
                <input
                  type="text"
                  placeholder="OFFICE"
                  value={searchField === "office_name" ? searchText : ""}
                  onChange={(e) =>
                    handleSearchByFieldName("office_name", e.target.value)
                  }
                />
              </div>
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" className='t-data' style={{ background: config?.client_config_fe?.button_color }}>
            {/* PARENT OFFICE */}
            <div className="ui fluid category">
              <i
                className={`pointer long arrow alternate ${
                  sortFieldOrder === "asc" && sortField === "parent_office_name"
                    ? "down"
                    : "up"
                } icon`}
                onClick={() =>
                  handleSortByFieldName("parent_office_name", sortFieldOrder)
                }
              ></i>
              <div className="ui icon input search-input">
                <input
                  type="text"
                  placeholder="PARENT OFFICE"
                  value={searchField === "parent_office_name" ? searchText : ""}
                  onChange={(e) =>
                    handleSearchByFieldName(
                      "parent_office_name",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" className='t-data' style={{ background: config?.client_config_fe?.button_color }}>
            {/* RM's */}
            <div className="ui fluid category">
              <i
                className={`pointer long arrow alternate ${
                  sortFieldOrder === "asc" && sortField === "rm_details"
                    ? "down"
                    : "up"
                } icon`}
                onClick={() =>
                  handleSortByFieldName("rm_details", sortFieldOrder)
                }
              ></i>
              <div className="ui icon input search-input">
                <input
                  type="text"
                  placeholder="RM's"
                  value={searchField === "rm_details" ? searchText : ""}
                  onChange={(e) =>
                    handleSearchByFieldName("rm_details", e.target.value)
                  }
                />
              </div>
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign='center' className='t-data' style={{ background: config?.client_config_fe?.button_color }}>
              {/* LOW RISK BANKS */}
              <div className='ui fluid category'>
                  <i
                      className={`pointer long arrow alternate ${sortFieldOrder === "asc" && sortField === "allowed_low_risk_processors" ? "down" : "up"} icon`}
                      onClick={() => handleSortByFieldName("allowed_low_risk_processors", sortFieldOrder)}></i>
                  <div className='ui icon input search-input'>
                      <input
                          type='text'
                          placeholder='LOW RISK BANKS'
                          value={searchField === "allowed_low_risk_processors" ? searchText : ""}
                          onChange={(e) => handleSearchByFieldName("allowed_low_risk_processors", e.target.value)}
                      />
                  </div>
              </div>
          </Table.HeaderCell>
          <Table.HeaderCell textAlign='center' className='t-data' style={{ background: config?.client_config_fe?.button_color }}>
              {/* HIGH RISK BANKS */}
              <div className='ui fluid category'>
                  <i
                      className={`pointer long arrow alternate ${sortFieldOrder === "asc" && sortField === "allowed_high_risk_processors" ? "down" : "up"} icon`}
                      onClick={() => handleSortByFieldName("allowed_high_risk_processors", sortFieldOrder)}></i>
                  <div className='ui icon input search-input'>
                      <input
                          type='text'
                          placeholder='HIGH RISK BANKS'
                          value={searchField === "allowed_high_risk_processors" ? searchText : ""}
                          onChange={(e) => handleSearchByFieldName("allowed_high_risk_processors", e.target.value)}
                      />
                  </div>
              </div>
          </Table.HeaderCell>
          {/* <Table.HeaderCell textAlign="center" className='t-data'>
            RISK LEVELS
            <div className="ui fluid category">
              <i
                className={`pointer long arrow alternate ${
                  sortFieldOrder === "asc" &&
                  sortField === "allowed_risk_levels"
                    ? "down"
                    : "up"
                } icon`}
                onClick={() =>
                  handleSortByFieldName("allowed_risk_levels", sortFieldOrder)
                }
              ></i>
              <div className="ui icon input search-input">
                <input
                  type="text"
                  placeholder="RISK LEVELS"
                  value={
                    searchField === "allowed_risk_levels" ? searchText : ""
                  }
                  onChange={(e) =>
                    handleSearchByFieldName(
                      "allowed_risk_levels",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
          </Table.HeaderCell> */}
          {(role === ROLES.SUPER_ADMIN || (role === ROLES.SUPER_ADMIN && super_user)) && (
            
            <>
              <Table.HeaderCell textAlign="center" className='t-data' style={{ background: config?.client_config_fe?.button_color }}>
               
                <div className="ui fluid category">
                  <i
                    className={`pointer long arrow alternate ${sortFieldOrder === "asc" &&
                        sortField === "reports_dashboard"
                        ? "down"
                        : "up"
                      } icon`}
                    onClick={() =>
                      handleSortByFieldName("reports_dashboard", sortFieldOrder)
                    }
                  ></i>
                  <div className="ui icon input search-input">
                    <input
                      type="text"
                      placeholder="REPORTS DASHBOARD"
                      value={
                        searchField === "reports_dashboard" ? searchText : ""
                      }
                      readOnly
                    />
                  </div>
                </div>
              </Table.HeaderCell>
            </>
          )}
          {/* HEADER FEE-NAV */}
          {(role === ROLES.SUPER_ADMIN || (role === ROLES.SUPER_ADMIN && super_user)) && (
            <>
              <Table.HeaderCell textAlign="center" className='t-data' style={{ background: config?.client_config_fe?.button_color }}>
                {/* RISK LEVELS */}
                <div className="ui fluid category">
                  <i
                    className={`pointer long arrow alternate ${sortFieldOrder === "asc" && sortField === "FEENAVIGATOR"
                        ? "down"
                        : "up"
                      } icon`}
                    onClick={() =>
                      handleSortByFieldName("FEENAVIGATOR", sortFieldOrder)
                    }
                  ></i>
                  <div className="ui icon input search-input">
                    <input
                      type="text"
                      placeholder="STMT Analysis"
                      value={searchField === "FEENAVIGATOR" ? searchText : ""}
                      readOnly
                    />
                  </div>
                </div>
              </Table.HeaderCell>
            </>
          )}
           
           {(role === ROLES.SUPER_ADMIN || role === ROLES.OFFICE_ADMIN || role === ROLES.OFFICE_MANAGER || role === ROLES.RELATIONSHIP_MANAGER) && (
            <>
              <Table.HeaderCell textAlign="center" className='t-data' style={{ background: config?.client_config_fe?.button_color }}>
              {/* FREE EQUIPMENT PLACEMENT */}
                <div className="ui fluid category">
                  <i
                    className={`pointer long arrow alternate ${sortFieldOrder === "asc" && sortField === "FREE_EQUIP"
                        ? "down"
                        : "up"
                      } icon`}
                    onClick={() =>
                      handleSortByFieldName("FREE_EQUIPMENT", sortFieldOrder)
                    }
                  ></i>
                  <div className="ui icon input search-input">
                    <input
                      type="text"
                      placeholder="FREE EQUIPMENT"
                      value={searchField === "FREE EQUIPMENT" ? searchText : ""}
                      readOnly
                    />
                  </div>
                </div>
              </Table.HeaderCell>
            </>
          )}
           {(role === ROLES.SUPER_ADMIN || role === ROLES.OFFICE_ADMIN || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER) && (
            <>
           <Table.HeaderCell textAlign='center' className='t-data' style={{ background: config?.client_config_fe?.button_color }}>
                    {/* USER STATUS */}
                    <div className='ui fluid category'>
                        <i
                            className={`pointer long arrow alternate ${sortFieldOrder === "asc" && sortField === "deactivate_status" ? "down" : "up"} icon`}
                            onClick={() => handleSortByFieldName("deactivate_status", sortFieldOrder)}></i>
                        <div className='ui icon input search-input'>
                            <input
                                type='text'
                                placeholder='USER STATUS'
                                value={searchField === "deactivate_status" ? searchText : ""}
                                readOnly
                            />
                        </div>
                    </div>
                </Table.HeaderCell>
                </>
           )}
          <Table.HeaderCell textAlign="center" style={{ background: config?.client_config_fe?.button_color }}>ACTIONS</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    );
}

export default UserTableHeader