import React from 'react'
import { Dimmer, Loader, SemanticSIZES } from 'semantic-ui-react'
import './progressLoader.css'

interface ILoaderProps {
  loading: boolean,
  message?: string,
  size?: SemanticSIZES,
  config?:any
}

const ProgressLoader = (props: ILoaderProps) => {
  const closeLoader = (e: any) => {
    if (props.loading) {
      return false
    }
  }
  const render = () => {
    if (!props.loading) {
      return
    }

    let loaderSize: SemanticSIZES = 'large'
    if (props.size) {
      loaderSize = props.size
    }

    return (
      <Dimmer active inverted>
        <Loader size={loaderSize} />
        <h2 className='loader-msg' style={{ color: props?.config?.client_config_fe?.button_color }} >{props?.message || ''}</h2>
      </Dimmer>
    )
  }
  return <div>{render()}</div>
}

export default ProgressLoader
