import React from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";

interface DeleteModalProps {
    deleteModalOpen: boolean;
    loading: boolean;
    editOfficeDetails: any;
    handleCancel: () => void
    handleDeleteOffice: () => Promise<void>
}

const DeleteModal = (props: DeleteModalProps) => {

    const { deleteModalOpen, handleCancel, editOfficeDetails, handleDeleteOffice, loading } = props

    return (
        <Modal
            centered
            size='tiny'
            open={deleteModalOpen}
            onClose={handleCancel}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <div className="close-button">
                    <button className="email-activity-close-button" onClick={handleCancel}>X</button>
                </div>
                <ProgressLoader loading={loading} size='small' />
                <div className="word-wrap">
                {`Are you sure you want to delete office ${editOfficeDetails.office_name} ( ID - ${editOfficeDetails.office_id} )?`}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleCancel}>CANCEL</Button>
                <Button onClick={handleDeleteOffice} color='red'>DELETE</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default DeleteModal;