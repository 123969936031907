import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Button, ColorPicker, Input, Modal, Upload, GetProp, ColorPickerProps, Spin } from 'antd';
import classNames from 'classnames';

import api from '../../../../Service/Api';
import backend from '../../../../Service/Backend';
import UploadImage from './UploadImage';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { firstBy } from 'thenby';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

type Color = GetProp<ColorPickerProps, 'value'>;
const { TabPane } = Tabs;

interface OnboardingComponentProps {
  onNext: () => void; // Define the type of onNext
}

interface OnboardingFormProps {
  successCallback: () => void,
  currentClient: any
}

const OnboardingForm = (props: OnboardingFormProps) => {
  const { successCallback, currentClient } = props;
  const [companyName, setCompanyName] = useState<string>('');
  const [companyURL, setCompanyURL] = useState<string>('');
  const [headerFooterBGColor, setHeaderFooterBGColor] = useState<Color>('#000000');
  const [headerFooterTextColor, setHeaderFooterTextColor] = useState<Color>('#000000');
  const [headerFooterBtnColor, setHeaderFooterBtnColor] = useState<Color>('#000000');
  const [headerFooterBtnActiveColor, setHeaderFooterBtnActiveColor] = useState<Color>('#000000');
  const [headerLoginLogo, setHeaderLoginLogo] = useState<string>('');
  const [loginBgImage, setLoginBgImage] = useState<string>('');
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<boolean>(false);
  const [clientId, setClientId] = useState(null);
  const [contactNumber, setContactNumber] = useState<string>('');
  const [tenantAdd, setTenantAdd] = useState<string>('');
  const [senderEmail, setSenderEmail] = useState<string>('');
  const [contactSupportEmail, setContactSupportEmail] = useState<string>('');
  const [faxNumber, setFaxNumber] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [tenantMainEmail, setTenantMainEmail] = useState<string>('');
  const [tenantWebsite, setTenantWebsite] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>('1');
  const [zip, setZip] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [contactEmailError, setContactEmailError] = useState<boolean>(false);
  const [tenantEmailError, setTenantEmailError] = useState<boolean>(false);
  const [senderEmailError, setSenderEmailError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [addressValid, setAddressValid] = useState<boolean>(true)
  const [sendEmailErrorMsg, setSendEmailErrorMsg] = useState<string>('')
  const [ContactEmailErrorMsg, setContactEmailErrorMsg] = useState<string>('')
  const [tenantEmailErrorMsg, setTenantEmailErrorMsg] = useState<string>('')
  const [stateOptions, setStateOptions] = useState([{
    text: '',
    value: ''
  }])

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const countDown = (isEdit: boolean = false) => {
    let secondsToGo = 5;

    const instance = Modal.success({
      title: isEdit ? 'Client Updated Successfully' : 'Client Added Successfully',
      content: `This notification will auto close after ${secondsToGo} seconds.`,
    });

    const timer = setInterval(() => {
      secondsToGo -= 1;
      instance.update({
        content: `This notification will auto close after ${secondsToGo} seconds.`,
      });
    }, 1000);

    setTimeout(() => {
      successCallback();
      clearInterval(timer);
      instance.destroy();
      resetForm()
    }, secondsToGo * 1000);
  };

   const handleCancel = () =>{
    successCallback();
    resetForm()
    window.location.reload();
   }

  const getClientCode = (name: string = '') => {
    let clientCode = name.toLowerCase();

    clientCode = clientCode?.replace(/\s+/g, '-').slice(0,20)

    return clientCode;
  }

  const isFormValid = () => {
    if (
        companyName
        && companyURL
        && headerFooterBGColor
        && headerFooterTextColor
        && headerFooterBtnColor
        && headerFooterBtnActiveColor
        && headerLoginLogo
        && contactNumber
        && tenantMainEmail
        && senderEmail
        && contactSupportEmail
        && !senderEmailError
        && !contactEmailError
        && !tenantEmailError
        && addressValid
      ) {
      return true;
    }
    Modal.error({
      title: 'Form Error',
      content: 'Please ensure all required fields are filled and valid.',
    });
    return false;
  }

  const getClientPayload = () => {
    const clientPayload = {
      "client_code": getClientCode(companyName),
      "client_name": companyName,
      "client_config_fe": {
        "name": companyName,
        "header_logo": headerLoginLogo,
        "button_color": headerFooterBtnColor,
        "login_bg_image": loginBgImage,
        "footer_bg_color": headerFooterBGColor,
        "header_bg_color": headerFooterBGColor,
        "footer_text_color": headerFooterTextColor,
        "header_login_logo": headerLoginLogo,
        "header_text_color": headerFooterTextColor,
        "header_button_bg_color": headerFooterBtnColor,
        "header_button_active_color": headerFooterBtnActiveColor
      },
      "client_config_be": {
        "name": companyName,
        "phone": contactNumber,
        "from_name": companyName,
        "client_url": companyURL,
        "main_logo": headerLoginLogo,
        "tenant_add1": `${tenantAdd}, ${city}`,
        "tenant_add2": `${state}, ${zip}`,
        "tenant_name": companyName,
        "business_name": companyName,
        "address": tenantAdd,
        "city": city,
        "tenant_email": tenantMainEmail,
        "tenant_website": tenantWebsite,
        "sender_email": senderEmail,
        "tenant_rm_email": contactSupportEmail,
        "tenant_fax": faxNumber,
        "zip": zip,
        "state": state,
        "sendgrid_fn_subject": `${companyName} - Fee Navigator Uploaded Statement Results`,
        "tenant_business_name": companyName,
        "sendgrid_email_subject": `Your ${companyName} Application`,
        "sendgrid_welcome_subject": `Welcome to ${companyName}`,
        "sendgrid_email_subject_reminder": `Your ${companyName} Application Remainder`
      },
    }

    return clientPayload;
  }

  const handleClientOnboardingSubmit = async() => {
    if (isFormValid()) {
      try {
        setFormLoading(true);
        setFormError(false);
        if (clientId) {
          const clientPayload = getClientPayload();
          const editPayload = {
            id: clientId,
            ...clientPayload
          }
    
          const data: any = {
            api: api.platformAdmin.updateClient,
            payLoad: JSON.stringify(editPayload),
          }
    
          const onboardedData = await backend.save(data);
          if (onboardedData.message) {
            countDown(true);
          }
        } else {
          const clientPayload = getClientPayload()
          const data: any = {
            api: api.platformAdmin.createClient,
            payLoad: JSON.stringify(clientPayload),
          }
    
          const onboardedData = await backend.save(data);
          if (onboardedData.message) {
            countDown();
          }
        }
      } catch(e: any) {
        console.log(e)
        Modal.error({
          title: 'ERROR',
          content: e.errMessage
        })
      } finally {
        setFormLoading(false);
      }
    } else {
      setFormError(true);
    }
  }

  const resetForm = () => {
    setClientId(null);
    setCompanyName('');
    setCompanyURL('');
    setHeaderFooterBGColor('#000000');
    setHeaderFooterTextColor('#000000');
    setHeaderFooterBtnColor('#000000');
    setHeaderFooterBtnActiveColor('#000000');
    setHeaderLoginLogo('');
    setLoginBgImage('');
    setContactNumber('')
    setTenantAdd('')
    setCity('')
    setTenantMainEmail('')
    setTenantWebsite('')
    setSenderEmail('')
    setFaxNumber('')
    setContactSupportEmail('')
    setZip('')
    setState('')
  }

  useEffect(() => {
    if (currentClient.id) {
      setSendEmailErrorMsg('')
      setContactEmailErrorMsg('')
      setTenantEmailErrorMsg('')
      setClientId(currentClient.id)
      setCompanyName(currentClient.client_name);
      setCompanyURL(currentClient.client_config_be.client_url);
      setHeaderFooterBGColor(currentClient.client_config_fe.header_bg_color);
      setHeaderFooterTextColor(currentClient.client_config_fe.header_text_color);
      setHeaderFooterBtnColor(currentClient.client_config_fe.header_button_bg_color);
      setHeaderFooterBtnActiveColor(currentClient.client_config_fe.header_button_active_color);
      setHeaderLoginLogo(currentClient.client_config_fe.header_login_logo);
      setLoginBgImage(currentClient.client_config_fe.login_bg_image);
      setContactNumber(currentClient.client_config_be.phone)
      setTenantAdd(currentClient.client_config_be.address)
      setCity(currentClient.client_config_be.city)
      setTenantMainEmail(currentClient.client_config_be.tenant_email)
      setTenantWebsite(currentClient.client_config_be.tenant_website)
      setSenderEmail(currentClient.client_config_be.sender_email)
      setFaxNumber(currentClient.client_config_be.tenant_fax)
      setContactSupportEmail(currentClient.client_config_be.tenant_rm_email)
      setZip(currentClient.client_config_be.zip)
      setState(currentClient.client_config_be.state)
    }
  }, [currentClient])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Onboarding',
    },
    {
      key: '2',
      label: 'Email Template',
    },
  ];

  useEffect(() => {
    fetchAllLookupData()
   }, []);
 
   const handleTabChange = (key: string) => {
     setActiveTab(key);
   };
 
   const handleNext = () => {
     setActiveTab('2');
   };
 
   const fetchAllLookupData = async () => {
     const data = {
       api: api.lookupData.getAllLookupData
     }
     const response = await backend.fetch(data)
     let current_index = -1
     let options = [{ text: '', value: '' }]
        // 'STATES' 
        current_index = response.findIndex((option: any) => option.LookupDataType === 'STATES')
        options = current_index > -1 ? prepareOptions(response[current_index]?.StateName) : [{ text: '', value: '' }]
        setStateOptions(options)
   }
 
   const prepareOptions = (data: any) => {
     let options = []
     if (data && data.length > 0) {
       data.sort(
         firstBy(function (option: any) {
           return option.toLowerCase();
         })
       )
 
       for (const option of data) {
         options.push({
           text: option,
           value: option
         })
       }
     }
     return options
   }

   const handleSenderEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setSenderEmail(email);
    const isValidEmail = emailRegex.test(email);
    setSenderEmailError(!isValidEmail);
    setSendEmailErrorMsg('Please enter a valid sender email address')
  };

  const handleContactEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setContactSupportEmail(email);
    const isValidEmail = emailRegex.test(email);
    setContactEmailError(!isValidEmail);
    setContactEmailErrorMsg('Please enter a valid contact email address')
  };

  const handleTenantEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setTenantMainEmail(email);
    const isValidEmail = emailRegex.test(email);
    setTenantEmailError(!isValidEmail);
    setTenantEmailErrorMsg('Please enter a valid tenant main email address')
  };

  // useEffect(() => {
  //   validateFormAddress();
  // }, []);
  
  const validateAddress = async (
    address1: string,
    address2: string,
    city: string,
    state: string,
    zip5: string,
  ) => {
    try {
      state = state.split("-")[1];
      const input = {
        address1: address1.replace("#", ""),
        address2: address2.replace("#", ""),
        city,
        state,
        zip5,
        zip4: "",
      };
      const data = {
        api: api.integrations.validateAddress,
        payLoad: JSON.stringify(input),
      };
      const response = await backend.save(data);
      return response;
    } catch (err: any) {
      const msg = "Error while validating address!";
      if (err.errMessage) {
        setErrorMessage(err.errMessage);
        console.log(err.errMessage, "error message");
      } else {
        setErrorMessage(msg);
        console.log(msg, "msg");
      }
      return false;
    }
  };
  
  const validateFormAddress = async () => {
    if (!!tenantAdd && !!zip && !!state && !!city) {
      setFormLoading(true);
      const addressValid: any = await validateAddress(
        "",
        tenantAdd,
        city,
        state,
        zip,
      );
      setAddressValid(addressValid.isValid);
      setFormLoading(false);
      if (addressValid.isValid) {
        if (tenantAdd.toLowerCase() !== addressValid.Address2[0].toLowerCase()) {
          setTenantAdd(addressValid.Address2[0]);
        }
        if (city.toLowerCase() !== addressValid.City[0].toLowerCase()) {
          setCity(addressValid.City[0]);
        }
        if (zip.toLowerCase() !== addressValid.Zip5[0].toLowerCase()) {
          setZip(addressValid.Zip5[0]);
        }
        if (
          !state.toLowerCase().includes(`-${addressValid.State[0].toLowerCase()}`)
        ) {
          const state = stateOptions.filter((state: any) =>
            state.value
              .toLowerCase()
              .includes(`-${addressValid.State[0].toLowerCase()}`),
          );
          if (!!state && state.length > 0) {
            setState(state[0].value);
          }
        }
      }
    }
  };
    
  const handleChange = (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    setState(data.value as string);
  };
  
  const onboardingComponent: React.FC<OnboardingComponentProps> = ({ onNext }) => (
    <div className="onboarding-form onboarding">
      {formLoading && <div className="form-loader-overlay"><Spin size='large' /></div>}
      <div className="form-content-left">
        <p className="form-title">Enter below configuration details</p>
        <div className="form-content">
          <div className={classNames('form-field', {
            'error-field': formError && companyName === ''
          })}>
            <p className="form-label">Company Name <sup>*</sup></p>
            <Input
              className='form-input'
              value={companyName}
              onChange={e => setCompanyName(e.target.value)}
            />
          </div>
          <div className={classNames('form-field', {
            'error-field': formError && companyURL === ''
          })}>
            <p className="form-label">Company URL <sup>*</sup></p>
            <Input
              className='form-input'
              value={companyURL}
              onChange={e => setCompanyURL(e.target.value)}
            />
          </div>
         <div className='onboarding-form color-container'>        
         <div className='form-content-left color-container'>        
          <div className={classNames('form-field', {
            'error-field': formError && headerFooterBGColor === ''
          })}>
            <p className="form-label">Header/Footer BG color <sup>*</sup></p>
            <ColorPicker
              showText
              value={headerFooterBGColor}
              className='color-picker'
              onChange={color => setHeaderFooterBGColor(color.toHexString())}
            />
          </div>
          <div className={classNames('form-field', {
            'error-field': formError && headerFooterTextColor === ''
          })}>
            <p className="form-label">Header/Footer Text color <sup>*</sup></p>
            <ColorPicker
              showText
              className='color-picker'
              value={headerFooterTextColor}
              onChange={color => setHeaderFooterTextColor(color.toHexString())}
            />
          </div>
          </div>
          <div className='form-content-right color-container onboarding'>
          <div className={classNames('form-field', {
            'error-field': formError && headerFooterBtnColor === ''
          })}>
            <p className="form-label">Header/Footer Button BG color <sup>*</sup></p>
            <ColorPicker
              showText
              className='color-picker'
              value={headerFooterBtnColor}
              onChange={color => setHeaderFooterBtnColor(color.toHexString())}
            />
          </div>
          <div className={classNames('form-field', {
            'error-field': formError && headerFooterBtnActiveColor === ''
          })}>
            <p className="form-label">Header/Footer Button Active color <sup>*</sup></p>
            <ColorPicker 
              showText
              className='color-picker'
              value={headerFooterBtnActiveColor}
              onChange={color => setHeaderFooterBtnActiveColor(color.toHexString())}
            />
          </div>
          </div> 
          </div>
        </div>
      </div>
      <div className="form-content-right onboarding">
        <div className="form-content">
          <div className={classNames('form-field', {
            'error-field': formError && headerLoginLogo === ''
          })}>
            <p className="form-label">Upload Header / Login Logo <sup>*</sup></p>
            <UploadImage
              handleSetImageUrl={setHeaderLoginLogo}
              value={headerLoginLogo}
            />
          </div>
          <div className='form-field'>
            <p className="form-label">Upload BG Image</p>
            <UploadImage
              handleSetImageUrl={setLoginBgImage}
              value={loginBgImage}
            />
          </div>
        </div>
      </div>
      <div className="button-container">
        <Button type="primary" className='next-button' onClick={onNext}>Next</Button>
      </div>
    </div>
  );
  const emailTemplateComponent = () => (
    <div className="onboarding-form">
      {formLoading && <div className="form-loader-overlay"><Spin size='large' /></div>}
      <div className="form-content-left color-container">
        <p className="form-title">Enter below configuration details</p>
        {!addressValid && <p className="address-error-message">Please enter a valid address</p>} 
        <div className="form-content">
          <div className={classNames('form-field', {
            'error-field': formError && contactNumber === ''
          })}>
            <p className="form-label">Contact Number <sup>*</sup></p>
            <Input
              className='form-input'
              value={contactNumber}
              onChange={e => {
                const inputValue = e.target.value;
                if (!inputValue) {
                  setContactNumber(inputValue);
                  return;
                }
                let formattedValue = inputValue
                  .replace(/\D/g, '') // Remove non-numeric characters
                  .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'); // Format as xxx-xxx-xxxx
                const maxLength = 12;
                if (formattedValue.length > maxLength) {
                  formattedValue = formattedValue.slice(0, maxLength);
                }
                setContactNumber(formattedValue);
              }} />
          </div>
          <div className='form-field'>
            <p className="form-label">Tenant Address </p>
            <Input
              className='form-input'
              value={tenantAdd}
              onChange={e => setTenantAdd(e.target.value)}
              onBlur={validateFormAddress} />
          </div>
          <div className='form-field'>
            <p className="form-label">Zip Code</p>
            <Input
              className='form-input'
              value={zip}
              onChange={e => setZip(e.target.value)} 
              onBlur={validateFormAddress} />
          </div>
          <div className={classNames('form-field', {
            'error-field': formError && senderEmail === ''
          })}>
            <p className="form-label">Sender Email <sup>*</sup></p>
            <div className='error-message-format'>
            <Input
              className='form-input'
              value={senderEmail}
              onChange={handleSenderEmailChange} />   
               {senderEmailError && senderEmail && <p className="email-error-message">{sendEmailErrorMsg}</p>} 
               </div>
          </div>        
          <div className={classNames('form-field', {
            'error-field': formError && contactSupportEmail === ''
          })}>
            <p className="form-label">Contact Email <sup>*</sup></p>
            <div className='error-message-format'>
            <Input
              className='form-input'
              value={contactSupportEmail}
              onChange={handleContactEmail} />
               {contactEmailError && contactSupportEmail &&<p className="email-error-message">{ContactEmailErrorMsg}</p>} 
          </div>
          </div>
        </div>
      </div>
      <div className="form-content-right color-container">
        <div className="form-content">
          <div className='form-field'>
            <p className="form-label">Fax Number </p>
            <Input
              className='form-input'
              value={faxNumber}
              maxLength={12}
              minLength={12}
              onChange={e => {
                const inputValue = e.target.value;
                if (!inputValue) {
                  setFaxNumber(inputValue);
                  return;
                }
                let formattedValue = inputValue
                  .replace(/\D/g, '') // Remove non-numeric characters
                  .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'); // Format as xxx-xxx-xxxx
                const maxLength = 12;
                if (formattedValue.length > maxLength) {
                  formattedValue = formattedValue.slice(0, maxLength);
                }
                setFaxNumber(formattedValue);
              }} />
          </div>
          <div className='form-field'>
            <p className="form-label">City</p>
            <Input
              className='form-input'
              value={city}
              onChange={e => setCity(e.target.value)}
              onBlur={validateFormAddress} />
          </div>
          <div className='form-field'>
            <p className="form-label">State</p>
            <Dropdown
              placeholder="select state"
              className='form-input-platform'
              fluid
              search
              selection
              options={stateOptions}
              value={state}
              onChange={handleChange}
              onBlur={validateFormAddress}
            />
          </div>
          <div className={classNames('form-field', {
            'error-field': formError && tenantMainEmail === ''
          })}>
            <p className="form-label">Tenant main Email<sup>*</sup></p>
            <div className='error-message-format'>
            <Input
              className='form-input'
              value={tenantMainEmail}
              onChange={handleTenantEmail} />
              {tenantEmailError && tenantMainEmail && <p className="email-error-message">{tenantEmailErrorMsg}</p>} 
          </div>
          </div>
          <div className='form-field'>
            <p className="form-label">Tenant Website </p>
            <Input
              className='form-input'
              value={tenantWebsite}
              onChange={e => setTenantWebsite(e.target.value)} />
          </div>
        </div>

      </div>
      <div className="button-container">
        {clientId && <Button onClick={handleCancel}>Cancel</Button>}
        <Button onClick={handleClientOnboardingSubmit}>{clientId ? 'Update Tenant' : 'Add Tenant'}</Button>
      </div>
    </div>
  );

  return (
    <Tabs activeKey={activeTab} onChange={handleTabChange}>
      <TabPane tab="Onboarding" key="1">
        {activeTab === '1' && onboardingComponent({onNext: handleNext})}
      </TabPane>
      <TabPane tab="Email Template" key="2">
        {activeTab === '2' && emailTemplateComponent()}
      </TabPane>
    </Tabs>
  );
};

export default OnboardingForm;
