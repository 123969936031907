import React from 'react';
import { Avatar, Dropdown as AntdDropdown, Menu } from "antd";

const AccountMenu = (props: any) => {
    const { config, name, signOut, customMenuItems = [] } = props

    const menuItems = [
        ...customMenuItems,
        { label: "Logout", onclick: signOut },
    ]

    const menu = (
        <Menu
            items={menuItems.map((item: any, index: number) => {
                return {
                    key: index,
                    label: <div onClick={item.onclick}>{item.label}</div>
                }
            })
            }
        />
    )

    return (
        <AntdDropdown
            overlay={menu}
            placement="topRight"
            arrow={false}
            trigger={["click"]}
            className="logout-menu"
        >
            <Avatar
                style={{
                    background: config?.client_config_fe?.header_button_bg_color,
                    border: "1px solid #FFFFFF",
                    cursor: 'pointer',
                    color: config?.client_config_fe?.header_text_color,
                }}>
                {name.split(' ').map((x: string) => x.charAt(0)).join('')}
            </Avatar>
        </AntdDropdown>
    );
};

export default AccountMenu;
