import React from "react";
import { Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";
import BasicButton from '../../Common/Button'

interface ActionResendLinkConfirmationProps {
    loading: boolean
    selectedApp: any
    deactivateModalOpen: boolean
    handleCancel: () => void
    modalAction: any
    handleConfirmActionResendlink: any
    statusCount:any
    additionalCheckStatus:any
    setUserMessageResend:any
    userMessageResend : any
    overlay : any
    overlayMsg : any
    config:any
}
const ActionModal = (props: ActionResendLinkConfirmationProps) => {
    const { loading, deactivateModalOpen, handleCancel, selectedApp, modalAction ,handleConfirmActionResendlink, statusCount , additionalCheckStatus , setUserMessageResend , userMessageResend,overlayMsg,overlay,config} = props
    return (
        <Modal
            centered
            size='large'
            open={deactivateModalOpen}
            onClose={handleCancel}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <div className="close-button">
                    <button className="email-activity-close-button" onClick={handleCancel}>X</button>
                </div>
                <ProgressLoader loading={loading} size='small' />
                <Modal.Description>
                    <div className='body-content min-height-0'>
                    <div className='ui form center'>
                        <div className='inline fields'>
                            <div className='three wide field'>
                                <label>Merchant Name :</label>
                            </div>
                            <div className='eight wide field'>
                                <input
                                type='text'
                                className='form-input-grey'
                                name='business_name'
                                value={selectedApp.map((item: { merchant_name: any }) => { return item.merchant_name }).join(',') }
                                readOnly
                                />
                            </div>
                        </div>
                        <div className='inline fields'>
                            <div className='three wide field'>
                                <label>Personal Message :</label>
                            </div>
                            <div className='eight wide field'>
                                <textarea name='personal_message'  onChange={e =>setUserMessageResend(e.target.value)} rows={5} value={userMessageResend}
                                autoFocus onFocus={(e) => {
                                    const val = e.target.value;
                                    e.target.value = '';
                                    e.target.value = val;
                                }}></textarea>
                            </div>
                        </div>
                        <div className='inline fields'>
                            <label>* Personal Message is mandatory</label>
                        </div>
                    </div>
                </div>
            </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <BasicButton
                    textColor={config?.client_config_fe?.button_color}
                    backgroundColor={'#FFFFFF'}
                    size='18px'
                    buttonText='CANCEL'
                    className="save-button button-border"
                    onClick={handleCancel}
                />
                <button
                    style={{ background: config?.client_config_fe?.button_color }}
                    className="save-button button-border"
                    onClick={() => { handleConfirmActionResendlink(modalAction) }}
                > SEND EMAIL</button>
                <div className='overlay' style={overlay.style}>
                    <div className='overlay-image'>
                        <span className='overlay-text'>{overlayMsg}</span>
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
        
    )
}

export default ActionModal;
