import algoliasearch from "algoliasearch";

const {
  REACT_APP_ALGOLIA_APP_LIST_INDEX_NAME,
  REACT_APP_ALGOLIA_APP_LINK_LIST_INDEX_NAME,
  REACT_APP_ALGOLIA_SEARCH_KEY,
  REACT_APP_ALGOLIA_APP_ID,
  REACT_APP_ALGOLIA_PAGINATION_LIMIT,
  REACT_APP_ALGOLIA_OFFICES_LIST_INDEX_NAME,
  REACT_APP_ALGOLIA_SQL_OFFICES_LIST_INDEX_NAME,
  REACT_APP_BACKEND_MYSQL,
  REACT_APP_ALGOLIA_SQL_APP_ID,
  REACT_APP_ALGOLIA_SQL_SEARCH_KEY,
  REACT_APP_ALGOLIA_USERS_LIST_INDEX_NAME,
  REACT_APP_ALGOLIA_SQL_USERS_LIST_INDEX_NAME,
  REACT_APP_ALGOLIA_SQL_APP_LIST_INDEX_NAME,
  REACT_APP_ALGOLIA_SQL_APP_LINK_LIST_INDEX_NAME
} = process.env;

const searchClient = algoliasearch(
  (REACT_APP_BACKEND_MYSQL ? REACT_APP_ALGOLIA_SQL_APP_ID : REACT_APP_ALGOLIA_APP_ID) || "",
  (REACT_APP_BACKEND_MYSQL ? REACT_APP_ALGOLIA_SQL_SEARCH_KEY : REACT_APP_ALGOLIA_SEARCH_KEY) || ""
);

const getClientId = () => {
  const super_user: any = sessionStorage.getItem("super_user") === 'true'
  if(super_user){
    return sessionStorage.getItem("selectedClientId")
  } else {
    return sessionStorage.getItem("clientId")
  }
}

export const fetchAppListData = async (
  searchText: string,
  page: number,
  hitsPerPage: number,
  filters: string
) => {
  try {
    const appListIndex: any = REACT_APP_BACKEND_MYSQL ? REACT_APP_ALGOLIA_SQL_APP_LIST_INDEX_NAME : REACT_APP_ALGOLIA_APP_LIST_INDEX_NAME;
    const index = searchClient.initIndex(appListIndex);
    await searchClient.clearCache();

    index.setSettings({
      paginationLimitedTo: Number(REACT_APP_ALGOLIA_PAGINATION_LIMIT)
    })

    const client_id = getClientId()

    if(!!filters){
      filters = `${filters} AND client_id:${client_id}`
    } else {
      filters = `client_id:${client_id}`
    }

    const result: any = await index.search(searchText, {
      filters: filters,
      hitsPerPage: hitsPerPage,
      page: page,
    });

    return result;
  } catch (err) {
    console.log(err);
  }
};

export const setSettingsAppListIndex = async (
  sortField: string,
  sortDirection: string
) => {
  const appListIndex: any = REACT_APP_BACKEND_MYSQL ? REACT_APP_ALGOLIA_SQL_APP_LIST_INDEX_NAME : REACT_APP_ALGOLIA_APP_LIST_INDEX_NAME;
  const index = searchClient.initIndex(appListIndex);
  await searchClient.clearCache();
  
  await index
    .setSettings({
      ranking: [`${sortDirection}(${sortField})`],
    })
    .wait();
};

export const fetchAppLinkListData = async () => {
  try {
    const appListIndex: any = REACT_APP_BACKEND_MYSQL ? REACT_APP_ALGOLIA_SQL_APP_LINK_LIST_INDEX_NAME : REACT_APP_ALGOLIA_APP_LINK_LIST_INDEX_NAME;
    const index = searchClient.initIndex(appListIndex);
    await searchClient.clearCache();

    const client_id = getClientId()

    // if(!!filters){
    //   filters = `${filters} AND client_id:${client_id}`
    // }

    const filters = `client_id:${client_id}`

    const result: any = await index.search("", {
      filters: filters,
      hitsPerPage: 10000,
    });

    return result?.hits;
  } catch (err) {
    console.log(err);
  }
};

export const fetchOfficeListData = async (filters?: string) => {
  try {
    const officeListIndex: any = REACT_APP_BACKEND_MYSQL ? REACT_APP_ALGOLIA_SQL_OFFICES_LIST_INDEX_NAME : REACT_APP_ALGOLIA_OFFICES_LIST_INDEX_NAME;
    const index = searchClient.initIndex(officeListIndex);
    await searchClient.clearCache();

    const client_id = getClientId()

    if(!!filters){
      filters = `${filters} AND client_id:${client_id}`
    } else {
      filters = `client_id:${client_id}`
    }

    const result: any = await index.search("", {
      filters: filters || "",
      hitsPerPage: 10000,
    });

    return result?.hits;
  } catch (err) {
    console.log(err);
  }
}; 

export const fetchUsersListData = async (filters?: string) => {
  try {
    const usersListIndex: any = REACT_APP_BACKEND_MYSQL ? REACT_APP_ALGOLIA_SQL_USERS_LIST_INDEX_NAME : REACT_APP_ALGOLIA_USERS_LIST_INDEX_NAME;
    const index = searchClient.initIndex(usersListIndex);
    await searchClient.clearCache();

    const client_id = getClientId()

    if(!!filters){
      filters = `${filters} AND client_id:${client_id}`
    } else {
      filters = `client_id:${client_id}`
    }

    const result: any = await index.search("", {
      filters: filters || "",
      hitsPerPage: 10000,
    });

    return result?.hits;
  } catch (err) {
    console.log(err);
  }
};