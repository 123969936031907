import React, { useEffect,useContext } from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../Common/ProgressLoader";
import { useState } from "react";
import backend from "../../Service/Backend";
import api from "../../Service/Api";
import { emailValidator } from "../../Utils/validators";
import { AppContext } from '../../context/appContext'


interface ResetPasswordModalProps {
  showVerifyEmailModal: boolean
  handleCancel: () => void
  handleOtpVerified: () => void
  setEmail: React.Dispatch<React.SetStateAction<string>>
  email: string
}

let OtpExpireInterval: any = null

const VerifyEmailModal = (props: ResetPasswordModalProps) => {
  const { config }: any = useContext(AppContext);
  const {
    handleCancel,
    showVerifyEmailModal,
    handleOtpVerified,
    email,
    setEmail
  } = props

  const [otp, setOTP] = useState('')
  const [otpSent, setOtpSent] = useState(false);

  const [errorEmail, setErrorEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [loading, setLoading] = useState(false)
  const [otpInvalid, setOtpInvalid] = useState(false)
  const [errMessage, setErrMessage] = useState('')
  const [otpMessage, setOtpMessage] = useState('')
  const [minutes, setMinutes] = useState(4)
  const [seconds, setSeconds] = useState(57)

  const validateEmail = (value: string) => {
    setEmail(value);
    setErrMessage("");
    if (emailValidator(value) !== '') {
      setEmailValid(false)
      setErrorEmail('Invalid email format')
    } else {
      setEmailValid(true)
      setErrorEmail('')
    }
  };

  useEffect(() => {
    if (otpSent) {
      OtpExpireInterval = setInterval(() => {
        setSeconds((prev) => prev - 1)
      }, 1000);
    }
  }, [otpSent])

  useEffect(() => {
    let message = `Temporary password will expire in 0${minutes}:${seconds}`
    if (seconds < 10) message = `Temporary password will expire in 0${minutes}:0${seconds}`
    setOtpMessage(message)
    if (minutes === 0 && seconds === 0) {
      clearInterval(OtpExpireInterval)
      setOtpMessage('')
      setErrMessage('Temporary password expired. Please click Resend to receive a new temporary password')
    }
  }, [seconds, minutes])

  useEffect(() => {
    if (seconds === 0 && minutes > 0 && otpSent) {
      setMinutes((prev) => prev - 1)
      setSeconds(60)
    }
  }, [seconds])



  const handleOtpChange = (value: any) => {
    setOTP(value);
    setErrMessage("");
  }

  useEffect(() => {
    validateEmail(email)
  }, [])

  const sendOTP = async () => {
    setOTP("")
    setErrMessage("")
    setOtpSent(false)
    clearInterval(OtpExpireInterval)
    try {
      setLoading(true)
      const data: any = {
        api: api.users.sendOTP,
        payLoad: JSON.stringify({
          email: email
        })
      }
      await backend.save(data,true);
      setOtpSent(true)
      setMinutes(4)
      setSeconds(57)
      setErrMessage('')
    }
    catch (err: any) {
      console.error(err);
      setEmailValid(false)
      setErrorEmail(err.errMessage)
    }
    finally {
      setLoading(false)
    }
  };

  const verifyOTP = async () => {
    try {
      setLoading(true)
      const data: any = {
        api: api.users.verifyOTP,
        payLoad: JSON.stringify({
          email: email,
          otp: otp
        })
      }
      const response = await backend.save(data,true);
      setOtpInvalid(true)
      if (response && response.status) handleOtpVerified()
      else if (response && !response.status && !response.password_expired) setErrMessage(response.message || 'Invalid temporary password')
      else if (response && !response.status && response.password_expired) setErrMessage('Temporary password expired. Please click Resend to receive a new temporary password')
      else setErrMessage("Something Went Wrong, Please Try Again");
    } catch (err: any) {
      console.error(err);
      setErrMessage(err.errMessage)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      centered
      open={showVerifyEmailModal}
      onClose={() => handleCancel}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <div className="header-resetPassword">
          <h3 className="resetPasswordTtl">Password Reset</h3>
          <div className="close-button-resetPassword">
            <button className="email-activity-close-button" onClick={handleCancel}>X</button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className="verify-email-title">
          <span className=''>Verify your email below so we may send your temporary password.</span>
        </div>
        <ProgressLoader loading={loading} size='small' config={config} />
        <Modal.Description>
          <div className='body-content  min-height-0' >
            {!otpSent &&
              <div className="ui form center display-flex-row-center">
                <div className="display-flex-row-end">
                  <div className='field'>
                    <span><b>Email :</b></span>
                  </div>
                </div>
                <div className="display-flex-row-start">
                  <div className='twelve wide field err-message'>
                    <input
                      type='text'
                      className='form-input-grey test'
                      name='business_email'
                      value={email}
                      maxLength={40}
                      placeholder="Enter Email"
                      autoComplete="off"
                      onChange={(e) => validateEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>}
            {!otpSent && <div className="ui form center display-flex-row-center">
              <div className="display-flex-row-end">
              </div>
              <div className="display-flex-row-start">
                <div className='twelve wide field err-message'>

                  {(!emailValid && email) && (<div className="message word-wrap">{errorEmail}</div>)}
                </div>
              </div>
            </div>}
            {otpSent && <div className="ui form center display-flex-row-center">
              <div className="display-flex-row-end">
              </div>
              <div className="display-flex-row-start">
                <div className='twelve wide field err-message'>
                  <span> Temporary password sent to {email}</span>
                </div>
              </div>
            </div>}
            <br/>
            {otpSent &&
              <div className="ui form center display-flex-row-center">
                <div className="display-flex-row-end">
                  <div className='field'>
                    <span><b>Temporary password :</b></span>
                  </div>
                </div>
                <div className="display-flex-row-start">
                  <div className='twelve wide field err-message'>
                    <input
                      type='text'
                      className='form-input'
                      name='business_email'
                      value={otp}
                      maxLength={6}
                      placeholder="Enter Temporary password"
                      autoComplete="off"
                      onChange={(e) => handleOtpChange(e.target.value.replace(/\D/g, ""))}
                    />
                  </div>
                </div>
              </div>}
              <br/>
            <div className="ui form center display-flex-row-center">
              <div className="display-flex-row-end">
              </div>
              <div className="display-flex-row-start">
                
                  <div className='ten wide field' style= {minutes <1 ? {color: 'red'} : {color: 'green'} }>{otpSent && <span>{otpMessage}</span>}
                  </div>
                  <div className='two wide field'>
                    {otpSent && <span className="section-active-text" style={{cursor:'pointer'}} onClick={sendOTP}>Resend</span>}
                  </div>
              </div>
            </div>
            <div className="ui form center display-flex-row-center">
              {/* <div className="display-flex-row-end">
              </div>
              <div className="display-flex-row-start">
              </div> */}
                <span className='section-error-text'>{errMessage}</span>
            </div>
          </div>
        </Modal.Description>
        <ProgressLoader loading={loading} size='small' config={config}/>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => {
          handleCancel()
          clearInterval(OtpExpireInterval)
        }}>CANCEL</Button>
        {!otpSent &&
          <Button color='blue'
            onClick={sendOTP}
            style={{ background: config?.client_config_fe?.button_color }}
            disabled={!emailValid ? true : false}>
            Reset</Button>}
        {otpSent &&
          <Button
            disabled={otp.length === 6 ? false : true}
            style={{ background: config?.client_config_fe?.button_color }}
            color='blue'
            onClick={verifyOTP}>
            VERIFY
          </Button>}
      </Modal.Actions>
    </Modal>
  )
}

export default VerifyEmailModal;
