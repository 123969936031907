/* API Base and EndPoints */

const baseUrl = process.env.REACT_APP_BASEURL;

/* App Version API's */
const appVersions = {
  get: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/versions",
  },
};

const integrations = {
  sendMail: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/integrations/send_mail",
  },
  validateAddress: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/integrations/validate_address",
  },
  sendToUW: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/integrations/send_to_uw",
  },
  testpubsub: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/integrations/pubsub/publisher",
  },
  sendMailToSecondSigner: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/integrations/send_mail_second_signer",
  },
  getIntegrations: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/integrations/get_all_integrations",
  },
  validateBankDetails: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/integrations/validate_bank_details",
  },
  validateCustomerDetails: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/integrations/validate_customer_details",
  },
  getCreditReportPDF: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/integrations/get_credit_report_pdf",
  },
  getCreditReportXML: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/integrations/get_credit_report_xml",
  },
  createApptivo: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/integrations/create_apptivo",
  },
  sendMailBulk: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/integrations/send_mail_bulk",
  },
  generateUrlIso: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/integrations/generate_url_iso",
  },
};

const merchant = {
  getMerchantAppById: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/merchant/get_merchant_app_by_id",
  },
  updateMerchantApp: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/merchant/update_merchant_app",
  },
  embeddSignMerchantApp: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/merchant/embedd_sign_merchant_app",
  },
  getDocusignAccessToken: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/merchant/docusign_access_token",
  },
  uploadDocument: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/merchant/upload",
  },
  updateMerchantAppDocuments: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/merchant/update_merchant_app_documents",
  },
  getAccessToken: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/merchant/create_token",
  },
  createMerchantApp: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/merchant/save_merchant_app",
  },
  updateMerchantAppMerchantEditFlag: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/merchant/update_merchant_edit_flag",
  },
  updateMerchantAppStatus: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/merchant/update_merchant_app_status",
  },
  // sendMailToRM: {
  //   method: 'POST',
  //   baseEndPoint: '/app',
  //   endPoint: '/merchant/send_mail_to_rm'
  // },
  sendMailToRMPartner: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/merchant/send_mail_to_rm_partner",
  },
  updateEncryptionData: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/merchant/save_encrypted_data",
  },
  verifyToken: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/merchant/verify_token",
  },
  duplicateMerchantApp: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/merchant/duplicate_merchant_app",
  },
  getSignerViewRequest: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/merchant/get_signer_view_request",
  },
  updateMerchantAppSigningStatus: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/merchant/update_merchant_app_signing_status",
  },
  updateMerchantAppStatusBulk: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/merchant/update_merchant_app_status_bulk",
  },
};

const merchantAppData = {
  getMerchantAppById: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/merchantapps/get_merchant_app",
  },
  getParentMerchantAppById: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/merchantapps/get_parent_merchant_app",
  },
  listMerchantApps: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/merchantapps/list_merchant_apps",
  },
  saveMerchantApp: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/merchantapps/save_merchant_app",
  },
  updateMerchantApp: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/merchantapps/update_merchant_app",
  },
  uploadDocument: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/merchantapps/upload",
  },
  updateMerchantAppDocuments: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/merchantapps/update_merchant_app_documents",
  },
  updateMerchantAppStatus: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/merchantapps/update_merchant_app_status",
  },
  duplicateMerchantApp: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/merchantapps/copy_merchant_app",
  },
  updateMerchantAppMiscStatus: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/merchantapps/update_misc_section_status",
  },
  deleteMerchantApp: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/merchantapps/delete_merchant_app",
  },
  getMerchantAppLogsById: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/merchantapps/get_merchant_app_logs",
  },
  updateOfflineAppStatus: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/merchantapps/update_offline_app_status",
  },
  updateSignedAppDocs: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/merchantapps/update_signed_app_documents",
  },
  updateMerchantAppArchivedStatus: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/merchantapps/update_merchant_app_archived_status",
  },
  updateMerchantAppArchivedStatusBulk: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/merchantapps/update_merchant_app_archived_status_bulk",
  },
};

const processorData = {
  getProcessors: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/processor",
  },
  getProcessorsUnprotected: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/processor/merchant",
  },
};

const rm = {
  listPartnersForRM: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/rm/list_partners",
  },
  listPartnersForSA: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/rm/list_partners_all",
  },
};

const partnerUsrRoles = {
  listRmsByPartnerId: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/partner_usr_roles/list_rms_by_partnerid",
  },
};

const businessDetailsDropdownData = {
  getDropdowns: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/business_dropdowns/get_all",
  },
};
const audit = {
  getAllAuditHistory: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/audit/get_all_audit_history",
  },
  getUserAuditHistory: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/audit/get_user_audit_history",
  },
  getOfficeAuditHistory: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/audit/get_office_audit_history",
  },
};
const users = {
  getByUserId: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/users/get_by_user_id",
  },
  getByDefaultRm: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/users/get_by_user_default_rm",
  },
  getByPartnerId: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/users/get_by_partner_id",
  },
  getByGUID: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/users/get_by_guid",
  },
  fetchAllUsers: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/users/get_all_users",
  },
  getRmUsers: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/users/get_rm_users",
  },
  createUser: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/users/create_user",
  },
  updateUser: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/users/update_user",
  },
  deleteUser: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/users/delete_user",
  },
  fetchUsersByRole: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/users/fetch_users_by_role",
  },
  fetchPartnersByOfficeId: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/users/fetch_partners_by_office_id",
  },
  enableIRISAccess: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/users/enable_iris_access",
  },
  enableFeenavigatorAccess: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/users/enable_feenavigator_access",
  },
 enableFreeEquipmentPlacementSubmission: {
    method: 'POST',
    baseEndPoint: '/app',
    endPoint: '/users/enable_free_equipment_placement'
  },

  forgotUserPassword: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/users/forgot_user_password",
  },
  resetUserPassword: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/users/reset_user_password",
  },
  sendOTP: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/users/send_otp",
  },
  verifyOTP: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/users/verify_otp",
  },
  deactivateUser: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/users/deactivate_user",
  },
  activateUser: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/users/activate_user",
  },
  resetUserPasswordBulk: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/users/reset_user_password_bulk",
  },
  deactivateUserBulk: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/users/deactivate_user_bulk",
  },
  activateUserBulk: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/users/activate_user_bulk",
  },
  getOfficeDetails: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/users/get_office_details",
  },
};

const saveLinks = {
  getSaveLinks: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/savelinks/get_all",
  },
  createSaveLink: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/savelinks/create_save_link",
  },
  getSaveLink: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/savelinks/get_one",
  },
  copySaveLink: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/savelinks/copy_save_link",
  },
  updateSaveLink: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/savelinks/update_save_link_status",
  },
  deleteSaveLink: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/savelinks/update_delete_link_status",
  },
  upateSaveLinkPatch: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/savelinks",
  },
  updateNumberOfClicks: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/update_no_of_clicks",
  },
  updateSaveLinkBulk: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/savelinks/update_save_link_status_bulk",
  },
  deleteSaveLinkBulk: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/savelinks/update_delete_link_status_bulk",
  },
};

const lookupData = {
  getAllLookupData: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/lookup_data/get_all",
  },
  getAllLookupDataUnprotected: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/lookup_data/get_all/merchant",
  },
  getLookupDataByType: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/lookup_data/get_by_type",
  },
  postLookupAddNewField: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/lookup_data/add_new_field",
  },
  postLookupUpdateField: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/lookup_data/update_field",
  },
  postLookupDeleteField: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/lookup_data/delete_field",
  },
};

const offices = {
  getAllOffices: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/office/get_all_offices",
  },
  getOfficesByUserId: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/office/get_office_by_office_id",
  },
  getOfficeDetails: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/office/get_office_details",
  },
  createOffice: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/office/create",
  },
  deleteOffice: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/office/delete",
  },
  updateOffice: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/office/update",
  },
  getParentChildOfficesDetailsByOfficeId: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/office/get_parent_child_offices_details",
  },
  enablePaperAppSubmission: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/office/enable_paperapps",
  },
  enableFreeEquipmentPlacementSubmission: {
    method: 'POST',
    baseEndPoint: '/app',
    endPoint: '/office/enable_free_equipment_placement'
  },
  deactivate: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/office/deactivate",
  },
  activate: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/office/activate",
  },
  deactivateOfficeBulk: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/office/deactivate_bulk_office",
  },
  activateOfficeBulk: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/office/activate_bulk_office",
  },
  enablePaperAppBulkSubmission: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/office/enable_bulk_paperapps",
  },
};

const config = {
  getConfigData: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/clients/config",
  },
};

const superuser = {
  getAccessibleClientList: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/client_superuser/access_clients",
  },
  getSuperUserAccess : {
    method: 'POST',
    baseEndPoint: '/app',
    endPoint: '/client_superuser/superuser_access'
  },
  removeSuperUserAccess : {
    method: 'POST',
    baseEndPoint: '/app',
    endPoint: '/client_superuser/remove_superuser_access'
  }  
};

const platformAdmin = {
  getClients: {
    method: "GET",
    baseEndPoint: "/app",
    endPoint: "/clients",
  },
  createClient: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/clients",
  },
  updateClient: {
    method: "PATCH",
    baseEndPoint: "/app",
    endPoint: "/clients",
  },
  activateClient: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/clients/activate",
  },
  deactivateClient: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/clients/deactivate",
  },
  deleteClient: {
    method: "DELETE",
    baseEndPoint: "/app",
    endPoint: "/clients",
  },
  getAccessibleSuperUserList : {
    method: 'GET',
    baseEndPoint: '/app',
    endPoint: '/client_superuser/list_superusers'
  },
  getUsersList: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/client_superuser/list_users_by_role",
  },
  addSuperUserAccess: {
    method: "POST",
    baseEndPoint: "/app",
    endPoint: "/client_superuser/create_superuser_access",
  },
  removeSuperUser : {
    method: 'POST',
    baseEndPoint: '/app',
    endPoint: '/client_superuser/remove_superuser'
  },

}

const api = {
  baseUrl,
  appVersions,
  integrations,
  merchant,
  merchantAppData,
  processorData,
  rm,
  users,
  saveLinks,
  businessDetailsDropdownData,
  lookupData,
  offices,
  audit,
  config,
  partnerUsrRoles,
  superuser,
  platformAdmin
};

export default api;
