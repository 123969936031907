import React, { useEffect, useState, useContext } from 'react'
import Header from '../PageLayout/Header'
import { Footer } from '../PageLayout/Footer'
import { useLocation } from 'react-router-dom'
import api from '../../Service/Api'
import backend from '../../Service/Backend'
import * as Sentry from "@sentry/react";
import ProgressLoader from '../Common/ProgressLoader'
import { Button } from 'semantic-ui-react'
import { AppContext } from '../../context/appContext'


const ThankyouPage = () => {
  const { config }: any = useContext(AppContext);
  
  const tenantId = config.client_auth_tenent_id || ""

  const location = useLocation<any>()
  const [loading, setLoading] = useState(false)
  const [overlay, setOverlay] = useState({ style: { display: 'none' } })
  const [overlayMsg, setOverlaymsg] = useState('')
  const [sentToOwnerOne, setSentToOwnerOne] = useState(location.state.sent_to_owner_one ? true : false)
  const [sentToOwnerTwo, setSentToOwnerTwo] = useState(location.state.sent_to_owner_two ? true : false)
  const [signed, setSigned] = useState(location.state.signed ? true : false)
  const [addLocation, setAddLocation] = useState(false)

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds))
  }
  
  const showOverlayForThreeSeconds = async () => {
    const sstyle: any = { style: { display: 'block', position: 'fixed' } }
    setOverlay(sstyle)
    await sleep(1000)
    const hstyle: any = { style: { display: 'none' } }
    setOverlay(hstyle)
  }

  const fetchDuplicateAppData = async () => {
    try {
      setLoading(true)
      Sentry.configureScope(function (scope) {
        scope.setTag("APP_ID", location.state.Merchant_app_id);
      });
      const tokenData: any = {
        api: api.merchant.duplicateMerchantApp,
        payLoad: JSON.stringify({
          token: location.state.tokenID,
          merchant_app_id: location.state.Merchant_app_id,
          tenantId
        })
      }
      let response = await backend.save(tokenData, true, location.state.tokenID);
      if (response.redirect_url) {
        window.location.href = response.redirect_url
        sessionStorage.setItem('addLocation', 'true');
      }else{
        showOverlayForThreeSeconds()
        setOverlaymsg("Somethong went wrong while duplicating app")
        Sentry.captureMessage("Failed while duplicating App with appID : " + location.state.Merchant_app_id+", Response -->",response);

      }
    }
    catch (err: any) { 
      Sentry.captureMessage("Failed while duplicating App with appID : " + location.state.Merchant_app_id + ", Error message -->" + err?.message || "");
      Sentry.captureException(err)
    }
    finally {
        setLoading(false);
        setAddLocation(true);
    }
  }

  return (
    <div>
      <Header page='thankspage' />
      <div>
        <div className='thankyou-body-content'>
          <ProgressLoader loading={loading} size='large' config={config}/>
          <div className='thankyou-page-container'>
            { sentToOwnerOne && <div className="thankyou-text">Successfully sent application to <b>{location.state.ownerOneName}</b> at ( {location.state.ownerOneEmail} ). After signature, we will contact you if any additional information is required.</div>}
            {
              sentToOwnerTwo &&
              <div className="thankyou-text">Successfully sent application to <b>{location.state.ownerTwoName}</b> at ( {location.state.ownerTwoEmail} ) for final signature.</div>
            }
            { location.state.tokenID && 
            <>
              <div className="thankyou-text">Thank you for your application! We will contact you if any additional information is required.</div>
              <div className='button_additiona_location'>
              <Button className='additiona_location' onClick={fetchDuplicateAppData}  style={{ background: config?.client_config_fe?.button_color}}> Additional Location / URL</Button> </div>
              <div className="text-duplicate">Submitting another location / URL under the same Corp and Owner?</div>
              <div className="text-duplicate">Click "Additional Location / URL" above to access your pre-filled app. </div>
            </>}
            {
              signed &&
              <div className="thankyou-text">Thank you for your application! We will contact you if any additional information is required.</div>
            }
            <div className='overlay' style={overlay.style}>
              <div className='overlay-image'>
                <span className='overlay-text' style={{ color: config?.client_config_fe?.button_color }}>{overlayMsg}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer page='thankspage' />
    </div>
  )
}

export default ThankyouPage
