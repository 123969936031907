import React, { useEffect, useState } from "react";
import { Checkbox, CheckboxProps,Segment, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";
import Select from 'react-select'
import { AllowedRolesListByOfficeAdmin, AllowedRolesListByRole, AllowedRolesListByRoleInTenant, AllowedRolesListBySuperuserOfficeManager, RISK_LEVELS, ROLES } from "../../../Utils/constants";
import BasicButton from '../../Common/Button'

const {
  REACT_APP_BACKEND_MYSQL,
  REACT_APP_DEFAULT_CLIENT_ID
} = process.env;
interface CreateUserProps {
    showCreateUserModal: boolean
    firstName: string
    lastName: string
    email: string
    emailValid: boolean
    emailError: string
    selectedRole: any
    irisEnabled: boolean
    feenavigatorEnabled:boolean
    role: string | null
    handleCancel: () => void
    officeAdminCreateRoles: {
        value: string;
        label: string;
    }[]
    officeManagerCreateRoles: {
        value: string;
        label: string;
    }[]
    loading: boolean
    superAdminCreateRoles: {
        value: string;
        label: string;
    }[]
    edit: boolean
    handleSelectedRole: (item: any) => void
    handleSelectedOffice: (item: any) => void
    handleSelectedRM: (item: any) => void
    handleSelectRiskLevel: (selectedRiskLevel: any) => void
    handleSaveUser: () => Promise<void>
    emailValidation: (value: any) => void
    selectedRiskLevel: any[]
    editRoles: {
        value: string;
        label: string;
    }[]
    selectedRM: any[]
    selectedOffice: any
    officesDropDown: any[]
    rmUsers: any[]
    riskLevels: any[]
    userCreationError: string
    createUserEnabled: boolean
    setLastName: (value: React.SetStateAction<string>) => void
    setFirstName: (value: React.SetStateAction<string>) => void
    setIrisEnabled: React.Dispatch<React.SetStateAction<boolean>>
    setFeenavigatorEnabled: React.Dispatch<React.SetStateAction<boolean>>
    freeEquipmentPlacementEnabled: boolean
    setFreeEquipmentPlacementEnabled: React.Dispatch<React.SetStateAction<boolean>>
    handleSelectedLowRiskProcessors: (value: any) => void
    handleSelectedHighRiskProcessors: (value: any) => void
    selectedLowRiskProcessors: any[]
    selectedHighRiskProcessors: any[]
    highRiskProcessors: any[]
    lowRiskProcessors: any[]
    fetchingOffices: boolean
    fetchingRms: boolean
    fetchingProcessors: boolean
    relationshipManagerCreateRoles: {
      value: string;
      label: string;
  }[]
  relationshipManagerCreateRolesEdit: {
    value: string;
    label: string;
}[]
  config:any
}

const CreateUser = (props: CreateUserProps) => {

    const { handleCancel,
        showCreateUserModal,
        firstName,
        lastName,
        email,
        emailValid,
        emailError,
        selectedRole,
        irisEnabled,
        feenavigatorEnabled,
        role,
        officeAdminCreateRoles,
        officeManagerCreateRoles,
        loading,
        superAdminCreateRoles,
        edit,
        handleSelectedRole,
        editRoles,
        selectedRM,
        selectedOffice,
        officesDropDown,
        handleSelectedOffice,
        handleSelectedRM,
        rmUsers,
        handleSelectRiskLevel,
        selectedRiskLevel,
        riskLevels,
        userCreationError,
        createUserEnabled,
        handleSaveUser,
        setLastName,
        emailValidation,
        setFirstName,
        setIrisEnabled,
        setFeenavigatorEnabled,
        handleSelectedLowRiskProcessors,
        handleSelectedHighRiskProcessors,
        selectedLowRiskProcessors,
        selectedHighRiskProcessors,
        highRiskProcessors,
        lowRiskProcessors,
        fetchingOffices,
        fetchingRms,
        fetchingProcessors,
        relationshipManagerCreateRoles,
        relationshipManagerCreateRolesEdit,
        freeEquipmentPlacementEnabled,
        setFreeEquipmentPlacementEnabled,
        config
    } = props

    const super_user: any = sessionStorage.getItem("super_user") === 'true'
    const selected_client_id : any = sessionStorage.getItem("selectedClientId") 
    const office_level : any = sessionStorage.getItem("office_level") 
    const [ checkSelectedLowRisk, setCheckSelectedLowRisk ] = useState(selectedRiskLevel?.filter((risklevel: any)=> risklevel.value === RISK_LEVELS.LOW_RISK))
    const [ checkSelectedHighRisk, setCheckSelectedHighRisk ] = useState(selectedRiskLevel?.filter((risklevel: any)=> risklevel.value === RISK_LEVELS.HIGH_RISK))

    useEffect(()=>{
      setCheckSelectedLowRisk(selectedRiskLevel?.filter((risklevel: any)=> risklevel.value === RISK_LEVELS.LOW_RISK))
      setCheckSelectedHighRisk(selectedRiskLevel?.filter((risklevel: any)=> risklevel.value === RISK_LEVELS.HIGH_RISK))
    },[selectedRiskLevel])
    const getRoleDropDown = ()=>{
      let roles = []
      if(role === ROLES.OFFICE_ADMIN && office_level == '1'){
        roles = AllowedRolesListByOfficeAdmin
      }else if(role === ROLES.OFFICE_MANAGER && super_user){
        roles = AllowedRolesListBySuperuserOfficeManager
      }else if(REACT_APP_BACKEND_MYSQL && REACT_APP_DEFAULT_CLIENT_ID === selected_client_id && super_user){
        roles = AllowedRolesListByRole[ROLES.SUPER_ADMIN]
      }else if(REACT_APP_BACKEND_MYSQL && REACT_APP_DEFAULT_CLIENT_ID === selected_client_id ){
        roles = role?AllowedRolesListByRole[role]:[]
      }else if(REACT_APP_BACKEND_MYSQL){
        roles = role?AllowedRolesListByRoleInTenant[role]:[]
      }else{
        roles = role?AllowedRolesListByRole[role]:[]
      }
      return roles;
    }
    return (
      <Modal
        centered
        open={showCreateUserModal}
        onClose={handleCancel}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div className="user-form create-edit-user">
            <div className="close-button">
              <button
                className="email-activity-close-button"
                onClick={handleCancel}
              >
                X
              </button>
            </div>
            <form className="ui form">
              <div className="fields">
                <div className="four wide field">
                  <p>
                    First name<span className="required-text"> *</span> :
                  </p>
                </div>
                <div className="six wide field">
                  <div className="ui input">
                    <input
                      className="form-input"
                      type="text"
                      value={firstName}
                      placeholder="First name"
                      onChange={(e) =>{
                        if(e.target.value===" ")
                        setFirstName("")
                        else
                        setFirstName(e.target.value)}}
                    />
                  </div>
                </div>
              </div>
              <div className="fields">
                <div className="four wide field">
                  <p>
                    Last name<span className="required-text"> *</span> :
                  </p>
                </div>
                <div className="six wide field">
                  <div className="ui input">
                    <input
                      className="form-input"
                      type="text"
                      value={lastName}
                      onChange={(e) =>{
                        if(e.target.value===" ")
                        setLastName("")  
                        else
                        setLastName(e.target.value)}}
                      placeholder="Last name"
                    />
                  </div>
                </div>
              </div>
              <div className="fields">
                <div className="four wide field">
                  <p>
                    Email<span className="required-text"> *</span> :
                  </p>
                </div>
                <div className="six wide field">
                  <div className="ui input">
                    <input
                      className="form-input"
                      type="text"
                      value={email}
                      onChange={(e) => emailValidation(e.target.value)}
                      placeholder="e-mail"
                    />
                  </div>
                </div>
              </div>
              { email && !emailValid && emailError && (<div className="fields">
                <div className="four wide field"></div>
                <div className="six wide field">
                  <span style={{ color: "#80000d" }}>{emailError}</span> 
                </div>
              </div>)}
              {!edit && (role === ROLES.SUPER_ADMIN || super_user) &&  
                <div className="fields fn-checkbox-wrapper">
                  <div className="four wide field">
                    <p>Enable STMT Analysis :</p>
                  </div>
                  <div className="six wide field fn-checkbox">
                    <Checkbox
                      name="enableFeenavigator"
                      checked={feenavigatorEnabled}
                      onChange={(e, data: CheckboxProps) =>
                        setFeenavigatorEnabled(data.checked || false)
                      }
                      disabled={edit}
                    />
                  </div>
                </div>
              }
              <div className="fields">
                <div className="four wide field">
                  <p>
                    Select Role<span className="required-text"> *</span> :
                  </p>
                </div>
                <div className="six wide field">
                  {!edit && (
                    <Select
                      isMulti={false}
                      value={Object.keys(selectedRole).length === 0 ? '' : selectedRole}
                      options={getRoleDropDown()}
                      onChange={handleSelectedRole}
                      placeholder="Select Role"
                      maxMenuHeight={160}
                    />
                  )}
                  {selectedRole?.value === ROLES.RELATIONSHIP_MANAGER &&
                    edit && (
                      <Select
                        isMulti={false}
                        value={Object.keys(selectedRole).length === 0 ? '' : selectedRole}
                        isDisabled={true}
                        onChange={handleSelectedRole}
                        placeholder="Select Role"
                        maxMenuHeight={160}
                      />
                    )}
                  {selectedRole?.value !== ROLES.RELATIONSHIP_MANAGER &&
                    edit && (
                      <Select
                        isMulti={false}
                        value={Object.keys(selectedRole).length === 0 ? '' : selectedRole}
                        options={getRoleDropDown()}
                        onChange={handleSelectedRole}
                        placeholder="Select Role"
                        maxMenuHeight={160}
                      />
                    )}
                </div>
              </div>

              {(selectedRole.value && selectedRole.value !== '' && selectedRole.value !== ROLES.RELATIONSHIP_MANAGER) && (
                <div className="fields">
                  <div className="four wide field">
                    <p>
                      Select Office<span className="required-text"> *</span> :
                    </p>
                  </div>
                  <div className="six wide field">
                    <Select
                      isMulti={false}
                      value={Object.keys(selectedOffice).length === 0 ? '' : selectedOffice}
                      options={officesDropDown}
                      onChange={handleSelectedOffice}
                      placeholder="Select Office"
                      maxMenuHeight={200}
                    />
                  </div>
                </div>
              )}

              {selectedRole?.value !== ROLES.RELATIONSHIP_MANAGER && 
                selectedOffice &&
                selectedOffice?.value && (
                  <div className="fields">
                    <div className="four wide field">
                      <p>Select RM's :</p>
                    </div>
                    <div className="six wide field">
                      <Select
                        isMulti={true}
                        value={selectedRM}
                        options={rmUsers}
                        onChange={handleSelectedRM}
                        placeholder="Select RM"
                        maxMenuHeight={160}
                      />
                    </div>
                  </div>
                )}
              {selectedRole?.value !== ROLES.RELATIONSHIP_MANAGER &&
                selectedOffice &&
                selectedOffice?.value && (
                  <div className="fields">
                    <div className="four wide field">
                      <p>Select Risk Levels :</p>
                    </div>
                    <div className="six wide field">
                      <Select
                        isMulti={true}
                        onChange={handleSelectRiskLevel}
                        value={selectedRiskLevel}
                        options={riskLevels}
                        placeholder="Select Risk Levels"
                        maxMenuHeight={160}
                      />
                    </div>
                  </div>
                )}
              { selectedRole?.value !== ROLES.RELATIONSHIP_MANAGER && checkSelectedLowRisk && checkSelectedLowRisk?.length > 0 && 
                <div className="fields">
                  <div className="four wide field">
                      <p>Select <b>Low Risk</b> Banks :</p>
                  </div>
                  <div className="six wide field">
                      <Select
                          isMulti={true}
                          options={lowRiskProcessors}
                          value={selectedLowRiskProcessors}
                          onChange={handleSelectedLowRiskProcessors}
                          placeholder='Select Bank'
                          maxMenuHeight={200}
                      />
                  </div>
                </div>}
              { checkSelectedHighRisk && checkSelectedHighRisk?.length > 0 && 
                <div className="fields">
                  <div className="four wide field">
                      <p>Select <b>High Risk</b> Banks :</p>
                  </div>
                  <div className="six wide field">
                      <Select
                          isMulti={true}
                          options={highRiskProcessors}
                          value={selectedHighRiskProcessors}
                          onChange={handleSelectedHighRiskProcessors}
                          placeholder='Select Bank'
                          maxMenuHeight={160}
                      />
                  </div>
                </div>}
                {(!edit && (role === ROLES.SUPER_ADMIN || role === ROLES.OFFICE_ADMIN || role === ROLES.OFFICE_MANAGER || role === ROLES.RELATIONSHIP_MANAGER))
                &&
                <div className="fields fn-checkbox-wrapper">
                  <div className="four wide field">
                    <p>Free Equipment Placement:</p>
                  </div>
                  <div className="six wide field fn-checkbox">
                    <Checkbox
                    disabled={!(selectedOffice?.free_equipment_placement_enabled || selectedRole.value === ROLES.RELATIONSHIP_MANAGER)}
                      name="free equipment placement"
                      checked={freeEquipmentPlacementEnabled}
                      onChange={(e, data: CheckboxProps) =>
                        setFreeEquipmentPlacementEnabled(data.checked || false)
                      }
                      
                    />
                  </div>
                </div>
              }
              <div className="fields">
                <div className="four wide field"></div>
                <div className="six wide field">
                  <span style={{ color: "#80000d" }}>{userCreationError}</span>
                </div>
              </div>
            </form>
          </div>
          <ProgressLoader loading={fetchingOffices || fetchingProcessors || fetchingRms || loading} size="small" config={config}/>
        </Modal.Content>
        <Modal.Actions>
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText="CANCEL"
            className="save-button button-border"
            onClick={handleCancel}
          />
          <button
            className={
              createUserEnabled ? "save-button button-border" : "not-active save-button button-border"
            }
            style={{ background: config?.client_config_fe?.button_color }}
            onClick={handleSaveUser}
          >
            {edit ? "UPDATE USER" : "CREATE USER"}
          </button>
        </Modal.Actions>
      </Modal>
    );
}

export default CreateUser;
