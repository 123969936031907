import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import React, { useEffect, useState ,useContext} from "react";
import { AppContext } from "../../../context/appContext";
import "./styles.css";

const ButtonScrollTop = () => {
  const { config }: any = useContext(AppContext);
  const [fabVisible, setFabVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setFabVisible(true);
    } else {
      setFabVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <button
      className={`fab ${fabVisible ? "visible" : ""}`}
      onClick={scrollToTop}
      style={{ background: config?.client_config_fe?.button_color,border:'1px' }}
    >
      <ArrowUpwardIcon />
    </button>
  );
};

export default ButtonScrollTop;