import React, { useContext, useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import ProgressLoader from "../Common/ProgressLoader";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { auth } from "../../Firebase";
import { PAGES, ROLES } from "../../Utils/constants";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import { Layout } from "../PageLayout/Layout";
import BuggyComponent from "../App/BuggyComponent";
import ResetPasswordModal from "../Pages/UsersList/ResetPasswordModal";
import { generatePassword, get, getParsedList } from '../../Utils/helpers'
import VerifyEmailModal from "./VerifyEmailModal";
import { emailValidator, passwordValidator } from "../../Utils/validators";
import { AppContext } from "../../context/appContext";
import { UserCredential, User } from 'firebase/auth'

interface Props extends RouteComponentProps { }

const { REACT_APP_BACKEND_MYSQL } = process.env;

const Login = (props: Props) => {
  const history = useHistory();
  const { config, setConfig }: any = useContext(AppContext);
  const tenantId = config.client_auth_tenent_id || ""

  const [errorMessage, setErrorMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [loginloading, setLoginLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [eyeIcon, setEyeIcon] = useState(false);
  const [modalForgetpassword, setModalForgetpassword] = useState(false)
  const [overlay, setOverlay] = useState({ style: { display: "none" } })
  const [overlayMsg, setOverlaymsg] = useState("")
  const [modalTitle, setModalTitle] = useState("Password Reset")
  const [generatedPassword, setGeneratedPassword] = useState("")
  const [errResponse, setErrResponse] = useState(false)
  const [errMsgResp, setErrmsgResp] = useState("")
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false)

  const handleCancel = () => {
    setModalForgetpassword(false)
    setGeneratedPassword("")
    setErrResponse(false)
    setShowVerifyEmailModal(false)
    setEmail('')
    setPassword('')
  }
  const handleGeneratePassword = async () => {
    setGeneratedPassword(generatePassword(16))
  }
  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const showOverlayForThreeSeconds = async () => {
    const sstyle: any = { style: { display: 'block', position: 'fixed' } };
    setOverlay(sstyle);
    await sleep(2000);
    const hstyle: any = { style: { display: "none" } };
    setOverlay(hstyle);
  };

  const setNewPassword = async (email: string, password: string) => {
    try {
      setLoginLoading(true)
      const Data: any = {
        api: api.users.forgotUserPassword,
        payLoad: JSON.stringify({
          email: email,
          new_password: password,
          tenantid: tenantId
        })
      }
      let response = await backend.save(Data,true);
      console.log(response)
      setOverlaymsg('Password sent to your email successfully!')
      showOverlayForThreeSeconds()
      setModalForgetpassword(false)
      setEmail("")
      setPassword("")
      setErrorMessage("")
      setEmailValid(true)
      setPasswordValid(true)
      setGeneratedPassword("")
      setErrResponse(false)
    }
    catch (err: any) {
      setErrResponse(true)
      setErrmsgResp(err.errMessage)
    }
    finally {
      setLoginLoading(false)
    }
  }
  const resetErrResponse = async () => {
    setErrResponse(false)
    setErrmsgResp("")
  }

  const validateEmail = (event: any) => {
    setEmail(event.target.value);
    const validateEmail = event.target.value;
    if (!validateEmail) {
      setEmailValid(false);
      setErrorEmail("Please enter email");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(validateEmail)) {
      setEmailValid(false);
      setErrorEmail("Invalid email format");
    } else {
      setEmailValid(true);
      setErrorEmail("");
    }
  };

  const validatePassword = (event: any) => {
    setPassword(event.target.value);
    const validatePassword = event.target.value;
    if (!validatePassword) {
      setPasswordValid(false);
      setErrorPassword("Please enter Password");
      setErrorMessage("")
    } else {
      setPasswordValid(true);
      setErrorPassword("");
    }
  };

  const fetchOfficeDetails = async () => {
    let office_id = sessionStorage.getItem('office_id')
    if (!office_id) {
      setErrorMessage("Unauthorised!");
      throw new Error("Unauthorised!");
    }
    const data = {
      api: api.offices.getOfficeDetails,
      queryParam: { office_id: office_id }
    };
    const profiledata = await backend.fetch(data);
    console.log(profiledata)
    sessionStorage.setItem('office_level', profiledata.office_level)
    sessionStorage.setItem('paper_apps_enabled', profiledata?.paper_apps_enabled || false)
    return profiledata
  }

  const checkSuperUserAccess = async (user_id: any, role_id: any) => {
    const data: any = {
      api: api.superuser.getAccessibleClientList,
      payLoad: JSON.stringify({ user_id, role_id})
    };
    try {
      console.log(data)
      const response = await backend.save(data);
      let accessible_clients: any = []
      if (response) {
        console.log("Accessible Client List", response);
        //Construct the dropdown
        if (get(response, "accessible_clients", []).length > 0) {
          //mapping the other clients
          accessible_clients = get(response, "accessible_clients", []).map((client: any) => {
            if (client.client_deleted || client.client_deactivate) {
              return null; // Skip clients marked as deleted
            }
            return {
              // "user_roles_id": client?.user_roles_id,
              "client_id": client?.access_client_id,
              "client_code": client?.client_code,
              "client_name": client?.client_name,
              "client_auth_tenent_id": client?.client_auth_tenent_id,
            };
          }).filter((client: any) => client !== null); // Filter out null values
        }

          console.log('config', config)
          //adding first client
          accessible_clients = [
            ...accessible_clients,
            {
              // "user_roles_id": accessible_clients[0]?.user_roles_id,
              "client_id": config?.id,
              "client_code": config?.client_code,
              "client_name": config?.client_name,
              "client_auth_tenent_id": config?.client_auth_tenent_id,
            }
          ]

          console.log('accessible_clients', accessible_clients)
      
          sessionStorage.setItem("accessible_clients", JSON.stringify(accessible_clients))
          sessionStorage.setItem("user_roles_id", get(response, "accessible_clients", [])[0]?.user_roles_id||response?.user_role_id)
          sessionStorage.setItem("selectedClientId", config?.id)

          setConfig({
            ...config,
            accessible_clients,
            user_roles_id : get(response, "accessible_clients", [])[0]?.user_roles_id||response?.user_role_id,
            selectedClientId: config?.id,
            default_config_fe: config?.client_config_fe
          })
      }
    } catch (err: any) {
      console.log(err)
      return err;
    }
  }

  const handleLogin = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      const emailError = emailValidator(email);
      const passwordError = passwordValidator(password)
      if (emailError !== '' || passwordError !== '') {
        setPasswordValid(false)
        setEmailValid(false)
        setErrorPassword(passwordError);
        setErrorEmail(emailError)
        return;
      }

      setErrorMessage("");
      setLoginLoading(true);

      const userCredentials: UserCredential = await auth.doSignInWithEmailAndPassword(
        email,
        password,
        tenantId
      );

      console.log("userCredentials: ", userCredentials)

      if (!userCredentials) {
        setErrorMessage("Error while login. Please check your credentials");
        throw new Error("Error while login. Please check your credentials");
      }

      if (userCredentials && userCredentials.user) {
        console.log("login successful!!", userCredentials);

        const user: User = userCredentials.user;
        const { uid } = user;

        const data = {
          api: api.users.getByGUID,
          urlParam: uid,
        };
        const profiledata = await backend.fetch(data);
        console.log("profiledata: ", profiledata)

        const role = REACT_APP_BACKEND_MYSQL ? profiledata.role_code : profiledata.roles[0]
        const first_name = REACT_APP_BACKEND_MYSQL ? profiledata.user_name_first : profiledata?.name?.first_name
        const last_name = REACT_APP_BACKEND_MYSQL ? profiledata.user_name_last : profiledata?.name?.last_name
        const guid =  REACT_APP_BACKEND_MYSQL ? profiledata.auth_user_id : profiledata?.guid

        if (profiledata && role && (profiledata.deleted !== 1)) {
          if((profiledata.deactivate !== 1)){
            const { client_id, user_id, role_id, office_id, deactivate } = profiledata;
            let { iris_enabled, feenavigator_enabled } = profiledata;

            iris_enabled = REACT_APP_BACKEND_MYSQL ? Number(iris_enabled) : iris_enabled;
            feenavigator_enabled = REACT_APP_BACKEND_MYSQL ? Number(feenavigator_enabled) : feenavigator_enabled;

            const allowed_low_risk_processors = getParsedList(profiledata.allowed_low_risk_processors)
            const allowed_high_risk_processors = getParsedList(profiledata.allowed_high_risk_processors)
            const allowed_risk_levels = getParsedList(profiledata.allowed_risk_levels)

            sessionStorage.setItem("user_id", user_id);
            sessionStorage.setItem("user_name", first_name + ' ' + last_name);
            sessionStorage.setItem("user_guid", guid);
            sessionStorage.setItem("role", role);
            sessionStorage.setItem("email", REACT_APP_BACKEND_MYSQL ? profiledata.user_email : profiledata.email);
            sessionStorage.setItem("office_id", office_id || '');
            sessionStorage.setItem("iris_enabled", iris_enabled.toString() || false);
            sessionStorage.setItem("feenavigator_enabled", feenavigator_enabled.toString() || false);
            sessionStorage.setItem("deactivate", deactivate || false);

            //Check if user is a superuser for other clients
            console.log(client_id)
            console.log(role)
            if (client_id && client_id === 1) {// Trinity user
              if (role === ROLES.SUPER_ADMIN || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_ADMIN
                || role === ROLES.OFFICE_MANAGER || role === ROLES.UNDER_WRITER) { // Allowed Roles for Superuser
                  console.log('** checkSuperUserAccess')
                  await checkSuperUserAccess(user_id, role_id)
                }
            }

            if (allowed_low_risk_processors && allowed_low_risk_processors.length > 0) {
              sessionStorage.setItem("allowed_low_risk_processors", allowed_low_risk_processors.join(','));
            }
            if (allowed_high_risk_processors && allowed_high_risk_processors.length > 0) {
              sessionStorage.setItem("allowed_high_risk_processors", allowed_high_risk_processors.join(','));
            }
            if (allowed_risk_levels && allowed_risk_levels.length > 0) {
              sessionStorage.setItem("allowed_risk_levels", allowed_risk_levels.join(','));
            }

            if (role !== ROLES.RELATIONSHIP_MANAGER) {
              const { partner_id } = profiledata;
              const partner_name = REACT_APP_BACKEND_MYSQL ? `${profiledata.user_name_first} ${profiledata.user_name_last}` : profiledata.partner_name
              sessionStorage.setItem("partner_id", partner_id);
              sessionStorage.setItem("partner_name", partner_name);
            }

            if (role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.PARTNER || role === ROLES.VIEW_ONLY || role === ROLES.SALES_LIMITED || role === ROLES.UNDER_WRITER) {
              const officeData = await fetchOfficeDetails()
              if (role === ROLES.OFFICE_ADMIN && !officeData.deleted && !officeData.deactivate && officeData.office_admin_ids.includes(user_id)) {
                history.push("/listapp");
              } else if (role === ROLES.OFFICE_MANAGER && !officeData.deleted && !officeData.deactivate && officeData.office_manager_ids.includes(user_id)) {
                history.push("/listapp");
              } else if ((role === ROLES.VIEW_ONLY || role === ROLES.SALES_LIMITED || role === ROLES.PARTNER || role === ROLES.UNDER_WRITER) && !officeData.deleted && !officeData.deactivate) {
                history.push("/listapp");
              } else {
                setErrorMessage("Unauthorised!");
                throw new Error("Unauthorised!");
              }
            } else if (role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.SUPER_ADMIN) {
              history.push("/listapp");
            }
          } else {
            setErrorMessage("User has been deactivated");
           // throw new Error("User has been deactivated");
          }
        } else {
          // history.push("/unauthorised");
          setErrorMessage("Unauthorised!");
          //throw new Error("Unauthorised!");
        }
      } else {
        setErrorMessage("Error while login");
        throw new Error("Error while login");
      }
    } catch (err: any) {
      console.error(err);
      let errorMessage = "Error while login";
      if (email === "" || password === "") {
        setErrorMessage("Above fields should not be empty");
        setErrorPassword("")
        setErrorEmail("")
      }
      else if(err.code && err.code === "auth/wrong-password") {
        setErrorMessage("The password is invalid or no account exists for the email entered.");
      }
      else if (err.code && err.code === "auth/user-not-found") {
        setErrorMessage("No user exist with corresponding email");
      }
      else {
        setErrorMessage(errorMessage)
      }
    } finally {
      setLoginLoading(false);
    }
  };

  const togglePasswordVisiblity = () => {
    setEyeIcon(!eyeIcon);
    setPasswordShown(!passwordShown);
  };

  const handleOtpVerified = () => {
    setShowVerifyEmailModal(false)
    setModalForgetpassword(true)
  }

  return (
    <Layout page={PAGES.LOGIN_PAGE}>
      <div className="overlay" style={overlay.style}>
        <div className="overlay-image">
          <span className="overlay-text" style={{ color: config?.client_config_fe?.button_color }}>{overlayMsg}</span>
        </div>
      </div>
      <ProgressLoader loading={loginloading} size="large" config={config} />
      <div className="login-container" style={{background: `transparent url(${config?.client_config_fe?.login_bg_image})`}}>
        <div className="login-content">
          {/* <BuggyComponent /> */}
          <div className="login-heading">
            <div className="login-heading-blue" ><span style={{ color: config?.client_config_fe?.button_color }}>360&deg;</span></div>
            <div className="login-heading-blue-smart"><span style={{ color: config?.client_config_fe?.button_color }}>Of Smart</span></div>
            <div className="login-heading-white"><span style={{ color: config?.client_config_fe?.button_color }}>Welcome To <span style={{color: config?.client_config_fe?.button_color,textTransform: 'capitalize'}}>{config.client_name}</span></span></div>
            {/* <div className="login-heading-blue">LOG IN</div> */}
          </div>

          <div className="login-form-content">
            <form className="ui form">
              {" "}
              {/* Semantic UI form */}
              <div className="form-field">
                <input
                  className={
                    emailValid === false
                      ? "form-input-login error-border"
                      : "form-input-login"
                  }
                  type="text"
                  name="email"
                  value={email}
                  onChange={validateEmail}
                  maxLength={40}
                  placeholder="LOGIN ID"
                />
                {emailValid === false && (
                  <span className="error-message">{errorEmail}</span>
                )}
              </div>
              <div className="form-field">
                <input
                  className={
                    passwordValid === false
                      ? "form-input-login error-border"
                      : "form-input-login"
                  }
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={validatePassword}
                  placeholder="PASSWORD"
                />
                <Icon
                  name={eyeIcon ? "eye slash" : "eye"}
                  size="large"
                  className="eye-icon-pwd"
                  onClick={togglePasswordVisiblity}
                />
              </div>
              {passwordValid === false && (
                  <span className="error-message">{errorPassword}</span>
                )}
              {errorMessage && (
                <>
                  <div className="flex">
                    <span className="alert-icon" />
                    <span className="error-message alert-text-message">
                      {errorMessage}
                    </span>
                  </div>
                </>
              )}
              <div className="form-field"><a onClick={(event) => setShowVerifyEmailModal(true)} style={{ color: config?.client_config_fe?.button_color }}>Forgot Password?</a></div>
              <button className="auth-button" onClick={handleLogin} style={{ backgroundColor: config?.client_config_fe?.button_color,border:`1px solid ${config?.client_config_fe?.button_color}` }}>
                Login
              </button>

            </form>
          </div>
        </div>
      </div>
      {modalForgetpassword &&
        <ResetPasswordModal
          business_email={email}
          loading={loginloading}
          modalForgetpassword={modalForgetpassword}
          handleCancel={handleCancel}
          setNewPassword={setNewPassword}
          modalTitle={modalTitle}
          handleGeneratePassword={handleGeneratePassword}
          generatedPassword={generatedPassword}
          errMsgResp={errMsgResp}
          errResponse={errResponse}
          resetErrResponse={resetErrResponse}
        />}
      {showVerifyEmailModal &&
        <VerifyEmailModal
          setEmail={setEmail}
          email={email}
          showVerifyEmailModal={showVerifyEmailModal}
          handleCancel={handleCancel}
          handleOtpVerified={handleOtpVerified}

        />}
    </Layout>
  );
};

export default Login;
