import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import api from '../../../Service/Api';
import backend from '../../../Service/Backend';
import { MERCHANT_APP_STATUS } from '../../../Utils/constants';
import ProgressLoader from '../../Common/ProgressLoader';
import { Footer } from '../../PageLayout/Footer';
import Header from '../../PageLayout/Header';
import { auth } from '../../../Firebase/firebase';
import { signInWithCustomToken, signOut } from 'firebase/auth'
import {
  Button,
  Modal,
} from 'semantic-ui-react'
import { AppContext } from '../../../context/appContext';

function UpdateSigningStatus() {
  const history = useHistory();

  const { config, setConfig }: any = useContext(AppContext);
  const tenantId = config.client_auth_tenent_id || ""

  const [loading, setLoading] = useState(false)
  const [overlay, setOverlay] = useState({ style: { display: 'none' } })
  const [overlayMsg, setOverlaymsg] = useState('')
  const [displyMessage, setDisplyMessage] = useState('')
  const [signingCompleted, setSigningCompleted] = useState(false)
  const [merchantAppID, setMerchantAppID] = useState('')
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false)

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds))
  }

  const showOverlayForThreeSeconds = async () => {
    const sstyle: any = { style: { display: 'block' } }
    setOverlay(sstyle)
    await sleep(2000)
    const hstyle: any = { style: { display: 'none' } }
    setOverlay(hstyle)
  }

  const updateMerchantApp = async () => {
    setLoading(true)
    const params = new URLSearchParams(history.location.search)
    const envelopId: any = params.get('envid')
    const merchantAppId: any = params.get('appid')
    const event: any = params.get('event')
    setMerchantAppID(merchantAppId)
    console.log('envelopId :>> ', envelopId);
    console.log('merchantAppId :>> ', merchantAppId);
    window.scroll({ top: 0, left: 0 });
    if( envelopId && merchantAppId ){
      const data: any = {
        api: api.merchant.updateMerchantAppSigningStatus,
        payLoad: JSON.stringify({
            "envelop_id":envelopId,
            "merchant_app_id":merchantAppId
        })
      }
      try {
        //handling signing event
        if(event !== 'signing_complete'){
          let message = 'Document signing was not completed. Please complete signing process to proceed...'
          throw { errMessage : message }
        }
        //updated
        const response = await backend.save(data,true, sessionStorage.merchant_token)
        console.log('response', response)

        setLoading(false)
        setOverlaymsg("Thank you for signing the appication")
        showOverlayForThreeSeconds()
        history.replace('/thankspage', { signed: true })
      }catch(err: any){
        setLoading(false)
        setOverlaymsg(err.errMessage || "Something went wrong while update status!")
        setDisplyMessage(err.errMessage || "Something went wrong while update status!")
        showOverlayForThreeSeconds()
      }
    }else{
      setLoading(false)
      setOverlaymsg('Your application has been sent to owners for signing.')
      showOverlayForThreeSeconds()
      setDisplyMessage('Thank you for applying to Trinity Payments. Your application is in process...')
    }
  }

  const fetchSigningURL = async (envelope_id: string, token: string, merchant_app_id: string) => {
    setLoading(true)
    try{
      const merchant_token = await merchantLogin(token)
      if(!merchant_token) throw {errMessage: 'Merchant authentication fiald'}
      let data: any = {
        api: api.merchant.getMerchantAppById
      }
      data.queryParam = { merchant_app_id: merchant_app_id }
      const response = await backend.fetch(data, merchant_token)
      if (response.merchant_app_status === MERCHANT_APP_STATUS.PARTIALLY_SIGNED && envelope_id) {
        const accessTokenData = {
          api: api.merchant.getDocusignAccessToken
        }
        const response = await backend.fetch(accessTokenData)
        const dsCode = response.access_token
    
        const input = {
          merchant_app_id: merchant_app_id,
          access_token: dsCode,
          envelope_id: envelope_id
        }
    
        const data: any = {
          payLoad: JSON.stringify(input),
          api: api.merchant.getSignerViewRequest
        }
    
        const result = await backend.save(data, true)
    
        if (result) {
          const signingUrl = result.redirectUrl
          window.location.href = signingUrl
        }
      }else if (response.merchant_app_status === MERCHANT_APP_STATUS.SIGNED ) {
        setSigningCompleted(true)
      } else if (response.merchant_app_status === MERCHANT_APP_STATUS.WITHDRAWN) {
        setWithdrawModalOpen(true)
       }
       else{
        history.replace('/')
      }
    }catch(err: any){
      setOverlaymsg(err.errMessage || 'something went wrong')
      await signOut(auth)
      const clientId = sessionStorage.getItem("clientId")
      sessionStorage.clear();
      if (!!clientId) {
        sessionStorage.setItem("clientId", clientId)
      }
      localStorage.clear()
      setConfig({
        ...config,
        accessible_clients: [],
        user_roles_id : null,
        selectedClientId: config?.id
      })
      setLoading(false)
    }
  }

  const merchantLogin = async (token: string) => {
    setLoading(true)

    let request = {
      api: api.merchant.verifyToken,
      queryParam: { token: token }
    }
    
    let tokenInfo = await backend.fetchUnprotected(request)
    if (tokenInfo.status) {
      auth.tenantId = tenantId
      
      const user: any = await signInWithCustomToken(auth, tokenInfo.token)

      const token: any = await user.user.getIdToken();

      sessionStorage.setItem("merchant_token",token);
      return token      
    }
    return 
  }

  useEffect(() => {
    const params = new URLSearchParams(history.location.search)
    const navigateToDS: any = params.get('navigateToDS')
    const envelopId: any = params.get('envid')
    const merchantAppId: any = params.get('appid')
    setMerchantAppID(merchantAppId)
    const token: any = params.get('token')
    console.log("CHECK one")

    if(token){
      fetchSigningURL(envelopId, token, merchantAppId)
    }
    else {
      updateMerchantApp()
    }
  }, [])

  const doSignOut = async () => {
    try {
      setLoading(true)
      setSigningCompleted(false)
      history.replace('/')
      await signOut(auth)
      const clientId = sessionStorage.getItem("clientId")
      sessionStorage.clear();
      if (!!clientId) {
        sessionStorage.setItem("clientId", clientId)
      }
      localStorage.clear()
      setConfig({
        ...config,
        accessible_clients: [],
        user_roles_id : null,
        selectedClientId: config?.id
      })
      setLoading(false)
    } catch (err: any) {
      console.log(err)
    }
  }

  return (
    <>
      <Header page='updatestatus' />
      <ProgressLoader loading={loading} size="large" />
      <div>
        <div className='body-content'>
          <div  className="thankyou-text">{displyMessage}</div>
        </div>
      </div>
      <div className='overlay' style={overlay.style}>
        <div className='overlay-image'>
          <span className='overlay-text'>{overlayMsg}</span>
        </div>
      </div>
      { withdrawModalOpen ?
        <Modal
        centered
        open={withdrawModalOpen}
        closeOnEscape={false}
        closeOnDimmerClick={false}
       >
        <Modal.Content>
          <Modal.Description>
            <div className='not-found-text'>
                Your Application has been withdrawn, Please contact Admin for more details. </div>
          </Modal.Description>

        </Modal.Content>

      </Modal> : null 
      }
      { signingCompleted ? 
          <Modal
            centered
            open={signingCompleted}
            onClose={() => doSignOut()}
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Content>
              <Modal.Description>
                <div className='not-found-text'>
                  {`Signing is already completed for - ${merchantAppID}`}
                </div>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button color='blue' onClick={() => doSignOut()}>OK</Button>
            </Modal.Actions>
          </Modal> : null
        }
      <Footer page='updatestatus'/>
    </>
  );
}

export default UpdateSigningStatus;