import React, { useState, createContext } from "react";

export type AppContextState = {
  config: {
    selectedClientId: number,
    user_roles_id: number,
    accessible_clients: any
  };
  setConfig: (data: any) => void;
};

const appContextDefaultValues: AppContextState = {
  config: {
    selectedClientId: 0,
    user_roles_id: 0,
    accessible_clients: null
  },
  setConfig: () => {},
};

if (sessionStorage.getItem("selectedClientId")) {
  const value = sessionStorage.getItem("selectedClientId");
  if (!!value) {
      appContextDefaultValues.config.selectedClientId = Number(value)
  }
}

if (sessionStorage.getItem("user_roles_id")) {
  const value = sessionStorage.getItem("user_roles_id");
  if (!!value) {
      appContextDefaultValues.config.user_roles_id = Number(value)
  }
}

if (sessionStorage.getItem("accessible_clients")) {
  const value = sessionStorage.getItem("accessible_clients");
  if (!!value) {
      appContextDefaultValues.config.accessible_clients = JSON.parse(value)
  }
}

export const AppContext = createContext<AppContextState>(appContextDefaultValues);

export const AppProvider = (props: any) => {

  const [config, setConfigData] = useState<any>(
    appContextDefaultValues.config
  );

  const setConfig = (data: any) => {
    if (data) {
      setConfigData(data)    
    } else {
      setConfigData({})
    }
  }

  return <AppContext.Provider value={{config, setConfig}}>{props.children}</AppContext.Provider>;
};
