import React, { memo } from 'react'
import { RISK_LEVELS, ROLES, riskLevelValue } from '../../../Utils/constants'

interface TransactionInformationSectionProps {
    monthAvgSales: string
    handleChangeTextInput: (e: any) => false | undefined
    updatetransactionSectionStatus: () => void
    pricingCardValue: any
    amexMonthAvgSales: string
    highestTicketAmount: string
    avgTicketAmount: string
    publicSalesPCT: string
    allowOnlyNumber: (e: any) => void
    businessTransPCT: string
    salesTotalWarningText: string
    avgSalesWarning: string
    avgTicketAmountWarning: string
    highestTicketAmountWarning: string
    amexMonthAvgWarning: string
    isMobile: boolean
    riskLevel: string
}
const TransactionInformationSection = (props: TransactionInformationSectionProps) => {
    const {
        monthAvgSales,
        handleChangeTextInput,
        updatetransactionSectionStatus,
        pricingCardValue,
        amexMonthAvgSales,
        avgTicketAmount,
        highestTicketAmount,
        publicSalesPCT,
        allowOnlyNumber,
        businessTransPCT,
        salesTotalWarningText,
        avgSalesWarning,
        avgTicketAmountWarning,
        highestTicketAmountWarning,
        amexMonthAvgWarning,
        isMobile,
        riskLevel
    } = props
    return (
        <div className='form-fields'>
            <div className='ui form transaction-fields'>
                <div className={isMobile && false ? 'inline fields' : 'fields'}>
                {riskLevelValue.indexOf(riskLevel) === -1 &&  <div className={isMobile && false ? 'two wide field' : 'eight wide field'}>
                        <label>Avg Monthly Volume (VS/MC/Disc)<span className="required-text"> *</span> </label>
                        <div>
                            <span className='input-dollar'>$</span>
                            <input
                                type='text'
                                name='monthAvgSales'
                                className={ `${!monthAvgSales ? 'not-filled padding-left-30' :'form-input padding-left-30'} input-mob-dollarfield`}
                                // placeholder='Avg Monthly Volume (VS/MC/Disc)'
                                value={monthAvgSales}
                                autoComplete="new-password"
                                onChange={handleChangeTextInput}
                                onBlur={updatetransactionSectionStatus}
                                // disabled={riskLevelValue.indexOf(riskLevel) > -1}
                            />
                            <div className='warning-text-message'>{(monthAvgSales!='')?avgSalesWarning:''}</div>
                         </div>
                    </div>}
                    {pricingCardValue.includes('AMEX') &&
                        <div className={isMobile && false ? 'two wide field' : 'eight wide field'}>
                            <label>Avg Monthly Volume (Amex)<span className="required-text"> *</span> </label>
                            <div>
                                <span className='input-dollar'>$</span>
                                <input
                                    type='text'
                                    name='amexMonthAvgSales'
                                    className={ !amexMonthAvgSales ? 'not-filled padding-left-30' :'form-input padding-left-30'}
                                    // placeholder='Avg Monthly Volume (Amex)'
                                    value={amexMonthAvgSales}
                                    autoComplete="new-password"
                                    onChange={handleChangeTextInput}
                                    onBlur={updatetransactionSectionStatus}
                                />
                                <div className='warning-text-message'>{(amexMonthAvgSales!='')?amexMonthAvgWarning:''}</div>
                            </div>
                        </div>
                    }
                </div>
                <div className={isMobile && false ? 'inline fields' : 'fields'}>
                    <div className={isMobile && false ? 'two wide field' : 'eight wide field'}>
                        <label>Avg Ticket Amount <span className="required-text"> *</span></label>
                        <div>
                            <span className='input-dollar'>$</span>
                            <input
                                type='text'
                                name='avgTicketAmount'
                                className={ !avgTicketAmount ? 'not-filled padding-left-30 transaction-fields' :'form-input padding-left-30'}
                                // placeholder='Avg Ticket Amount'
                                value={avgTicketAmount}
                                autoComplete="new-password"
                                onChange={handleChangeTextInput}
                                onBlur={updatetransactionSectionStatus}
                            />
                            <div className='warning-text-message'>{(avgTicketAmount!='')?avgTicketAmountWarning:''}</div>
                        </div>
                    </div>
                    <div className={isMobile && false ? 'two wide field' : 'eight wide field'}>
                        <label>Highest Ticket Amount<span className="required-text"> *</span> </label>
                        <div>
                            <span className='input-dollar'>$</span>
                            <input
                                type='text'
                                name='highestTicketAmount'
                                className={ !highestTicketAmount ? 'not-filled padding-left-30' :'form-input padding-left-30'}
                                // placeholder='Highest Ticket Amount'
                                value={highestTicketAmount}
                                autoComplete="new-password"
                                onChange={handleChangeTextInput}
                                onBlur={updatetransactionSectionStatus}
                            />
                            <div className='warning-text-message'>{(highestTicketAmount!='')?highestTicketAmountWarning:''}</div>
                        </div>
                    </div>
                </div>
                <div className={isMobile && false ? ' inline fields' : 'fields'}>
                    <div className={isMobile && false ? 'two wide field' : 'eight wide field'}>
                        <label>Percentage of sales Business to Consumer<span className="required-text"> *</span> </label>
                        <div>
                            <input
                                type='tel'
                                name='publicSalesPCT'
                                className={ !publicSalesPCT ? 'not-filled' :'form-input'}
                                // placeholder='Percentage of sales Business to Consumer'
                                value={publicSalesPCT}
                                autoComplete="new-password"
                                onChange={handleChangeTextInput}
                                onBlur={updatetransactionSectionStatus}
                                maxLength={3}
                            />
                            <span className='input-percent'>%</span>
                        </div>
                    </div>
                    <div className={isMobile && false ? 'two wide field' : 'eight wide field'}>
                        <label>Percentage of sales Business to Business<span className="required-text"> *</span> </label>
                        <div>
                            <input
                                type='tel'
                                name='businessTransPCT'
                                className={ !businessTransPCT ? 'not-filled' :'form-input'}
                                // placeholder='Percentage of sales Business to Business'
                                value={businessTransPCT}
                                autoComplete="new-password"
                                onChange={handleChangeTextInput}
                                onBlur={updatetransactionSectionStatus}
                                maxLength={3}
                            />
                            <span className='input-percent'>%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='warning-text'>
                {(!!publicSalesPCT || !!businessTransPCT) ? salesTotalWarningText : ''}
            </div>
        </div>
    )
}

export default memo(TransactionInformationSection)
