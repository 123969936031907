import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import AppLayout from '../../PageLayout/AppLayout';
import { ROLES } from '../../../Utils/constants';
import PlatformAdminHeader from './Header';
import PlatformAdminSidebar from './Sidebar';
import PlatformAdminDashboard from './Dashboard/Dashboard';
import PlatformAdminOnboarding from './Onboarding/Onboarding';
import PlatformAdminSuperUser from './SuperUser/SuperUser';
import { layoutStyle, siderStyle } from './StyleConstants';
import './platform-admin.css';

const { REACT_APP_PLATFORM_ADMIN } = process.env;

const PlatformAdmin :React.FC = () => {
  const { Sider } = Layout;

  const role = sessionStorage.getItem('role') || '';

  const PlatformAdminAccessUsers = [
    `${ROLES.SUPER_ADMIN}`
  ]

  const isPlatformAdminEnabled = (REACT_APP_PLATFORM_ADMIN === 'true');

  return (
    <AppLayout>
      <PlatformAdminHeader />
      <Layout style={layoutStyle}>
        <Sider width="250px" style={siderStyle}>
          <PlatformAdminSidebar />
        </Sider>
        {
          !isPlatformAdminEnabled || !PlatformAdminAccessUsers.includes(role) ? (
            <Redirect to={{ pathname: '/listapp' }} />
          ) : (
            <Switch>
              <Route exact path={'/platformadmin'} component={PlatformAdminDashboard} />
              <Route exact path={'/platformadmin/onboarding'} component={PlatformAdminOnboarding} />
              <Route exact path={'/platformadmin/superuser'} component={PlatformAdminSuperUser} />
            </Switch>
          )
        }
      </Layout>
    </AppLayout>
  )
}
export default PlatformAdmin
