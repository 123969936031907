import React from 'react'
import { PAGES } from '../../Utils/constants'
import { Layout } from '../PageLayout/Layout'

const UnAuthorised :React.FC = () => {
  return (
    <Layout page={PAGES.UNAUTHORISED_PAGE}>
      <div className='un-authorised-text'>
        You don't have permission to access the page !
      </div>

    </Layout>
  )
}
export default UnAuthorised
