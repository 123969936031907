import React from "react";
import { Table } from "semantic-ui-react";
import { ROLES } from "../../../Utils/constants";
const { REACT_APP_BACKEND_MYSQL } = process.env;
interface SendAppTableHeaderProps {
    sortField: string;
    handleSortByFieldName: (field: string, order: string) => Promise<void>;
    sortFieldOrder: string;
    searchText: string;
    handleSearchByFieldName: (field: string, value: string) => Promise<void>;
    searchField: string;
    isParentChecked : any;
    changeCheckboxStatus : any;
    role: string | null;
    config:any

}
const SendAppTableHeader = (props: SendAppTableHeaderProps) => {
    const { sortField, handleSortByFieldName, sortFieldOrder, searchText, handleSearchByFieldName, searchField, isParentChecked ,changeCheckboxStatus, role,config} = props
    const createdTS = REACT_APP_BACKEND_MYSQL ? "created_ts" : "createdTS"
    const updatedTS = REACT_APP_BACKEND_MYSQL ? "updated_ts" : "updatedTS"
    return (
               <>
                     <Table.Header>
                <Table.Row>
                {(role !== ROLES.VIEW_ONLY && role !== ROLES.SALES_LIMITED ) && (
                    <>
                    <Table.HeaderCell textAlign="center" className="t-data headercheckbox" style={{ background: config?.client_config_fe?.button_color }} >
                        {/* NAME */}
                        <div className="ui fluid category">    
                            <div className= {`parentcheckbox ${
                                role === ROLES.SUPER_ADMIN  ? "sa-check": " " }`} >
                            <input
                                type="checkbox"
                                value={"parent"}
                                onChange={(e) => changeCheckboxStatus(e, "p1")}
                                checked={isParentChecked}
                            />
                            </div>
                        </div>
                        </Table.HeaderCell> 
                    </>
                    )}
                    <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>
                    <span className='view-text'>
                        <div className='ui fluid category'>
                            <i
                                className={`long arrow alternate ${sortFieldOrder === "asc" && sortField === "app_link_id" ? "down" : "up"} icon`}
                                onClick={() => handleSortByFieldName("app_link_id", sortFieldOrder)}></i>
                            <div className='ui icon input search-input'>
                                <input
                                    type='text'
                                    placeholder='LINK ID'
                                    value={searchField === "app_link_id" ? searchText : ""}
                                    onChange={(e) => handleSearchByFieldName("app_link_id", e.target.value)}
                                />
                            </div>
                        </div>
                    </span>
                    </Table.HeaderCell>                    
                    <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>
                    <span className='view-text'>
                        <div className='ui fluid category'>
                            <i
                                className={`long arrow alternate ${sortFieldOrder === "asc" && sortField === "partner_name" ? "down" : "up"} icon`}
                                onClick={() => handleSortByFieldName("partner_name", sortFieldOrder)}></i>
                            <div className='ui icon input search-input'>
                                <input
                                    type='text'
                                    placeholder='SALES USER NAME'
                                    value={searchField === "partner_name" ? searchText : ""}
                                    onChange={(e) => handleSearchByFieldName("partner_name", e.target.value)}
                                />
                            </div>
                        </div>
                    </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>
                    <span className='view-text'>
                        <div className='ui fluid category'>
                            <i
                                className={`long arrow alternate ${sortFieldOrder === "asc" && sortField === "link_name" ? "down" : "up"} icon`}
                                onClick={() => handleSortByFieldName("link_name", sortFieldOrder)}></i>
                            <div className='ui icon input search-input'>
                                <input
                                    type='text'
                                    placeholder='LINK NAME'
                                    value={searchField === "link_name" ? searchText : ""}
                                    onChange={(e) => handleSearchByFieldName("link_name", e.target.value)}
                                />
                            </div>
                        </div>
                    </span>
                    </Table.HeaderCell>
                    
                    <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>
                    <span className='view-text'>
                        <div className='ui fluid category'>
                            <i
                                className={`long arrow alternate ${sortFieldOrder === "asc" && sortField === "processor_name" ? "down" : "up"} icon`}
                                onClick={() => handleSortByFieldName("processor_name", sortFieldOrder)}></i>
                            <div className='ui icon input search-input'>
                                <input
                                    type='text'
                                    placeholder='BANK NAME'
                                    value={searchField === "processor_name" ? searchText : ""}
                                    onChange={(e) => handleSearchByFieldName("processor_name", e.target.value)}
                                />
                            </div>
                        </div>
                    </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>
                    <span className='view-text'>
                        <div className='ui fluid category'>
                            <i
                                className={`long arrow alternate ${sortFieldOrder === "asc" && sortField === "tiny_url" ? "down" : "up"} icon`}
                                onClick={() => handleSortByFieldName("tiny_url", sortFieldOrder)}></i>
                            <div className='ui icon input search-input'>
                                <input
                                    type='text'
                                    placeholder='LINK URL'
                                    value={searchField === "tiny_url" ? searchText : ""}
                                    onChange={(e) => handleSearchByFieldName("tiny_url", e.target.value)}
                                />
                            </div>
                        </div>
                    </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>
                    <span className='view-text'>
                        <div className='ui fluid category'>
                            <i
                                className={`long arrow alternate ${sortFieldOrder === "asc" && sortField === "createdTS" ? "down" : "up"} icon`}
                                onClick={() => handleSortByFieldName(createdTS, sortFieldOrder)}></i>
                            <div className='ui icon input search-input'>
                                <input
                                    type='text'
                                    placeholder='CREATE DATE'
                                    value={searchField === createdTS ? searchText : ""}
                                    onChange={(e) => handleSearchByFieldName(createdTS, e.target.value)}
                                />
                            </div>
                        </div>
                    </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>
                    <span className='view-text'>
                        <div className='ui fluid category'>
                            <i
                                className={`long arrow alternate ${sortFieldOrder === "asc" && sortField === "updatedTS" ? "down" : "up"} icon`}
                                onClick={() => handleSortByFieldName(updatedTS, sortFieldOrder)}></i>
                            <div className='ui icon input search-input'>
                                <input
                                    type='text'
                                    placeholder='LAST MODIFIED DATE'
                                    value={searchField === updatedTS ? searchText : ""}
                                    onChange={(e) => handleSearchByFieldName(updatedTS, e.target.value)}
                                />
                            </div>
                        </div>
                    </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>
                    <span className='view-text'>
                        <div className='ui fluid category'>
                            <i
                                className={`long arrow alternate ${sortFieldOrder === "asc" && sortField === "app_link_status" ? "down" : "up"} icon`}
                                onClick={() => handleSortByFieldName("app_link_status", sortFieldOrder)}></i>
                            <div className='ui icon input search-input'>
                                <input
                                    type='text'
                                    placeholder='STATUS'
                                    value={searchField === "app_link_status" ? searchText : ""}
                                    onChange={(e) => handleSearchByFieldName("app_link_status", e.target.value)}
                                />
                            </div>
                        </div>
                    </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>
                    <span className='view-text'>
                        <div className='ui fluid category'>
                            <i
                                className={`long arrow alternate ${sortFieldOrder === "asc" && sortField === "no_of_clicks" ? "down" : "up"} icon`}
                                onClick={() => handleSortByFieldName("no_of_clicks", sortFieldOrder)}></i>
                            <div className='ui icon input search-input'>
                                <input
                                    type='text'
                                    placeholder='CLICKS'
                                    value={searchField === "no_of_clicks" ? searchText : ""}
                                    onChange={(e) => handleSearchByFieldName("no_of_clicks", e.target.value)}
                                />
                            </div>
                        </div>
                    </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color,width:"100px" }}>
                    <span className='view-text'>
                        <div className='ui fluid category'>
                            <i
                                className={`long arrow alternate ${sortFieldOrder === "asc" && sortField === "no_of_apps" ? "down" : "up"} icon`}
                                onClick={() => handleSortByFieldName("no_of_apps", sortFieldOrder)}></i>
                            <div className='ui icon input search-input'>
                                <input
                                    type='text'
                                    placeholder='APPS'
                                    value={searchField === "no_of_apps" ? searchText : ""}
                                    onChange={(e) => handleSearchByFieldName("no_of_apps", e.target.value)}
                                />
                            </div>
                        </div>
                    </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color}}> ACTIONS </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        </>
    )
}

export default SendAppTableHeader;