import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { auth } from '../../Firebase'
import { NON_AUTH_ROUTES } from './AllRoutes'

interface IProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

const PrivateRoute = ({ component: Component, ...rest }: IProps) => {
  const path = rest.path
  console.log(path)
  if (auth.isAuthenticated()) {
    console.log('Authenticated')
  } else {
    console.log('Not Authenticated')
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated() === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: NON_AUTH_ROUTES.login, state: { from: props.location } }}
          />)}
    />
  )
}

export default PrivateRoute
