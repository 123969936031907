import React from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";
import BasicButton from '../../Common/Button';

interface DeactivateModalProps {
    loading: boolean
    selectedUser: any
    deactivateModalOpen: boolean
    handleCancel: () => void
    handleActivateDeActivate: (user_guid: any, user_id: any, user_email: any, deactivate: boolean, role_code: string) => Promise<void>
    config?: any
}

const { REACT_APP_BACKEND_MYSQL } = process.env;

const DeactivateModal = (props: DeactivateModalProps) => {
    const { loading, deactivateModalOpen, handleCancel, handleActivateDeActivate, selectedUser, config } = props
    return (
        <Modal
            centered
            size='tiny'
            open={deactivateModalOpen}
            onClose={handleCancel}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <div className="close-button">
                    <button className="email-activity-close-button" onClick={handleCancel}>X</button>
                </div>
                <ProgressLoader loading={loading} size='small' />
                <div className="word-wrap">
                    {
                        `Are you sure you want to ${selectedUser.deactivate ? 'activate' : 'deactivate'} user ${REACT_APP_BACKEND_MYSQL ? selectedUser.user_name_first : selectedUser.user_name.first_name} ${REACT_APP_BACKEND_MYSQL ? selectedUser.user_name_last : selectedUser.user_name.last_name} ( ID - ${selectedUser.user_id} )?`
                    }
                </div>
            </Modal.Content>
            <Modal.Actions>
                <BasicButton
                    textColor={config?.client_config_fe?.button_color}
                    backgroundColor={'#FFFFFF'}
                    size='18px'
                    buttonText='CANCEL'
                    className="save-button button-border"
                    onClick={handleCancel}
                />
                <button
                    className="save-button button-border ipad-blue"
                    style={{ background: config?.client_config_fe?.button_color }}
                    onClick={() => handleActivateDeActivate(selectedUser.user_guid, selectedUser.user_id, selectedUser.user_email, selectedUser.deactivate ? false : true, selectedUser.role_code)} color={selectedUser.deactivate ? 'blue' : 'red'}>{selectedUser.deactivate ? "ACTIVATE" : "DEACTIVATE"}</button>
            </Modal.Actions>
        </Modal>
    )
}

export default DeactivateModal;

