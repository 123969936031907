import React from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";
import BasicButton from '../../Common/Button';

interface DeactivateModalProps {
    loading: boolean
    selectedUser: any
    deactivateModalOpen: boolean
    handleCancel: () => void
    modalAction: any
    handleConfirmAction: any
    handleActivateDeActivate: (user_guid: any, user_id: any, user_email: any, deactivate: boolean, role_code: string) => Promise<void>
    config?:any
  
}
const DeactivateModal = (props: DeactivateModalProps) => {
    const { loading, deactivateModalOpen, handleCancel, handleActivateDeActivate, selectedUser, modalAction ,handleConfirmAction,config} = props
    return (
        <Modal
            centered
            size='tiny'
            open={deactivateModalOpen}
            onClose={handleCancel}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <div className="close-button">
                    <button className="email-activity-close-button" onClick={handleCancel}>X</button>
                </div>
                <ProgressLoader loading={loading} size='small' />
                <div className="word-wrap">
                <span className="word-wrap">{`Are you sure you want to  ${modalAction} for ${selectedUser.length} ${selectedUser.length > 1 ? 'users' : 'user'} ?`}</span> 
                </div>
            </Modal.Content>
            <Modal.Actions>
                <BasicButton
                    textColor={config?.client_config_fe?.button_color}
                    backgroundColor={'#FFFFFF'}
                    size='18px'
                    buttonText='CANCEL'
                    className="save-button button-border"
                    onClick={handleCancel}
                />

                <button
                    className="save-button button-border ipad-blue"
                    style={{ background: config?.client_config_fe?.button_color }}
                    onClick={() => { handleConfirmAction(modalAction) }}>YES</button>
                
            </Modal.Actions>
        </Modal>
    )
}

export default DeactivateModal;

