import React, { memo, useContext, useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'
import ProgressLoader from '../../Common/ProgressLoader'
import { Footer } from '../../PageLayout/Footer'
import { Layout } from '../../PageLayout/Layout'
import './offices.css'
import api from '../../../Service/Api'
import backend from '../../../Service/Backend'
import { firstBy } from 'thenby'
import { BANK_CODES, RISK_LEVELS, ROLES, cdBanks } from '../../../Utils/constants'
import { get, getParsedList } from '../../../Utils/helpers'
import SearchResultPopUp from '../../Common/SearchResultModal'
import WarningModal from './WarningModal'
import DeleteModal from './DeleteModal'
import CreateOffice from './CreateOffice'
import OfficeTable from './OfficeTable'
import EnablePaperAppsModal from './EnablePaperAppsModal'
import EnableFreeEquipmentPlacementModal from '../../FreeEquipmentPlacement/EnableFreeEquipmentPlacementModal'
import DeactivateModal from './DeactivateModal'
import addButton from '../../../Images/add-button.svg'
import useCheckMobileScreen, { useCheckTabScreen } from '../../../hooks/useCheckMobileScreen'
import ButtonScrollTop from '../../Common/ButtonScrollTop'
import Select from 'react-select'
import ActionConfirmation from './BulkActionConfirmModal'
import PaperAppsActionConfirmation from './BulkPaperAppsModal'
import { fetchOfficeListData } from '../../../Service/Algolia'
import { AppContext } from '../../../context/appContext'
import BasicButton from '../../Common/Button'
 
const allRiskLevels: any = [
    { value: "Low Risk", label: "Low Risk" },
    { value: "High Risk", label: "High Risk" }
]

const { REACT_APP_BACKEND_MYSQL } = process.env;

const Offices = () => {
    const { config }: any = useContext(AppContext);
    
    const [algoliaLoading, setAlgoliaLoading] = useState(true);

    const role: any = sessionStorage.getItem('role')
    const office_id: any = sessionStorage.getItem('office_id')
    const office_level: any = sessionStorage.getItem('office_level')
    const user_id: any = sessionStorage.getItem('user_id')
    const user_guid: any = sessionStorage.getItem('user_guid')
    let client_id: any = Number(sessionStorage.getItem('clientId'))
    const super_user: any = sessionStorage.getItem("super_user") === 'true'
    
    // console.log("Super User Value in office :", super_user)
    
    if (super_user) {
        client_id = Number(sessionStorage.getItem("selectedClientId"))
        console.log(client_id)
    }

    const [overlay, setOverlay] = useState({ style: { display: "none" } })
    const [overlayMsg, setOverlaymsg] = useState("Offices Fetched Successfully!")
    const [offices, setOffices] = useState([] as any[])
    const [rmUsers, setRmUsers] = useState([] as any[])
    const [allRmUsers, setAllRmUsers] = useState([] as any[])
    const [loading, setLoading] = useState(true)
    const [processors, setProcessors] = useState([] as any[])
    const [lowRiskProcessors, setLowRiskProcessors] = useState([] as any[])
    const [highRiskProcessors, setHighRiskProcessors] = useState([] as any[])
    const [allProcessors, setAllProcessors] = useState([] as any[])
    const [riskLevels, setRiskLevels] = useState([] as any[])
    const [isEditable, setIsEditable] = useState(false)
    const [editOfficeDetails, setEditOfficeDetails] = useState({} as any)
    const [details, setDetails] = useState({} as any)
    const [showCreateOfficeModal, setShowCreateOfficeModal] = useState(false)
    const [officeName, setOfficeName] = useState("")
    const [selectedOffice, setSelectedOffice] = useState({} as any)
    const [selectedLowRiskProcessors, setSelectedLowRiskProcessors] = useState([] as any[])
    const [selectedHighRiskProcessors, setSelectedHighRiskProcessors] = useState([] as any[])
    const [selectedRiskLevel, setSelectedRiskLevel] = useState([] as any[])
    const [selectedRM, setSelectedRM] = useState([] as any[])
    const [currentOffice, setCurrentOffice] = useState({} as any)
    const [officesDropDown, setofficesDropDown] = useState([] as any[])
    const [error, setError] = useState('')
    const [createOfficeEnabled, setCreateOfficeEnabled] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [deactivateModalOpen, setDeactivateModalOpen] = useState(false)
    const [warningModalOpen, setWarningModalOpen] = useState(false)
    //USEING TO SAVE LEVEL OFFICE DETAILS WHEN LEVEL TWO OFFICE ADMIN LOGGED IN
    const [levelOneOfficeDetails, setLevelOneOfficeDetails] = useState({} as any)
    const [allOffices, setAllOffices] = useState([] as any[])

    //Search and Sorting for Links
    const [searchField, setSearchField] = useState('');
    const [searchText, setSearchText] = useState('')

    const [sortField, setSortField] = useState('');
    const [sortFieldOrder, setSortFieldOrder] = useState('desc');
    const [noSearchResultModal, setNoSearchResultModal] = useState(false)
    const [sortAndSearchResultData, setSortAndSearchResultData] = useState([] as any[])

    const [TempState, setTempState] = useState([] as any[])

    const [paperAppsEnabled, setPaperAppsEnabled] = useState(false)
    const [freeEquipmentPlacementEnabled, setFreeEquipmentPlacementEnabled] = useState(false)
    const [freeEquipmentPlacement, setFreeEquipmentPlacement] = useState(false)
    const [openEnablePaperAppsModal, setOpenEnablePaperAppsModal] = useState(false)
    const [openEnableFreeEquipmentPlacementModal, setOpenEnableFreeEquipmentPlacementModal] = useState(false)
    const [isParentChecked, setIsParentChecked] = useState(false);
    const [modalAction, setModalAction] = useState('')
    const [countforAction, setCountforAction] = useState('')
    const [officeActiveDeactiveBulkConfirmation, setOfficeActiveDeactiveBulkConfirmation] = useState(false)
    const [officePaperAppsBulkConfirmation, setOfficePaperAppsBulkConfirmation] = useState(false)

   //OFFICE BULK UPDATES
    const [selectedOfficeBulk, setSelectedOfficeBulk] = useState([] as any[])
    const [bulkaction, setBulkaction] = useState('')

    const officeBulkAction: any = [
        { value: 'activateOffice', label: 'Activate' },
        { value: 'deactivateOffice', label: 'Deactivate' },
      ]

    const officeBulkActionWithPaper: any = [
        { value: 'enablePaperApps', label: 'Enable Paper Apps' },
        { value: 'disablePaperApps', label: 'Disable Paper Apps' },
        { value: 'activateOffice', label: 'Activate' },
        { value: 'deactivateOffice', label: 'Deactivate' },
      ]

    const isMobile = useCheckMobileScreen();
    const isTablet = useCheckTabScreen();
    let viewOptions = true
   if(role === ROLES.VIEW_ONLY ){ viewOptions = false }

    const fetchOfficeList = async () => {
        try {
            setAlgoliaLoading(true);
            //fetching daat from algolia
            const dataList = await fetchOfficeListData();
            setAlgoliaLoading(false);

            if (!super_user) {
                if (role === ROLES.SUPER_ADMIN) {
                    let updatedDataList = dataList?.filter((office: any) => !office.deleted && office.client_id === client_id)
                    setOffices(updatedDataList)
                } else if (role === ROLES.OFFICE_ADMIN) {
                    let updatedDataList = dataList?.filter((office: any) => (get(office, "parent_office_ids", []).includes(parseInt(office_id, 10)) || (office_id && office?.office_id === parseInt(office_id, 10))) && !office.deleted && office.client_id === client_id)
                    setOffices(updatedDataList)
                    if (office_level === '2') {
                        let currentOffice = dataList?.filter((office: any) => office?.office_id === office_id && office.client_id === client_id)
                        let levelOneOffice = dataList?.filter((office: any) => office?.office_id == currentOffice[0].parent_office_id && office.client_id === client_id)
                        setLevelOneOfficeDetails(levelOneOffice[0])
                    }
                } else if (role === ROLES.RELATIONSHIP_MANAGER) {
                    let reducedOffices = dataList?.filter((office: any) => !office.deleted && office.client_id === client_id)?.reduce((filteredOffices: any, office: any) => {
                        const rm_details = office?.allowed_RMs?.filter((rm_user: any) => Number(user_id) === rm_user.user_id)
                        if (rm_details && rm_details.length > 0) {
                            filteredOffices.push({ ...office, value: office.office_id, label: office.office_name });
                        }
                        return filteredOffices;
                    }, []);
                    setOffices(reducedOffices)
                    let allOfficesList = dataList?.filter((office: any) => !office.deleted)
                    setAllOffices(allOfficesList)
                }
            } else {
                let updatedDataList = dataList?.filter((office: any) => !office.deleted && office.client_id === client_id)
                setOffices(updatedDataList)
                if (role === ROLES.OFFICE_ADMIN) {
                    //TODO - setLevelOneOfficeDetails
                }
                if (role === ROLES.RELATIONSHIP_MANAGER) {
                    let allOfficesList = dataList?.filter((office: any) => !office.deleted)
                    setAllOffices(allOfficesList)
                }
            }
        } catch (err) {
            console.log('err', err)
        }
    }

    useEffect(() => {
        const fetchInitialOfficesList = async () => {
            await fetchOfficeList()
            setOverlaymsg("Offices Fetched Successfully!")
            showOverlayForThreeSeconds()
        }

        fetchInitialOfficesList()
        fetchProcessorsData()
        fetchRmUsers()
    }, [config.selectedClientId])

    useEffect(() => {
        if (offices?.length > 0 && allProcessors?.length > 0 && allRmUsers?.length > 0 && role === ROLES.OFFICE_ADMIN) {
            let currentOfficeIndex = offices?.findIndex((office: any) => office?.office_id == office_id)
            let currentOffice = { ...offices[currentOfficeIndex], value: offices[currentOfficeIndex]?.office_id, label: offices[currentOfficeIndex]?.office_name }
            setCurrentOffice(currentOffice)
            setSelectedOffice(currentOffice)
            let officeDropDown: any;
            if (super_user) {
                officeDropDown = offices?.filter((office: any) => office.office_level !== '3' && !office.deleted && !office?.deactivate)?.map((office: any) => {
                    return { ...office, value: office.office_id, label: office.office_name }
                })
            } else {
                officeDropDown = offices?.filter((office: any, index: number) => (currentOfficeIndex !== index && office.office_level !== '3' && !office.deleted && !office?.deactivate))?.map((office: any) => {
                    return { ...office, value: office.office_id, label: office.office_name }
                })
            }
            officeDropDown.push(currentOffice)
            setofficesDropDown(officeDropDown)
            updateDropDownOptions(offices[currentOfficeIndex])
        } else if (offices?.length > 0 && allProcessors?.length > 0 && allRmUsers?.length > 0 && role === ROLES.SUPER_ADMIN) {
            let officeDropDown = offices?.filter((office: any) => office.office_level !== '3' && !office.deleted && !office?.deactivate)?.map((office: any) => {
                return { ...office, value: office.office_id, label: office.office_name }
            })
            setofficesDropDown(officeDropDown)
            if (!isEditable || Object.keys(selectedOffice).length === 0) {
                updateDropDownOptions()
            }
        } else if (allProcessors?.length > 0 && allRmUsers?.length > 0 && role === ROLES.RELATIONSHIP_MANAGER) {
            let reducedOffices = allOffices?.filter((office: any) => office.office_level !== '3' && !office.deleted && !office?.deactivate)?.reduce((filteredOffices: any, office: any) => {
                if (super_user) {
                    filteredOffices.push({ ...office, value: office.office_id, label: office.office_name });
                } else {
                    const rm_details = office?.allowed_RMs?.filter((rm_user: any) => user_id == rm_user.user_id)
                    if (rm_details && rm_details.length > 0) {
                        filteredOffices.push({ ...office, value: office.office_id, label: office.office_name });
                    }
                }
                return filteredOffices;
            }, []);
            setofficesDropDown(reducedOffices)
            if (!isEditable || Object.keys(selectedOffice).length === 0) {
                updateDropDownOptions()
            }
        }else if(allProcessors?.length > 0){
            if (!isEditable || Object.keys(selectedOffice).length === 0) {
                updateDropDownOptions()
            }
        }
    }, [offices, allProcessors, allRmUsers, allOffices]);

    const fetchRmUsers = async () => {
        setLoading(true)
        const data: any = {
            api: api.users.getRmUsers,
        }

        try {
            const response = await backend.fetch(data)
            if (response && response.data.length > 0) {
                const rm_users = response.data?.map((item: any) => {
                    const dummy = {
                        ...item,
                        value: item.user_name.first_name + " " + item.user_name.last_name,
                        label: item.user_name.first_name + " " + item.user_name.last_name
                    }
                    return dummy
                })
                setAllRmUsers(rm_users)
            }
        } catch (error) {
            console.log(error)
        } finally{
            setLoading(false)
        }
    }

    let finalarray: any[] = [];
    const changeCheckboxStatus = async (e: any, id: string) => {
      const myOffices = [...offices].filter(off => !off.deleted);
      const { checked } = e.target;
      if (id === "p1") {
        finalarray = [];
        setSelectedOfficeBulk([]);
      }
      myOffices.map((office) => {
        if (id === "p1") {
          setIsParentChecked(checked);
          office.isChecked = checked;
          if (e.target.checked) {
            finalarray.push({
              office_id: office.office_id,
              office_level: office.office_level,
              deactivate: Boolean(office?.deactivate) || false,
              paper_apps_enabled: Boolean(office?.paper_apps_enabled) || false,
              free_equipment_placement_enabled: office?.free_equipment_placement_enabled || false
            });
            finalarray.sort((a,b) => Number(a.office_level) - Number(b.office_level))
            setSelectedOfficeBulk(finalarray);
            console.log("bulk update finalarray >>>>>",finalarray.length);
          }
        } else {
          if (office.office_id === id) {
            office.isChecked = checked;
            let checkID = selectedOfficeBulk.findIndex(
              (office: any) => office.office_id === id
            );
            console.log(checkID, "checkID");
            console.log(office);
            if (checkID === -1) {
              selectedOfficeBulk.push({
                office_id: office.office_id,
                office_level: office.office_level,
                deactivate: office?.deactivate || false,
                paper_apps_enabled: Boolean(office?.paper_apps_enabled) || false
              });
              selectedOfficeBulk.sort((a,b) => Number(a.office_level) - Number(b.office_level))
              setSelectedOfficeBulk(selectedOfficeBulk);
              console.log("length", selectedOfficeBulk);
            } else {
              let secondRemoved = selectedOfficeBulk.filter(
                (office: any) => office.office_id !== id
              );
              setSelectedOfficeBulk(secondRemoved);
            }
          }
          const isAllChildsChecked = myOffices.every(
            (office) => office.isChecked === true
          );
          if (isAllChildsChecked) {
            setIsParentChecked(checked);
          } else {
            setIsParentChecked(false);
          }
        }
        return office;
      });
      const dummydata:any = [];
      myOffices.forEach((obj:any)=>{
        sortAndSearchResultData.forEach(tobj=>{
            if(obj.office_id === tobj.office_id){
                obj.paper_apps_enabled=tobj.paper_apps_enabled
            }
          })
          dummydata.push(obj) 
      })
    setOffices([...dummydata]);
    // setOffices([...myOffices]);
    };
    
    const handleConfirmAction = (action: any) => {
      if (action === "Activate" || action === "Deactivate") {
        let officeAction = action === "Deactivate" ? true : false;
        handleActivateDeActivateBulk(officeAction);
      }
    };

    const bulkUpdateAction  = async (item: any) => {
        // console.log("alloffices",sortAndSearchResultData)
        setBulkaction(item)
        setModalAction(item.label)
        
        if(item.value === 'activateOffice' || item.value === 'deactivateOffice'){
            let officeStatus = item.value === 'activateOffice'  ? false : true
            console.log(finalarray)
            const checkOfficeStatus = selectedOfficeBulk.filter((item: any) => Boolean(item.deactivate) === officeStatus);
            const officeStatusCount:any = checkOfficeStatus.length
            console.log(officeStatusCount)
            setCountforAction(officeStatusCount)
            setOfficeActiveDeactiveBulkConfirmation(true)
        } else if(item.value === 'enablePaperApps' || item.value === 'disablePaperApps'){
            let officeStatus = item.value === 'disablePaperApps'  ? false : true
            //const checkOfficeStatus = offices.filter(off => !!selectedOfficeBulk.some(sOff => sOff.office_id === off.office_id)).filter((item: any) => item.paper_apps_enabled === officeStatus);
            const checkOfficeStatus = selectedOfficeBulk.filter((item: any) => item.paper_apps_enabled === officeStatus);
            const officeStatusCount:any = checkOfficeStatus.length
            console.log(officeStatusCount)
            setCountforAction(officeStatusCount)
            const data = { api: api.users.getByGUID, urlParam: user_guid};
            const profiledata = await backend.fetch(data);
            let loggedInOfficeId = profiledata.office_id
            console.log(loggedInOfficeId)
            console.log(selectedOfficeBulk)
            setOfficePaperAppsBulkConfirmation(true)
        }
        let officeIds = selectedOfficeBulk.map((res)=>{
            return res.office_id;
        })
        if(role === ROLES.OFFICE_ADMIN && officeIds.some((id:any) => id == office_id)){
            setOfficeActiveDeactiveBulkConfirmation(false)
            setOfficePaperAppsBulkConfirmation(false)
            setWarningModalOpen(true)
        }
}

    const handleConfirmActionPaperApps = (action: any) => {
        if (action === "Enable Paper Apps" || action === "Disable Paper Apps") {
          let officeAction = action === "Enable Paper Apps" ? true : false;
          handlePaperAppsUpdateBulk(officeAction);
        }
      };
  const uncheckparentcheckbox = async()=>{
    setBulkaction('')
    let myOffice = [...offices];
    setIsParentChecked(false)
    myOffice.map((office) => {
      office.isChecked = false
      return office;
    });
    setSelectedOfficeBulk([])
    setOffices([...myOffice]);
  }

    const handleActivateDeActivateBulk = async (deactivate: boolean) => {
        setOfficeActiveDeactiveBulkConfirmation(false)
        setLoading(true)
        console.log(office_id, deactivate)
        const data: any = {
          api:deactivate?  api.offices.deactivateOfficeBulk : api.offices.activateOfficeBulk,
          payLoad: JSON.stringify({officelist:  selectedOfficeBulk})
        };
        try {
          const countOffice = [...selectedOfficeBulk]
          const response = await backend.save(data)
          setOverlaymsg(deactivate? `${countOffice.length > 1? `Offices` : `Office`} deactivated successfully` : `${countOffice.length > 1? `Offices` : `Office`} activated successfully` );
          showOverlayForThreeSeconds()
          setBulkaction('')
          uncheckparentcheckbox();
            if (response && response.length) {
            // let officeIds = [...response].map((res)=>{
            //     if(isValidArray(res?.deactivated_office_ids)) return [...res?.deactivated_office_ids];
            //     if(isValidArray(res?.activated_office_ids)) return [...res?.activated_office_ids];
            // }).join().split(",")
            // let deactivatedOffices = offices?.map((office: any) => {
            //     if(isValidArray(officeIds) && officeIds.includes(office.office_id)) return { ...office, deactivate: deactivate }
            //     return {...office}
            // })
            // setOffices(deactivatedOffices)
            setOverlaymsg(deactivate? `${countOffice.length > 1? `Offices` : `Office`} deactivated successfully` : `${countOffice.length > 1? `Offices` : `Office`} activated successfully`);
            showOverlayForThreeSeconds()
            setOfficeActiveDeactiveBulkConfirmation(false)
            setBulkaction('')
            await fetchOfficeList()
          }
        }
        catch (err: any) {
          setOfficeActiveDeactiveBulkConfirmation(false)
          console.log(err)
          setOverlaymsg(err.errMessage || "Error occured while deactivating Office.");
          showOverlayForThreeSeconds()
        }
        finally {
            window.scroll({ top: 0, left: 0 }); 
            setLoading(false); setBulkaction(""); 
        }
    }; 

    useEffect(() => {
        setSortAndSearchResultData([...TempState])
    }, [TempState])
    
    
    const handlePaperAppsUpdateBulk = async (paperAppsEnabled: boolean) => {
        console.log(office_id, paperAppsEnabled)
        try {
            setLoading(true);
            const payload: any = {
                api: api.offices.enablePaperAppBulkSubmission,
                payLoad: JSON.stringify({officelist:  selectedOfficeBulk,paper_apps_enabled:paperAppsEnabled})
            };
            const response = await backend.save(payload, true);
            if (response) {
                setOffices(
                offices.map((office: any) => {
                    office.isChecked = false
                    if (selectedOfficeBulk.some(off => off.office_id === office.office_id)) {
                    return { ...office, paperAppsEnabled : office.paper_apps_enabled };
                    } else {
                    return office;
                    }
                })
                );
                let copy = await [...sortAndSearchResultData].map((office: any)=>{
                    if(selectedOfficeBulk.some(off => off.office_id === office.office_id)){
                        return ({ ...office, paper_apps_enabled: paperAppsEnabled })
                    } else{
                        return office
                    }
                })
                // setSortAndSearchResultData([...copy]);
                setTempState([...copy])
                setOfficePaperAppsBulkConfirmation(false)
                let countOffice = parseInt(countforAction)
                let message = response.message || `Paper Apps ${paperAppsEnabled ? 'enabled' : 'disabled'}`
                setSelectedOfficeBulk([]);
                setCountforAction('')
                // setPaperAppsEnabled(data.checked)
                setIsParentChecked(false)
                setOverlaymsg(message);
                setPaperAppsEnabled(!!paperAppsEnabled)
                handleCancel();
                window.scroll({ top: 0, left: 0 });
                showOverlayForThreeSeconds();
                // refreshPage()
                setBulkaction('')
                await fetchOfficeList()
            } else {
                setOverlaymsg(response.message || "Something went wrong while updating!");
                showOverlayForThreeSeconds();
            }
        } catch (err: any) {
            console.log(err);
            setOverlaymsg("Something went wrong while updating office!");
            showOverlayForThreeSeconds();
        } finally {
            setLoading(false);
            setBulkaction('')
        }
    };

    const fetchProcessorsData = async () => {
        const data = {
            api: api.processorData.getProcessors
        }
        const response = await backend.fetch(data)
        if (response) {
            response.sort(
                firstBy(function (option: any) {
                    return option?.processor_name?.toLowerCase();
                })
            )
            const options = response?.map((item: any) => {
                const dummy = {
                    value: REACT_APP_BACKEND_MYSQL ? item.processor_internal_id : item.processor_code,
                    label: item.processor_name
                }
                return dummy
            })
            setAllProcessors(options)
        }
        setLoading(false)
    }

    const updateDropDownOptions = (currentOffice?: any) => {
        if (currentOffice && currentOffice.office_id) {

            let low_risk_processors = allProcessors?.filter((processor: any)=>currentOffice?.allowed_low_risk_processors?.includes(processor.value))
            if (low_risk_processors && low_risk_processors.length > 0) {
                setLowRiskProcessors(low_risk_processors)
            } else {
                setLowRiskProcessors(allProcessors?.filter((processor: any) => processor.value !== BANK_CODES.DART))
            }
            let high_risk_processors = allProcessors?.filter((processor: any)=>currentOffice?.allowed_high_risk_processors?.includes(processor.value))
            if (high_risk_processors && high_risk_processors.length > 0) {
                setHighRiskProcessors(high_risk_processors)
            } else {
                setHighRiskProcessors(allProcessors)
            }
            const riskLevels = currentOffice?.allowed_risk_levels?.map((item: any) => {
                const dummy = {
                    value: item,
                    label: item
                }
                return dummy
            })
            setRiskLevels((riskLevels && riskLevels.length > 0) ? riskLevels : allRiskLevels)
            const rmUsers = currentOffice?.allowed_RMs?.map((item: any) => {
                const dummy = {
                    ...item,
                    value: `${item?.user_name?.first_name} ${item?.user_name?.last_name}`,
                    label: `${item?.user_name?.first_name} ${item?.user_name?.last_name}`
                }
                return dummy
            })

            if (rmUsers && rmUsers.length > 0) {
                setRmUsers(rmUsers)
            } else {
                setRmUsers(allRmUsers)
            }
        } else {
            setLowRiskProcessors(allProcessors?.filter((processor: any) => processor.value !== BANK_CODES.DART))
            setHighRiskProcessors(allProcessors?.filter((processor: any) => cdBanks.indexOf(processor.value) === -1))
            setRmUsers(allRmUsers)
            setRiskLevels(allRiskLevels)
        }
    }

    useEffect(() => {
        if ((!!officeName && (role === ROLES.OFFICE_ADMIN ? (selectedOffice && selectedOffice?.value) : true)||(!!officeName && super_user))) { 
            setCreateOfficeEnabled(true)
        } else {
            setCreateOfficeEnabled(false)
        }
    }, [officeName, selectedOffice, selectedHighRiskProcessors, selectedLowRiskProcessors, selectedRiskLevel, selectedRM])


    const sleep = (milliseconds: number) => {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };

    const showOverlayForThreeSeconds = async () => {
        const sstyle: any = { style: { display: 'block', position: 'fixed' } };
        setOverlay(sstyle);
        await sleep(2000);
        const hstyle: any = { style: { display: "none" } };
        setOverlay(hstyle);
    };

    const handleSelectedOffice = (item: any) => {
        setSelectedOffice(item)
        let selected_office_level = parseInt(item.office_level)
        let dummyOffice: any = item
        let dummyLowRiskProcessors: any[] = []
        let dummyHighRiskProcessors: any[] = []
        let dummyRms: any[] = []
        let dummyRiskLevels: any[] = []
        for (let i = selected_office_level; i > 0; i--) {
            if (i < selected_office_level) {
                let currentIndex: any 
                if( role === ROLES.OFFICE_ADMIN || ROLES.SUPER_ADMIN){
                    currentIndex = offices.findIndex((office: any) => office.office_id === dummyOffice?.parent_office_id)
                    dummyOffice = offices[currentIndex]
                }
                else if( role === ROLES.RELATIONSHIP_MANAGER){
                    currentIndex = allOffices.findIndex((office: any) => office.office_id === dummyOffice?.parent_office_id)
                    dummyOffice = allOffices[currentIndex]
                }
            }
            if (dummyLowRiskProcessors?.length > 0 && dummyHighRiskProcessors?.length > 0 && dummyRms?.length > 0 && dummyRiskLevels?.length > 0) {
                break;
            } else {
                if ( !dummyLowRiskProcessors || dummyLowRiskProcessors?.length === 0) {
                    dummyLowRiskProcessors = dummyOffice?.allowed_low_risk_processors
                }
                if ( !dummyHighRiskProcessors || dummyHighRiskProcessors?.length === 0) {
                    dummyHighRiskProcessors = dummyOffice?.allowed_high_risk_processors
                }
                if (!dummyRms || dummyRms?.length === 0) {
                    dummyRms = dummyOffice?.allowed_RMs
                }
                if (!dummyRiskLevels || dummyRiskLevels?.length === 0) {
                    dummyRiskLevels = dummyOffice?.allowed_risk_levels
                }
            }
        }
        //WHEN LEVEL TWO OFFICE LOGGED IN
        if (( !dummyLowRiskProcessors || dummyLowRiskProcessors?.length === 0) && ( !dummyHighRiskProcessors || dummyHighRiskProcessors?.length === 0) && (!dummyRms || dummyRms?.length === 0) && (!dummyRiskLevels || dummyRiskLevels?.length === 0)) {
            if ( !dummyLowRiskProcessors || dummyLowRiskProcessors?.length === 0) {
                dummyLowRiskProcessors = levelOneOfficeDetails?.allowed_low_risk_processors
            }
            if ( !dummyHighRiskProcessors || dummyHighRiskProcessors?.length === 0) {
                dummyHighRiskProcessors = levelOneOfficeDetails?.allowed_high_risk_processors
            }
            if (!dummyRms || dummyRms?.length === 0) {
                dummyRms = levelOneOfficeDetails?.allowed_RMs
            }
            if (!dummyRiskLevels || dummyRiskLevels?.length === 0) {
                dummyRiskLevels = levelOneOfficeDetails?.allowed_risk_levels
            }
        }
        //SETTING DATA INTO STATES
        // setProcessors(allProcessors?.filter((processor: any)=> dummyProcessors?.includes(processor.value)))
        setLowRiskProcessors(allProcessors?.filter((processor: any)=> dummyLowRiskProcessors?.includes(processor.value) && processor.value !== BANK_CODES.DART))
        setHighRiskProcessors(allProcessors?.filter((processor: any)=> dummyHighRiskProcessors?.includes(processor.value)))
        setRmUsers(dummyRms?.map((item: any) => {
            return {
                ...item,
                value: `${item?.user_name?.first_name} ${item?.user_name?.last_name}`,
                label: `${item?.user_name?.first_name} ${item?.user_name?.last_name}`
            }
        }))
        setRiskLevels(dummyRiskLevels?.map((item: any) => {
            return {
                value: item,
                label: item
            }
        }))
        
        //FETCHING DATA FROM BACK-END
        if (!dummyLowRiskProcessors || dummyLowRiskProcessors?.length === 0) {
            setLowRiskProcessors(allProcessors?.filter((processor: any) => processor.value !== BANK_CODES.DART))
        }
        if (!dummyHighRiskProcessors || dummyHighRiskProcessors?.length === 0) {
            setHighRiskProcessors(allProcessors)
        }
        if (!dummyRms || dummyRms?.length === 0) {
            setRmUsers(allRmUsers)
        }
        if (!dummyRiskLevels || dummyRiskLevels?.length === 0) {
            setRiskLevels(allRiskLevels)
        }
    }

    const handleSelectedLowRiskProcessors = (value: any) => {
        setSelectedLowRiskProcessors(value)
    }
    const handleSelectedHighRiskProcessors = (value: any) => {
        setSelectedHighRiskProcessors(value)
    }

    const handleSelectedRiskLevel = (value: any) => {
        setSelectedRiskLevel(value)
        const lowRiskLevel = value.find((item: any) => item.value === RISK_LEVELS.LOW_RISK)
        const highRiskLevel = value.find((item: any) => item.value === RISK_LEVELS.HIGH_RISK)
        if(!lowRiskLevel){
            setSelectedLowRiskProcessors([]);
        }
        if(!highRiskLevel){
            setSelectedHighRiskProcessors([]);
        }
        if (value.length === 0) {
            setSelectedLowRiskProcessors([]);
            setSelectedHighRiskProcessors([]);
        }
        const allowed_risk_levels = value.map((item: any) => { return item.value })
        if(isEditable){
            if(!allowed_risk_levels.includes(RISK_LEVELS.HIGH_RISK)){
                setSelectedHighRiskProcessors([])
            }else if(!selectedHighRiskProcessors || selectedHighRiskProcessors.length <= 0){
                const allowed_highrisk_processors = editOfficeDetails?.allowed_high_risk_processors?.map((processor: string) => {
                    return {
                        value: processor,
                        label: processor
                    }
                })
            setSelectedHighRiskProcessors(allowed_highrisk_processors)
            }
            if(!allowed_risk_levels.includes(RISK_LEVELS.LOW_RISK)){
            setSelectedLowRiskProcessors([])
            }else if(!selectedLowRiskProcessors || selectedLowRiskProcessors.length <= 0){
                const allowed_lowrisk_processors = editOfficeDetails?.allowed_low_risk_processors?.map((processor: string) => {
                    return {
                        value: processor,
                        label: processor
                    }
                })
                setSelectedLowRiskProcessors(allowed_lowrisk_processors)
            }
        }
    }
    const handleSelectedRM = (value: any) => {
        console.log(value)
        setSelectedRM(value)
    }
    const handleUpdateOffice = async () => {
        let dataObj: any = {
            office_name: officeName,
            office_id: editOfficeDetails.office_id
        }
        if (selectedOffice && selectedOffice.office_id && editOfficeDetails.office_level!== '1') {
            dataObj.parent_office_id = selectedOffice.office_id
        }
        if (selectedLowRiskProcessors && selectedLowRiskProcessors.length > 0) {
            dataObj.allowed_low_risk_processors = selectedLowRiskProcessors?.map((item: any) => { return item.value })
        }
        if (selectedHighRiskProcessors && selectedHighRiskProcessors.length > 0) {
            dataObj.allowed_high_risk_processors = selectedHighRiskProcessors?.map((item: any) => { return item.value })
        }
        if (selectedRiskLevel && selectedRiskLevel.length > 0) {
            dataObj.allowed_risk_levels = selectedRiskLevel?.map((item: any) => { return item.value })
        }
        if (selectedRM && selectedRM.length > 0) {
            if (REACT_APP_BACKEND_MYSQL) {
                dataObj.allowed_rm_ids = selectedRM?.map((item: any) => {
                    return item.user_id
                })
            } else {
                dataObj.allowed_RMs = selectedRM?.map((item: any) => {
                    return { user_id: item.user_id, user_guid: item.user_guid, user_name: item.user_name }
                })
            }
        }
        try {
            const data: any = {
                api: api.offices.updateOffice,
                payLoad: JSON.stringify(dataObj)
            };
            setDeleteModalOpen(false);
            setLoading(true);
            const response = await backend.save(data);
            if (response) {
                handleCancel()
                setIsEditable(false);
                setOverlaymsg("Office updated successfully");
                window.scroll({ top: 0, left: 0 });
                showOverlayForThreeSeconds() 
                await fetchOfficeList()               
            } else {
                setError("Something went wrong while updating office!")
                setOverlaymsg("Something went wrong while updating office!")
                showOverlayForThreeSeconds()
            }
        } catch (err: any) {
            setError(err.errMessage || "Something went wrong while updating office!")
            setOverlaymsg(err.message || "error occured while updating");
        }finally{
            setLoading(false);
        }
    }

    const handleCreateOffice = async () => {
        setLoading(true)
        uncheckparentcheckbox()
        if (isEditable) {
            handleUpdateOffice()
        } else {
            let dataObj: any = {
                office_name: officeName,
                paper_apps_enabled: paperAppsEnabled,
                free_equipment_placement_enabled: freeEquipmentPlacementEnabled
            }
            if (selectedOffice && selectedOffice.office_id) {
                dataObj.parent_office_id = selectedOffice.office_id
            }
            if (selectedLowRiskProcessors && selectedLowRiskProcessors.length > 0) {
                dataObj.allowed_low_risk_processors = selectedLowRiskProcessors?.map((item: any) => { return item.value })
            }
            if (selectedHighRiskProcessors && selectedHighRiskProcessors.length > 0) {
                dataObj.allowed_high_risk_processors = selectedHighRiskProcessors?.map((item: any) => { return item.value })
            }
            if (selectedRiskLevel && selectedRiskLevel.length > 0) {
                dataObj.allowed_risk_levels = selectedRiskLevel?.map((item: any) => { return item.value })
            }
            if (selectedRM && selectedRM.length > 0) {
                if (REACT_APP_BACKEND_MYSQL) {
                    dataObj.allowed_rm_ids = selectedRM?.map((item: any) => {
                        return item.user_id
                    })
                } else {
                    dataObj.allowed_RMs = selectedRM?.map((item: any) => {
                        return { user_id: item.user_id, user_guid: item.user_guid, user_name: item.user_name }
                    })
                }
            }
            const data: any = {
                api: api.offices.createOffice,
                payLoad: JSON.stringify(dataObj)
            }
            try {
                const response = await backend.save(data)
                if (response) {
                    handleCancel()
                    window.scroll({ top: 0, left: 0 });
                    setOverlaymsg("Office created Successfully")
                    showOverlayForThreeSeconds()
                    await fetchOfficeList()
                } else {
                    setError("Something went wrong while creating office!")
                    setOverlaymsg("Something went wrong while creating office!")
                    showOverlayForThreeSeconds()
                }
            }
            catch (err: any) {
                console.log(err)
                setError(err.errMessage || "Something went wrong while creating office!")
                showOverlayForThreeSeconds()
                setOverlaymsg("Something went wrong while creating office!")
            }finally{
                setLoading(false)
                setFreeEquipmentPlacementEnabled(false);
            }
        }

    }

    const handleCancel = () => {
        setOfficeName('')
        setSelectedRM([])
        setSelectedLowRiskProcessors([])
        setSelectedHighRiskProcessors([])
        setSelectedRiskLevel([])
        setSelectedOffice(currentOffice)
        setShowCreateOfficeModal(false)
        setIsEditable(false)
        setEditOfficeDetails({})
        setDetails({})
        setDeleteModalOpen(false)
        setDeactivateModalOpen(false)
        setWarningModalOpen(false)
        setSearchText('')
        setSearchField('')
        setNoSearchResultModal(false)
        setPaperAppsEnabled(false)
        setFreeEquipmentPlacementEnabled(false)
        setOpenEnablePaperAppsModal(false)
        setOpenEnableFreeEquipmentPlacementModal (false)
        setOfficeActiveDeactiveBulkConfirmation(false)
        setOfficePaperAppsBulkConfirmation(false)
        setError('')
        setBulkaction('')
        // setRmUsers([])
    }

    // useEffect(() => {
    //     if(showCreateOfficeModal && super_user) {
    //         setRmUsers(allRmUsers)
    //     }
    // }, [showCreateOfficeModal, super_user])

    useEffect(() => {
        if (isEditable) {
            let editOfficeLowRiskProcessors = allProcessors?.reduce((filteredProcessors: any, processor: any) => {
                if (editOfficeDetails.allowed_low_risk_processors?.includes(processor.value)) {
                    filteredProcessors.push(processor);
                }
                return filteredProcessors;
            }, []);

            let editOfficeHighRiskProcessors = allProcessors?.reduce((filteredProcessors: any, processor: any) => {
                if (editOfficeDetails.allowed_high_risk_processors?.includes(processor.value)) {
                    filteredProcessors.push(processor);
                }
                return filteredProcessors;
            }, []);

            let selectedRiskLevel = riskLevels?.reduce((filteredRiskLevel: any, riskLevel: any) => {
                if (editOfficeDetails.allowed_risk_levels?.includes(riskLevel.value)) {
                    filteredRiskLevel.push(riskLevel);
                }
                return filteredRiskLevel;
            }, []);

            let selectedRms: any = rmUsers?.filter((item: any) => {
                let data = editOfficeDetails?.allowed_RMs?.filter((rm_details: any) => {
                    if (item.user_id === rm_details.user_id) {
                        return item
                    }
                })
                if (data && data.length > 0) {
                    return data[0]
                }
            }) 

           /* Hidden this block for TP-1131
           
           if(selectedRms?.length === 0){
                selectedRms = rmUsers?.filter((item: any) => {
                    let data = selectedRM?.filter((rm_details: any) => {
                        if (item.user_id === rm_details.user_id) {
                            return item
                        }
                    })
                    if (data && data.length > 0) {
                        return data[0]
                    }
                })
            }*/
            setSelectedLowRiskProcessors(editOfficeLowRiskProcessors)
            setSelectedHighRiskProcessors(editOfficeHighRiskProcessors)
            setSelectedRiskLevel(selectedRiskLevel)
            setSelectedRM(selectedRms)
        }
    }, [isEditable, processors, rmUsers, riskLevels])

    
    const handleEditOffice = (id: number) => {

        const editOfficeIndex = offices.findIndex((office: any) => office.office_id === id)

        const editOfficeData = offices[editOfficeIndex]
        console.log("officeid: ",editOfficeData.office_id)
        console.log("office_id: ",office_id)
        console.log("super_user: ",super_user)
        if((role === ROLES.OFFICE_ADMIN && editOfficeData.office_id === Number(office_id ))){
            setWarningModalOpen(true)
        }else{
            setEditOfficeDetails(editOfficeData)
            setIsEditable(true)
            setShowCreateOfficeModal(true)
            setOfficeName(editOfficeData.office_name)
        
            if (editOfficeData.office_level === '1' ) {
                updateDropDownOptions()
            } else if (editOfficeData.office_level === '3' || editOfficeData.office_level === '2') {
                if( role === ROLES.RELATIONSHIP_MANAGER){
                    let parentOfficeIndex = allOffices?.findIndex((office: any) => editOfficeData.parent_office_id === office.office_id)
                    let selectedOffice = { ...allOffices[parentOfficeIndex], value: allOffices[parentOfficeIndex].office_id, label: allOffices[parentOfficeIndex].office_name }
                    handleSelectedOffice(selectedOffice)
                    let officeDropDown = allOffices?.filter((office: any) => !office.deleted && !office?.deactivate )?.reduce((filteredOffices: any, office: any) => {
                        const rm_details = office?.allowed_RMs?.filter((rm_user: any) => user_id === rm_user.user_id)

                        //adding only if loged in user exist in rms & ignoring selecected offie here & ignoring level 3 offices & ignoring current editing office & showing L2 offices for L3 & L1 offices for L2
                        if (rm_details && rm_details.length > 0 && office.office_id !== selectedOffice.office_id && office.office_level!=='3' && office.office_id !== editOfficeData.office_id && (( editOfficeData.office_level ==='2' && office.office_level === '1') || ( editOfficeData.office_level ==='3' && office.office_level === '2'))) {
                          filteredOffices.push({ ...office, value: office.office_id, label: office.office_name });
                        }
                        return filteredOffices;
                    }, []);
                    officeDropDown.push(selectedOffice)
                    setofficesDropDown(officeDropDown)
                }else{
                    let parentOfficeIndex = offices?.findIndex((office: any) => editOfficeData.parent_office_id === office.office_id)
                    let selectedOffice = { ...offices[parentOfficeIndex], value: offices[parentOfficeIndex].office_id, label: offices[parentOfficeIndex].office_name }
                    handleSelectedOffice(selectedOffice)
                    
                    //ignoring selected office here and ignoring level 3 offices 
                    let officeDropDown = offices?.filter((office: any, index: number) => (parentOfficeIndex !== index && office.office_level!=='3' && (( editOfficeData.office_level ==='2' && office.office_level === '1') || ( editOfficeData.office_level ==='3' && office.office_level === '2')) && !office.deleted && !office?.deactivate))?.map((office: any) => {
                        return { ...office, value: office.office_id, label: office.office_name }
                    })
                    officeDropDown.push(selectedOffice)
                    setofficesDropDown(officeDropDown)
                }
            }
        }
        
    }

    const handleDeleteModal = (id: number) => {

        const editOfficeIndex = offices.findIndex((office: any) => office.office_id === id)

        const editOfficeData = offices[editOfficeIndex]
        setEditOfficeDetails(editOfficeData)
        
        if(role === ROLES.OFFICE_ADMIN && editOfficeData.office_id == office_id ){
            setWarningModalOpen(true)
        }else{
            setDeleteModalOpen(true)
        }
    }

    const handleDeactivateModal = async (id: string)=>{
        const editOfficeIndex = offices.findIndex((office: any) => office.office_id === id)
        const editOfficeData = offices[editOfficeIndex]
        setEditOfficeDetails(editOfficeData)
        
        if(role === ROLES.OFFICE_ADMIN && editOfficeData.office_id == office_id ){
            setWarningModalOpen(true)
        }else{
            setDeactivateModalOpen(true)
        }
    }
    
  const handleDeactivateActivate = async (office_id:any, deactivate: boolean) =>{
    setLoading(true)
    const officeDetails: any = {
     office_id: office_id,
     deactivate: deactivate
    }
    console.log(office_id, deactivate)
    const data: any = {
      api: deactivate? api.offices.deactivate : api.offices.activate,
      payLoad: JSON.stringify(officeDetails)
    };
    setDeleteModalOpen(false);
    try {
      const response = await backend.save(data)
      if (response) {
        await fetchOfficeList()
        setOverlaymsg(deactivate? "Office deactivated successfully" : "Office activated successfully" );
        showOverlayForThreeSeconds()
        setDeactivateModalOpen(false)
      }
    }
    catch (err: any) {
      setOverlaymsg(err.errMessage || "Error occured while deactivating user.");
      showOverlayForThreeSeconds()
      setDeactivateModalOpen(false)
    } finally {
      window.scroll({ top: 0, left: 0 });
              setLoading(false) 
  }
}

    const handleDeleteOffice = async () => {
        try {
            const data: any = {
                api: api.offices.deleteOffice,
                payLoad: JSON.stringify({ office_id: editOfficeDetails.office_id })
            };
            setDeleteModalOpen(false);
            setLoading(true);
            const response = await backend.save(data);
            if (response) {
                uncheckparentcheckbox()
                let updatedOffices = offices?.map((office: any) => {
                    if(office.office_id === response.office_id || response?.deleted_office_ids?.includes(office.office_id)){
                        return { ...office, deleted: true }
                    }else{
                        return office
                    }
                })
                let updatedAllOffices = allOffices?.map((office: any) => {
                    if(office.office_id === response.office_id || response?.deleted_office_ids?.includes(office.office_id)){
                        return { ...office, deleted: true }
                    }else{
                        return office
                    }
                })
                setAllOffices(updatedAllOffices)
                setOffices(updatedOffices)
                handleCancel()
                setIsEditable(false);
                window.scroll({ top: 0, left: 0 });
                setOverlaymsg("Office deleted successfully");
                showOverlayForThreeSeconds()
                setSelectedOfficeBulk([])
            } else {
                handleCancel()
                window.scroll({ top: 0, left: 0 });
                setOverlaymsg("Something went wrong while deleting office!")
                showOverlayForThreeSeconds()
            }
        } catch (err: any) {
            handleCancel()
            window.scroll({ top: 0, left: 0 });
            setOverlaymsg(err.errMessage || "error occured while deleting");
            showOverlayForThreeSeconds()
        }finally{
            setLoading(false);
        }
    }

      //Searching
  const handleSearchByFieldName = async (field: string, value: string) => {
    if(!noSearchResultModal){
        if (!!value) {
            setSearchField(field);
            } else {
            setSearchField("");
        }
        setSearchText(value);
    }
  }

  //Sorting
  const handleSortByFieldName = async (field: string, order: string) => {
    setSortFieldOrder(sortFieldOrder === "desc" ? "asc" : "desc")
    setSortField(field);
    let officesTemp = sortAndSearchResultData;

    officesTemp.sort(
    firstBy(function (office: any) {
        switch (field) {
        case 'office_id':
            return office?.office_id?.toString().toLowerCase();
        case 'office_name':
            return office?.office_name?.toLowerCase();
        case 'parent_office_name':
            return office?.parent_office_name?.toLowerCase() || '';
        case 'office_manager_names':
            let office_manager_names = getParsedList(office?.office_manager_names)
            return office_manager_names.length > 0 ?
            office_manager_names?.map((manager: any) => { return manager.toLowerCase() }).join(', ')
            : ''

        case 'office_admin_names':
            let office_admin_names = getParsedList(office?.office_admin_names)
            return office_admin_names.length > 0 ?
            office_admin_names?.map((admin: any) => { return admin.toLowerCase() }).join(', ')
            : ''
        case 'allowed_RMs':
            return office?.allowed_RMs?.length > 0 ?
            office?.allowed_RMs?.map((rm: any) => { return `${rm.user_name.first_name} ${rm.user_name.last_name}`.toLowerCase() }).join(', ')
            : ''
        case 'allowed_low_risk_processors':
            return office?.allowed_low_risk_processors?.length > 0 ?
            office?.allowed_low_risk_processors?.map((processor: any) => { return processor.toLowerCase() }).join(', ')
            : ''
        case 'allowed_high_risk_processors':
            return office?.allowed_high_risk_processors?.length > 0 ?
            office?.allowed_high_risk_processors?.map((processor: any) => { return processor.toLowerCase() }).join(', ')
            : ''
        case 'allowed_risk_levels':
            return office?.allowed_risk_levels?.length > 0 ?
            office?.allowed_risk_levels?.map((risk_level: any) => { return risk_level.toLowerCase() }).join(', ')
            : ''
        case 'free_equipment_placement':
            return office?.free_equipment_placement_enabled || false;    
        case 'paper_apps':
            return office?.paper_apps_enabled || false;
        case 'deactivate_status':
            return office?.deactivate || false;
        default:
            return office?.office_id?.toString().toLowerCase();
        }
    },{ direction: order === 'desc' ? 'asc' : 'desc' })
    );
    
    setSortAndSearchResultData(officesTemp);
  }


    useEffect(() => {
        const sortAndSearchResult = (data: any) => {
            const officesTemp: any = data;
            let officesSearchResult: any = officesTemp;

            if (!!searchText && !!searchField) {
                officesSearchResult = officesTemp?.filter((item: any) => {
                    let searched_Field = "";
                    if (searchField === "allowed_high_risk_processors" || searchField === "allowed_low_risk_processors" || searchField === "allowed_risk_levels" ||
                        searchField === "office_manager_names" || searchField === "office_admin_names") {
                        searched_Field = (!!item[searchField] && getParsedList(item[searchField])?.length > 0) ? getParsedList(item[searchField]).join(', ') : ''
                    } else if (searchField === "allowed_RMs") {
                        searched_Field = item[searchField]?.length > 0 ?
                            item[searchField]?.map((rm: any) => { return `${rm.user_name.first_name} ${rm.user_name.last_name}` }).join(', ')
                            : ''
                    } else {
                        if (item[searchField]) {
                            searched_Field = item[searchField]
                        };
                    }
                    return searched_Field.toString().toLowerCase().indexOf(searchText.toLowerCase()) > -1
                })
            }

            const sortFieldOrderTemp: any = sortFieldOrder === "desc" ? -1 : 1;

            officesSearchResult.sort(
                firstBy((user: any) => {
                    if (sortField === "allowed_high_risk_processors" || sortField === "allowed_low_risk_processors" || sortField === "allowed_risk_levels" ||
                        sortField === "office_manager_names" || sortField === "office_admin_names") {
                        return user[sortField]?.length > 0 ? user[sortField]?.join(', ') : ''
                    } else if (sortField === "allowed_RMs") {
                        return user[sortField]?.length > 0 ?
                            user[sortField]?.map((rm: any) => { return `${rm.user_name.first_name} ${rm.user_name.last_name}` }).join(', ')
                            : ''
                    }
                    return get(user, sortField, "").toLowerCase();
                }, { direction: sortFieldOrderTemp })
            );
            return officesSearchResult;
        }
        if (!algoliaLoading && offices.length > 0) {
            const sortedOffices = sortAndSearchResult(offices)
            if (sortedOffices && sortedOffices.length > 0) {
                setSortAndSearchResultData(sortedOffices)
            } else {
                setNoSearchResultModal(true)
            }
        } else if (!algoliaLoading && offices.length === 0) {
            setSortAndSearchResultData([])
        }

    }, [searchText, searchField, offices])

const updatePaperAppsEnabledStatus = async () => {
    try {
        setLoading(true);
        const payload: any = {
            api: api.offices.enablePaperAppSubmission,
            payLoad: JSON.stringify({
            office_id: editOfficeDetails.office_id,
            paper_apps_enabled: paperAppsEnabled || false,
            }),
        };
        const response = await backend.save(payload, true);
        if (response) {
            uncheckparentcheckbox()
            setOffices(
            offices.map((office: any) => {
                if (office.office_id === editOfficeDetails.office_id) {
                return { ...office, paper_apps_enabled: paperAppsEnabled };
                } else {
                return office;
                }
            })
            );
            setTimeout(() =>{
            setSortAndSearchResultData(
            sortAndSearchResultData.map((office: any) => {
                if (office.office_id === editOfficeDetails.office_id) {
                return { ...office, paper_apps_enabled: paperAppsEnabled };
                } else {
                return office;
                }
            })
            );
            }, 100);
            let message = response.message || `Paper Apps ${paperAppsEnabled ? 'enabled' : 'disabled'} for ${editOfficeDetails.office_name} ( ID - ${editOfficeDetails.office_id})`
            setOverlaymsg(message);
            setPaperAppsEnabled(!!paperAppsEnabled)
            handleCancel();
            window.scroll({ top: 0, left: 0 });
            showOverlayForThreeSeconds();
        } else {
            setOverlaymsg(response.message || "Something went wrong while updating updating!");
            showOverlayForThreeSeconds();
        }
    } catch (err: any) {
        console.log(err);
        setOverlaymsg("Something went wrong while updating office!");
        showOverlayForThreeSeconds();
    } finally {
        setLoading(false);
    }
};

const updateFreeEquipmentPlacementStatus = async () => {
    try {
        setLoading(true);
        const payload: any = {
            api: api.offices.enableFreeEquipmentPlacementSubmission,
            payLoad: JSON.stringify({
            office_id: editOfficeDetails.office_id,
            free_equipment_placement_enabled: freeEquipmentPlacementEnabled || false,
            }),
        };
        const response = await backend.save(payload, true);
        if (response) {
            uncheckparentcheckbox()
            setOffices(
            offices.map((office: any) => {
                if (office.office_id === editOfficeDetails.office_id) {
                return { ...office, free_equipment_placement_enabled: freeEquipmentPlacementEnabled };
                } else {
                return office;
                }
            })
            );
            setTimeout(() =>{
            setSortAndSearchResultData(
            sortAndSearchResultData.map((office: any) => {
                if (office.office_id === editOfficeDetails.office_id) {
                return { ...office, free_equipment_placement_enabled: freeEquipmentPlacementEnabled };
                } else {
                return office;
                }
            })
            );
            }, 100);
            let message = response.message || `Free Equipment Placement ${freeEquipmentPlacementEnabled ? 'enabled' : 'disabled'} for ${editOfficeDetails.office_name} ( ID - ${editOfficeDetails.office_id})`
            setOverlaymsg(message);
            setFreeEquipmentPlacementEnabled(!!freeEquipmentPlacementEnabled)
            handleCancel();
            window.scroll({ top: 0, left: 0 });
            showOverlayForThreeSeconds();
        } else {
            setOverlaymsg(response.message || "Something went wrong while updating updating!");
            showOverlayForThreeSeconds();
        }
    } catch (err: any) {
        console.log(err);
        setOverlaymsg("Something went wrong while updating office!");
        showOverlayForThreeSeconds();
    } finally {
        setLoading(false);
    }
};


const handlePaperAppsChecked = async (data: any, id: string) => {
    
    const editOfficeIndex = offices.findIndex((office: any) => office.office_id === id)

    const editOfficeData = offices[editOfficeIndex]
    setEditOfficeDetails(editOfficeData)
    setPaperAppsEnabled(data.checked)
    
    if(role === ROLES.OFFICE_ADMIN && editOfficeData.office_id == office_id ){
        setWarningModalOpen(true)
    }else{
        setOpenEnablePaperAppsModal(true)
    }
}

const handleFreeEquipmentPlacementChecked = async (data: any, id: string) => {
    const editOfficeIndex = offices.findIndex((office: any) => office.office_id === id)

    const editOfficeData = offices[editOfficeIndex]
    setEditOfficeDetails(editOfficeData)
    setFreeEquipmentPlacementEnabled(data.checked)
    
    if(role === ROLES.OFFICE_ADMIN && editOfficeData.office_id === office_id ){
        setWarningModalOpen(true)
    }else{
        setOpenEnableFreeEquipmentPlacementModal(true)
    }
}


    const renderShowUsersTable = () => {
        return (
            <>
               <OfficeTable
                    sortAndSearchResultData= {sortAndSearchResultData}
                    role= {role}
                    allProcessors= {allProcessors}
                    handleEditOffice= {handleEditOffice}
                    handleDeleteModal= {handleDeleteModal}
                    sortFieldOrder= {sortFieldOrder}
                    sortField= {sortField}
                    searchField= {searchField}
                    searchText= {searchText}
                    handleSearchByFieldName= {handleSearchByFieldName}
                    handleSortByFieldName= {handleSortByFieldName}
                    handlePaperAppsChecked={handlePaperAppsChecked}
                    handleFreeEquipmentPlacementChecked = {handleFreeEquipmentPlacementChecked}
                    handleDeactivateModal={handleDeactivateModal}
                    loading={loading}
                    algoliaLoading={algoliaLoading}
                    changeCheckboxStatus={changeCheckboxStatus}
                    isParentChecked={isParentChecked}
                    selectedOfficeBulk = {selectedOfficeBulk}
                    viewOptions = {viewOptions}
                    config={config}
                />
                {
                    (offices?.length === 0 && !loading && !algoliaLoading) && (<div className='body-content mobile-user-list-body'>
                        <div className='heading'>
                            <span className='heading-center mobile-user-list'>No Offices Found!!</span>
                        </div>
                    </div>)
                }
            </>
        )
    }

    return (
        <Layout page='offices'>
            <div className="offices-container">
                <div className={(window.innerWidth > 1640) ? 'body-content' : 'listapp-body-content'}>
                    {isMobile || isTablet ?
                    <button className={'mobile-add-button'} onClick={() => setShowCreateOfficeModal(true)}>
                        <img src={addButton} alt="Add" />
                        <span>Add Office</span>
                        </button> : <div className='heading flex-spacebetween'>
                        <span className='heading-text-left'>Offices List</span>
                         {/* <Button inverted  color='blue' size='massive' onClick={() => setShowCreateOfficeModal(true)}></Button> */}
                            <BasicButton
                                textColor={config?.client_config_fe?.button_color}
                                backgroundColor={'#FFFFFF'}
                                size='25px'
                                buttonText="ADD OFFICE"
                                className="list-app-btn"
                                onClick={() => setShowCreateOfficeModal(true)}
                            />
                    </div>}
                    { Object.keys(selectedOfficeBulk).length > 1 && (
                        <div className='bulkupdate-action'>
                            <Select
                               isMulti={false}
                               value={bulkaction}
                               options={role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.SUPER_ADMIN || role === ROLES.OFFICE_ADMIN ? officeBulkActionWithPaper : officeBulkAction}
                               onChange={bulkUpdateAction}
                               placeholder="Bulk Action"
                               isSearchable={false}
                            />
                        </div>
                    )} 

                    {renderShowUsersTable()}
                    <ButtonScrollTop />
                </div>
                <ProgressLoader loading={loading} size='large' />
            </div>
            <div className="overlay" style={overlay.style}>
                <div className="overlay-image">
                    <span className="overlay-text" style={{ color: config?.client_config_fe?.button_color }}>{overlayMsg}</span>
                </div>
            </div>
            { showCreateOfficeModal &&
                <CreateOffice 
                    officesDropDown={officesDropDown}
                    handleCancel={handleCancel}
                    editOfficeDetails={editOfficeDetails}
                    details={details}
                    selectedOffice={selectedOffice}
                    loading={loading}
                    showCreateOfficeModal={showCreateOfficeModal}
                    isEditable={isEditable}
                    handleSelectedOffice={handleSelectedOffice}
                    lowRiskProcessors={lowRiskProcessors}
                    highRiskProcessors={highRiskProcessors}
                    selectedLowRiskProcessors={selectedLowRiskProcessors}
                    selectedHighRiskProcessors={selectedHighRiskProcessors}
                    officeName={officeName}
                    setOfficeName={setOfficeName}
                    handleSelectedLowRiskProcessors={handleSelectedLowRiskProcessors}
                    handleSelectedHighRiskProcessors={handleSelectedHighRiskProcessors}
                    riskLevels={riskLevels}
                    selectedRiskLevel={selectedRiskLevel}
                    handleSelectedRiskLevel={handleSelectedRiskLevel}
                    rmUsers={rmUsers}
                    selectedRM={selectedRM}
                    handleSelectedRM={handleSelectedRM} 
                    error={error}
                    createOfficeEnabled={createOfficeEnabled}
                    handleCreateOffice={handleCreateOffice}
                    role={role}
                    paperAppsEnabled={paperAppsEnabled}
                    setPaperAppsEnabled={setPaperAppsEnabled}
                    freeEquipmentPlacementEnabled={freeEquipmentPlacementEnabled}
                    setFreeEquipmentPlacementEnabled={setFreeEquipmentPlacementEnabled}
                    config={config}
                />
            }
            { deleteModalOpen &&
                <DeleteModal
                    deleteModalOpen={deleteModalOpen}
                    handleCancel={handleCancel}
                    editOfficeDetails={editOfficeDetails}
                    handleDeleteOffice={handleDeleteOffice} 
                    loading={loading}
                />          
            }
            {deactivateModalOpen && (
                <DeactivateModal
                    editOfficeDetails={editOfficeDetails}
                    loading={loading}
                    deactivateModalOpen={deactivateModalOpen}
                    handleCancel={handleCancel}
                    handleDeactivateActivate={handleDeactivateActivate}
                    config={config}

                />
            )}
            { warningModalOpen &&
                <WarningModal
                    warningModalOpen={warningModalOpen}
                    handleCancel={handleCancel}
                    loading={loading}
                    config={config}
                />
            }
            { noSearchResultModal &&
                <SearchResultPopUp
                    noSearchResultModal={noSearchResultModal}
                    handleCancel={handleCancel}
                    searchText={searchText}
                    config={config}
                />
            }
            {openEnablePaperAppsModal && (
                <EnablePaperAppsModal
                handleCancel={handleCancel}
                updatePaperAppsEnabledStatus={updatePaperAppsEnabledStatus}
                loading={loading}
                openEnablePaperAppsModal={openEnablePaperAppsModal}
                editOfficeDetails={editOfficeDetails}
                paperAppsEnabled={paperAppsEnabled}
                config={config}
                />
            )}
            {openEnableFreeEquipmentPlacementModal && (
                <EnableFreeEquipmentPlacementModal
                handleCancel={handleCancel}
                updateFreeEquipmentPlacementStatus={updateFreeEquipmentPlacementStatus}
                loading={loading}
                openEnableFreeEquipmentPlacementModal ={openEnableFreeEquipmentPlacementModal}
                editName={editOfficeDetails?.office_name}
                editId={editOfficeDetails?.office_id}
                freeEquipmentPlacementEnabled={freeEquipmentPlacementEnabled}
                />
              )}
            {officeActiveDeactiveBulkConfirmation && (
            <ActionConfirmation
                selectedOffice={selectedOfficeBulk}
                loading={loading}
                handleCancel={handleCancel}
                modalAction={modalAction}
                handleConfirmAction={handleConfirmAction}
                officeActiveDeactiveBulkConfirmation={officeActiveDeactiveBulkConfirmation} 
                countforAction = {countforAction}
                config={config}
                />
            )}
             {officePaperAppsBulkConfirmation && (
            <PaperAppsActionConfirmation
                selectedOffice={selectedOfficeBulk}
                loading={loading}
                handleCancel={handleCancel}
                modalAction={modalAction}
                handleConfirmActionPaperApps={handleConfirmActionPaperApps}
                officePaperAppsBulkConfirmation={officePaperAppsBulkConfirmation} 
                countforAction = {countforAction}  
                config={config}
                />
            )}
            <Footer page="office"/>
        </Layout>
    )
}

export default memo(Offices )
