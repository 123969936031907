/**
 * A file with all Style Constants for Antd Component's inline style prop.
 * Add the style object to export and use within pages/modules/components etc.
 * 
 */

export const layoutStyle = {
  backgroundColor: '#FFF',
  fontFamily: "\"Montserrat\", sans-serif",
};

export const siderStyle = {
  backgroundColor: '#FFF',
};

export const dashboardContentStyle = {
  width: '100%',
  padding: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const onboardingContentStyle = {
  width: '100%',
  padding: 30,
  display: 'flex',
  color: "#FFF",
  flexDirection: 'column' as 'column',
};

export const superuserContentStyle = {
  width: '100%',
  padding: 30,
  display: 'flex',
  flexDirection: 'column' as 'column',
};

