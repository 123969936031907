import React ,{ useContext }from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../Common/ProgressLoader";
import { AppContext } from '../../context/appContext';
import BasicButton from '../Common/Button';

interface EnableFreeEquipmentPlacementProps {
  loading: boolean;
  editName: any;
  editId: any;
  openEnableFreeEquipmentPlacementModal: boolean;
  handleCancel: () => void;
  updateFreeEquipmentPlacementStatus: () => Promise<void>;
  freeEquipmentPlacementEnabled: boolean;
}

const EnableFreeEquipmentPlacementModal = (props: EnableFreeEquipmentPlacementProps ) => {
  const { config }: any = useContext(AppContext);
  const {
    handleCancel,
    updateFreeEquipmentPlacementStatus,
    openEnableFreeEquipmentPlacementModal,
    editName,
    editId,
    freeEquipmentPlacementEnabled,
    loading,
  } = props;

  return (
    <Modal
      centered
      open={openEnableFreeEquipmentPlacementModal}
      onClose={handleCancel}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <div className="close-button">
          <button
            className="email-activity-close-button"
            onClick={handleCancel}
          >
            
          </button>
        </div>
        <ProgressLoader loading={loading} size="small"  config={config} />
        <div className="word-wrap">
        {`Are you sure you want to ${
          freeEquipmentPlacementEnabled ? "ENABLE" : "DISABLE"
        } Free Equipment Placement for ${editName} ( ID - ${editId} )?`}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <BasicButton
          textColor={config?.client_config_fe?.button_color}
          backgroundColor={'#FFFFFF'}
          size='18px'
          buttonText='CANCEL'
          className="save-button button-border"
          onClick={handleCancel}
        />
        <button
          style={{ background: config?.client_config_fe?.button_color }}
          onClick={updateFreeEquipmentPlacementStatus}
          className="ipad-blue save-button button-border"
        >  YES
        </button>
        
      </Modal.Actions>
    </Modal>
  );
};

export default EnableFreeEquipmentPlacementModal