import React from "react";
import OfficeTableRow from "./OfficeTableRow";

interface OfficeTableBodyProps {
    sortAndSearchResultData: any[]
    role: string | null
    allProcessors: any[]
    handleEditOffice: (id: number) => void
    handleDeleteModal: (id: number) => void
    handlePaperAppsChecked: (data: any, user_id: string) => Promise<void>
    handleFreeEquipmentPlacementChecked: (data: any, user_id: string) => Promise<void>
    handleDeactivateModal: (office_id: string) => Promise<void>
    changeCheckboxStatus: (e: any, id: string) => Promise<void>
    selectedOfficeBulk: any[]
    config:any
}

const OfficeTableBody = (props: OfficeTableBodyProps) => {
    
    const { handleEditOffice, handleDeleteModal, sortAndSearchResultData, allProcessors,role, handlePaperAppsChecked,handleDeactivateModal, changeCheckboxStatus, selectedOfficeBulk,handleFreeEquipmentPlacementChecked,config} = props

    return (
        <>
        {sortAndSearchResultData?.filter((office: any, index: any) => !office.deleted)
            .map((office: any, index: any) => {
                return <OfficeTableRow
                    key={office.office_id || index}
                    office={office}
                    index={index}
                    role={role}
                    handleDeleteModal={handleDeleteModal}
                    handleEditOffice={handleEditOffice}
                    allProcessors={allProcessors}
                    handlePaperAppsChecked={handlePaperAppsChecked}
                    handleFreeEquipmentPlacementChecked={handleFreeEquipmentPlacementChecked}
                    handleDeactivateModal={handleDeactivateModal}
                    changeCheckboxStatus={changeCheckboxStatus}
                    selectedOfficeBulk={selectedOfficeBulk}
                    config={config}
                />
            })
        }
        </>
    )
}

export default OfficeTableBody;
