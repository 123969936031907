import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";

import "semantic-ui-css/semantic.min.css";
import "../Common/progressLoader.css";

import { AUTH_ROUTES, NON_AUTH_ROUTES } from "./AllRoutes";
import PrivateRoute from "./PrivateRoute";
import Login from "../Auth/Login";
import StartApp from "../Pages/StartApp";
import SendApp from "../Pages/SendApp";
import ListApp from "../Pages/ListApp";
import DocUpload from "../Pages/DocUpload";
import ThankyouPage from "./ThanksPage";
import NotFound from "./NotFound";
import { auth } from "../../Firebase";
import UnAuthorised from "./UnAuthorised";
import SessionHandler from "./SessionHandler";
import ScrollToTop from "./ScrollToTop";
import FieldList from "../Pages/FieldList/FieldList";
import UsersList from '../Pages/UsersList';
import Offices from '../Pages/Offices';
import Audit from '../Pages/Audit';
import UpdateSigningStatus from "../Pages/UpdateSigningStatus";
import ButtonScrollTop from '../Common/ButtonScrollTop';
import APIIntegrations from "../Pages/APIIntegrations";
import { AppContext } from "../../context/appContext";
import api from '../../Service/Api'
import backend from '../../Service/Backend'
import PlatformAdmin from "../Pages/PlatformAdmin";
import MaintenanceMessage from '../Pages/MaintenanceMessage';
import DowntimeMessage from "../Pages/DowntimeMessage";




const {
   REACT_APP_MAINTENANCE_FLAG,REACT_APP_DOWNTIME_FLAG,REACT_APP_DEFAULT_HOST,REACT_APP_DEFAULT_CLIENT_ID
} = process.env;

const App: React.FC = () => {
  const { config, setConfig }: any = useContext(AppContext);
  const [firebaseInitialised, setFirebaseInitialised] = useState(false);

  useEffect(() => {
    console.log(REACT_APP_MAINTENANCE_FLAG,":::::REACT_APP_MAINTENANCE_FLAG :::::")
    const isFirbaseInitialised = async () => {
      let url = window.location.host.split('.')
      const host = url[0].includes("localhost") ? REACT_APP_DEFAULT_HOST : url[0]
      const data: any = {
        queryParam: { host },
        api: api.config.getConfigData
      }
      const configData: any = await backend.fetchUnprotected(data)
      if(configData.id != REACT_APP_DEFAULT_CLIENT_ID){
        document.title = configData?.client_name
        if(configData?.client_config_fe?.header_logo){
        let link = document.querySelector("link[rel='icon']");
         link?.setAttribute('href',configData?.client_config_fe?.header_logo)
        }
      }
      const status: boolean = (await auth.isInitialised()) as boolean;
      sessionStorage.setItem("clientId", configData.id)
      setConfig({
        ...config,
        ...configData
      })
      setFirebaseInitialised(status);
    };
    isFirbaseInitialised();
  }, []);

  return firebaseInitialised !== false ? (
    <div className="App">
    <Router>
      <SessionHandler />
      <ScrollToTop />
      {REACT_APP_MAINTENANCE_FLAG === "true" &&
        <Route path={AUTH_ROUTES.maintenanceMessage} component={MaintenanceMessage}>
        </Route>
      }
      {REACT_APP_DOWNTIME_FLAG === "true" &&
        <Route path={AUTH_ROUTES.downtime} component={DowntimeMessage}>
        </Route>
      }
      {REACT_APP_MAINTENANCE_FLAG === "false" && REACT_APP_DOWNTIME_FLAG === "false" &&
        <Switch>
          <Route exact path={NON_AUTH_ROUTES.login} component={Login} />
          <PrivateRoute
            exact
            path={NON_AUTH_ROUTES.startapp}
            component={StartApp}
          />
          <PrivateRoute
            exact
            path={NON_AUTH_ROUTES.sendapp}
            component={SendApp}
          />
          <Route path={NON_AUTH_ROUTES.fieldlist} component={FieldList} />
          <PrivateRoute
            exact
            path={NON_AUTH_ROUTES.listapp}
            component={ListApp}
          />
          <Route
            path={NON_AUTH_ROUTES.docupload}
            component={DocUpload}
          />
          <Route path={NON_AUTH_ROUTES.thankspage} component={ThankyouPage} />
          <Route path={NON_AUTH_ROUTES.startapp} component={StartApp} />
          <Route path={NON_AUTH_ROUTES.docusign_code} component={StartApp} />
          <Route path={NON_AUTH_ROUTES.docusign_return} component={StartApp} />
          <PrivateRoute path={NON_AUTH_ROUTES.users} component={UsersList}/>
          <PrivateRoute exact path={NON_AUTH_ROUTES.offices} component={Offices}/>
          <PrivateRoute exact path={NON_AUTH_ROUTES.audit} component={Audit}/>
          <PrivateRoute exact path={NON_AUTH_ROUTES.api_integrations} component={APIIntegrations}/>
          <Route path={NON_AUTH_ROUTES.updateSigningStatus} component={UpdateSigningStatus} />
          <PrivateRoute
            exact
            path={AUTH_ROUTES.unauthorised}
            component={UnAuthorised}
          />
          <PrivateRoute path={'/platformadmin'} component={PlatformAdmin}></PrivateRoute>

          <Route component={NotFound} />
        </Switch>
        }
      </Router>
      <ButtonScrollTop />
    </div>
  ) : (
    <div>
      <Dimmer active inverted>
        <Loader size="massive" />
      </Dimmer>
    </div>
  );
};

export default App;
