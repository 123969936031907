import React, { useState } from 'react';

const BasicButton = (props: any) => {
  const { textColor, backgroundColor, size, buttonText, className, onClick } = props
  const [hovered, setHovered] = useState(false);

  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseOut = () => {
    setHovered(false);
  };

  const dynamicColor = hovered ? backgroundColor : textColor;
  const dynamicBGColor = hovered ? textColor : backgroundColor;

  return (
    <button
      style={{
        color: dynamicColor,
        backgroundColor: dynamicBGColor,
        borderColor: textColor,
        fontSize: size,
      }}
      className={className || ''}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={onClick}
    >
      {buttonText}
    </button>
  );
};

export default BasicButton;