import React, { memo } from 'react'
import { Checkbox, CheckboxProps, Dropdown, Radio } from 'semantic-ui-react'
import { FIELDS_INFO, RISK_LEVELS, ROLES } from '../../../Utils/constants'

interface BusinessDetailsSectionProps {
  allowOnlyNumber: (e: any) => void
  handleChangeTextInput: (e: any) => false | undefined
  salesSumWarningText: string
  salesSumWarningTextFlag: boolean
  handleChangeRetailLocation: (e: any, data: any) => void
  isRetailLocation: string
  businessAddress: string
  setBusinessAddress: React.Dispatch<React.SetStateAction<string>>
  updatebusinessSectionStatus: () => void
  business_location_options: {
    text: string;
    value: string;
  }[]
  businessLocation: string
  handleChangeBusinessLocation: (e: any, data: any) => void
  otherBusinessLocation: string
  numberOfLocations: string
  setNumberOfLocations: React.Dispatch<React.SetStateAction<string>>
  option_yesno: {
    text: string;
    value: string;
  }[]
  productStoredAtPhysicalAdd_yesno: {
    text: string;
    value: string;
  }[]
  isSeasonalMerchant: string
  handleChangeSeasonalMerchant: (e: any, data: any) => void
  isExported: string
  handleChangeIsExported: (e: any, data: any) => void
  foreignSalesPct: string
  setForeignSalesPct: React.Dispatch<React.SetStateAction<string>>
  condBusiDispValues: {
    affliateMarketingPartnerName: boolean
    chargeBackCompanyName: boolean
    fulfillmentCompanyName: boolean
    charge_customer: boolean
    advertisingMethods: boolean
    retailSalesPct: boolean
    telephoneSalesPct: boolean
    crmCompanyName: boolean
    is_exported_visible: boolean;
    is_own_inventory: boolean;
    vendorNames: boolean;
    is_inventory_stored_retail: boolean;
    selected_sell_national_local: boolean;
    internetSalesPct: boolean;
    callCenterName: boolean;
  }
  isOwnInventory: string
  handleChangeOwnInventory: (e: any, data: any) => void
  setVendorNames: React.Dispatch<React.SetStateAction<string>>
  vendorNames: string
  isInventoryStoredatRetailLocation: string
  handleChangeInventoryStoredatRetailLocation: (e: any, data: any) => void
  disableAddress: boolean
  inventoryLocation: string
  setInventoryLocation: React.Dispatch<React.SetStateAction<string>>
  sellarea: {
    text: string;
    value: string;
  }[]
  selectedSellNationalLocal: string
  handleChangeSellNationalLocal: (e: any, data: any) => void
  internetSalesPct: string
  telephoneSalesPct: string
  retailSalesPct: string
  oneTimePct: string
  billingSumWarningTextFlag: boolean
  billingSumWarningText: string
  advertisingMethods: string
  setAdvertisingMethods: React.Dispatch<React.SetStateAction<string>>
  salesProcessDesc: string
  setSalesProcessDesc: React.Dispatch<React.SetStateAction<string>>
  charge_customer: {
    text: string;
    value: string;
  }[]
  selectedChargeCustomerMethod: string
  handleChangeChargeCustomerMethod: (e: any, data: any) => void
  daysToDelivery: string
  setDaysToDelivery: React.Dispatch<React.SetStateAction<string>>
  returnPolicyDesc: string
  setReturnPolicyDesc: React.Dispatch<React.SetStateAction<string>>
  isPrevProcessorTerminated: string
  handleChangePrevProcessorTerminated: (e: any, data: any) => void
  retrive_info: {
    text: string;
    value: string;
  }[]
  selectedSendChargeback_AddressType: string
  handleChangeSendChargeback_AddressType: (e: any, data: any) => void
  legalBusinessFax: string
  fullFillmentCompanyOptions: {
    text: string;
    value: string;
  }[]
  fulfillmentCompanyName: string
  handleFullFillmentCompanyChange: (e: any, data: any) => void
  fulfillmentCompanyNameFreeFormText: string
  setFulfillmentCompanyNameFreeFormText: React.Dispatch<React.SetStateAction<string>>
  crmCompanyOptions: {
    text: string;
    value: string;
  }[]
  crmCompanyName: string
  handleCRMCompanyChange: (e: any, data: any) => void
  crmCompanyNameFreeFormText: string
  setCrmCompanyNameFreeFormText: React.Dispatch<React.SetStateAction<string>>
  chargebackCompanyOptions: {
    text: string;
    value: string;
  }[]
  chargeBackCompanyName: string
  handleChargebackCompanyChange: (e: any, data: any) => void
  chargeBackCompanyNameFreeFormText: string
  setChargeBackCompanyNameFreeFormText: React.Dispatch<React.SetStateAction<string>>
  affiliateMarketingOptions: {
    text: string;
    value: string;
  }[]
  affliateMarketingPartnerName: string
  handleAffiliateMarketingChange: (e: any, data: any) => void
  affliateMarketingPartnerNameFreeFormText: string
  setAffliateMarketingPartnerNameFreeFormText: React.Dispatch<React.SetStateAction<string>>
  callCenterOptions: {
    text: string;
    value: string;
  }[]
  rdrPreferredVendorOptions:{
    text: string;
    value: string;
  }[]
  callCenterName: string
  rdrPreferredVendor:string
  handleCallCenterChange: (e: any, data: any) => void
  handleRDRVendorChange:(e: any, data: any) => void
  callCenterNameFreeFormText: string
  setCallCenterNameFreeFormText: React.Dispatch<React.SetStateAction<string>>
  setOtherBusinessLocation: React.Dispatch<React.SetStateAction<string>>
  monthlyPct: string
  quarterlyPct: string
  yearlyPct: string
  seasonalMonth: {
    id: string;
    text: string;
    value: string;
    ischecked: boolean;
  }[]
  billingMethod: {
    id: string;
    text: string;
    value: string;
    ischecked: boolean;
  }[]
  setSeasonalMonthValue: React.Dispatch<any>
  seasonalMonthValue: any
  setSeasonalMonth: React.Dispatch<React.SetStateAction<{
    id: string;
    text: string;
    value: string;
    ischecked: boolean;
  }[]>>
  role: string | null
  sellService: string;
  sellProduct: string;
  handleService: (data: any) => void;
  handleProduct: (data: any) => void;
  isMobile: boolean,
  isTab: boolean,
  riskLevel:string
}
const BusinessDetailsSection = (props: BusinessDetailsSectionProps) => {
  const {
    salesSumWarningText,
    salesSumWarningTextFlag,
    handleChangeRetailLocation,
    isRetailLocation,
    businessAddress,
    setBusinessAddress,
    updatebusinessSectionStatus,
    business_location_options,
    businessLocation,
    handleChangeBusinessLocation,
    otherBusinessLocation,
    numberOfLocations,
    setNumberOfLocations,
    option_yesno,
    productStoredAtPhysicalAdd_yesno,
    isSeasonalMerchant,
    handleChangeSeasonalMerchant,
    isExported,
    handleChangeIsExported,
    foreignSalesPct,
    setForeignSalesPct,
    condBusiDispValues,
    isOwnInventory,
    handleChangeOwnInventory,
    vendorNames,
    setVendorNames,
    isInventoryStoredatRetailLocation,
    handleChangeInventoryStoredatRetailLocation,
    disableAddress,
    inventoryLocation,
    setInventoryLocation,
    sellarea,
    selectedSellNationalLocal,
    handleChangeSellNationalLocal,
    internetSalesPct,
    handleChangeTextInput,
    allowOnlyNumber,
    telephoneSalesPct,
    retailSalesPct,
    oneTimePct,
    billingSumWarningTextFlag,
    billingSumWarningText,
    advertisingMethods,
    setAdvertisingMethods,
    salesProcessDesc,
    setSalesProcessDesc,
    charge_customer,
    selectedChargeCustomerMethod,
    handleChangeChargeCustomerMethod,
    daysToDelivery,
    setDaysToDelivery,
    returnPolicyDesc,
    setReturnPolicyDesc,
    isPrevProcessorTerminated,
    handleChangePrevProcessorTerminated,
    retrive_info,
    selectedSendChargeback_AddressType,
    handleChangeSendChargeback_AddressType,
    legalBusinessFax,
    fullFillmentCompanyOptions,
    fulfillmentCompanyName,
    handleFullFillmentCompanyChange,
    fulfillmentCompanyNameFreeFormText,
    setFulfillmentCompanyNameFreeFormText,
    crmCompanyOptions,
    crmCompanyName,
    handleCRMCompanyChange,
    crmCompanyNameFreeFormText,
    setCrmCompanyNameFreeFormText,
    chargebackCompanyOptions,
    chargeBackCompanyName,
    handleChargebackCompanyChange,
    chargeBackCompanyNameFreeFormText,
    setChargeBackCompanyNameFreeFormText,
    affiliateMarketingOptions,
    affliateMarketingPartnerName,
    handleAffiliateMarketingChange,
    affliateMarketingPartnerNameFreeFormText,
    setAffliateMarketingPartnerNameFreeFormText,
    callCenterOptions,
    callCenterName,
    handleCallCenterChange,
    callCenterNameFreeFormText,
    setCallCenterNameFreeFormText,
    setOtherBusinessLocation,
    monthlyPct,
    quarterlyPct,
    yearlyPct,
    seasonalMonth,
    billingMethod,
    setSeasonalMonthValue,
    seasonalMonthValue,
    setSeasonalMonth,
    role,
    sellService,
    sellProduct,
    handleService,
    handleProduct, 
    isMobile,
    isTab,
    rdrPreferredVendorOptions,
    rdrPreferredVendor,
    handleRDRVendorChange,
    riskLevel
  } = props

  // Render Seasonal Merchant Months
  const renderSeasonalMonths = () => {
    let seasonalChecked = seasonalMonth.filter((item) => item.ischecked)?.length > 0 ? true : false
    return seasonalMonth.map((month: any, index: number) => {
      return (
        <div className='one wide field month-check-box' key={index}>
          <Checkbox
            label= {isMobile || isTab ? month.value : ''}
            name='seasonalMonth'
            value={month.value}
            checked={month.ischecked}
            onChange={(event, data: CheckboxProps) => {
              handleSeasonalMonthSelect(event, data)
            }}
          />
          {!isTab ? <label>{month.value}</label> : <></>}
        </div>
      )
    })
  }

  const handleSeasonalMonthSelect = (event: any, data: CheckboxProps) => {
    const value = data.value;
    const checked: any = data.checked;
    const month_value = [...seasonalMonthValue];
    if (checked) {
      month_value.push(value)
      setSeasonalMonthValue(month_value)
    } else {
      month_value.splice(month_value.indexOf(value), 1);
      setSeasonalMonthValue(month_value)
    }
    const newSeasonalMonth = [...seasonalMonth];
    const index = newSeasonalMonth.findIndex((Item: any) => Item.value === data.value)
    newSeasonalMonth[index].ischecked = checked;
    setSeasonalMonth(newSeasonalMonth)
  }

  // Render Checkbox CardType
  const renderBilling = () => {
    return billingMethod.map((bill: any, index: number) => {
      let inputValue = ''
      if (bill.value === 'One time') {
        inputValue = oneTimePct
      }
      if (bill.value === 'Monthly') {
        inputValue = monthlyPct
      }
      if (bill.value === 'Quarterly') {
        inputValue = quarterlyPct
      }
      if (bill.value === 'Yearly') {
        inputValue = yearlyPct
      }
      inputValue = inputValue ? inputValue.replace(/[^0-9]/, "") : "";
      return (
        <div className='three wide field billing-field' key={index}>
          {/* <Checkbox
            label=''
            name='billingMethod'
            value={bill.value}
            checked={bill.ischecked}
            onChange={(event, data: CheckboxProps) => {
              handleBillSelect(event, data)
            }}
          /> */}
          <label>{bill.value}</label>
          <input
            type='tel'
            name={bill.value}
            className='form-input'
            // disabled={!bill.ischecked}
            value={inputValue}
            placeholder={!!bill.value ? bill.value : 'Total'}
            autoComplete="off"
            onChange={handleChangeTextInput}
            maxLength={3}
          />
          <span className='input-percent'>%</span>
        </div>
      )
    })
  }

  const CommonRadioSection = (props: any) => {
    return (<div className='inline fields'>
      <span>{props.placeholder}</span>
      {!isTab ? <div className='two wide field'></div> : <></>}
      <div>
        {props.options.map((value : any)=>{
          return <Radio
            label={value.text}
            value={value.value}
            checked={!!props.value && props.value === value.value}
            onChange={props.handler}
          />
        })}
      </div>
    </div>)
  }

  return (
    <div className='form-fields business-info-section'>
      <div className='ui form'>
        <div className='fields'>
          <div className='sixteen wide field'>
            <div className='inline fields' style={{ justifyContent : 'space-between' }}>
              <label>Do you sell a service or product? <span className="required-text"> *</span> </label>
              {/* {!isTab ? <div className='two wide field'></div> : <></>} */}
              <div>
                <Checkbox
                  label='Service'
                  name='service'
                  value='service'
                  checked={sellService === 'Yes' ? true : false}
                  onChange={(event, data: CheckboxProps) => {
                    handleService(data)
                  }}
                />
                {/* {!isTab ? <div className={isMobile || isTab?'four wide field' : 'two wide field'}></div> : <></>} */}
                <Checkbox
                  label='Product'
                  name='product'
                  value='service'
                  className={'margin-left-10'}
                  checked={sellProduct === 'Yes' ? true : false}
                  onChange={(event, data: CheckboxProps) => {
                    handleProduct(data)
                  }}
                />
              </div>
            </div>
            </div>
            </div>
            <div className='fields'>
          <div className='sixteen wide field'>
            {!isMobile && !isTab ? <><label>Do you have a retail location? <span className="required-text"> *</span> </label>
              <Dropdown
                placeholder={isMobile || isTab? 'Do you have a retail location?' : 'Choose One'}
                name=''
                className={!isRetailLocation ? 'not-filled' : 'form-input'}
                fluid
                search
                selection
                options={option_yesno}
                value={isRetailLocation}
                onChange={handleChangeRetailLocation}
              /></> : <>
                <CommonRadioSection placeholder={isMobile || isTab? 'Do you have a retail location?' : 'Choose One'} options={option_yesno} value={isRetailLocation} handler={handleChangeRetailLocation} />
              </>}
          </div>
        </div>
        {isRetailLocation === 'Yes' && (
          <div className='fields'>
            <div className='sixteen wide field'>
              <label>What is the physical address of your business? <span className="required-text"> *</span> </label>
              <input
                type='text'
                name='businessAddress'
                className={!businessAddress ? 'not-filled' : 'form-input'}
                placeholder={isMobile || isTab? 'What is the physical address of your business?' : ''}
                value={businessAddress}
                autoComplete="new-password"
                onChange={(e) => setBusinessAddress(e.target.value)}
                onBlur={updatebusinessSectionStatus}
              />
            </div>
          </div>
        )}
        <div className='fields'>
          <div className='sixteen wide field'>
            <label>Business Location <span className="required-text"> *</span> </label>
            <Dropdown
              placeholder={isMobile || isTab? 'Business Location' : 'Choose One'}
              name=''
              className={!businessLocation ? 'not-filled' : 'form-input'}
              fluid
              search
              selection
              options={business_location_options}
              value={businessLocation}
              autoComplete="new-password"
              onChange={handleChangeBusinessLocation}
            />
          </div>
        </div>
        {businessLocation === 'Other' && (<div className='fields'>
          <div className='sixteen wide field'>
            <label>
              Other Business Location <span className="required-text"> *</span> </label>
            <input
              type='text'
              name='otherBusinessLocation'
              className={!otherBusinessLocation ? 'not-filled' : 'form-input'}
              placeholder={isMobile || isTab? 'Other Business Location ' : ''}
              value={otherBusinessLocation}
              autoComplete="new-password"
              onChange={(e) => setOtherBusinessLocation(e.target.value)}
              onBlur={updatebusinessSectionStatus}
            />
          </div>
        </div>
        )}
        <div className='fields'>
          <div className='sixteen wide field'>
            <label>Number of locations <span className="required-text"> *</span> </label>
            <input
              type='text'
              name='numberOfLocations'
              className={!numberOfLocations ? 'not-filled' : 'form-input'}
              placeholder='Number of locations'
              value={numberOfLocations}
              autoComplete="off"
              onChange={handleChangeTextInput}
              onBlur={updatebusinessSectionStatus}
            />
          </div>
        </div>
        <div className='fields'>
          <div className='sixteen wide field'>
          {!isMobile && !isTab ? <><label>Are you a seasonal Merchant? <span className="required-text"> *</span> </label>
            <Dropdown
              placeholder={isMobile || isTab? 'Are you a seasonal Merchant?' : 'Choose One'}
              name=''
              className={!isSeasonalMerchant ? 'not-filled' : 'form-input'}
              fluid
              search
              selection
              options={option_yesno}
              value={isSeasonalMerchant}
              onChange={handleChangeSeasonalMerchant}
            /> </> : <>
              <CommonRadioSection placeholder={isMobile || isTab? 'Are you a seasonal Merchant?' : 'Choose One'} options={option_yesno} value={isSeasonalMerchant} handler={handleChangeSeasonalMerchant} />
            </>}
          </div>
        </div>
        {isSeasonalMerchant === 'Yes' && (<div className='fields'>
          <div className='sixteen wide field'>
            <label>Check your active months : <span className="required-text"> *</span> </label>
          </div>
        </div>)}
        {isSeasonalMerchant === 'Yes' && (<div className='inline fields seasonal-month-wrapper'>{renderSeasonalMonths()}</div>)}
        {condBusiDispValues.is_exported_visible && (<div className='fields'>
          <div className='sixteen wide field'>
            {!isMobile && !isTab ? <><label>Will Product(s) or Service(s)Be Sold Outside of the U.S.? <span className="required-text"> *</span> </label>
              <Dropdown
                placeholder={isMobile || isTab? 'Sold Outside of the U.S.? ' : 'Choose One'}
                name=''
                className={!isExported ? 'not-filled' : 'form-input'}
                fluid
                search
                selection
                options={option_yesno}
                value={isExported}
                onChange={handleChangeIsExported}
              /> </>: <>
              <CommonRadioSection placeholder={isMobile || isTab? 'Sold Outside of the U.S.? ' : 'Choose One'} options={option_yesno} value={isExported} handler={handleChangeIsExported} />
            </>}
          </div>
        </div>)}
        {isExported === 'Yes' && (<div className='fields'>
          <div className='sixteen wide field'>
            <label>
              What percentage of sales will be foreign?
              <span className="required-text"> *</span>  </label>
            <input
              type='text'
              name='inventoryLocation'
              className={!foreignSalesPct ? 'not-filled' : 'form-input'}
              placeholder='What percentage of sales will be foreign?'
              value={foreignSalesPct}
              autoComplete="new-password"
              onChange={(e) => setForeignSalesPct(e.target.value)}
              onBlur={updatebusinessSectionStatus}
            />
          </div>
        </div>
        )}
        {condBusiDispValues.is_own_inventory && (<div className='fields'>
          <div className='sixteen wide field'>
              {!isMobile && !isTab ? <><label>Do you own product/inventory? <span className="required-text"> *</span> </label>
              <Dropdown
                placeholder={isMobile || isTab? 'Do you own product/inventory? ' : 'Choose One'}
                className={!isOwnInventory ? 'not-filled' : 'form-input'}
                name=''
                fluid
                search
                selection
                options={option_yesno}
                value={isOwnInventory}
                onChange={handleChangeOwnInventory}
              /></> :<>
              <CommonRadioSection placeholder={isMobile || isTab? 'Do you own product/inventory? ' : 'Choose One'} options={option_yesno} value={isOwnInventory} handler={handleChangeOwnInventory} />
              </>}
          </div> 
        </div>)}
        {condBusiDispValues.vendorNames && (<div className='fields'>
          <div className='sixteen wide field'>
            <label>
              Name and address of Manufacturer/Vendor from which supplies are purchased
              <span className="required-text"> *</span> </label>
            <input
              type='text'
              name='vendorNames'
              className={!vendorNames ? 'not-filled' : 'form-input'}
              placeholder='Name and address of Manufacturer/Vendor'
              value={vendorNames}
              autoComplete="new-password"
              onChange={(e) => setVendorNames(e.target.value)}
              onBlur={updatebusinessSectionStatus}
            />
          </div>
        </div>)}
        {condBusiDispValues.is_inventory_stored_retail && (<div className='fields'>
          <div className='sixteen wide field'>
            {!isMobile && !isTab ? <><label>
                Is the product stored at the physical address above?
                <span className="required-text"> *</span> </label>
              <Dropdown
                placeholder={isMobile || isTab? 'Is the product stored at the physical' : 'Choose One'}
                className={!isInventoryStoredatRetailLocation ? 'not-filled' : 'form-input'}
                autoComplete="new-password"
                name=''
                fluid
                search
                selection
                options={productStoredAtPhysicalAdd_yesno}
                value={isInventoryStoredatRetailLocation}
                onChange={handleChangeInventoryStoredatRetailLocation}
              />
              </>:<>
                <CommonRadioSection placeholder={isMobile || isTab? 'Is the product stored at the physical' : 'Choose One'} options={productStoredAtPhysicalAdd_yesno} value={isInventoryStoredatRetailLocation} handler={handleChangeInventoryStoredatRetailLocation} />
              </>}
          </div>
        </div>)}
        {condBusiDispValues.is_inventory_stored_retail && isInventoryStoredatRetailLocation === 'No' && (<div className='fields'>
          <div className='sixteen wide field'>
            <label>
              If not stored on site, please provide address where product is
              held
              <span className="required-text"> *</span> </label>
            <input
              type='text'
              name='inventoryLocation'
              className={!inventoryLocation ? 'not-filled' : 'form-input'}
              placeholder='If not stored on site,'
              disabled={disableAddress}
              value={inventoryLocation}
              autoComplete="new-password"
              onChange={(e) => setInventoryLocation(e.target.value)}
            />
          </div>
        </div>
        )}
        {condBusiDispValues.selected_sell_national_local && (<div className='fields'>
          <div className='sixteen wide field'>
            {!isMobile && !isTab ? <><label>Do you sell nationally or locally? <span className="required-text"> *</span> </label>
              <Dropdown
                placeholder={isMobile || isTab? 'Do you sell nationally or locally?' : 'Choose One'}
                className={!selectedSellNationalLocal ? 'not-filled' : 'form-input'}
                name=''
                fluid
                search
                selection
                options={sellarea}
                value={selectedSellNationalLocal}
                onChange={handleChangeSellNationalLocal}
              /></>:<>
                <CommonRadioSection placeholder={isMobile || isTab? 'Do you sell nationally or locally?' : 'Choose One'} options={sellarea} value={selectedSellNationalLocal} handler={handleChangeSellNationalLocal} />
              </>}
          </div>
        </div>)}
        {condBusiDispValues.internetSalesPct && (<div className='fields'>
          <div className='sixteen wide field'>
            <label>Percentage of internet sales <span className={(role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN) ? 'info1-icon' : 'info1-icon-merchant'}
              data-tooltip={FIELDS_INFO['Business Details'].internetSalesPct.helperText}></span>
              <span className="required-text"> *</span> </label>
            <input
              type='tel'
              name='internetSalesPct'
              className={(!internetSalesPct || salesSumWarningTextFlag) ? 'not-filled' : 'form-input'}
              placeholder='Percentage of internet sales'
              value={internetSalesPct}
              autoComplete="new-password"
              onChange={handleChangeTextInput}
              onBlur={updatebusinessSectionStatus}
              maxLength={3}
            />
          </div>
        </div>)}
        {condBusiDispValues.telephoneSalesPct && (<div className='fields'>
          <div className='sixteen wide field'>
            <label>Percentage of MO/TO sales <span className={(role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN) ? 'info1-icon' : 'info1-icon-merchant'}
              data-tooltip={FIELDS_INFO['Business Details'].telephoneSalesPct.helperText}></span>
              <span className="required-text"> *</span> </label>
            <input
              type='tel'
              name='telephoneSalesPct'
              className={(!telephoneSalesPct || salesSumWarningTextFlag) ? 'not-filled' : 'form-input'}
              placeholder='Percentage of MO/TO sales'
              value={telephoneSalesPct}
              autoComplete="new-password"
              onChange={handleChangeTextInput}
              onBlur={updatebusinessSectionStatus}
              maxLength={3}
            />
          </div>
        </div>)}
        {condBusiDispValues.retailSalesPct && (<div className='fields'>
          <div className='sixteen wide field'>
            <label>Percentage of retail sales <span className={(role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN) ? 'info1-icon' : 'info1-icon-merchant'}
              data-tooltip={FIELDS_INFO['Business Details'].retailSalesPct.helperText}></span>
              <span className="required-text"> *</span> </label>
            <input
              type='tel'
              name='retailSalesPct'
              className={(!retailSalesPct || salesSumWarningTextFlag) ? 'not-filled' : 'form-input'}
              placeholder='Percentage of retail sales'
              value={retailSalesPct}
              autoComplete="new-password"
              onChange={handleChangeTextInput}
              onBlur={updatebusinessSectionStatus}
              maxLength={3}
            />
          </div>
        </div>)}
        {/* {condBusiDispValues.mailOrderSalesPct && (<div className='fields'>
              <div className='sixteen wide field'>
                <label>Percentage of mail order sales <span className="required-text"> *</span> </label>
                <input
                  type='tel'
                  name='mailOrderSalesPct'
                  className='form-input'
                  placeholder=''
                  value={mailOrderSalesPct}
                  autoComplete="new-password"
                  onChange={handleChangeTextInput}
                  onBlur={updatebusinessSectionStatus}
                  maxLength={3}
                />
              </div>
            </div>)} */}
        <div className='form-fields business-info-section'>
          {salesSumWarningTextFlag && (
            <div className='warning-text'>
              {salesSumWarningText}
            </div>
          )}
        </div>
        <div className='fields'>
          <div className='four wide field'>
           <div className='inline-fields fields1'> <label>Billing Method <span className={(role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN) ? 'info1-icon' : 'info1-icon-merchant'}
              data-tooltip={FIELDS_INFO['Business Details'].total.helperText}></span><span className="required-text"> *</span> </label></div>
          </div>
        </div>
        <div className='inline fields1 billing-month-wrapper'>
          {renderBilling()}
          <div className='three wide field billing-field'>
            <label>Total</label>
            <input
              type='text'
              name="total"
              className={(Number(oneTimePct) || 0)
                 + (Number(monthlyPct) || 0) 
                 + (Number(quarterlyPct) || 0) 
                 + (Number(yearlyPct) || 0) 
                  !== 100 ? 'not-filled form-input' : 'form-input'}
              placeholder='Total'
              readOnly={true}
              value={((Number(oneTimePct) || 0)
                 + (Number(monthlyPct) || 0) 
                 + (Number(quarterlyPct) || 0) 
                 + (Number(yearlyPct) || 0) 
                )|| ''}
              autoComplete="new-password"
              maxLength={3}
            />
            <span className='input-percent'>%</span>
          </div>
        </div>
        <div className='form-fields business-info-section'>
          {billingSumWarningTextFlag && (
            <div className='warning-text'>
              {billingSumWarningText}
            </div>
          )}
        </div>
        {condBusiDispValues.advertisingMethods && (<div className='fields'>
          <div className='eight wide field'>
            <label>How do you advertise? <span className="required-text"> *</span></label>
            <input
              type='text'
              name='advertisingMethods'
              className={!advertisingMethods ? 'not-filled' : 'form-input'}
              placeholder='How do you advertise?'
              value={advertisingMethods}
              autoComplete="new-password"
              onChange={(e) => setAdvertisingMethods(e.target.value)}
              onBlur={updatebusinessSectionStatus}
            />
          </div>
          <div className='eight wide field'>
            <label>Describe the sales process from start to finish<span className="required-text"> *</span> </label>
            <input
              type='text'
              name='salesProcessDesc'
              className={!salesProcessDesc ? 'not-filled' : 'form-input'}
              placeholder='Describe the sales process from start to finish'
              value={salesProcessDesc}
              autoComplete="new-password"
              onChange={(e) => setSalesProcessDesc(e.target.value)}
              onBlur={updatebusinessSectionStatus}
            />
          </div>
        </div>)}
        {condBusiDispValues.charge_customer && (<div className='fields'>
          <div className='eight wide field gdt-100'>
            {!isMobile && !isTab ? <><label>When do you charge the customer <span className="required-text"> *</span> </label>
              <Dropdown
                placeholder={isMobile || isTab? 'When do you charge the customer' : 'Choose One'}
                className={!selectedChargeCustomerMethod ? 'not-filled' : 'form-input'}
                name=''
                fluid
                search
                selection
                options={charge_customer}
                value={selectedChargeCustomerMethod}
                onChange={handleChangeChargeCustomerMethod}
              /></>:<>
                <CommonRadioSection placeholder={isMobile || isTab? 'When do you charge the customer' : 'Choose One'} options={charge_customer} value={selectedChargeCustomerMethod} handler={handleChangeChargeCustomerMethod} />
              </>}
          </div>
          <div className='eight wide field'>
            <label>How many days to delivery?<span className="required-text"> *</span> </label>
            <input
              type='tel'
              name='daysToDelivery'
              className={!daysToDelivery || (daysToDelivery && parseInt(daysToDelivery) <=0) ? 'not-filled' : 'form-input'}
              placeholder='How many days to delivery?'
              value={daysToDelivery}
              autoComplete="new-password"
              onChange={(e) => {
                setDaysToDelivery(e.target.value.replace(/\D/,''))
              }}
              onBlur={updatebusinessSectionStatus}
            />
          </div>
        </div>)}
        <div className='fields'>
          <div className='sixteen wide field'>
            <label>Description of Return/Refund Policy<span className="required-text"> *</span> </label>
            <input
              type='text'
              name='returnPolicyDesc'
              className={!returnPolicyDesc ? 'not-filled' : 'form-input'}
              placeholder='Description of Return/Refund Policy'
              value={returnPolicyDesc}
              autoComplete="new-password"
              onChange={(e) => setReturnPolicyDesc(e.target.value)}
              onBlur={updatebusinessSectionStatus}
            />
          </div>
        </div>
        <div className='fields'>
          <div className='sixteen wide field'>
            {!isMobile && !isTab ? <><label>
              Has the merchant/owner been terminated from a previous
              processor?
              <span className="required-text"> *</span> </label>
              <Dropdown
                placeholder={isMobile || isTab? 'Has the merchant/owner been terminated from a previous processor?' : 'Choose One'}
                className={!isPrevProcessorTerminated ? 'not-filled' : 'form-input'}
                name=''
                fluid
                search
                selection
                options={option_yesno}
                value={isPrevProcessorTerminated}
                onChange={handleChangePrevProcessorTerminated}
              /></>:<>
                <CommonRadioSection placeholder={isMobile || isTab? 'Has the merchant/owner been terminated from a previous processor?' : 'Choose One'} options={option_yesno} value={isPrevProcessorTerminated} handler={handleChangePrevProcessorTerminated} />
            </>}
          </div>
        </div>
        <div className='fields'>
          <div className='sixteen wide field'>
            {!isMobile && !isTab ? <><label>
                Where should we send your Chargeback & Retrieval information?
                <span className="required-text"> *</span> </label>
              <Dropdown
                placeholder={isMobile || isTab? 'Where should we send your Chargeback & Retrieval information?' : 'Choose One'}
                className={!selectedSendChargeback_AddressType ? 'not-filled' : 'form-input'}
                name=''
                fluid
                search
                selection
                options={retrive_info}
                value={selectedSendChargeback_AddressType}
                onChange={handleChangeSendChargeback_AddressType}
              /></>:<>
                <CommonRadioSection placeholder={isMobile || isTab? 'Where should we send your Chargeback & Retrieval information?' : 'Choose One'} options={retrive_info} value={selectedSendChargeback_AddressType} handler={handleChangeSendChargeback_AddressType} />
              </>}
          </div>
        </div>
        {selectedSendChargeback_AddressType === 'FAX' && (
          <div className='fields'>
            <div className='sixteen wide field'>
              <label>FAX (positive number with maximum 10 digits)<span className="required-text"> *</span></label>
              <input
                type='text'
                name='legalBusinessFax'
                className={!legalBusinessFax ? 'not-filled' : 'form-input'}
                placeholder='FAX (positive number with maximum 10 digits)'
                value={legalBusinessFax}
                autoComplete="new-password"
                onChange={handleChangeTextInput}
                onBlur={updatebusinessSectionStatus}
              />
            </div>
          </div>
        )}
        <div>
          {condBusiDispValues.fulfillmentCompanyName && (<div className='fields'>
            <div className='sixteen wide field'>
              <label>Fulfillment Company Name<span className="required-text"> *</span> </label>
              <Dropdown
                placeholder={isMobile || isTab? 'Fulfillment Company Name' : 'Choose One'}
                className={!fulfillmentCompanyName ? 'not-filled' : 'form-input'}
                name=''
                fluid
                search
                selection
                options={fullFillmentCompanyOptions}
                value={fulfillmentCompanyName}
                onChange={handleFullFillmentCompanyChange}
              />
            </div>
          </div>)}
          {condBusiDispValues.fulfillmentCompanyName && fulfillmentCompanyName.toLowerCase() === 'other' && (
            <div className='fields'>
              <div className='sixteen wide field'>
                <label>Fullfillment Company Name Free Form<span className="required-text"> *</span></label>
                <input
                  type='text'
                  name='fullfillmentCompanyNameFreeForm'
                  className={!fulfillmentCompanyNameFreeFormText ? 'not-filled' : 'form-input'}
                  placeholder='Fullfillment Company Name Free Form'
                  value={fulfillmentCompanyNameFreeFormText}
                  autoComplete="new-password"
                  onChange={(e) => setFulfillmentCompanyNameFreeFormText(e.target.value)}
                  onBlur={updatebusinessSectionStatus}
                />
              </div>
            </div>
          )}
          {condBusiDispValues.crmCompanyName && (<div className='fields'>
            <div className='sixteen wide field'>
              <label>CRM Company Name<span className="required-text"> *</span> </label>
              <Dropdown
                placeholder={isMobile || isTab? 'CRM Company Name' : 'Choose One'}
                className={!crmCompanyName ? 'not-filled' : 'form-input'}
                name=''
                fluid
                search
                selection
                options={crmCompanyOptions}
                value={crmCompanyName}
                onChange={handleCRMCompanyChange}
              />
            </div>
          </div>)}
          {condBusiDispValues.crmCompanyName && crmCompanyName.toLowerCase() === 'other' && (
            <div className='fields'>
              <div className='sixteen wide field'>
                <label>CRM Company Name Free Form<span className="required-text"> *</span></label>
                <input
                  type='text'
                  name='crmCompanyNameFreeForm'
                  className={!crmCompanyNameFreeFormText ? 'not-filled' : 'form-input'}
                  placeholder='CRM Company Name Free Form'
                  value={crmCompanyNameFreeFormText}
                  autoComplete="new-password"
                  onChange={(e) => setCrmCompanyNameFreeFormText(e.target.value)}
                  onBlur={updatebusinessSectionStatus}
                />
              </div>
            </div>
          )}
          {condBusiDispValues.chargeBackCompanyName && (<div className='fields'>
            <div className='sixteen wide field'>
              <label>Chargeback Company Name<span className="required-text"> *</span></label>
              <Dropdown
                placeholder={isMobile || isTab? 'Chargeback Company Name' : 'Choose One'}
                className={!chargeBackCompanyName ? 'not-filled' : 'form-input'}
                name=''
                fluid
                search
                selection
                options={chargebackCompanyOptions}
                value={chargeBackCompanyName}
                onChange={handleChargebackCompanyChange}
              />
            </div>
          </div>)}
          {condBusiDispValues.chargeBackCompanyName && chargeBackCompanyName.toLowerCase() === 'other' && (
            <div className='fields'>
              <div className='sixteen wide field'>
                <label>Chargeback Company Name Free Form<span className="required-text"> *</span></label>
                <input
                  type='text'
                  name='chargeBackCompanyNameFreeForm'
                  className={!chargeBackCompanyNameFreeFormText ? 'not-filled' : 'form-input'}
                  placeholder='Chargeback Company Name Free Form'
                  value={chargeBackCompanyNameFreeFormText}
                  autoComplete="new-password"
                  onChange={(e) => setChargeBackCompanyNameFreeFormText(e.target.value)}
                  onBlur={updatebusinessSectionStatus}
                />
              </div>
            </div>
          )}
          {condBusiDispValues.affliateMarketingPartnerName && (<div className='fields'>
            <div className='sixteen wide field'>
              <label>Affiliate Marketing Partner Name<span className="required-text"> *</span> </label>
              <Dropdown
                placeholder={isMobile || isTab? 'Affiliate Marketing Partner Name' : 'Choose One'}
                className={!affliateMarketingPartnerName ? 'not-filled' : 'form-input'}
                name=''
                fluid
                search
                selection
                options={affiliateMarketingOptions}
                value={affliateMarketingPartnerName}
                onChange={handleAffiliateMarketingChange}
              />
            </div>
          </div>)}
          {condBusiDispValues.affliateMarketingPartnerName && affliateMarketingPartnerName.toLowerCase() === 'other' && (
            <div className='fields'>
              <div className='sixteen wide field'>
                <label>Affiliate Marketing Partner Name Free Form<span className="required-text"> *</span></label>
                <input
                  type='text'
                  name='affiliateMarketingNameFreeForm'
                  className={!affliateMarketingPartnerNameFreeFormText ? 'not-filled' : 'form-input'}
                  placeholder='Affiliate Marketing Partner Name Free Form'
                  value={affliateMarketingPartnerNameFreeFormText}
                  autoComplete="new-password"
                  onChange={(e) => setAffliateMarketingPartnerNameFreeFormText(e.target.value)}
                  onBlur={updatebusinessSectionStatus}
                />
              </div>
            </div>
          )}
          {condBusiDispValues.callCenterName && (<div className='fields'>
            <div className='sixteen wide field'>
              <label>Call Center Name<span className="required-text"> *</span> </label>
              <Dropdown
                placeholder={isMobile || isTab? 'Call Center Name' : 'Choose One'}
                className={!callCenterName ? 'not-filled' : 'form-input'}
                name=''
                fluid
                search
                selection
                options={callCenterOptions}
                value={callCenterName}
                onChange={handleCallCenterChange}
              />
            </div>
          </div>)}
          {condBusiDispValues.callCenterName && callCenterName.toLowerCase() === 'other' && (
            <div className='fields'>
              <div className='sixteen wide field'>
                <label>Call Center Name Free Form<span className="required-text"> *</span></label>
                <input
                  type='text'
                  name='callCenterNameFreeForm'
                  className={!callCenterNameFreeFormText ? 'not-filled' : 'form-input'}
                  placeholder='Call Center Name Free Form<'
                  value={callCenterNameFreeFormText}
                  autoComplete="new-password"
                  onChange={(e) => setCallCenterNameFreeFormText(e.target.value)}
                  onBlur={updatebusinessSectionStatus}
                />
              </div>
            </div>
          )}
          {riskLevel === RISK_LEVELS.HIGH_RISK && (<div className='fields'>
            <div className='sixteen wide field'>
              <label>RDR Preferred Vendor<span className="required-text"> *</span> </label>
              <Dropdown
                placeholder={isMobile || isTab? 'RDR Preferred Vendor' : 'Choose One'}
                className={!rdrPreferredVendor ? 'not-filled' : 'form-input'}
                name=''
                fluid
                search
                selection
                options={rdrPreferredVendorOptions}
                value={rdrPreferredVendor}
                onChange={handleRDRVendorChange}
              />
            </div>
          </div>)}
        </div>
      </div>
    </div>
  )
}

export default memo(BusinessDetailsSection)
