import React, { useEffect , useContext} from "react";
import { Modal, Icon } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";
import { useState } from "react";
import BasicButton from '../../Common/Button'
import { AppContext } from '../../../context/appContext'

interface ResetPasswordModalProps {
  loading: boolean
  modalForgetpassword: boolean
  handleCancel: () => void
  business_email: string
  modalTitle: string
  generatedPassword?: string
  setNewPassword: (email: string, password: string) => void
  handleGeneratePassword: () => void
  errMsgResp: string
  errResponse: boolean
  resetErrResponse: () => void
}

const ResetPasswordModal = (props: ResetPasswordModalProps) => {  
  const { config }: any = useContext(AppContext);
  const { handleCancel, modalForgetpassword, business_email, setNewPassword, loading, modalTitle, handleGeneratePassword, generatedPassword, errMsgResp, errResponse, resetErrResponse } = props
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState(business_email);
  const [repassword, setRePassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorRePassword, setErrorRePassword] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [rePasswordValid, setRePasswordValid] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [eyeIcon, setEyeIcon] = useState(false);

  useEffect(() => {
    if (business_email) {
      validateEmail(business_email)
    }
  }, [])
  useEffect(() => {
    if (generatedPassword) {
      setPassword(generatedPassword)
      setRePassword(generatedPassword)
      setPasswordValid(true);
      setRePasswordValid(true)
    }
  }, [generatedPassword])

  const validateRePassword = (event: any) => {
    setRePassword(event.target.value);
    const validateRePassword = event.target.value;
    if (!validateRePassword) {
      setRePasswordValid(false);
      setErrorRePassword("Please re-enter Password");
    } else if (password !== validateRePassword) {
      setRePasswordValid(false);
      setErrorRePassword("Password not Matched");
    }
    else {
      setRePasswordValid(true);
      setErrorRePassword("");
    }
  }
  const validateEmail = (email: string) => {
    setEmail(email);
    const validateEmail = email;
    if (!validateEmail) {
      setEmailValid(false);
      setErrorEmail("Please Enter Email");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(validateEmail)) {
      setEmailValid(false);
      setErrorEmail("Invalid Email Format");
    } else {
      setEmailValid(true);
      setErrorEmail("");
      resetErrResponse()
    }
  };

  const validatePassword = (event: any) => {
    setPassword(event.target.value);
    const validatePassword = event.target.value;
    if (validatePassword.length < 8) {
      setPasswordValid(false);
      setErrorPassword("Password must contain minimum of 8 characters.");
    }
    else {
      setPasswordValid(true);
      setErrorPassword("");
    }
    if (repassword && repassword !== validatePassword) {
      setRePasswordValid(false);
      setErrorRePassword("Password not Matched");
    }
    else {
      setRePasswordValid(true);
      setErrorRePassword("");
    }
    if (!validatePassword && repassword) {
      setRePasswordValid(true);
      setErrorRePassword("");
      setRePassword("");
      setPasswordValid(true);
      setErrorPassword("");
    }
  };

  const togglePasswordVisiblity = () => {
    setEyeIcon(!eyeIcon);
    setshowPassword(!showPassword);
  };

  const getShouldDisable = () => {
    if(!emailValid || !passwordValid || !repassword || errorRePassword){
      return true;
    } else return false;
  }

  const handleSubmitPassword = () => {
    setNewPassword(email, password)
  }

  return (
    <Modal
      centered
      open={modalForgetpassword}
      onClose={() => handleCancel}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <div className="header-resetPassword">
          <h3 className="resetPasswordTtl" style={{ color: config?.client_config_fe?.button_color }}>{modalTitle}</h3>
          <div className="close-button-resetPassword">
            <button className="email-activity-close-button" onClick={handleCancel}>X</button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Content>
        <ProgressLoader loading={loading} size='small' />
        <Modal.Description>
          <div className='body-content  min-height-0' >
            <div className='ui form center'>
              {!business_email ? <div className='inline fields align-fields-cnt'>
                <div className='three wide field'>
                  <label>Email :</label>
                </div>
                <div className='eight wide field err-message'>
                  <input
                    type='text'
                    className='form-input-grey test'
                    name='business_email'
                    value={email}
                    placeholder="Enter Email"
                    autoComplete="new-password"
                    onChange={(e) => validateEmail(e.target.value)}
                  />
                  {emailValid === false && (<div className="message">{errorEmail}</div>)}
                </div>
              </div> : <div className='inline fields align-fields-cnt'>
                <div className='three wide field'>
                  <label>Email :</label>
                </div>
                <div className='eight wide field'>
                  <input
                    value={email}
                    readOnly
                    style={{ background: '#D3D3D3', pointerEvents: 'none' }}
                  />
                </div>
              </div>}
              <div className='inline fields align-fields-cnt'>
                <div className='three wide field'>
                  <label>New Password :</label>
                </div>
                <div className='eight wide field err-message'>
                  <input
                    type='text'
                    name='business_password'
                    value={password}
                    placeholder="Enter New Password"
                    autoComplete="new-password"
                    onChange={validatePassword}
                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  />
                  {passwordValid === false && (<div className="message"> {errorPassword}</div>)}
                </div>
              </div>
              <div className='inline fields align-fields-cnt'>
                <div className='three wide field'>
                  <label>Confirm Password :</label>
                </div>
                <div className='eight wide field err-message'>
                  <form className="ui form">
                    <div className="form-field">
                      <input
                        className={`form-input-grey${!showPassword ? ' text-type-password': '' }`}
                        // type={showPassword ? "text" : "password"}
                        type={"text"}
                        name="password"
                        autoComplete={'off'}
                        value={repassword}
                        onChange={validateRePassword}
                        onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        placeholder="Confirm Password"
                      />
                      <Icon
                        name={eyeIcon ? "eye slash" : "eye"}
                        size="large"
                        className="eye-icon-pwd"
                        onClick={togglePasswordVisiblity}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className='inline fields align-fields-cnt'>
                <div className='three wide field'>
                </div>
                <div className='eight wide field email-activity-header'>
                  <div>
                    {rePasswordValid === false && (<div className="message">{errorRePassword}</div>)}
                  </div>
                  <div>
                    <a onClick={handleGeneratePassword} style={{ color: config?.client_config_fe?.button_color }}>Generate Password</a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Modal.Description>

      </Modal.Content>
      <Modal.Actions>
      <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText="CANCEL"
            className="save-button button-border"
            onClick={handleCancel}
          />
          <button
            className={
              getShouldDisable() ? "not-active save-button button-border" : "save-button button-border"
            }
            style={{ background: config?.client_config_fe?.button_color }}
            onClick={handleSubmitPassword}
          >
            SUBMIT
          </button>
      </Modal.Actions>
    </Modal>
  )
}

export default ResetPasswordModal;
