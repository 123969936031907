import React, { useContext, useEffect } from "react"
import { Select } from "antd"
import { AppContext } from "../../context/appContext";
import { get } from "../../Utils/helpers";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import { auth } from '../../Firebase'

const ClientList = (props: any) => {
    const { config, setConfig }: any = useContext(AppContext);
    const { page, currentClient } = props;

    const getSuperUserAccess = async (value: any) => {
        let data: any = {}
        if (value === 1) {
            sessionStorage.setItem("super_user", 'false')
            //remove super user access
            data = {
                api: api.superuser.removeSuperUserAccess,
            }
        } else {
            sessionStorage.setItem("super_user", 'true')
            data = {
                api: api.superuser.getSuperUserAccess,
                payLoad: {
                    "user_role_id": config.user_roles_id,
                    "access_client_id": value
                }
            }
        }

        const response = await backend.save(data)
        console.log('response-success', response)

        sessionStorage.setItem("selectedClientId", value)

        const token = await auth.getToken()
        console.log('token-clientSuperUser', token)

        setConfig({
            ...config,
            selectedClientId: value
        })
    }

    const getClientConfig = async (value: any) => {
        const clients = get(config, 'accessible_clients', []);
        const currentClient = clients.find((client: any) => client.client_id === value)

        const data: any = {
            queryParam: { host: currentClient.client_code },
            api: api.config.getConfigData
        }
          
        const configData: any = await backend.fetchUnprotected(data)

        setConfig({
            ...config,
            client_config_fe: {
                ...configData.client_config_fe
            }
        });
    }
    
    useEffect(() => {
        getClientConfig(currentClient);
    }, [currentClient]);

    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
        getSuperUserAccess(value)
    };

    return (
        <Select
            disabled={page === "startapp" || page === "createlink" || page === "docupload"}
            value={currentClient}
            onChange={handleChange}
            options={
                get(config, "accessible_clients", []).map((client: any) => {
                    return {
                        value: client.client_id,
                        label: client.client_name
                    }
                })
            }
        />
    )
}

export default ClientList
