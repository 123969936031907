import React, { useEffect, useState, useContext } from 'react'
import { Layout, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import api from '../../../../Service/Api';
import backend from '../../../../Service/Backend';
import Search from 'antd/lib/input/Search';
import { superuserContentStyle } from '../StyleConstants'
import SuperUserForm from './SuperUserForm';
import SuperUserTable from './SuperUserTable';
import { firstBy } from 'thenby'
import { AppContext } from "../../../../context/appContext";

const PlatformAdminSuperUser: React.FC = () => {
  const { Content } = Layout;
  const { config }: any = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState([] as any);
  const [searchText, setSearchText] = useState([])


  const fetchSuperUserData = async () => {
    try {
      setLoading(true)
      const data: any = {
        api: api.platformAdmin.getAccessibleSuperUserList,
      };
      let response: any = await backend.fetch(data);   
      console.log("response",response)   
      setLoading(false)
      response = response.map((item: any) => {
        return {
          key: item.id,
          user_id: item.user_id,
          role: item.role_name,
          superUserName: item.user_name_first + " " + item.user_name_last,
          clientName: item.client_name,
          clientUrl: item?.client_config_be?.client_url,
          user_roles_id: item.user_roles_id,
          auth_user_id: item.auth_user_id,
          user_client_id: item.user_client_id,
          access_client_id: item.access_client_id
        }
      });
      response.sort(firstBy((option: any) => option.clientName.toLowerCase()) )
      setData(response)
    } catch (err) {
      console.log('err', err)
    } finally {
    }
  }
  useEffect(() => {
    fetchSuperUserData()
  }, [])
  
  const handleSearch = (searchText: any) => {
    setSearchText(searchText)
    handleFilterSearch(searchText)
  }
  const handleFilterSearch = (searchText: any) => {
    if (searchText) {
      const filteredEvents = data?.filter((item: any) => {
        const role = item.role?.toLowerCase() ?? "";
        const superUserName = item.superUserName?.toLowerCase() ?? "";
        const clientName = item.clientName?.toLowerCase() ?? "";
        const clientUrl = item.clientUrl?.toLowerCase() ?? "";
        return role.includes(searchText) || superUserName.includes(searchText) || clientName.includes(searchText) || clientUrl.includes(searchText);
      });
      if(filteredEvents.length>0){
      setData(filteredEvents);
      }
    }else{
      fetchSuperUserData()
    } 
  }
   useEffect(() => {
    if(searchText){
      handleSearch(searchText)
    }
  }, [searchText])
  const renderSuperUserList = () => {
    return (
      <SuperUserTable
        data={data}
        successCallback={fetchSuperUserData}
        config={config}
      />
    )
  }
  return (
    <Content style={superuserContentStyle}>
      <SuperUserForm successCallback={fetchSuperUserData} data={data} config={config}/>
      <div className='clients-list'>
        <p className='section-title'>List of Super Users</p>
        <Search placeholder="Search ..." className='search-box' onChange={(e)=> handleSearch(e.target.value)}/>
        {loading ? <div className="clients-list-loading-overlay"><Spin indicator={<LoadingOutlined rev={undefined} className="clients-list-loader" />} /></div> : renderSuperUserList()}
      </div>
    </Content>
  )
}
export default PlatformAdminSuperUser
