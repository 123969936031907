import React from "react";
import { Button, Modal, Icon } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";

interface SecureFieldsModalProps {
  sendToTrinityModalOpen: boolean
  setSendToTrinityModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleCancelTrinityModal: () => void
  loading: boolean
  bankAccountNoDummy: string
  setBankAccountNoDummy: React.Dispatch<React.SetStateAction<string>>
  handleChangeTextInput: (e: any) => false | undefined
  updateBankSectionStatus: () => void
  handleCopy: (e: any) => void
  handlePaste: (e: any) => boolean
  confirmBankAccountNoDummy: string
  setConfirmBankAccountNoDummy: React.Dispatch<React.SetStateAction<string>>
  handleConfirmBankAccountNo: (event: any) => void
  fedexTaxIdShown: boolean
  fedTaxIDDummy: string
  setFedTaxIDDummy: React.Dispatch<React.SetStateAction<string>>
  validateFedTaxId: (fedTaxId: string) => boolean
  fedTaxIdValid: boolean
  setFedTaxIdValid: React.Dispatch<React.SetStateAction<boolean>>
  updatelegalSectionStatus: () => void
  allowOnlyNumberIncludingHyphen: (e: any) => void
  eyeIconFedexTaxId: boolean
  togglefedexTaxIdVisiblity: () => void
  ownerSectionSecureDataDummy: any[]
  dlIdShown: any
  handleOwnerSEctionDummyData: (e: any, index: any, data?: any) => void
  updateownerSectionStatus: () => void
  eyeIcondl: any
  toggleDlIdVisiblity: (index: any) => void
  ssnShown: any
  eyeIconssn: any
  togglessnVisiblity: (index: any) => void
  handleSaveSecureFileds: () => void
  errorMessage1: string
  alertCopyMsg: string
  setErrorMessage1: React.Dispatch<React.SetStateAction<string>>
  setSuccessMessage1: React.Dispatch<React.SetStateAction<string>>
  setAlertCopyMsg: React.Dispatch<React.SetStateAction<string>>
  config?:any
  errorMessage2: string
  setErrorMessage2: React.Dispatch<React.SetStateAction<string>>
  bankRoutingNo?:any
}

const SecureFieldsModal = (props: SecureFieldsModalProps) => {

  const {
    sendToTrinityModalOpen,
    setSendToTrinityModalOpen,
    handleCancelTrinityModal,
    loading,
    bankAccountNoDummy,
    setBankAccountNoDummy,
    handleChangeTextInput,
    updateBankSectionStatus,
    handleCopy,
    handlePaste,
    confirmBankAccountNoDummy,
    setConfirmBankAccountNoDummy,
    handleConfirmBankAccountNo,
    fedexTaxIdShown,
    fedTaxIDDummy,
    setFedTaxIDDummy,
    validateFedTaxId,
    setFedTaxIdValid,
    fedTaxIdValid,
    updatelegalSectionStatus,
    allowOnlyNumberIncludingHyphen,
    eyeIconFedexTaxId,
    togglefedexTaxIdVisiblity,
    ownerSectionSecureDataDummy,
    dlIdShown,
    handleOwnerSEctionDummyData,
    updateownerSectionStatus,
    eyeIcondl,
    toggleDlIdVisiblity,
    ssnShown,
    eyeIconssn,
    togglessnVisiblity,
    handleSaveSecureFileds,
    errorMessage1,
    alertCopyMsg,
    setErrorMessage1,
    setSuccessMessage1,
    setAlertCopyMsg,
    config,
    errorMessage2,
    setErrorMessage2,
    bankRoutingNo
  } = props

  const renderOwnerFields = () => {
    return ownerSectionSecureDataDummy.map((item, i) => {
      var number_of_owner = []
      for (let p = 1; p <= 1; p = p + 1) {
        number_of_owner.push(
          <>
            <div>
              <label style={{ fontWeight: "bold" }}>OWNER {i + 1}</label>
            </div>
            <div className=' fields'>
              <div className='eight wide field'>
                <label>DL/ID # <span className="required-text"> *</span> </label>
                <input
                  type={dlIdShown[i] ? 'text' : 'password'}
                  name='dl_Id'
                  className={!item.dl_Id ? `not-filled ${dlIdShown[i] ? 'placeholder-visible' : ""}` : `form-input-grey ${dlIdShown[i] ? 'placeholder-visible' : ""}`}
                  value={item.dl_Id}
                  autoComplete="new-password"
                  onChange={(e) => handleOwnerSEctionDummyData(e, i)}
                  onBlur={updateownerSectionStatus}
                  maxLength={20}
                />
                <Icon
                  name={eyeIcondl[i] ? 'eye slash' : 'eye'}
                  size='large'
                  className='eye-icon-owner-dlId'
                  onClick={() => toggleDlIdVisiblity(i)}
                />
              </div>
              <div className='eight wide field'>
                <label>SSN (xxx-xx-xxxx)<span className="required-text"> *</span> </label>
                <input
                  type={'text'}
                  name='ssn'
                  className={!item.ssn || ownerSectionSecureDataDummy[i]["ssnInvalid"]? `not-filled ${!ssnShown[i] && "text-type-password" }` : `form-input-grey ${!ssnShown[i] && "text-type-password" }`}
                  value={item.ssn}
                  autoComplete="new-password"
                  onChange={(e) => handleOwnerSEctionDummyData(e, i)}
                  onBlur={updateownerSectionStatus}
                  minLength={11}
                  maxLength={11}
                />
                <Icon
                  name={eyeIconssn[i] ? 'eye slash' : 'eye'}
                  size='large'
                  className='eye-icon-owner-dlId'
                  onClick={() => togglessnVisiblity(i)}
                />
                  {(item.ssnInvalid) && <span className='owner-email-validation'></span>}
              </div>
            </div>
          </>
        )
      }
      return (
        <div>
          {number_of_owner}
        </div>)
    })
  }

  const checkOwnerSecureDataFilled = () => {
    let checked = true
    ownerSectionSecureDataDummy.forEach((owner: any) => {
      if (!owner.dl_Id || owner.dl_Id === '' || !owner.ssn || owner.ssn === '' || owner.ssn.length <11) checked = false
    })
    return checked
  }


  return (
    <Modal
      centered
      open={sendToTrinityModalOpen}
      onClose={() => setSendToTrinityModalOpen(false)}
      onOpen={() => setSendToTrinityModalOpen(true)}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <div className="close-button-sendToTrinity">
          <button className="email-activity-close-button" onClick={handleCancelTrinityModal}>X</button>
        </div>
      </Modal.Header>
      <Modal.Content>
        <ProgressLoader loading={loading} size='small' />
        <Modal.Description>
          <div className='body-content  min-height-0'>
            <div className='ui form center'>
              <div className='fields'>
                <div className='eight wide field'>
                  <label>Bank Account # <span className="required-text"> *</span> </label>
                  <input
                    className={(!bankAccountNoDummy || (bankAccountNoDummy !== confirmBankAccountNoDummy)) ? 'not-filled' : 'form-input-grey'}
                    name='bankAccountNo'
                    value={bankAccountNoDummy}
                    autoComplete="new-password"
                    onChange={(e) => {  
                      if(e.target.value===" "){
                      setBankAccountNoDummy("")
                      }
                      else {  
                      setBankAccountNoDummy(e.target.value)
                        let accountValue= e.target.value.replace(" ","")
                        setBankAccountNoDummy(accountValue.replace(" ",""))
                      // user changing a/c number after entering the confirm a/c number
                        setAlertCopyMsg("");
                        setErrorMessage1("");
                        if(bankRoutingNo === (accountValue)){
                          setErrorMessage2("Please provide a valid bank account number to proceed.");
                        }else{
                          setErrorMessage2("")
                        }
                        
                        if (confirmBankAccountNoDummy){
                          if ((accountValue === confirmBankAccountNoDummy) || confirmBankAccountNoDummy === "") {
                            setErrorMessage1("");
                          } else if(confirmBankAccountNoDummy !== e.target.value){
                              setSuccessMessage1("");
                              setErrorMessage1("Bank account numbers do not match. Please confirm your bank account number.");
                          }
                        }
                      }
                    }}
                    onBlur={updateBankSectionStatus}
                    onCopy={handleCopy}
                    onPaste={handlePaste}
                  />
                </div>
                <div className='eight wide field'>
                  <label>Confirm Bank Account # <span className="required-text"> *</span> </label>
                  <input
                    className={(!confirmBankAccountNoDummy || (bankAccountNoDummy !== confirmBankAccountNoDummy)) ? 'not-filled' : 'form-input-grey'}
                    name='confirmBankAccountNo'
                    value={confirmBankAccountNoDummy}
                    autoComplete="new-password"
                    onChange={(e) => {
                      if(e.target.value===" "){
                      setConfirmBankAccountNoDummy("")
                      setErrorMessage1("");
                      }
                      else {
                        setConfirmBankAccountNoDummy(e.target.value)
                        let confirmAccountNoValue = e.target.value.replace(" ","")
                        setConfirmBankAccountNoDummy(e.target.value.replace(" ",""));
                        setAlertCopyMsg("");
                        setErrorMessage1("");
                        if(bankRoutingNo === confirmAccountNoValue){
                          setErrorMessage2("Please provide a valid bank account number to proceed.");
                        }else{
                          setErrorMessage2("")
                        }

                        if ((bankAccountNoDummy === confirmAccountNoValue) || bankAccountNoDummy === ""){
                          setErrorMessage1("");
                        } else if(bankAccountNoDummy !== confirmAccountNoValue){
                            setSuccessMessage1("");
                            setErrorMessage1("Bank account numbers do not match. Please confirm your bank account number.");
                          }
                      }
                    }}
                    onBlur={updateBankSectionStatus}
                    onCopy={handleCopy}
                    onPaste={handlePaste}
                  />
                </div>
              </div>
              <div>
                <div className="warning-text">{errorMessage1}</div>
                <div className="warning-text">{alertCopyMsg}</div>
                <div className="warning-text">{errorMessage2}</div>
              </div>
              <div className='fields'>
                <div className='eight wide field'>
                  <label>Fed Tax Id ( xx-xxxxxxx  /  xxxxxxxxx )<span className="required-text"> *</span> </label>
                  <input
                    type={'text'}
                    name='fedTaxID'
                    className={!fedTaxIDDummy || !fedTaxIdValid ? `not-filled ${!fedexTaxIdShown && "text-type-password" }` : `form-input-grey ${!fedexTaxIdShown && "text-type-password" }`}
                    value={fedTaxIDDummy}
                    autoComplete="new-password"
                    onChange={(e) => {
                      if (validateFedTaxId(e.target.value.replace(/[^\d-]/g,''))) {
                        setFedTaxIdValid(true)
                      } else {
                        setFedTaxIdValid(false)
                      }
                      setFedTaxIDDummy(e.target.value.replace(/[^\d-]/g,''))
                    }}
                    onBlur={updatelegalSectionStatus}
                    minLength={9}
                    maxLength={(fedTaxIDDummy.search('-') >= 0) ? 10 : 9}
                    onMouseLeave={updatelegalSectionStatus}
                  />
                  <Icon
                    name={eyeIconFedexTaxId ? 'eye slash' : 'eye'}
                    size='large'
                    className='eye-icon-owner-dlId'
                    onClick={togglefedexTaxIdVisiblity}
                    data-content="text"
                  />
                </div>
              </div>
              {renderOwnerFields()}
            </div>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => {
          handleCancelTrinityModal()
        }} >CANCEL</Button>
        <button
         className={!bankAccountNoDummy || !confirmBankAccountNoDummy || !(fedTaxIDDummy && fedTaxIdValid) || (bankAccountNoDummy !== confirmBankAccountNoDummy) || errorMessage2 || !checkOwnerSecureDataFilled() ? "save-button button-border not-active" : "save-button button-border" }
          onClick={(event) => handleSaveSecureFileds()} style={{ background: config?.client_config_fe?.button_color,color:"#FFF"}}>SEND</button>
      </Modal.Actions>
    </Modal>

  )
}

export default SecureFieldsModal;
