import React from "react";
import { Button, Modal } from "semantic-ui-react";

interface ShowViewLinkDetailsModalProps {
    showViewLinkDetailsModal: boolean;
    setShowViewLinkDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
    linkDetailsID: string;
    linkDetailsName: string;
    linkDetailsTinyURL: string;
    linkDetailsURL: string;
    linkDetailsNoOfClicks: string;
    linkDetailsNoOfApps: string;
    linkDetailsPartnerName: string;
    linkDetailsconditionalSellGood: string;
    linkDetailssellTypeValue: string;
    linkDetailsconditionalSellType: string;
    linkDetailsbusinessType: string;
    linkDetailsselectedBankOption: string;
    linkDetailsselectedSellTime: string;
    config?:any
}
const ShowViewLinkDetailsModal = (props: ShowViewLinkDetailsModalProps) => {
    const { showViewLinkDetailsModal, linkDetailsID, linkDetailsName, linkDetailsTinyURL, linkDetailsURL, linkDetailsNoOfClicks, linkDetailsNoOfApps, linkDetailsPartnerName,
        linkDetailsconditionalSellType, linkDetailssellTypeValue, linkDetailsconditionalSellGood, linkDetailsbusinessType, linkDetailsselectedBankOption, linkDetailsselectedSellTime, setShowViewLinkDetailsModal,config } = props
    console.log('--props--', props);
    return (
        <Modal
            centered
            open={showViewLinkDetailsModal}
            onClose={() => setShowViewLinkDetailsModal(false)}
            onOpen={() => setShowViewLinkDetailsModal(true)}
            size="small"
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <Modal.Description>
                    <div>
                        <h2>View Link Details</h2>
                    </div>
                    <div>
                        <div className="link-details-field-container">
                            <div className="link-details-label-container">Link ID</div>
                            <div>{linkDetailsID}</div>
                        </div>
                        <div className="link-details-field-container">
                            <div className="link-details-label-container">Link Name</div>
                            <div>{linkDetailsName}</div>
                        </div>
                        <div className="link-details-field-container">
                            <div className="link-details-label-container">Short URL</div>
                            <div>{linkDetailsTinyURL}</div>
                        </div>
                        <div className="link-details-field-container">
                            <div className="link-details-label-container">Long URL</div>
                            <div className="link-details-label-container">{linkDetailsURL}</div>
                        </div>
                        <div className="link-details-field-container">
                            <div className="link-details-label-container">
                                Number of Clicks
                            </div>
                            <div>{linkDetailsNoOfClicks}</div>
                        </div>
                        <div className="link-details-field-container">
                            <div className="link-details-label-container">
                                Number of Apps
                            </div>
                            <div>{linkDetailsNoOfApps}</div>
                        </div>
                        <div className="link-details-field-container">
                            <div className="link-details-label-container">
                                Sales User Name
                            </div>
                            <div>{linkDetailsPartnerName}</div>
                        </div>
                        {!!linkDetailsconditionalSellType ? (
                            <div className="link-details-field-container">
                                <div className="link-details-label-container">
                                    Business SubType
                                </div>
                                <div>{linkDetailsconditionalSellType}</div>
                            </div>
                        ) : null}
                        <div className="link-details-field-container">
                            <div className="link-details-label-container">
                                Business Type
                            </div>
                            <div>{linkDetailssellTypeValue}</div>
                        </div>
                        {!!linkDetailsconditionalSellGood ? (
                            <div className="link-details-field-container">
                                <div className="link-details-label-container">
                                    Physical Goods Type
                                </div>
                                <div>{linkDetailsconditionalSellGood}</div>
                            </div>
                        ) : null}
                        <div className="link-details-field-container">
                            <div className="link-details-label-container">Risk Level</div>
                            <div>{linkDetailsbusinessType}</div>
                        </div>
                        <div className="link-details-field-container">
                            <div className="link-details-label-container">
                                Bank Name
                            </div>
                            <div>{linkDetailsselectedBankOption}</div>
                        </div>
                        <div className="link-details-field-container">
                            <div className="link-details-label-container">
                                Sales Frequency
                            </div>
                            <div>{linkDetailsselectedSellTime}</div>
                        </div>
                    </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <button
                    className="save-button button-border"
                    style={{ background: config?.client_config_fe?.button_color }}
                    onClick={() => setShowViewLinkDetailsModal(false)}
                >
                    CLOSE
                </button>
            </Modal.Actions>
        </Modal>
    )
}

export default ShowViewLinkDetailsModal;