import React, { useEffect, useState } from "react";
import { Modal, Checkbox, CheckboxProps } from "semantic-ui-react";
import { RISK_LEVELS, ROLES, freeEquipCheckRole } from "../../../Utils/constants";
import ProgressLoader from "../../Common/ProgressLoader";
import Select from 'react-select';
import BasicButton from '../../Common/Button'

interface CreateOfficeProps {
    editOfficeDetails: any;
    details:any;
    loading: boolean;
    selectedOffice: any;
    showCreateOfficeModal: boolean;
    isEditable: boolean;
    lowRiskProcessors: any[];
    highRiskProcessors: any[];
    selectedLowRiskProcessors: any[];
    selectedHighRiskProcessors: any[];
    officeName: string;
    riskLevels: any[];
    selectedRiskLevel: any[];
    rmUsers: any[];
    selectedRM: any[];
    officesDropDown: any[];
    error: string;
    createOfficeEnabled: boolean;
    role: string | null
    handleSelectedLowRiskProcessors: (value: any) => void;
    handleSelectedHighRiskProcessors: (value: any) => void
    handleSelectedOffice: (item: any) => void;
    handleCancel: () => void;
    handleSelectedRiskLevel: (value: any) => void;
    handleSelectedRM: (value: any) => void;
    handleCreateOffice: () => Promise<void>
    setOfficeName: React.Dispatch<React.SetStateAction<string>>
    paperAppsEnabled: boolean
    setPaperAppsEnabled: React.Dispatch<React.SetStateAction<boolean>>
    freeEquipmentPlacementEnabled: boolean
    setFreeEquipmentPlacementEnabled: React.Dispatch<React.SetStateAction<boolean>>
    config:any
}

const CreateOffice = (props: CreateOfficeProps) => {
    const {
        officesDropDown,
        editOfficeDetails,
        details,
        selectedOffice,
        loading,
        showCreateOfficeModal,
        isEditable,
        lowRiskProcessors,
        highRiskProcessors,
        selectedLowRiskProcessors,
        selectedHighRiskProcessors,
        officeName,
        riskLevels,
        selectedRiskLevel,
        rmUsers,
        selectedRM,
        error,
        createOfficeEnabled,
        handleCreateOffice,
        handleCancel,
        handleSelectedRM,
        handleSelectedRiskLevel,
        setOfficeName,
        handleSelectedLowRiskProcessors,
        handleSelectedHighRiskProcessors,
        handleSelectedOffice,
        role,
        paperAppsEnabled,
        setPaperAppsEnabled,
        freeEquipmentPlacementEnabled,
        setFreeEquipmentPlacementEnabled,
        config
    } = props

    const [ checkSelectedLowRisk, setCheckSelectedLowRisk ] = useState(selectedRiskLevel?.filter((risklevel: any)=> risklevel.value === RISK_LEVELS.LOW_RISK))
    const [ checkSelectedHighRisk, setCheckSelectedHighRisk ] = useState(selectedRiskLevel?.filter((risklevel: any)=> risklevel.value === RISK_LEVELS.HIGH_RISK))

    useEffect(()=>{
        setCheckSelectedLowRisk(selectedRiskLevel?.filter((risklevel: any)=> risklevel.value === RISK_LEVELS.LOW_RISK))
        setCheckSelectedHighRisk(selectedRiskLevel?.filter((risklevel: any)=> risklevel.value === RISK_LEVELS.HIGH_RISK))
    },[selectedRiskLevel])

    return (
        <Modal
            centered
            open={showCreateOfficeModal}
            onClose={handleCancel}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <div className="user-form">
                    <div className="close-button">
                        <button className="email-activity-close-button" onClick={handleCancel}>X</button>
                    </div>
                    <form className="ui form">
                        <div className="fields">
                            <div className="four wide field">
                                <p>Office Name<span className="required-text"> *</span> :</p>
                            </div>
                            <div className="six wide field">
                                <div className="ui input">
                                    <input className='form-input' type="text" value={officeName} onChange={(e) => {
                                        if(e.target.value===" ")
                                        setOfficeName("")
                                        else
                                        setOfficeName(e.target.value)}} placeholder="Enter Office Name" />
                                </div>
                            </div>
                        </div>
                        {(isEditable ? editOfficeDetails.office_level !== '1' ? true : false : true
                        ) &&
                            <div className="fields">
                                <div className="four wide field">
                                    <p>Select Parent Office<span className="required-text"> {role === ROLES.SUPER_ADMIN ||role === ROLES.RELATIONSHIP_MANAGER? '' : '*'}</span> :</p>
                                </div>
                                <div className="six wide field">
                                    <Select
                                        isMulti={false}
                                        options={officesDropDown}
                                        value={selectedOffice}
                                        onChange={handleSelectedOffice}
                                        placeholder='Select Office (Optional)'
                                    />
                                </div>
                            </div>
                        }

                        <div className="fields">
                            <div className="four wide field">
                                <p>Select Risk Levels :</p>
                            </div>
                            <div className="six wide field">
                                <Select
                                    isMulti={true}
                                    options={riskLevels}
                                    value={selectedRiskLevel}
                                    onChange={handleSelectedRiskLevel}
                                    placeholder='Select Risk Level'
                                />
                            </div>
                        </div>
                        { checkSelectedLowRisk && checkSelectedLowRisk?.length > 0 && <div className="fields">
                            <div className="four wide field">
                                <p>Select <b>Low Risk</b> Banks :</p>
                            </div>
                            <div className="six wide field">
                                <Select
                                    isMulti={true}
                                    options={lowRiskProcessors}
                                    value={selectedLowRiskProcessors}
                                    onChange={handleSelectedLowRiskProcessors}
                                    placeholder='Select Bank'
                                />
                            </div>
                        </div>}
                        { checkSelectedHighRisk && checkSelectedHighRisk?.length > 0 && <div className="fields">
                            <div className="four wide field">
                                <p>Select <b>High Risk</b> Banks :</p>
                            </div>
                            <div className="six wide field">
                                <Select
                                    isMulti={true}
                                    options={highRiskProcessors}
                                    value={selectedHighRiskProcessors}
                                    onChange={handleSelectedHighRiskProcessors}
                                    placeholder='Select Bank'
                                />
                            </div>
                        </div>}
                        <div className="fields">
                            <div className="four wide field">
                                <p>Select RM's :</p>
                            </div>
                            <div className="six wide field">
                                <Select
                                    isMulti={true}
                                    options={rmUsers}
                                    value={selectedRM}
                                    onChange={handleSelectedRM}
                                    placeholder='Select RM'
                                />
                            </div>
                        </div>
                        {!isEditable && 
                            <div className="fields fn-checkbox-wrapper">
                            <div className="four wide field">
                                <p>Paper Apps :</p>
                            </div>
                            <div className="six wide field fn-checkbox">
                                <Checkbox
                                name="enablePaperApps"
                                checked={paperAppsEnabled}
                                onChange={(e, data: CheckboxProps) =>
                                    setPaperAppsEnabled(data.checked || false)
                                }
                                disabled={isEditable}
                                />
                            </div>
                            </div>
                        }
                         {!isEditable && freeEquipCheckRole.includes(role as ROLES) &&  
                            <div className="fields fn-checkbox-wrapper">
                            <div className="four wide field">
                                <p>Free Equipment Placement:</p>
                            </div>
                            <div className="six wide field fn-checkbox">
                                <Checkbox
                                name="enableFreeEquipmentPlacement"
                                checked={freeEquipmentPlacementEnabled}
                                onChange={(e, data: CheckboxProps) =>
                                    setFreeEquipmentPlacementEnabled(data.checked || false)
                                }
                                disabled={isEditable}
                                />
                            </div>
                            </div>
                        }
                        <div className="fields">
                            <div className="four wide field">
                            </div>
                            <div className="six wide field">
                                <span style={{ color: '#80000d' }}>{error}</span>
                            </div>
                        </div>
                    </form>
                </div>
                <ProgressLoader loading={loading} size='small' />
            </Modal.Content>
            <Modal.Actions>
                <BasicButton
                    textColor={config?.client_config_fe?.button_color}
                    backgroundColor={'#FFFFFF'}
                    size='18px'
                    buttonText="CANCEL"
                    className="save-button button-border"
                    onClick={handleCancel}
                />
                <button className={createOfficeEnabled ? "save-button button-border" : "not-active save-button button-border"}  style={{ background: config?.client_config_fe?.button_color }} onClick={handleCreateOffice}>{isEditable ? 'UPDATE OFFICE' : 'ADD OFFICE'}</button>
            </Modal.Actions>
        </Modal>
    )
}

export default CreateOffice;
