import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { Footer } from "../../PageLayout/Footer";
import { Layout } from "../../PageLayout/Layout";
import api from "../../../Service/Api";
import backend from "../../../Service/Backend";
import "./FieldList.css";
import ValueList from "./ValueList";
import ProgressLoader from "../../Common/ProgressLoader";
import { AppContext } from "../../../context/appContext";

const { REACT_APP_DEFAULT_CLIENT_ID } = process.env;

const FieldList = () => {
  const { config }: any = useContext(AppContext);
  
  const [lookupData, setLookupData] = useState([] as any); //for maintating the response for backend
  const [lookupDataTypes, setLookupDataTypes] = useState([]);
  const [selectedLookupDataType, setSelectedLookupDataType] = useState("");
  const [fieldsList, setFieldsList] = useState([] as any);
  const [selectedBusinessTypeOptions, setSelectedBusinessTypeOptions] =useState([]);
  const [isBusinessTypeSelected, setIsBusinessTypeSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedBusinessType, setSelectedBusinessType] = useState("");
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [selectedEquipmentTypeOptions, setSelectedEquipmentTypeOptions] = useState([]);
  const [isEquipmentTypeSelected, setIsEquipmentTypeSelected] = useState(false);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState("");
  const [isEquipmentSelected, setIsEquipmentSelected] = useState(false);
  const [addField, setAddField] = useState(false);
  const [showAdd, setShowAdd] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [productPricesValidation, setProductPricesValidation] = useState("");
  //RUNS WHEN LOOKUP DATA TYPE SELECTED
  const handleLookupDataTypeSelected = (e: any, data: any) => {
    setSelectedLookupDataType(data.value);
    setAddField(false)
    setShowAdd(true)
    setIsUpdate(false)
    setProductPricesValidation("")
    setSelectedEquipmentType("")
  };
  useEffect(() => {
    const current_index = lookupData.findIndex(
      
      (option: any) => option.LookupDataType === selectedLookupDataType
    );
    let fieldList = []; // array stores the values of value
    switch (selectedLookupDataType) {
     
      case "AFFILIATES": {
        setIsBusinessTypeSelected(false);
        setIsEquipmentTypeSelected(false);
        setIsEquipmentSelected(false);
        setSelectedBusinessType("");
        setIsOptionSelected(true);
        fieldList = lookupData[current_index]?.AffiliateName;
        fieldList.sort()
        setFieldsList(fieldList);
        break;
      }
      case "BUSINESS_CATEGORY": {
        setIsBusinessTypeSelected(false);
        setIsEquipmentTypeSelected(false);
        setIsEquipmentSelected(false);
        setSelectedBusinessType("");
        setIsOptionSelected(true);
        fieldList = lookupData[current_index]?.BusinessCategoryName;
        fieldList.sort()
        setFieldsList(fieldList);
        break;
      }
      case "BUSINESS_TYPE": {
        setIsBusinessTypeSelected(true);
        setIsEquipmentTypeSelected(false);
        setIsEquipmentSelected(false);
        setIsOptionSelected(false);
        setFieldsList([]);
        const data = lookupData[current_index]?.BusinessCategory.map(
          (item: any) => {
            return {
              text: item.BusinessCategoryName,
              value: item.BusinessCategoryName,
            };
          }
        );
        let newData=data.sort((a: { text: string; }, b: { text: string; })=> a.text < b.text ? -1 : 1).sort((a: { text: string; }, b: { text: string; })=> a.text < b.text ? -1 : 1)
        setSelectedBusinessTypeOptions(newData)
        break;
      }
      case "CALLCENTER": {
        setIsBusinessTypeSelected(false);
        setIsEquipmentTypeSelected(false);
        setIsEquipmentSelected(false);
        setSelectedBusinessType("");
        setIsOptionSelected(true);
        fieldList = lookupData[current_index]?.CallcenterName;
        fieldList.sort()
        setFieldsList(fieldList);
        break;
      }
      case "CHARGEBACK": {
        setIsBusinessTypeSelected(false);
        setIsEquipmentTypeSelected(false);
        setIsEquipmentSelected(false);
        setSelectedBusinessType("");
        setIsOptionSelected(true);
        fieldList = lookupData[current_index]?.ChargebackName;
        fieldList.sort()
        setFieldsList(fieldList);
        break;
      }
      case "COUNTRIES": {
        setIsBusinessTypeSelected(false);
        setIsEquipmentTypeSelected(false);
        setIsEquipmentSelected(false);
        setSelectedBusinessType("");
        setIsOptionSelected(true);
        fieldList = lookupData[current_index]?.CountryName;
        fieldList.sort()
        setFieldsList(fieldList);
        break;
      }
      case "CRM": {
        setIsBusinessTypeSelected(false);
        setIsEquipmentTypeSelected(false);
        setIsEquipmentSelected(false);
        setSelectedBusinessType("");
        setIsOptionSelected(true);
        fieldList = lookupData[current_index]?.CRMCompanyName;
        fieldList.sort()
        setFieldsList(fieldList);
        break;
      }
      case "FULFILLMENT": {
        setIsBusinessTypeSelected(false);
        setIsEquipmentTypeSelected(false);
        setIsEquipmentSelected(false);
        setSelectedBusinessType("");
        setIsOptionSelected(true);
        fieldList = lookupData[current_index]?.FulfillmentName;
        fieldList.sort()
        setFieldsList(fieldList);
        break;
      }
      case "PRODUCT_PRICES": {
        setIsBusinessTypeSelected(false);
        setIsEquipmentTypeSelected(false);
        setIsEquipmentSelected(false);
        setSelectedBusinessType("");
        setIsOptionSelected(true);
        fieldList = lookupData[current_index]?.ProductPrices;
        fieldList.sort()
        setFieldsList(fieldList);
        break;
      }
      case "STATES": {
        setIsBusinessTypeSelected(false);
        setIsEquipmentTypeSelected(false);
        setIsEquipmentSelected(false);
        setSelectedBusinessType("");
        setIsOptionSelected(true);
        fieldList = lookupData[current_index]?.StateName;
        fieldList.sort()
        setFieldsList(fieldList);
        break;
      }

      case "EQUIPMENT_TYPE": {
        setIsBusinessTypeSelected(false);
        setIsEquipmentTypeSelected(false);
        setIsEquipmentSelected(true);
        setSelectedBusinessType("");
        setIsOptionSelected(true);
        fieldList = lookupData[current_index]?.Type;
        fieldList.sort(Intl.Collator().compare)
        setFieldsList(fieldList);
        break;
      }
      case "MOBILE_EQUIPMENT": {
        setIsBusinessTypeSelected(false);
        setIsEquipmentTypeSelected(false);
        setIsEquipmentSelected(false);
        setSelectedBusinessType("");
        setIsOptionSelected(true);
        fieldList = lookupData[current_index]?.MobileEquipmentType;
        fieldList.sort()
        setFieldsList(fieldList);
        break;
      }
      case "RDR_PREFERRED_VENDOR": {
        setIsBusinessTypeSelected(false);
        setIsEquipmentTypeSelected(false);
        setIsEquipmentSelected(false);
        setSelectedBusinessType("");
        setIsOptionSelected(true);
        fieldList = lookupData[current_index]?.vendorType;
        fieldList.sort()
        setFieldsList(fieldList);
        break;
      }
      case "PRODUCT_NAME": {
        setIsBusinessTypeSelected(false);
        setIsEquipmentTypeSelected(true);
        setIsEquipmentSelected(true);
        setIsOptionSelected(false);
        setSelectedBusinessType("");
        setSelectedEquipmentType("")
        setFieldsList([]);
        
        const data = lookupData[current_index]?.EquipmentType.map(
          (item: any) => {
            return {
              text: item.EquipmentTypeName,
              value: item.EquipmentTypeName,
            };
          }
        );
        let newData=data.sort((a: { text: string; }, b: { text: string; })=> a.text < b.text ? -1 : 1).sort((a: { text: string; }, b: { text: string; })=> a.text < b.text ? -1 : 1)
        setSelectedEquipmentTypeOptions(newData );
        break;
      }
    }
  }, [selectedLookupDataType, lookupData]);

  //RUNS WHEN BUSINESS TYPE SELECTED
  const handleBusinessSelection = (e: any, data: any) => {
    setSelectedBusinessType(data.value);
    
  };
  useEffect(() => {
    if (isBusinessTypeSelected) {
      setAddField(false)
      setShowAdd(true)
      setIsUpdate(false)
      setIsOptionSelected(true);
      const index = lookupData.findIndex(
        (option: any) => option.LookupDataType === "BUSINESS_TYPE"
      );
      const current_index = lookupData[index]?.BusinessCategory?.findIndex(
        (option: any) => option.BusinessCategoryName === selectedBusinessType
      );
      let businessValues =
        lookupData[index]?.BusinessCategory[current_index]?.BusinessTypeName;
        businessValues.sort()
      setFieldsList(businessValues);
    }
  }, [selectedBusinessType, lookupData]);

  const handleEquipementSelection = (e: any, data: any) => {
    setSelectedEquipmentType(data.value);
  };
  useEffect(() => {
    if (isEquipmentTypeSelected && selectedEquipmentType) {
      setSelectedEquipmentType(selectedEquipmentType)
      setIsOptionSelected(true);
      setAddField(false)
      setShowAdd(true)
      setIsUpdate(false)
      setProductPricesValidation("")
      const index = lookupData.findIndex(
        (option: any) => option.LookupDataType === "PRODUCT_NAME"
      );
      const current_index = lookupData[index]?.EquipmentType?.findIndex(
        (option: any) => option.EquipmentTypeName === selectedEquipmentType
      );
      let businessValues =
        lookupData[index]?.EquipmentType[current_index]?.ProductName;
        businessValues.sort(Intl.Collator().compare)
      setFieldsList(businessValues);
    }
  }, [selectedEquipmentType, lookupData]);

  //FETCHING LOOKUP DATA
  const fetchLookupOptions = () => {
    setLoading(true);
    const data = {
      api: api.lookupData.getAllLookupData,
    };
    backend.fetch(data).then((response) => {
      if(response.length > 0 ){
      var index = response.findIndex(function(e: { LookupDataType: string; }) {
        return e.LookupDataType === "PRODUCT_PRICES";
      })
        response.splice(index, 1)
      }
      setLookupData(response);
      setLookupDataTypes(
        response.map((item: any) => {
          return { text: item.LookupDataType, value: item.LookupDataType };
        })
      );
      setLoading(false);
    }).catch(e => {
      console.log("something went wrong", e);
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchLookupOptions();
  }, [config.selectedClientId]);

  //HANDLE ADD
  const handleAdd = async (newValue: string) => {
    setLoading(true);
    let selectedtype =''
    if(isBusinessTypeSelected){
      selectedtype=selectedBusinessType
    }
    if(isEquipmentTypeSelected){
      selectedtype=selectedEquipmentType
    }
    const data: any = {
      api: api.lookupData.postLookupAddNewField,
      payLoad: {
        lookup_data_type: selectedLookupDataType,
        business_category_name: selectedtype,
        field: newValue,
      },
    };

    try {
      const response = await backend.save(data);
      if (response) {
        fetchLookupOptions();
        setLoading(false);
      }
    } catch (error) {
      console.log("something went wrong", error);
      setLoading(false);
    }
  };
  //HANDLE DELETE
  const handleDelete = async (deleteValue: string) => {
    setLoading(true);
    let selecttype =''
    if(isBusinessTypeSelected){
       selecttype=selectedBusinessType
    }
    if(isEquipmentTypeSelected){
      selecttype=selectedEquipmentType
    }
    const data: any = {
      api: api.lookupData.postLookupDeleteField,
      payLoad: {
        lookup_data_type: selectedLookupDataType,
        business_category_name: selecttype,
        field: deleteValue,
      },
    };
    try {
      //console.log(data);
      const response = await backend.save(data);
      setLoading(false);
      if (response) {
        setLoading(true);
        fetchLookupOptions();
        setLoading(false);
      }
    } catch (error) {
      console.log("something went wrong", error);
      setLoading(false);
    }
  };
  //HANDLE UPDATE
  const handleUpdate = async (currentvalue: string, updatedvalue: string) => {
    setLoading(true);
    let selecttype =''
    if(isBusinessTypeSelected){
       selecttype=selectedBusinessType
    }
    if(isEquipmentTypeSelected){
      selecttype=selectedEquipmentType
    }
    const data: any = {
      api: api.lookupData.postLookupUpdateField,
      payLoad: {
        lookup_data_type: selectedLookupDataType,
        business_category_name: selecttype,
        field: currentvalue,
        updatedField: updatedvalue,
      },
    };

    try {
      const response = await backend.save(data);

      if (response) {
        setLoading(false);
        console.log(response);
        fetchLookupOptions();
      }
    } catch (error) {
      setLoading(false);
      console.log("something went wrong", error);
    }
  };

  return (
    <Layout page="fieldlist">
      <div className="fieldlist-container">
        <h1 className="heading-dropdown"><span className='heading-text-left'>Dropdown Field List</span></h1>
        <div className="line-dropdown"></div>
        <div className="dropdown-whole">
          <div className="left-dropdown">
            <div>
              <div className="selection-dropdown">
                <div className="select-option">
                  <h3 className="select-dropdown">Select Field:</h3>
                  <Dropdown
                    placeholder="Choose One"
                    name="business_category"
                    className="form-input"
                    fluid
                    selection
                    options={lookupDataTypes}
                    onChange={handleLookupDataTypeSelected}
                  ></Dropdown>
                </div>
                {isBusinessTypeSelected && (
                  <div className="select-option">
                    <h3 className="select-dropdown">
                      Select Business Category:
                    </h3>

                    <Dropdown
                      placeholder="Choose One"
                      name="unique"
                      className="form-input"
                      fluid
                      selection
                      options={selectedBusinessTypeOptions}
                      onChange={handleBusinessSelection}
                    ></Dropdown>
                  </div>
                )}
                 {isEquipmentTypeSelected && (
                  <div className="select-option">
                    <h3 className="select-dropdown">
                      Select Equipment Type
                    </h3>

                    <Dropdown
                      placeholder="Choose One"
                      name="unique"
                      className="form-input"
                      fluid
                      selection
                      options={selectedEquipmentTypeOptions}
                      onChange={handleEquipementSelection}
                    ></Dropdown>
                  </div>
                )}
              </div>
            </div>
          </div>
          {(isOptionSelected) &&
          <div>
            <div className="field-area">
              <h3 className="field-dropdown-name">
                {selectedBusinessType ||
                  selectedLookupDataType ||
                  "Select Field"}
              </h3>
              <div className="field-box-dropdown">
                <div className="flex-dropdown">
                  <h3 className="value-dropdown">values</h3>
                  {REACT_APP_DEFAULT_CLIENT_ID == config.selectedClientId && <h3 className="actions-dropdown">actions</h3>}
                </div>
                <div className="line1-dropdown"></div>
                <ValueList
                  selectedValues={fieldsList}
                  handleAdd={handleAdd}
                  handleDelete={handleDelete}
                  handleUpdate={handleUpdate}
                  isOptionSelected={isOptionSelected}
                  isEquipmentSelected={isEquipmentSelected}
                  addField={addField}
                  setAddField={setAddField}
                  showAdd={showAdd}
                  setShowAdd={setShowAdd}
                  setIsUpdate={setIsUpdate}
                  isUpdate={isUpdate}
                  selectedEquipmentType={selectedEquipmentType}
                  setProductPricesValidation={setProductPricesValidation}
                  productPricesValidation={productPricesValidation}
                  selectedClientId={config.selectedClientId}
                />
              </div>
            </div>
          </div>
          }
        </div>
        <ProgressLoader loading={loading} size="large" />
      </div>
      <Footer page="fieldlist"/>
    </Layout>
  );
};

export default FieldList;
