import React from "react";
import { Button } from "semantic-ui-react";

interface MobileSignModal {
    signModalOpen: boolean;
    setSignModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    business_name: string;
    business_email: string;
    setMerchantMessage: (event: any) => Promise<void>;
    userMessage: string;
    setUserMessage: React.Dispatch<React.SetStateAction<string>>;
    handleSendApp: (event: any) => Promise<void>;
    loading: boolean;
    business_contact: string;
}
const MobileSignModal = (props: MobileSignModal) => {
    const { setSignModalOpen, business_name, business_contact, business_email, setMerchantMessage, userMessage, setUserMessage, handleSendApp } = props
    window.scroll({ top: 0, left: 0 });
    return (
        <><div className='top-container'>
            <div className='top-content'>
                <div className='inline fields'>
                    <div className='twelve wide field'>
                        <input
                            type="text"
                            className="form-input-grey"
                            placeholder="Merchant Name : "
                            name="business_contact"
                            value={business_contact}
                            readOnly
                        />
                    </div>
                </div>

                <div className='inline fields'>
                    <div className='twelve wide field'>
                        <input
                            type="text"
                            className="form-input-grey"
                            placeholder="DBA Name : "
                            name="business_name"
                            value={business_name}
                            readOnly
                        />
                    </div>
                </div>
                <div className='inline fields'>
                    <div className='twelve wide field'>
                        <input
                            type="text"
                            className="form-input-grey"
                            placeholder="Merchant Email : "
                            name="business_email"
                            value={business_email}
                            readOnly
                        />
                    </div>
                </div>
                <div className='inline fields'>
                    <div className='twelve wide field'>
                        <textarea
                            name="personal_message"
                            placeholder="Personal Message : "
                            onChange={setMerchantMessage}
                            value={userMessage}
                            rows={5}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='button-containers'>
            <Button onClick={() => {
                setSignModalOpen(false);
                setUserMessage("");
            }} color='blue' className='send-email-buttons'>CANCEL</Button>
            <Button onClick={(event) => handleSendApp(event)} color="blue" className='send-email-buttons'>
                SEND EMAIL
            </Button>
        </div>
        </>
    )
}

export default MobileSignModal;