import React from "react";
import { Table } from "semantic-ui-react";
import { firstBy } from "thenby";
import { ROLES, freeEquipCheckRole } from "../../../Utils/constants";
import useCheckMobileScreen, {
  useCheckTabScreen,
} from "../../../hooks/useCheckMobileScreen";
import TableComponent from "../../TableComponent";
import OfficeTableBody from "./OfficeTableBody";
import OfficeTableHeader from "./OfficeTableHeader";
import { useLocation } from "react-router-dom";
import { getParsedList } from "../../../Utils/helpers";

const { REACT_APP_BACKEND_MYSQL } = process.env;

interface OfficeTableProps {
  sortAndSearchResultData: any[];
  role: string | null;
  allProcessors: any[];
  handleEditOffice: (id: number) => void;
  handleDeleteModal: (id: number) => void;
  sortFieldOrder: string;
  sortField: string;
  searchField: string;
  searchText: string;
  handleSearchByFieldName: (field_name: string, sort_order: string) => void;
  handleSortByFieldName: (field_name: string, sort_order: string) => void;
  handlePaperAppsChecked: (data: any, user_id: string) => Promise<void>;
  handleFreeEquipmentPlacementChecked: (data: any, user_id: string) => Promise<void>;
  handleDeactivateModal: (office_id: string) => Promise<void>;
  loading: boolean;
  algoliaLoading: boolean;
  changeCheckboxStatus: (e: any, id: string) => Promise<void>
  isParentChecked: boolean;
  selectedOfficeBulk: any[]
  viewOptions : boolean,
  config: any
}

const getTableKeys = (
  isMobile: boolean,
  isTablet: boolean,
  role: string | null,
  handlePaperAppsChecked: (data: any, user_id: string) => Promise<void>,
  handleFreeEquipmentPlacementChecked: (data: any, user_id: string) => Promise<void>
) => {
  let headerKeys: any = [];
  let rowKeys: any = [];
  if (isMobile) {
    headerKeys = [
      { label: "Office ID", key: REACT_APP_BACKEND_MYSQL ? "office_code" : "office_id" },
      { label: "Office Name", key: "office_name" },
      { label: "Status", key: "status" }
    ];

    rowKeys = [
      { key: "parent_office_name", label: "Parent Office" },
      { key: "office_managers", label: "Office Managers" },
      { key: "office_admin_names", label: "Office Admin" },
      { key: "rmNames", label: "RM's" },
      { key: "low_risk_processors", label: "Low Risk Banks" },
      { key: "high_risk_processors", label: "High Risk Bank" },
      { key: "allowed_risk_levels", label: "Risk Level" },
      {
        display: !(role === ROLES.SUPER_ADMIN || role === ROLES.OFFICE_ADMIN || role === ROLES.RELATIONSHIP_MANAGER),
        key: "paperapp_enabled",
        label: "Paper Apps",
        type: "checkbox",
        name: "enablePaperApps",
        change: handlePaperAppsChecked,
        checkProperty: "paper_apps_enabled",
      },
      {
        display: role !== null && !freeEquipCheckRole.includes(role as ROLES),
        key: "freeequipmentplacement_enabled",
        label: "Free Equipment Placement",
        type: "checkbox",
        name: "enableFreeEquipmentPlacement",
        change: handleFreeEquipmentPlacementChecked,
        checkProperty: "free_equipment_placement_enabled",
      }
    ];
  } else if (isTablet) {
    if (window.innerWidth < window.innerHeight) {
      headerKeys = [
        { label: "Office ID", key: REACT_APP_BACKEND_MYSQL ? "office_code" : "office_id" },
        { label: "Office Name", key: "office_name" },
        { key: "rmNames", label: "RM's" },
        { key: "allowed_risk_levels", label: "Risk Level" },
        { label: "Status", key: "status" }
      ];

      rowKeys = [
        { key: "office_managers", label: "Office Managers" },
        { key: "parent_office_name", label: "Parent Office" },
        { key: "low_risk_processors", label: "Low Risk Banks" },
        { key: "high_risk_processors", label: "High Risk Bank" },
        {
          display: !(role === ROLES.SUPER_ADMIN || role === ROLES.RELATIONSHIP_MANAGER),
          key: "office_admin_names",
          label: "Office Admin"
        },
        {
          display: !(role === ROLES.SUPER_ADMIN || role === ROLES.OFFICE_ADMIN || role === ROLES.RELATIONSHIP_MANAGER),
          key: "paperapp_enabled",
          label: "Paper Apps",
          type: "checkbox",
          name: "enablePaperApps",
          change: handlePaperAppsChecked,
          checkProperty: "paper_apps_enabled",
        },
        {
          display: role !== null && !freeEquipCheckRole.includes(role as ROLES),
          key: "freeequipmentplacement_enabled",
          label: "Free Equipment Placement",
          type: "checkbox",
          name: "enableFreeEquipmentPlacement",
          change: handleFreeEquipmentPlacementChecked,
          checkProperty: "free_equipment_placement_enabled",
        }
      ];
    } else {
      headerKeys = [
        { label: "Office ID", key: REACT_APP_BACKEND_MYSQL ? "office_code" : "office_id" },
        { label: "Office Name", key: "office_name" },
        { key: "parent_office_name", label: "Parent Office" },
        { key: "rmNames", label: "RM's" },
        { key: "allowed_risk_levels", label: "Risk Level" },
        { label: "Status", key: "status" }
      ];

      rowKeys = [
        { key: "office_managers", label: "Office Managers" },
        { key: "low_risk_processors", label: "Low Risk Banks" },
        { key: "high_risk_processors", label: "High Risk Bank" },
        {
          display: !(role === ROLES.SUPER_ADMIN || role === ROLES.RELATIONSHIP_MANAGER),
          key: "office_admin_names",
          label: "Office Admin"
        },
        {
          display: !(role === ROLES.SUPER_ADMIN || role === ROLES.OFFICE_ADMIN || role === ROLES.RELATIONSHIP_MANAGER),
          key: "paperapp_enabled",
          label: "Paper Apps",
          type: "checkbox",
          name: "enablePaperApps",
          change: handlePaperAppsChecked,
          checkProperty: "paper_apps_enabled",
        },
        {
          display: role !== null && !freeEquipCheckRole.includes(role as ROLES),
          key: "freeequipmentplacement_enabled",
          label: "Free Equipment Placement",
          type: "checkbox",
          name: "enableFreeEquipmentPlacement",
          change: handleFreeEquipmentPlacementChecked,
          checkProperty: "free_equipment_placement_enabled",
        }
      ];
    }
  }

  return {
    headerKeys,
    rowKeys,
  };
};

const OfficeTableMobile = (props: OfficeTableProps) => {
  const {
    handleEditOffice,
    handleDeleteModal,
    sortAndSearchResultData,
    role,
    handleDeactivateModal,
    handlePaperAppsChecked,
    handleFreeEquipmentPlacementChecked,
    loading,
    algoliaLoading,
    changeCheckboxStatus,
    isParentChecked,
    selectedOfficeBulk,
    viewOptions
  } = props;

  const isMobile = useCheckMobileScreen();
  const isTablet = useCheckTabScreen();

  const tableKeys = getTableKeys(
    isMobile,
    isTablet,
    role,
    handlePaperAppsChecked,
    handleFreeEquipmentPlacementChecked
  );

  const enableActivteDeactivate =
    role === ROLES.RELATIONSHIP_MANAGER ||
    role === ROLES.OFFICE_ADMIN ||
    role === ROLES.SUPER_ADMIN ||
    role === ROLES.OFFICE_MANAGER;
  const search = useLocation().search;
  const sortOrder = new URLSearchParams(search).get("sort");
  const ascFn = (a: string, b: string) => a.localeCompare(b);
  if (!!sortOrder) {
    sortAndSearchResultData.sort((a: any, b: any) =>
      sortOrder === "asc"
        ? ascFn(a.office_id, b.office_id)
        : ascFn(b.office_id, a.office_id)
    );
  }
  const officeData = sortAndSearchResultData
    ?.filter((office: any, index: any) => !office.deleted)
    ?.map((office) => {
      let office_name = office.office_name;
      let officeManagerNames = getParsedList(office.office_manager_names)
        ?.map((manager: String) => manager)
        .join(", ");
      let officeAdminNames =
        office.office_admin_names ? getParsedList(office.office_admin_names).join(", ") : '';
      let parentOfficeName = office.parent_office_name;
      let rmNames = office.allowed_RMs
        ?.map((rm: any) => {
          return `${rm.user_name.first_name} ${rm.user_name.last_name}`;
        })
        .join(", ");
      let low_risk_processors: any = office?.allowed_low_risk_processors || [];
      let high_risk_processors: any =
        office?.allowed_high_risk_processors || [];
      low_risk_processors.sort(
        firstBy(function (option: any) {
          return option?.toLowerCase();
        })
      );
      high_risk_processors.sort(
        firstBy(function (option: any) {
          return option?.toLowerCase();
        })
      );
      low_risk_processors = low_risk_processors?.join(", ");
      high_risk_processors = high_risk_processors?.join(", ");
      let allowed_risk_levels = office?.allowed_risk_levels?.join(", ");
      let status = office.deactivate ? "INACTIVE" : "ACTIVE";
      const office_id = office.office_id;

      const uiActions = [
        {
          display: enableActivteDeactivate,
          className: selectedOfficeBulk.length > 1 ?"not-active deactivate-icon userList" : "deactivate-icon userList",
          label: office.deactivate ? "Activate" : "Deactivate",
          action: () => handleDeactivateModal(office.office_id),
        },
        {
          className: selectedOfficeBulk.length > 1 ? "not-active edit-icon userList" : "edit-icon userList",
          label: "Edit",
          action: () => handleEditOffice(office.office_id),
        },
        {
          className: selectedOfficeBulk.length > 1 ? "not-active delete-icon userList" : "delete-icon userList",
          label: "Delete",
          action: () => handleDeleteModal(office.office_id),
        },
      ];
      return {
        ...office,
        office_name,
        office_id,
        parent_office_name: parentOfficeName,
        rmNames,
        office_managers: officeManagerNames,
        low_risk_processors,
        high_risk_processors,
        allowed_risk_levels,
        status,
        uiActions,
        office_admin_names: officeAdminNames,
      };
    });

  return (
    <TableComponent
      headerList={tableKeys.headerKeys}
      rowKeys={tableKeys.rowKeys}
      tableData={officeData}
      heading={"OFFICES LIST"}
      uniqueId={"office_id"}
      className={'mobile-table-max'}
      noAppMessage="NO OFFICES FOUND"
      loading={loading || algoliaLoading}
      changeCheckboxStatus = {changeCheckboxStatus}
      isParentChecked = {isParentChecked}
      role = {role}
      viewOptions = {viewOptions}
      selectedBulkCount={selectedOfficeBulk.length} 
      bulkId= {"office_id"}
    />
  );
};

const OfficeTable = (props: OfficeTableProps) => {
  const {
    handleEditOffice,
    handleDeleteModal,
    sortAndSearchResultData,
    allProcessors,
    role,
    sortFieldOrder,
    sortField,
    searchField,
    searchText,
    handleSearchByFieldName,
    handleSortByFieldName,
    handleDeactivateModal,
    handlePaperAppsChecked,
    handleFreeEquipmentPlacementChecked,
    changeCheckboxStatus,
    isParentChecked,
    selectedOfficeBulk,
    config
  } = props;

  const isMobile = useCheckMobileScreen();
  const isTablet = useCheckTabScreen();

  if (isMobile || isTablet) {
    return <OfficeTableMobile {...props} />;
  }

  return (
    <>
      <Table
        textAlign="center"
        celled
        striped
        structured
        className="list-table-view"
      >
        <OfficeTableHeader
          sortFieldOrder={sortFieldOrder}
          sortField={sortField}
          searchField={searchField}
          searchText={searchText}
          role={role}
          handleSearchByFieldName={handleSearchByFieldName}
          handleSortByFieldName={handleSortByFieldName}
          changeCheckboxStatus={changeCheckboxStatus}
          isParentChecked={isParentChecked}
          config={config}
        />
        <Table.Body className="t-header">
          <OfficeTableBody
            handleEditOffice={handleEditOffice}
            handleDeleteModal={handleDeleteModal}
            sortAndSearchResultData={sortAndSearchResultData}
            role={role}
            allProcessors={allProcessors}
            handlePaperAppsChecked={handlePaperAppsChecked}
            handleFreeEquipmentPlacementChecked={handleFreeEquipmentPlacementChecked}
            handleDeactivateModal={handleDeactivateModal}
            changeCheckboxStatus={changeCheckboxStatus}
            selectedOfficeBulk={selectedOfficeBulk}
            config={config}
          />
        </Table.Body>
      </Table>
    </>
  );
};

export default OfficeTable;
