import React from "react";
import { useLocation } from "react-router-dom";
import { Table } from "semantic-ui-react";
import { firstBy } from "thenby";
import useCheckMobileScreen, {
  useCheckTabScreen,
} from "../../../hooks/useCheckMobileScreen";
import { ROLES } from "../../../Utils/constants";
import TableComponent from "../../TableComponent";
import UserTableBody from "./UserTableBody";
import UserTableHeader from "./UserTableHeader";
import { getParsedList } from "../../../Utils/helpers";
const { REACT_APP_BACKEND_MYSQL } = process.env;

interface UsersTableProps {
  sortAndSearchResultData: any[];
  role: string | null;
  sortFieldOrder: string;
  sortField: string;
  searchField: string;
  searchText: string;
  heading?: string | any;
  handleSearchByFieldName: (field_name: string, sort_order: string) => void;
  handleSortByFieldName: (field_name: string, sort_order: string) => void;
  handleEditUser: (user_id: number) => Promise<void>;
  handleDeleteUser: (user_id: number) => Promise<void>;
  handleIRISChecked: (data: any, user_id: string) => Promise<void>;
  handleFeenavigatorChecked: (data: any, user_id: string) => Promise<void>;
  handleResetPassword: (email: string) => Promise<void>;
  handleDeactivateModal: (user_id: number) => Promise<void>;
  handleFreeEquipmentPlacementChecked: (data: any, user_id: string) => Promise<void>;
  loading: boolean;
  algoliaLoading: boolean
  changeCheckboxStatus: (e: any, id: string) => void
  isParentChecked: boolean
  viewOptions : boolean
  selectedUserBulk: any[]
  config:any
}

const getTableKeys = (
  isMobile: boolean,
  isTablet: boolean,
  role: string | null,
  handleIRISChecked: (data: any, user_id: string) => Promise<void>,
  handleFeenavigatorChecked: (data: any, user_id: string) => Promise<void>,
  handleFreeEquipmentPlacementChecked: (data: any, user_id: string) => Promise<void>
) => {
  let headerKeys: any = [];
  let rowKeys: any = [];
  if (isMobile) {
    headerKeys = [
      { label: "Name", key: "name" },
      { label: "Role", key: "role" },
      { label: "Status", key: "status" },
    ];
  
    rowKeys = [
      { key: "user_email", label: "Email" },
      { key: "office_name", label: "Office" },
      { key: "parent_office_name", label: "Parent Office" },
      { key: "rmNames", label: "RM's" },
      { key: "low_risk_processors", label: "Low Risk Banks" },
      { key: "high_risk_processors", label: "High Risk Bank" },
      { key: "allowed_risk_levels", label: "Risk Level" },
      {
        display: role !== ROLES.SUPER_ADMIN,
        key: "iris_enabled",
        label: "Reports Dashboard",
        type: "checkbox",
        name: "enableIRIS",
        change: handleIRISChecked,
        checkProperty: "iris_enabled",
      },
      {
        display: role !== ROLES.SUPER_ADMIN,
        key: "feenavigator_enabled",
        label: "STMT Analysis",
        type: "checkbox",
        name: "enableFeeNavigator",
        change: handleFeenavigatorChecked,
        checkProperty: "feenavigator_enabled",
      },
      {
        display: !(role === ROLES.SUPER_ADMIN || role === ROLES.OFFICE_ADMIN || role === ROLES.OFFICE_MANAGER || role === ROLES.RELATIONSHIP_MANAGER),
        key: "freeequipmentplacement_enabled",
        label: "Free Equipment Placement",
        type: "checkbox",
        name: "enableFreeEquipmentPlacement",
        change: handleFreeEquipmentPlacementChecked,
        checkProperty: "free_equipment_placement_enabled",
      }
    ];
  } else if (isTablet) {
    if (window.innerWidth < window.innerHeight ) {
      headerKeys = [
        { label: "Name", key: "name" },
        { label: "Role", key: "role" },
        { label: "Office", key: "office_name" },
        { label: "RM's", key: "rmNames" },
        { label: "Risk Level", key: "allowed_risk_levels" },
        { label: "User Status", key: "status" }
      ];
    
      rowKeys = [
        { key: "user_email", label: "Email" },
        { key: "parent_office_name", label: "Parent Office" },
        { key: "low_risk_processors", label: "Low Risk Banks" },
        { key: "high_risk_processors", label: "High Risk Bank" },
        {
          display: role !== ROLES.SUPER_ADMIN,
          key: "iris_enabled",
          label: "Reports Dashboard",
          type: "checkbox",
          name: "enableIRIS",
          change: handleIRISChecked,
          checkProperty: "iris_enabled",
        },
        {
          display: role !== ROLES.SUPER_ADMIN,
          key: "feenavigator_enabled",
          label: "STMT Analysis",
          type: "checkbox",
          name: "enableFeeNavigator",
          change: handleFeenavigatorChecked,
          checkProperty: "feenavigator_enabled",
        },
        {
          display: !(role === ROLES.SUPER_ADMIN || role === ROLES.OFFICE_ADMIN || role === ROLES.OFFICE_MANAGER || role === ROLES.RELATIONSHIP_MANAGER),
          key: "freeequipmentplacement_enabled",
          label: "Free Equipment Placement",
          type: "checkbox",
          name: "enableFreeEquipmentPlacement",
          change: handleFreeEquipmentPlacementChecked,
          checkProperty: "free_equipment_placement_enabled",
        }
      ];
    } else {
      headerKeys = [
        { label: "Name", key: "name" },
        { label: "Role", key: "role" },
        { label: "Office", key: "office_name" },
        { label: "Parent Office", key: "parent_office_name" },
        { label: "RM's", key: "rmNames" },
        { label: "Risk Level", key: "allowed_risk_levels" },
        { label: "User Status", key: "status" }
      ];
    
      rowKeys = [
        { key: "user_email", label: "Email" },
        { key: "low_risk_processors", label: "Low Risk Banks" },
        { key: "high_risk_processors", label: "High Risk Bank" },
        {
          display: role !== ROLES.SUPER_ADMIN,
          key: "iris_enabled",
          label: "Reports Dashboard",
          type: "checkbox",
          name: "enableIRIS",
          change: handleIRISChecked,
          checkProperty: "iris_enabled",
        },
        {
          display: role !== ROLES.SUPER_ADMIN,
          key: "feenavigator_enabled",
          label: "STMT Analysis",
          type: "checkbox",
          name: "enableFeeNavigator",
          change: handleFeenavigatorChecked,
          checkProperty: "feenavigator_enabled",
        },
        {
          display: !(role === ROLES.SUPER_ADMIN || role === ROLES.OFFICE_ADMIN || role === ROLES.OFFICE_MANAGER || role === ROLES.RELATIONSHIP_MANAGER),
          key: "freeequipmentplacement_enabled",
          label: "Free Equipment Placement",
          type: "checkbox",
          name: "enableFreeEquipmentPlacement",
          change: handleFreeEquipmentPlacementChecked,
          checkProperty: "free_equipment_placement_enabled",
        }
      ];
    }
  }
  
  return {
    headerKeys,
    rowKeys
  }
};

const UserTableMobile = (props: UsersTableProps) => {
  const {
    sortAndSearchResultData,
    role,
    heading,
    handleEditUser,
    handleDeleteUser,
    handleIRISChecked,
    handleFeenavigatorChecked,
    handleResetPassword,
    handleDeactivateModal,
    loading,
    algoliaLoading,
    changeCheckboxStatus,
    isParentChecked,
    viewOptions,
    selectedUserBulk,
    handleFreeEquipmentPlacementChecked
  } = props;
  const search = useLocation().search;
  const sortOrder = new URLSearchParams(search).get("sort");
  const ascFn = (a: string, b: string) => a.localeCompare(b);
  if (!!sortOrder) {
    sortAndSearchResultData.sort((a: any, b: any) =>
      sortOrder === "asc"
        ? ascFn(a.user_id, b.user_id)
        : ascFn(b.user_id, a.user_id)
    );
  }

  const isMobile = useCheckMobileScreen();
  const isTablet = useCheckTabScreen();

  const tableKeys = getTableKeys(
    isMobile,
    isTablet,
    role,
    handleIRISChecked,
    handleFeenavigatorChecked,
    handleFreeEquipmentPlacementChecked
  );

  const enableActivteDeactivate =
    role === ROLES.RELATIONSHIP_MANAGER ||
    role === ROLES.OFFICE_ADMIN ||
    role === ROLES.SUPER_ADMIN ||
    role === ROLES.OFFICE_MANAGER;

  const userData = sortAndSearchResultData
    ?.filter((user: any) => !user.deleted)
    ?.map((user) => {
      let rmNames = getParsedList(user?.rm_details)
        .map((rm: any) => {
          const rm_name = REACT_APP_BACKEND_MYSQL ? `${rm.user_name_first} ${rm.user_name_last}` : `${user.user_name.first_name} ${user.user_name.last_name}`
          return rm_name;
        })
        .join(",");
      let low_risk_processors: any = getParsedList(user?.allowed_low_risk_processors) || [];
      let high_risk_processors: any = getParsedList(user?.allowed_high_risk_processors) || [];
      low_risk_processors?.sort(
        firstBy(function (option: any) {
          return option?.toLowerCase();
        })
      );
      high_risk_processors?.sort(
        firstBy(function (option: any) {
          return option?.toLowerCase();
        })
      );
      const user_name = REACT_APP_BACKEND_MYSQL ? `${user?.user_name_first.toUpperCase()} ${user?.user_name_last.toUpperCase()}` : `${user?.user_name?.first_name.toUpperCase()} ${user?.user_name?.last_name.toUpperCase()}`
      let name = user_name;
      let userRole =
        user.role_code === ROLES.PARTNER ? "SALES USER" : user?.role_code;
      low_risk_processors = low_risk_processors?.join(", ");
      high_risk_processors = high_risk_processors?.join(", ");
      let allowed_risk_levels = getParsedList(user?.allowed_risk_levels).join(", ");
      let status = user.deactivate ? "INACTIVE" : "ACTIVE";

      const uiActions = [
        {
          display: enableActivteDeactivate,
          className: selectedUserBulk.length>1? "not-active deactivate-icon userList" : "deactivate-icon userList",
          label: user.deactivate ? "Activate" : "Deactivate",
          action: () => handleDeactivateModal(user.user_id),
        },
        {
          className: selectedUserBulk.length>1? "not-active edit-icon userList" : "edit-icon userList",
          label: "Edit",
          action: () => handleEditUser(user.user_id),
        },
        {
          display: role === ROLES.SUPER_ADMIN || role === ROLES.OFFICE_ADMIN || role === ROLES.OFFICE_MANAGER || role === ROLES.RELATIONSHIP_MANAGER,
          className: selectedUserBulk.length>1? "not-active reset-password-icon userList" : "reset-password-icon userList",
          label: "Reset Password",
          action: () => handleResetPassword(user.user_email),
        },
        {
          //display: role !== ROLES.RELATIONSHIP_MANAGER,
          className: selectedUserBulk.length>1? "not-active delete-icon userList" : "delete-icon userList",
          label: "Delete",
          action: () => handleDeleteUser(user.user_id),
        },
      ];
      return {
        ...user,
        name,
        role: userRole,
        rmNames,
        low_risk_processors,
        high_risk_processors,
        allowed_risk_levels,
        status,
        uiActions,
      };
    });

  return (
    <TableComponent
      headerList={tableKeys.headerKeys}
      rowKeys={tableKeys.rowKeys}
      tableData={userData}
      heading={heading}
      uniqueId={"user_id"}
      className={'mobile-table-max'}
      noAppMessage={'NO USERS FOUND!!'}
      loading={loading || algoliaLoading}
      changeCheckboxStatus = {changeCheckboxStatus}
      isParentChecked = {isParentChecked}
      role = {role}
      bulkId= {"user_email"}
      viewOptions = { viewOptions}
    />
  );
};
const UserTable = (props: UsersTableProps) => {
  const {
    sortAndSearchResultData,
    role,
    sortFieldOrder,
    sortField,
    searchField,
    searchText,
    handleSearchByFieldName,
    handleSortByFieldName,
    handleEditUser,
    handleDeleteUser,
    handleIRISChecked,
    handleFeenavigatorChecked,
    handleResetPassword,
    handleDeactivateModal,
    changeCheckboxStatus,
    isParentChecked,
    viewOptions,
    selectedUserBulk,
    handleFreeEquipmentPlacementChecked,
    config
  } = props;

  const isMobile = useCheckMobileScreen();
  const isTablet = useCheckTabScreen();

  if (isMobile || isTablet) {
    return <UserTableMobile {...props} />;
  }

  return (
    <Table
      textAlign="center"
      celled
      striped
      structured
      className="list-table-view"
    >
      <UserTableHeader
        sortFieldOrder={sortFieldOrder}
        sortField={sortField}
        searchField={searchField}
        searchText={searchText}
        role={role}
        handleSearchByFieldName={handleSearchByFieldName}
        handleSortByFieldName={handleSortByFieldName}
        changeCheckboxStatus = {changeCheckboxStatus}
        isParentChecked = {isParentChecked} 
        config={config}
      />
      <Table.Body className="t-header">
        <UserTableBody
          handleEditUser={handleEditUser}
          handleDeleteUser={handleDeleteUser}
          sortAndSearchResultData={sortAndSearchResultData}
          role={role}
          handleIRISChecked={handleIRISChecked}
          handleFeenavigatorChecked={handleFeenavigatorChecked}
          handleResetPassword={handleResetPassword}
          handleDeactivateModal={handleDeactivateModal}
          changeCheckboxStatus = {changeCheckboxStatus}
          selectedUserBulk={selectedUserBulk}
          handleFreeEquipmentPlacementChecked={handleFreeEquipmentPlacementChecked}
        />
      </Table.Body>
    </Table>
  );
};

export default UserTable;
