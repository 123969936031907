import React, { useState, useEffect, memo, useContext } from 'react'
import { Table, Button, Modal } from 'semantic-ui-react'
import { useHistory, useLocation } from 'react-router-dom'
import api from '../../Service/Api'
import backend from '../../Service/Backend'
import { Layout } from '../PageLayout/Layout'
import { Footer } from '../PageLayout/Footer'
import ProgressLoader from '../Common/ProgressLoader'
import { AppListWithdrawInactiveStatus, MERCHANT_APP_STATUS, RISK_LEVELS, ROLES } from '../../Utils/constants'
import useCheckMobileScreen, { useCheckTabScreen } from '../../hooks/useCheckMobileScreen'
import TableComponent from '../TableComponent'
import ArchivedResultModal from '../Common/ArchivedResultModal'
import { fetchAppListData, setSettingsAppListIndex } from '../../Service/Algolia';
import { Input, Pagination } from 'antd';
import Select from 'react-select'
import ActionConfirmation from "./AppSectionComponents/ActionConfirmationModal";
import ActionResendLinkConfirmation from "./AppSectionComponents/ActionResenLinkConfirmationModal";
import { AppContext } from '../../context/appContext'
import BasicButton from '../Common/Button'
const { Search } = Input;

const { REACT_APP_BACKEND_MYSQL } = process.env;

const ListApp = (props: any) => {
  const { config }: any = useContext(AppContext);
  const history = useHistory();
  const pageSize = 50;

  const search = useLocation().search;
  const searchObj = new URLSearchParams(search);
  const sortOrder: any = searchObj.get('sort');
  const searchTerm: any = searchObj.get('searchTerm');
  const appType: any = searchObj.get('appType');

  const [signModalOpen, setSignModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [emailActivityModalOpen, setEmailActivityModalOpen] = useState(false);
  const [loading, setLoading] = useState(true)
  const [overlay, setOverlay] = useState({ style: { display: 'none' } })
  const [overlayMsg, setOverlaymsg] = useState('Successfully pulled Merchant Apps list')
 
  
  const [merchantApps, setMerchantApps] = useState([] as any[])
  const [merchantArchivedApps, setMerchantArchivedApps] = useState([] as any[])
  const [selectedUser, setSelectedUser] = useState({} as any)
  const [userMessage, setUserMessage] = useState('')

  const [algoliaSearchText, setAlgoliaSearchText] = useState("");
  const [algoliaAppType, setAlgoliaAppType] = useState("");

  const [sortField, setSortField] = useState('updated_ts');
  const [sortDirection, setSortDirection] = useState('desc');

  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const user_id = sessionStorage.getItem('user_id')
  const role = sessionStorage.getItem('role')
  const office_id = sessionStorage.getItem('office_id')
  const partner_id = sessionStorage.getItem('partner_id')
  const partner_rm_id = sessionStorage.getItem('partner_rm_id')
  const client_id = sessionStorage.getItem('clientId')

  // Searching
  const [searchText, setSearchText] = useState('')
  const [searchType, setSearchType] = useState()
  const [searchId, setSearchId] = useState('')

  
  // const [searchParentAppId, setSearchParentAppId] = useState('')
  const [SearchPartnerName, setSearchPartnerName] = useState('')
  const [searchLegal, setSearchLegal] = useState('')
  const [searchDba, setSearchDba] = useState('')
  const [searchBank, setSearchBank] = useState('')
  const [searchLink, setSearchLink] = useState('')
  const [searchStartDate, setSearchStartDate] = useState('')
  const [searchLastModifiedDate, setSearchLastModifiedDate] = useState('')
  const [searchStatus, setSearchStatus] = useState('')
  const [pageUsers, setPageUsers] = useState([] as any[])
  const [searchedUsers, setSearchedUsers] = useState([] as any[])
  const [noSearchDataAlert, setNoSearchDataAlert] = useState(false)
  const [noArchivedDataAlert, setNoArchivedDataAlert] = useState(false)

  const [emailLogsData, setEmailLogsData] = useState({} as any)
  const [emailLogsLoading, setEmailLogsLoading] = useState(false);

  const isMobile = useCheckMobileScreen();
  const isTablet = useCheckTabScreen();
  const [sendMailToSecondSigner, setSendMailToSecondSigner] = useState(false)

  let defaultApp = true  
  let titleDefault = 'LIST OF MERCHANT APPS' 
  let buttonDefault = 'Archived Apps' 
  let viewOptions = true
  if(role === ROLES.VIEW_ONLY){ viewOptions = false }

  if(searchTerm !== "" && appType === "ArchivedApps" && (isMobile || isTablet)){
    defaultApp = false
    titleDefault = 'ARCHIVED APPLICATIONS LIST' 
    buttonDefault = 'Active Apps' 
 }

  const [showNonArchivedApps, setShowNonArchivedApps] = useState(defaultApp)
  const [tilte, setTitle] = useState(titleDefault);
  const [buttonText, setButtonText] = useState(buttonDefault);

  //Bulk update ;
  const appBulkActionRM: any = [
    { value: 'withdrawn', label: 'Withdraw',text : 'Withdraw' },
    { value: 'started', label: 'Reinstate',text : 'Start Again'  },
    { value: 'sent to uw', label: 'Send to UW',text : 'SEND TO UW'  },
    { value: 'Resend link', label: 'Resend link' ,text : 'Resend Link' },
  ]
  const appBulkActionSA: any = [
    { value: 'withdrawn', label: 'Withdraw',text : 'Withdraw'  },
    { value: 'started', label: 'Reinstate', text : 'Start Again'},
    { value: 'Resend link', label: 'Resend link' ,text : 'Resend Link'},
  ]
  const [appsToUpdate, setApps] = useState([] as any[])
  const [selectedAppBulk, setSelectedAppBulk] = useState([] as any)
  const [bulkaction, setBulkaction] = useState('')
  const [isParentChecked, setIsParentChecked] = useState(false);
  const [actionConfirmation, setActionConfirmation] = useState(false)
  const [modalAction, setModalAction] = useState('')
  const [statusCount, setStatusCount] = useState('')
  const [additionalCheckStatus, setadditionalCheckStatus] = useState([] as any)
  const [actionConfirmationforResendlink, setActionConfirmationforResendlink] = useState(false)
  const [userMessageResend, setUserMessageResend] = useState('')
  const [bulkUpdateStatus, setBulkUpdateStatus] = useState('')

   //Bulk Update
  const bulkUpdateAction  = (item: any) => {
    setBulkaction(item)
    setModalAction(item.text)
    setBulkUpdateStatus(item.value)
    let selectedAppStatus = item.value
    setadditionalCheckStatus('')
    const checkAppStatus = selectedAppBulk.filter((item: any) => item.app_status === selectedAppStatus);
    setStatusCount(checkAppStatus.length)
    if(checkAppStatus.length === 0){
      let searchTerms : any[] = [];
      let checkforResendLink = false
      if(item.value === 'withdrawn'){
         searchTerms = ['signed', 'docs uploaded','sent to uw'];
      }
      else if(item.value === 'sent to uw'){
        searchTerms = ['sent to merchant', 'withdrawn','started',''];
      }
      else if(item.value === 'started'){
        searchTerms = ['sent to merchant', 'docs uploaded','started','sent to uw','signed','partially signed'];
      }
      else if(item.value === 'Resend link'){
         searchTerms = ['sent to uw','withdrawn','signed'];
         let searchTermsNew = [false]
         let getFlaseFlag = selectedAppBulk.filter((obj: { send_mail_flag: boolean }) => searchTermsNew.includes(obj.send_mail_flag))
         if(getFlaseFlag.length > 0){
          checkforResendLink = true
          setadditionalCheckStatus(getFlaseFlag)
         }
      }
      if(!checkforResendLink){
        let result = selectedAppBulk.filter((obj: { app_status: string }) => searchTerms.includes(obj.app_status));
        setadditionalCheckStatus(result)
      }
    }
    console.log(checkAppStatus.length , 'length')
    setActionConfirmation(true)
    
  }

  const uncheckparentcheckbox = async()=>{
    setBulkaction('')
    const myUsers = [...pageUsers];
    setIsParentChecked(false)
    myUsers.map((user) => {
      user.isChecked = false
      return user;
    });
    setSelectedAppBulk([])
  }

  let finalarray : any[] = [];
  const changeCheckboxStatus = async(e:any, id:string) => {
   setBulkaction('')
   const myApps = [...pageUsers];
   console.log(pageUsers)
    const { checked } = e.target;
    if (id === "p1" ) {
      finalarray = [] 
      setSelectedAppBulk([])
    }
    myApps.map((apps) => {
      if (id === "p1") {
        setIsParentChecked(checked);
        apps.isChecked = checked;
        if(e.target.checked){
          finalarray.push({'app_id' : apps.merchant_app_id,'app_status':apps.merchant_app_status,'merchant_name':apps.merchant_name,'merchant_email' :apps.merchant_email,'dba_name' : apps.dba_name,'send_mail_flag':apps.merchant_send_mail_flag, 'app_code': apps.merchant_app_code})
          setSelectedAppBulk(finalarray)
        }
      } else {
        if (apps.merchant_app_id === id) {
          apps.isChecked = checked;
          let checkID =  selectedAppBulk.findIndex((app: any) => app.app_id === id)
          if(checkID === -1){
            selectedAppBulk.push({'app_id' : apps.merchant_app_id,'app_status':apps.merchant_app_status,'merchant_name':apps.merchant_name,'merchant_email' :apps.merchant_email,'dba_name' : apps.dba_name,'send_mail_flag':apps.merchant_send_mail_flag, 'app_code': apps.merchant_app_code})
            setSelectedAppBulk(selectedAppBulk)
          }
          else{
            let secondRemoved  = selectedAppBulk.filter((app: any) => app.app_id !== id)
            setSelectedAppBulk(secondRemoved)
          }
        }
        const isAllChildsChecked = myApps.every(
          (apps) => apps.isChecked === true
        );
        if (isAllChildsChecked) {
          setIsParentChecked(checked);
        } else {
          setIsParentChecked(false);
        }
      }
      return apps;
    });
   
    setApps([...myApps]);
  };
  const handleCancel = () => {
    setActionConfirmation(false)
    setBulkaction('')
    setActionConfirmationforResendlink(false)
  }
  const  handleConfirmAction = async( modalAction : string)=>{
     setLoading(true)
      try{
        if(modalAction !== 'Resend Link'){
            
            let updateAllType = true
            let newApplist : any[] = [];
            let newApplistarchive : any[] = [];
            if(!showNonArchivedApps && (modalAction === 'Start Again' || modalAction === 'SEND TO UW')){
                updateAllType = false
                //Update Archived Status
                const data = {
                  api: api.merchantAppData.updateMerchantAppArchivedStatusBulk,
                  payLoad: JSON.stringify({
                    applist:  selectedAppBulk,
                    status:bulkUpdateStatus
                    })
                  }
                const response = await backend.save(data)
                if(modalAction === 'Start Again'){
                  newApplistarchive  = pageUsers.filter((app: any) => app.merchant_app_status !== 'withdrawn')
                }
                else if(modalAction === 'SEND TO UW'){
                  newApplistarchive  = pageUsers.filter((app: any) => app.merchant_app_status !== 'docs uploaded')
                }
            }
            const data = {
              api: api.merchant.updateMerchantAppStatusBulk,
              payLoad: JSON.stringify({
                applist:  selectedAppBulk,
                status:bulkUpdateStatus
                })
              }
              const response = await backend.save(data)
              if(response){
                uncheckparentcheckbox()
                    newApplist = pageUsers?.map((app: any) => {
                      if(selectedAppBulk.findIndex((bulkapp: any) => bulkapp.app_id === app.merchant_app_id) > -1){
                      return  { ...app, merchant_app_status:bulkUpdateStatus}
                      }else{
                        return app
                      }
                    })
            }
            if(updateAllType){
              setPageUsers(newApplist)
            }else{
              setPageUsers(newApplistarchive)
            }
            showOverlayForThreeSeconds()
            setOverlaymsg('Status updated successfully')
            setLoading(false)
            setActionConfirmation(false)
        }
        else{
          setActionConfirmation(false)
          setActionConfirmationforResendlink(true)
        }
    }
    catch (err: any) {
      showOverlayForThreeSeconds()
      setOverlaymsg('Bulk Action App Failed')
    }
    finally {
      setLoading(false);
    }

  }
  const handleConfirmActionResendlink = async( modalAction : string)=>{
    if (!userMessageResend) {
      showOverlayForThreeSeconds()
      setOverlaymsg('Personal Message is required to send the mail')
      return
    }
    const data = {
      api: api.integrations.sendMailBulk,
      payLoad: JSON.stringify({
        applist:  selectedAppBulk,
        personalMessage:userMessageResend
        })
    }
    setLoading(true)
    try {
      const response = await backend.save(data)
      if (response) {
       const newbulk = selectedAppBulk.filter((element: { app_status: string }) => {
        return element.app_status !== 'signed' && element.app_status !== 'docs uploaded';
      });
      //console.log(newbulk)

        setActionConfirmationforResendlink(false)
        uncheckparentcheckbox()
        let newApplist = pageUsers?.map((app: any) => {
          if(newbulk.findIndex((bulkapp: any) => bulkapp.app_id === app.merchant_app_id && (bulkapp.app_status !== 'signed'  || bulkapp.app_status !== 'docs uploaded')) > -1){  
            return  { ...app, merchant_app_status:'sent to merchant'}
          }else{
            return app
          }
        })      
        setPageUsers(newApplist)
      }
      setLoading(false)
      showOverlayForThreeSeconds()
      setOverlaymsg('Mail Sent successfully')
      const merchant_apps = merchantApps
      setLoading(false)
      setMerchantApps(merchant_apps)
      
    } catch (err: any) {
      setLoading(false)
      showOverlayForThreeSeconds()
      setOverlaymsg('Sending Mail Failed')
    }
    finally {
      setUserMessage('')
      setSignModalOpen(false)
    }
  }

  const fetchData = async () => {
    console.log("fetchData");
    try {
      setLoading(true)
      let filters: string = ''

      const super_user = sessionStorage.getItem("super_user") === 'true'
      if (!super_user) {
        if (role === ROLES.PARTNER || role === ROLES.VIEW_ONLY || role === ROLES.SALES_LIMITED) {
          filters = `partner_id:${partner_id}`
        }

        if (role === ROLES.RELATIONSHIP_MANAGER) {
          filters = REACT_APP_BACKEND_MYSQL ? `partner_rm_user_id:${user_id}` : `partner_rm_id:${user_id}`
        }

        if (role === ROLES.OFFICE_ADMIN || role === ROLES.OFFICE_MANAGER || role === ROLES.UNDER_WRITER) {
          filters = `partner_id:${partner_id} OR office_id:${office_id} OR parent_office_ids:${office_id}`
        }
      } else {
        if (role === ROLES.RELATIONSHIP_MANAGER) {
          filters = REACT_APP_BACKEND_MYSQL ? `partner_rm_user_id:${user_id}` : `partner_rm_id:${user_id}`
        }
      }

      if (REACT_APP_BACKEND_MYSQL) {
        filters = `${!!filters ? `${filters} AND ` : ''} NOT merchant_app_status:deleted`
        if (showNonArchivedApps) {
          filters = `${filters} AND NOT archived:true`
        } else {
          filters = `${filters} AND archived:true`
        }
      } else {
        filters = `${!!filters ? `${filters} AND ` : ''}archived:${filters = `${!!filters ? `${filters} AND ` : ''} NOT merchant_app_status:deleted` ? 'false' : 'true'} AND NOT merchant_app_status:deleted`
      }

      const appList: any = await fetchAppListData(algoliaSearchText, activePage - 1, pageSize, filters);
      if (activePage > appList.nbPages) {
        setActivePage(1)
      }
      console.log('appList-listapp', appList)
      setTotalRecords(appList?.nbHits)

      let non_archived_apps: any = []
      let archived_apps: any = []

     
      appList?.hits.forEach((merchantApp: any) => {
        if (merchantApp?.archived == 1)
          archived_apps.push(merchantApp)
        else non_archived_apps.push(merchantApp)
      });

      setMerchantApps(non_archived_apps)
      setPageUsers(showNonArchivedApps ? non_archived_apps : archived_apps)
      setMerchantArchivedApps(archived_apps)
      console.log(archived_apps)
      uncheckparentcheckbox()

    } catch (err: any) {
      const msg = 'Error while pulling Merchant Apps!'
      if (err.errMessage) {

        showOverlayForThreeSeconds()
        setOverlaymsg(err.errMessage)
      } else {
        showOverlayForThreeSeconds()
        setOverlaymsg(msg)
      }
    } finally {
      setLoading(false)
    }
  }

  const fetchLogsData = async (user: any) => {
    const getMerchantAppLogsById = async () => {
      try {
        const data: any = {
          queryParam: { merchant_app_id: user.merchant_app_id },
          api: api.merchantAppData.getMerchantAppLogsById
        }
        setEmailLogsLoading(true)
        const merchant_app_logs: any = await backend.fetch(data)
        console.log('merchant_app_logs : ', merchant_app_logs)
        setEmailLogsData(merchant_app_logs);
        return merchant_app_logs
      } catch (err: any) {
        const msg = 'Error while pulling Merchant App Email Logs!'
        if (err.errMessage) {
          showOverlayForThreeSeconds()
          setOverlaymsg(err.errMessage)
        } else {
          showOverlayForThreeSeconds()
          setOverlaymsg(msg)
        }
        setEmailLogsData({})
      } finally {
        setEmailLogsLoading(false)
      }
    }
    getMerchantAppLogsById();
  }

  const sortResults = async () => {
    setLoading(true)
    await setSettingsAppListIndex(sortField, sortDirection);
    fetchData();
  }

  useEffect(() => {
    fetchData()
  }, [partner_id, role, user_id, activePage, showNonArchivedApps, algoliaSearchText,algoliaAppType, config.selectedClientId])

  useEffect(() => {
    sortResults()
  }, [sortDirection, sortField])


  useEffect(() => {
    if (!!sortOrder) {
      setSortDirection(sortOrder)
      setSortField("updated_ts")
    }
    setAlgoliaSearchText(!!searchTerm ? searchTerm : '')
    setAlgoliaAppType(!!appType ? appType : '')
  }, [search])

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds))
  }

  const showOverlayForThreeSeconds = async () => {
    const sstyle: any = { style: { display: 'block', position: 'fixed' } }
    setOverlay(sstyle)
    await sleep(1000)
    const hstyle: any = { style: { display: 'none' } }
    setOverlay(hstyle)
  }

  const documentUploadClick = async (app: any) => {
    setSignModalOpen(false)
    history.push('/')
    history.replace(`/docupload?merchant_app_id=${app.merchant_app_id}`)
  }

  const listApps = async (type: any) => {
    setAlgoliaSearchText(" ")
    if (type === true) {
      setTitle("ARCHIVED APPLICATIONS LIST");
      setButtonText("Active Apps");
      setShowNonArchivedApps(false);
    } else {
      setShowNonArchivedApps(true);
      setTitle("LIST OF MERCHANT APPS");
      setButtonText("Archived Apps");
    }
    setActivePage(1)
    uncheckparentcheckbox()
  };


  const signClick = async (user: any) => {
    setSelectedUser(user)
    if (user.merchant_app_status === MERCHANT_APP_STATUS.PARTIALLY_SIGNED) setSendMailToSecondSigner(true)
    else setSignModalOpen(true)
  }
  const deleteClick = async (user: any) => {
    setSelectedUser(user)
    setDeleteModalOpen(true)
  }

  const emailActivityClick = async (user: any) => {
    setSelectedUser(user)
    setEmailActivityModalOpen(true);
    fetchLogsData(user);
  }

  const duplicateClick = async (merchant_app_id: string) => {
    const data: any = {}
    data.api = api.merchantAppData.duplicateMerchantApp;
    data.payLoad = { merchant_app_id: merchant_app_id }
    setLoading(true)
    try {
      const response = await backend.save(data)
      showOverlayForThreeSeconds()
      setOverlaymsg('Duplicating Merchant App successfully')
      history.push({
        pathname: `/startapp/edit/MA/${response?.merchant_app_id}`
      });
      uncheckparentcheckbox()
    } catch (err: any) {
      showOverlayForThreeSeconds()
      setOverlaymsg('Duplicating Merchant App Failed')
    }
    finally {
      setLoading(false);
    }

  }

  const handleSort = (field: string, order: string) => {
    setSortField(field);
    setSortDirection(order);
  }

  const formatDate = (date: any) => {
    var d = new Date(date)
    var month = '' + (d.getMonth() + 1)
    var day = '' + d.getDate()
    var year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [month, day, year].join('-')
  }

  const handleEditButtonClick = (merchant_app_id: any) => {
    history.push({
      pathname: `/startapp/edit/MA/${merchant_app_id}`
    })
  }

  const handleWithdrawButtonClick = async (merchant_app_id: string, merchant_app_status: string) => {
    setSelectedAppBulk([])
    uncheckparentcheckbox()
    const update_status = merchant_app_status === 'withdrawn' ? 'started' : 'withdrawn'
    const payload = {
      merchant_app_id: merchant_app_id,
      status: update_status
    }
    const data = {
      api: api.merchantAppData.updateMerchantAppStatus,
      payLoad: { ...payload }
    }

    setLoading(true)
    const response = await backend.save(data)
    console.log(showNonArchivedApps)

    if (showNonArchivedApps === true) {
      const merchant_apps = merchantApps
      let index = merchantApps.findIndex((merchant_app: any) => merchant_app.merchant_app_id === merchant_app_id)
      merchant_apps[index].merchant_app_status = update_status
      setLoading(false)

      let page_users = pageUsers;
      let index1 = pageUsers.findIndex((merchant_app: any) => merchant_app.merchant_app_id === merchant_app_id)
      page_users[index1].merchant_app_status = update_status

      setPageUsers(page_users)
      setMerchantApps(merchant_apps)
      setSearchedUsers(merchant_apps)
    }

    if (showNonArchivedApps === false) {
      console.log(merchant_app_id)
      const payload = {
        merchant_app_id: merchant_app_id,
        archived: false
      }
      const data = {
        api: api.merchantAppData.updateMerchantAppArchivedStatus,
        payLoad: { ...payload }
      }
      const response = await backend.save(data)

      setLoading(false)
      const list = [...merchantArchivedApps]
      let index = list.findIndex((merchant_app: any) => merchant_app.merchant_app_id === merchant_app_id)

      //Toggle Status
      const merchant_archived_apps = merchantArchivedApps
      let index2 = merchantArchivedApps.findIndex((merchant_app: any) => merchant_app.merchant_app_id === merchant_app_id)
      merchant_archived_apps[index2].merchant_app_status = update_status

      let page_users = pageUsers;
      let index1 = pageUsers.findIndex((merchant_app: any) => merchant_app.merchant_app_id === merchant_app_id)
      page_users[index1].merchant_app_status = update_status

      //Add index to merchant list and remove the index
      const merchant_apps = merchantApps
      const newMerchantApp = merchant_apps.concat(list[index]);

      list.splice(index, 1)
      setMerchantArchivedApps(list)
      setMerchantApps(newMerchantApp)
      if (list && list.length) {
        setPageUsers(list)
        setSearchedUsers(list)
      }
      else {
        setNoArchivedDataAlert(true)
        setPageUsers(newMerchantApp)
        setShowNonArchivedApps(true)
        setTitle('LIST OF MERCHANT APPS')
        setButtonText('Archived Apps')
      }
    }
    
  }
  const handleNoDataSearchAlert = async () => {
    setNoSearchDataAlert(false)
    setNoArchivedDataAlert(false)
    setSearchText('')
    setSearchId('')
    setSearchPartnerName('')
    setSearchLegal('')
    setSearchDba('')
    setSearchStartDate('')
    setSearchBank('')
    setSearchLink('')
    setSearchStatus('')
    if (showNonArchivedApps === true) {
      setPageUsers(merchantApps)
    }
    else {
      setPageUsers(merchantArchivedApps)
    }
  }

  const handlePaginationChange = (page: number, pagesize: number) => {
    setActivePage(page)
    uncheckparentcheckbox()
  }

  const genarateModifiedData = () => {
    return pageUsers.filter((user: any) => user.merchant_app_status !== "deleted").map((user: any, index: any) => {
      const createdTS = REACT_APP_BACKEND_MYSQL ? formatDate(user?.created_ts) :formatDate(new Date(user.merchant_app_start_ts._seconds * 1000 + user.merchant_app_start_ts._nanoseconds / 1000000).toDateString())
      const updatedTS = REACT_APP_BACKEND_MYSQL ? formatDate(user?.updated_ts) :formatDate(new Date(user?.updated_ts?._seconds * 1000 + user?.updated_ts?._nanoseconds / 1000000).toDateString())
      return ({
        bankName: user.processor_name,
        legalName: user.legal_name, dbaName: user.dba_name,
        ...user, createdTS, updatedTS
      });
    });
  };

  const getTableKeys = () => {
    let detailKeys: any = [];
    let headerKeys: any = [];
    if (isMobile) {
      detailKeys = [
        { label: 'Parent App ID', key: 'parent_app_id' },
        { label: 'Link Name', key: 'app_link_name' },
        { label: 'Sales User Name', key: 'partner_name' },
        { label: 'Corp Name', key: 'legalName' },
        { label: 'Bank', key: 'bankName' },
        { label: 'App Start Date', key: 'createdTS' },
        { label: 'Last Modified Date', key: 'updatedTS' },
      ];
      headerKeys = [
        { label: 'App ID', key: REACT_APP_BACKEND_MYSQL ? 'merchant_app_code' : 'merchant_app_id' },
        { label: 'DBA Name', key: 'dbaName' },
        { label: 'Status', key: 'merchant_app_status' }];
    } else if (isTablet) {
      if (window.innerWidth < window.innerHeight) {
        headerKeys = [
          { label: 'App ID', key: REACT_APP_BACKEND_MYSQL ?  'merchant_app_code' : 'merchant_app_id' },
          { label: 'Parent App ID', key: 'parent_app_id' },
          { label: 'DBA Name', key: 'dbaName' },
          { label: 'Bank', key: 'bankName' },
          { label: 'Status', key: 'merchant_app_status' }
        ];

        detailKeys = [
          { label: 'Link Name', key: 'app_link_name' },
          { label: 'Sales User Name', key: 'partner_name' },
          { label: 'Corp Name', key: 'legalName' },
          { label: 'App Start Date', key: 'createdTS' },
          { label: 'Last Modified Date', key: 'updatedTS' }
        ];
      } else {
        headerKeys = [
          { label: 'App ID', key: REACT_APP_BACKEND_MYSQL ?  'merchant_app_code' : 'merchant_app_id' },
          { label: 'Parent App ID', key: 'parent_app_id' },
          { label: 'DBA Name', key: 'dbaName' },
          { label: 'Bank', key: 'bankName' },
          { label: 'App Start Date', key: 'createdTS' },
          { label: 'Last Modified Date', key: 'updatedTS' },
          { label: 'Status', key: 'merchant_app_status' }];

        detailKeys = [
          { label: 'Link Name', key: 'app_link_name' },
          { label: 'Sales User Name', key: 'partner_name' },
          { label: 'Corp Name', key: 'legalName' },
        ];
      }
    }

    return {
      headerKeys,
      detailKeys
    }
  }

  const RenderTableforMobile = () => {
    const tableKeys = getTableKeys()
    let records = genarateModifiedData().map(user => ({ ...user, uiActions: getAppControls({ ...user, user }) }));
    const ascFn = (a: string, b: string) => a.localeCompare(b);
    if (!!sortOrder) {
      records.sort((a: any, b: any) => sortOrder === 'asc' ? ascFn(a.updatedTS, b.updatedTS) : ascFn(b.updatedTS, a.updatedTS));
    }
    return (
      <>
        <TableComponent
          headerList={tableKeys.headerKeys}
          hasAction={role === ROLES.VIEW_ONLY ? false : true}
          rowKeys={tableKeys.detailKeys}
          tableData={records}
          heading={
            <>
              <h3><b>{tilte}</b></h3>
              <span onClick={() => listApps(showNonArchivedApps)}>
                {buttonText}
              </span>
            </>
          }
          uniqueId={'merchant_app_id'}
          loading={loading}
          noAppMessage={algoliaSearchText !== "" ? 'No Data Found' : showNonArchivedApps ? 'No Merchant Apps as of now, please start from Create Link or Start App' : 'No data found in Archived Applications List'}
          displayFooter={true}
          className='mobile-table-max'
          changeCheckboxStatus = {changeCheckboxStatus}
          isParentChecked = {isParentChecked}
          role = {role}
          bulkId= {"app_id"}
          viewOptions = {viewOptions}
        />
        {noArchivedDataAlert &&
          <ArchivedResultModal
            noArchivedDataAlert={noArchivedDataAlert}
            handleCancel={handleNoDataSearchAlert}
          />}
      </>
    );
  };
  const renderRow = () => {
    return genarateModifiedData().map((user: any, index: any) => {
      const { legalName, bankName, dbaName, partner_name, createdTS, updatedTS,
        merchant_app_status, merchant_app_id, merchant_app_code, parent_app_id, app_link_name,
        created_by_user_id, price, offline_app_status, merchant_send_mail_flag, parent_app_code } = user
      return (
        <Table.Row key={index}>
          {(role !== ROLES.VIEW_ONLY) && (
            <Table.Cell className='t-data checkbox-action'>
            <>
            <div className="parentcheckbox">
              <input type="checkbox" checked={user?.isChecked} value={merchant_app_id} onChange={(e) => changeCheckboxStatus(e, merchant_app_id)}
              />
            </div>
            </>
            </Table.Cell>
          )}
          <Table.Cell>{REACT_APP_BACKEND_MYSQL ?  merchant_app_code : merchant_app_id}</Table.Cell>
          <Table.Cell>{REACT_APP_BACKEND_MYSQL ?  parent_app_code : parent_app_id}</Table.Cell>
          <Table.Cell>{app_link_name}</Table.Cell>
          <Table.Cell>{partner_name}</Table.Cell>
          <Table.Cell>{legalName}</Table.Cell>
          <Table.Cell>{dbaName}</Table.Cell>
          <Table.Cell>{bankName}</Table.Cell>
          <Table.Cell>{createdTS}</Table.Cell>
          <Table.Cell>{updatedTS}</Table.Cell>
          <Table.Cell>{merchant_app_status}</Table.Cell>
          {role !== ROLES.VIEW_ONLY && <Table.Cell className='display_oneline drop-downclass'>
            <AppControls
              merchant_app_id={merchant_app_id}
              merchant_app_status={merchant_app_status}
              offline_app_status={offline_app_status}
              price={price}
              user={user}
              created_by_user_id={created_by_user_id}
              merchant_send_mail_flag = {merchant_send_mail_flag}
              merchant_app_code={merchant_app_code}
            ></AppControls>
          </Table.Cell>}
        </Table.Row>
      )
    })
  }

  const getAppControls = (data: any) => {
    const { merchant_app_id, merchant_app_status, offline_app_status, user, created_by_user_id, merchant_send_mail_flag} = data
    const { merchant_prescreen: {
      price
    } = { price: "" } } = user;
    let editControl = (merchant_app_status === MERCHANT_APP_STATUS.WITHDRAWN || merchant_app_status === MERCHANT_APP_STATUS.SENT_TO_UW || merchant_app_status === MERCHANT_APP_STATUS.SIGNED || merchant_app_status === MERCHANT_APP_STATUS.PARTIALLY_SIGNED || merchant_app_status === MERCHANT_APP_STATUS.DOC_UPLOAD || selectedAppBulk.length >1) ? 'not-active edit-icon' : 'edit-icon';
    let withdrawControl = AppListWithdrawInactiveStatus.includes(merchant_app_status) || selectedAppBulk.length >1 ? 'not-active withdraw-icon' : 'withdraw-icon'
    let uploadControl = price === RISK_LEVELS.HIGH_RISK && selectedAppBulk.length <= 1 ? 'upload-icon' : price === RISK_LEVELS.LOW_RISK && !!offline_app_status  && selectedAppBulk.length <= 1 ? 'upload-icon' : 'not-active upload-icon'
    let duplicateControl = (merchant_app_status === MERCHANT_APP_STATUS.WITHDRAWN || selectedAppBulk.length >1) ? 'not-active duplicate-icon' : 'duplicate-icon'
    let sendMailControl = (merchant_app_status === MERCHANT_APP_STATUS.WITHDRAWN || merchant_app_status === MERCHANT_APP_STATUS.SENT_TO_UW || merchant_send_mail_flag === 0? true : false || selectedAppBulk.length >1) ? 'not-active send-icon' : 'send-icon'
    let sendtoUWControl = (role === ROLES.RELATIONSHIP_MANAGER && (merchant_app_status === MERCHANT_APP_STATUS.SIGNED || merchant_app_status === MERCHANT_APP_STATUS.DOC_UPLOAD) && selectedAppBulk.length <= 1) ? 'uw-icon' : 'not-active uw-icon'
    let deleteControl =   ( role === ROLES.SUPER_ADMIN || role === ROLES.OFFICE_ADMIN ) && (merchant_app_status === MERCHANT_APP_STATUS.STARTED || merchant_app_status === MERCHANT_APP_STATUS.WITHDRAWN) && selectedAppBulk.length <= 1 ? 'delete-icon' 
    : (role === ROLES.RELATIONSHIP_MANAGER && (merchant_app_status === MERCHANT_APP_STATUS.STARTED || merchant_app_status === MERCHANT_APP_STATUS.SENT_TO_MERC || merchant_app_status === MERCHANT_APP_STATUS.WITHDRAWN)) && selectedAppBulk.length <= 1 ? 'delete-icon' 
    : (user_id === created_by_user_id && merchant_app_status === MERCHANT_APP_STATUS.STARTED) && selectedAppBulk.length <= 1 ? 'delete-icon' 
    : 'not-active delete-icon'
    let withdrawToolTip = merchant_app_status === MERCHANT_APP_STATUS.WITHDRAWN ? 'Start Again' : 'Withdraw'
    let emailActivity = selectedAppBulk.length >1 ? 'not-active email-activity-icon':'email-activity-icon'

    const uiActions =
      [
        { display: role !== ROLES.VIEW_ONLY, className: editControl, label: 'Edit', action: () => handleEditButtonClick(merchant_app_id) },
        { display: role !== ROLES.VIEW_ONLY, className: withdrawControl, label: withdrawToolTip, action: () => handleWithdrawButtonClick(merchant_app_id, merchant_app_status) },
        { display: role !== ROLES.VIEW_ONLY, className: uploadControl, label: 'Upload Doc', action: (() => documentUploadClick(user)) },
        { display: role !== ROLES.VIEW_ONLY, className: duplicateControl, label: 'Duplicate', action: () => duplicateClick(merchant_app_id) },
        { display: role !== ROLES.VIEW_ONLY, className: sendMailControl, label: 'Send Email', action: () => signClick(user) },
        { display: role === ROLES.RELATIONSHIP_MANAGER, className: sendtoUWControl, label: 'Send To UW', action: () => sendToUW(merchant_app_id, user.partner_id, user.merchant_email) },
        { display: (role !== ROLES.SALES_LIMITED && role !== ROLES.VIEW_ONLY), className: deleteControl, label: 'Delete', action: () => deleteClick(user) },
        { display: role !== ROLES.VIEW_ONLY, className: emailActivity, label: 'Email Activity', action: () => emailActivityClick(user) }
      ];
    return uiActions
  }

  const AppControls = (props: any) => {
    const uiActions = getAppControls(props);
    return (
      <>{uiActions.map(action => (action.display && <span
        className={action.className}
        data-tooltip={action.label}
        onClick={action.action}
      />))}
      </>
    )
  }

  const sendMailToSecondOwner = async () => {
    setSendMailToSecondSigner(false)
    if (!selectedUser.envelope_id) {
      setSelectedUser({})
      showOverlayForThreeSeconds()
      setOverlaymsg('Unable to send mail. Envelope ID is required.')
      return
    }
    setLoading(true)
    const data = {
      merchant_app_id: selectedUser.merchant_app_id,
      dba_name: selectedUser.dba_name,
      envelope_id: selectedUser.envelope_id
    }

    const payload: any = {
      api: api.integrations.sendMailToSecondSigner,
      payLoad: data
    }
    try {
      await backend.save(payload, true)
      setSelectedUser({})
      showOverlayForThreeSeconds()
      setOverlaymsg('Mail Sent successfully')
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
      setSelectedUser({})
      showOverlayForThreeSeconds()
      setOverlaymsg('Sending Mail Failed')
    }
  }
  const updateAppArchiveStatus = async (id: string, archiveStatus: boolean) => {
    if (showNonArchivedApps === false) {
      const payload = {
        merchant_app_id: id,
        archived: archiveStatus
      }
      const data = {
        api: api.merchantAppData.updateMerchantAppArchivedStatus,
        payLoad: { ...payload }
      }
      await backend.save(data)

      setLoading(false)
      const list = [...merchantArchivedApps]
      let index = list.findIndex((merchant_app: any) => merchant_app.merchant_app_id === id)

      //Add index to merchant list and remove the index
      const merchant_apps = merchantApps
      const newMerchantApp = merchant_apps.concat(list[index]);

      list.splice(index, 1)
      setMerchantArchivedApps(list)
      setMerchantApps(newMerchantApp)
      if (list && list.length) {
        setPageUsers(list)
        setSearchedUsers(list)
      } else {
        setNoArchivedDataAlert(true)
        setPageUsers(newMerchantApp)
        setShowNonArchivedApps(true)
        setTitle('LIST OF MERCHANT APPS')
        setButtonText('Archived Apps')
      }
      if (!showNonArchivedApps) {
        listApps(false);
      }
    }
  }

  const sendMail = async () => {
    const payload = {
      to_email: selectedUser.merchant_email,
      body: userMessage,
      merchant_app_id: selectedUser.merchant_app_id,
      dba_name: selectedUser.dba_name
    }
    const data: any = {}
    data.api = api.integrations.sendMail
    data.payLoad = { ...payload }
    setLoading(true)
    try {
      const response = await backend.save(data)
      if (response) {
        let page_users = pageUsers;
        let index1 = pageUsers.findIndex((merchant_app: any) => merchant_app.merchant_app_id === selectedUser.merchant_app_id)
        if (page_users[index1].merchant_app_status !== "signed" && page_users[index1].merchant_app_status !== "docs uploaded") {
          page_users[index1].merchant_app_status = 'sent to merchant'
          setPageUsers(page_users)
        }
      }
      setLoading(false)
      showOverlayForThreeSeconds()
      setOverlaymsg('Mail Sent successfully')
      const merchant_apps = merchantApps
      setLoading(false)
      setMerchantApps(merchant_apps)
      uncheckparentcheckbox()



      updateAppArchiveStatus(selectedUser.merchant_app_id, false)

    } catch (err: any) {
      setLoading(false)
      showOverlayForThreeSeconds()
      setOverlaymsg('Sending Mail Failed')
    }
    finally {
      setUserMessage('')
      setSignModalOpen(false)
   }
  }

  const deleteApp = async () => {
    const data: any = {}
    data.api = api.merchantAppData.deleteMerchantApp;
    data.payLoad = { merchant_app_id: selectedUser.merchant_app_id }
    console.log('##del..data', data);
    setLoading(true)
    try {
      const response = await backend.save(data)
      if (response) {
        let page_users = pageUsers;
        let index1 = pageUsers.findIndex((merchant_app: any) => merchant_app.merchant_app_id === selectedUser.merchant_app_id)
        page_users[index1].merchant_app_status = 'deleted'
        setPageUsers(page_users)
      }
      setLoading(false)
      showOverlayForThreeSeconds()
      setOverlaymsg('App Deleted successfully')
      uncheckparentcheckbox()
    } catch (err: any) {
      setLoading(false)
      showOverlayForThreeSeconds()
      setOverlaymsg('Deleting App Failed')
    }
    finally {
      setDeleteModalOpen(false)
    }

  }

  const sendToUW = async (merchant_app_id: string, partner_id: string, merchant_email: string) => {
    uncheckparentcheckbox()
    setSelectedAppBulk([])
    const payload = {
      partner_id: partner_id,
      merchant_email: merchant_email,
      body: "Welcom to Trinity. Sending mail from RM",
      merchant_app_id: merchant_app_id
    }
    const data: any = {}
    data.api = api.integrations.sendToUW
    data.payLoad = { ...payload }
    setLoading(true)
    try {
      await backend.save(data)

      const update_status = 'sent to uw'
      const payload = {
        merchant_app_id: merchant_app_id,
        status: update_status
      }

      const update_data = {
        api: api.merchantAppData.updateMerchantAppStatus,
        payLoad: { ...payload }
      }

      await backend.save(update_data)
      if (showNonArchivedApps === true) {
        const merchant_apps = merchantApps
        let index = merchantApps.findIndex((merchant_app: any) => merchant_app.merchant_app_id === merchant_app_id)
        merchant_apps[index].merchant_app_status = update_status
        setMerchantApps(merchant_apps)
        let page_users = pageUsers;
        let index1 = pageUsers.findIndex((merchant_app: any) => merchant_app.merchant_app_id === merchant_app_id)
        page_users[index1].merchant_app_status = update_status
        setPageUsers(page_users)
      }

      if (showNonArchivedApps === false) {
        //Toggle Status
        const merchant_archived_apps = merchantArchivedApps
        let index2 = merchantArchivedApps.findIndex((merchant_app: any) => merchant_app.merchant_app_id === merchant_app_id)
        merchant_archived_apps[index2].merchant_app_status = update_status

        let page_users = pageUsers;
        let index1 = pageUsers.findIndex((merchant_app: any) => merchant_app.merchant_app_id === merchant_app_id)
        page_users[index1].merchant_app_status = update_status
      }

      updateAppArchiveStatus(merchant_app_id, false);

      showOverlayForThreeSeconds()
      setOverlaymsg('Status changed successfully')

    } catch (err: any) {
      showOverlayForThreeSeconds()
      setOverlaymsg('Please try again')
    }
    finally {
      setLoading(false);
    }
  }

  const getTableHeaderCell = (columnName: string, columnField: string) => (<Table.HeaderCell
    style={{ background: config?.client_config_fe?.button_color }}
    textAlign="center" className='list-app-table-header' onClick={() => { handleSort(columnField, sortDirection === "asc" ? "desc" : "asc") }}>
    {columnName}
    {sortField === columnField && <i className={`pointer long arrow alternate ${sortDirection === "asc" ? 'up' : 'down'} icon`}></i>}
  </Table.HeaderCell>)

  const renderShowUsersTable = () => {
    return (
      <Table celled structured textAlign='center' striped className={role === ROLES.VIEW_ONLY ? 'list-table-view-viewonly' : 'list-table-view'}>
        <Table.Header >
          <Table.Row>
          {(role !== ROLES.VIEW_ONLY ) && (
            <>
              <Table.HeaderCell textAlign="center" className="t-data headercheckbox" style={{ background: config?.client_config_fe?.button_color }}>
                  {/* NAME */}
                  <div className="ui fluid category">    
                    <div className= {`parentcheckbox ${
                        role === ROLES.SUPER_ADMIN  ? "sa-check": " " }`} >
                      <input
                        type="checkbox"
                        value={"parent"}
                        onChange={(e) => changeCheckboxStatus(e, "p1")}
                        checked={isParentChecked}
                      />
                    </div>
                  </div>
                </Table.HeaderCell> 
            </>
            )}
            {getTableHeaderCell('APP ID', "merchant_app_id")}
            {getTableHeaderCell('PARENTAPP ID', "parent_app_id")}
            {getTableHeaderCell('LINK NAME', "app_link_name")}
            {getTableHeaderCell('SALES USER NAME', "partner_name")}
            {getTableHeaderCell('CORP NAME', "legal_name")}
            {getTableHeaderCell('DBA NAME', "dba_name")}
            {getTableHeaderCell('BANK', "processor_name")}
            {getTableHeaderCell('APP START DATE', "merchant_app_start_ts")}
            {getTableHeaderCell('LAST MODIFIED DATE', "updated_ts")}
            {getTableHeaderCell('APP STATUS', "merchant_app_status")}
            {role !== ROLES.VIEW_ONLY && <Table.HeaderCell textAlign='center' className='list-app-table-header' style={{ background: config?.client_config_fe?.button_color }}> ACTIONS </Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>{renderRow()} </Table.Body>
      </Table>
    )
  }

  const SignModalUtil = () => {
    window.scroll({ top: 0, left: 0 });
    return (
      <>
        <div className="top-container" style={{ background: config?.client_config_fe?.button_color }}>
          <div className="top-content">
            <div className="inline fields">
              <div className="twelve wide field">
                <input
                  type="text"
                  className="form-input"
                  name="business_name"
                  value={selectedUser.merchant_name}
                  autoComplete="new-password"
                  placeholder="Merchant Name :"
                  readOnly
                />
              </div>
            </div>
            <div className="inline fields">
              <div className="twelve wide field">
                <input
                  type="text"
                  className="form-input"
                  name="business_email"
                  value={selectedUser.merchant_email}
                  autoComplete="new-password"
                  placeholder="Merchant Email :"
                  readOnly
                />
              </div>
            </div>
            <div className="inline fields">
              <div className="twelve wide field">
                <input
                  type="text"
                  className="form-input"
                  name="dbaName"
                  value={selectedUser.dba_name}
                  placeholder="DBA Name : "
                  readOnly
                />
              </div>
            </div>
            <div className="inline fields">
              <div className="twelve wide field">
                <textarea
                  name="personal_message"
                  placeholder="Personal Message :"
                  onChange={e => setUserMessage(e.target.value)}
                  rows={5}
                  autoFocus
                  defaultValue={userMessage}
                  value={userMessage}
                  onFocus={(e) => {
                    const val = e.target.value;
                    e.target.value = '';
                    e.target.value = val;
                  }}
                >
                </textarea>
              </div>
            </div>
          </div>
        </div>
        <div className='button-containers'>
          <Button
            onClick={() => {
              setSignModalOpen(false);
              setUserMessage('')
            }}
            color="blue"
            className="send-email-buttons"
          >
            CANCEL
          </Button>
          <Button onClick={sendMail} color="blue" className="send-email-buttons">
            SEND EMAIL
          </Button>
        </div>
      </>
    );
  };

  const ModalsStartApp = () => (<>
    {!isMobile ? <>{(signModalOpen === true) &&
      <Modal
        centered
        open={signModalOpen}
        onClose={() => setSignModalOpen(false)}
        onOpen={() => setSignModalOpen(true)}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <ProgressLoader loading={loading} size='small' config={config}/>
          <Modal.Description>
            <div className='body-content min-height-0'>
              <div className='ui form center'>
                <div className='inline fields'>
                  <div className='three wide field'>
                    <label>Merchant Name :</label>
                  </div>
                  <div className='eight wide field'>
                    <input
                      type='text'
                      className='form-input-grey'
                      name='business_name'
                      value={selectedUser.merchant_name}
                      readOnly
                    />
                  </div>
                </div>
                <div className='inline fields'>
                  <div className='three wide field'>
                    <label>Merchant Email :</label>
                  </div>
                  <div className='eight wide field'>
                    <input
                      type='text' className='form-input-grey'
                      name='business_email'
                      value={selectedUser.merchant_email}
                      readOnly
                    />
                  </div>
                </div>
                <div className='inline fields'>
                  <div className='three wide field'>
                    <label>DBA Name :</label>
                  </div>
                  <div className='eight wide field'>
                    <input
                      type='text' className='form-input-grey'
                      name='dbaName'
                      value={selectedUser.dba_name}
                      readOnly
                    />
                  </div>
                </div>
                <div className='inline fields'>
                  <div className='three wide field'>
                    <label>Personal Message :</label>
                  </div>
                  <div className='eight wide field'>
                    <textarea name='personal_message' onChange={e => setUserMessage(e.target.value)} rows={5} value={userMessage
                    }
                      defaultValue={userMessage} autoFocus onFocus={(e) => {
                        const val = e.target.value;
                        e.target.value = '';
                        e.target.value = val;
                      }}></textarea>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Description>

        </Modal.Content>
        <Modal.Actions>
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='CANCEL'
            className="save-button button-border"
            onClick={() => {
              setSignModalOpen(false);
              setUserMessage('');
            }}
          />
          <button
            style={{background:config?.client_config_fe?.button_color}}
            className="save-button button-border"
            onClick={sendMail}
          > SEND EMAIL</button>   
          <div className='overlay' style={overlay.style}>
            <div className='overlay-image'>
              <span className='overlay-text' style={{ color: config?.client_config_fe?.button_color }}>{overlayMsg}</span>
            </div>
          </div>
        </Modal.Actions>
      </Modal>}</> : (signModalOpen) && <><div className='ui form startapp'><SignModalUtil /></div></>}

    {deleteModalOpen &&
      <Modal
        centered
        size='mini'
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onOpen={() => setDeleteModalOpen(true)}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div className="close-button">
            <button className="email-activity-close-button" onClick={() => {
              setSelectedUser({})
              setDeleteModalOpen(false)
            }}>X</button>
          </div>
          <ProgressLoader loading={loading} size='small' config={config} />
          {`Are you sure you want to delete ${selectedUser.merchant_app_id}?`}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => {
            setSelectedUser({})
            setDeleteModalOpen(false)
          }}>CANCEL</Button>
          <Button onClick={deleteApp} color='red'>DELETE</Button>
          <div className='overlay' style={overlay.style}>
            <div className='overlay-image'>
              <span className='overlay-text' style={{ color: config?.client_config_fe?.button_color }}>{overlayMsg}</span>
            </div>
          </div>
        </Modal.Actions>
      </Modal>}

    {emailActivityModalOpen &&
      <Modal
        className="email-activity-modal"
        centered
        open={emailActivityModalOpen}
        onClose={() => setEmailActivityModalOpen(false)}
        onOpen={() => setEmailActivityModalOpen(true)}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <ProgressLoader loading={emailLogsLoading} size='small' config={config} />
          <div>
            <div className="email-activity-header">
              <h2>Email Activity for {selectedUser.merchant_app_id}</h2>
              <button className="email-activity-close-button" onClick={() => {
                setEmailActivityModalOpen(false)
                setEmailLogsData({})
              }} >X</button>
            </div>
            <br />
            <div>
              {
                emailLogsData?.email_events?.length > 0 ?
                  <Table celled structured textAlign='center' striped>
                    <Table.Header >
                      <Table.Row>
                        <Table.HeaderCell textAlign='center'> Email </Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'> Email Event </Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'> Event TS </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        emailLogsData?.email_events.map((item: any, index: number) => {
                          return (<Table.Row key={index}>
                            <Table.Cell>{emailLogsData?.email_to}</Table.Cell>
                            <Table.Cell>{item?.event_name}</Table.Cell>
                            <Table.Cell>{
                              new Date(item?.processed).toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
                            }</Table.Cell>
                          </Table.Row>)
                        })
                      }
                    </Table.Body>
                  </Table> :
                  <span>{!emailLogsLoading && `No Email Activity found for Application ${selectedUser.merchant_app_id}`}</span>
              }
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor='#FFFFFF'
            size='18px'
            buttonText='CLOSE'
            className="save-button button-border"
            onClick={() => {
              setEmailActivityModalOpen(false)
              setEmailLogsData({})
              setOverlaymsg(" ")
            }}
          />
        </Modal.Actions>
      </Modal>}
    {sendMailToSecondSigner &&
      <Modal
        centered
        open={sendMailToSecondSigner}
        onClose={() => setSendMailToSecondSigner(false)}
        onOpen={() => setSendMailToSecondSigner(true)}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <ProgressLoader loading={loading} size='small' config={config}/>
          <Modal.Description>
            <div className='body-content min-height-0'>
              <div className='ui form center'>
                <div className='inline fields'>
                  <div className='three wide field'>
                    <label>Owner Name :</label>
                  </div>
                  <div className='eight wide field'>
                    <input
                      type='text'
                      className='form-input-grey'
                      name='business_name'
                      value={selectedUser?.owner2_name}
                      readOnly
                    />
                  </div>
                </div>
                <div className='inline fields'>
                  <div className='three wide field'>
                    <label>Owner Email :</label>
                  </div>
                  <div className='eight wide field'>
                    <input
                      type='text' className='form-input-grey'
                      name='business_email'
                      value={selectedUser?.owner2_email}
                      readOnly
                    />
                  </div>
                </div>
                <div className='inline fields'>
                  <div className='three wide field'>
                    <label>DBA Name :</label>
                  </div>
                  <div className='eight wide field'>
                    <input
                      type='text' className='form-input-grey'
                      name='dbaName'
                      value={selectedUser.dba_name}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Description>

        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => {
            setSendMailToSecondSigner(false)
            setSelectedUser({})
          }} color='blue'>CANCEL</Button>
          <Button onClick={sendMailToSecondOwner} color='blue'>SEND EMAIL</Button>
          <div className='overlay' style={overlay.style}>
            <div className='overlay-image'>
              <span className='overlay-text' style={{ color: config?.client_config_fe?.button_color }}>{overlayMsg}</span>
            </div>
          </div>
        </Modal.Actions>
      </Modal>}

    <div className='overlay' style={overlay.style}>
      <div className='overlay-image'>
        <span className='overlay-text' style={{ color: config?.client_config_fe?.button_color }}>{overlayMsg}</span>
      </div>
    </div></>);


  return (    
    <Layout page="listapp" listType={showNonArchivedApps}>
      <div className="listapp-container">
        {isMobile || isTablet ? (
          <>
            <ProgressLoader loading={loading} size="large" config={config} />
            <ModalsStartApp />
            {!signModalOpen || (!!signModalOpen && !isMobile) ? (
              <>
              <div className='search-mob'>
               <Search
               placeholder="Search"
               value={algoliaSearchText}
               onChange={(e:any) => setAlgoliaSearchText(e.target.value)}
             />
             </div>
                 <Pagination
                  current={activePage}
                  hideOnSinglePage={true}
                  pageSize={pageSize}
                  total={totalRecords}
                  showTitle={false}
                  onChange={handlePaginationChange}
                  showSizeChanger={false}
                />
                 { selectedAppBulk.length >= 2 && (
                  <div className='bulkupdate-action'>
                  <Select
                        isMulti={false}
                        value={bulkaction}
                        options= {(role === ROLES.RELATIONSHIP_MANAGER) ? appBulkActionRM  : appBulkActionSA}
                        onChange={bulkUpdateAction}
                        placeholder="Bulk Action"
                        isSearchable={false}
                      />
                      </div>
                  )}
                <RenderTableforMobile />
                <Pagination
                  current={activePage}
                  hideOnSinglePage={true}
                  pageSize={pageSize}
                  total={totalRecords}
                  showTitle={false}
                  onChange={handlePaginationChange}
                  showSizeChanger={false}

                />
              </>
            ) : ("")}
          </>
        ) : (
          <>
            <div className="applist-container">
              <div className={window.innerWidth > 1640 ? "body-content" : "listapp-body-content"}>
                <div className="heading">
                  <span className="heading-text-left">{tilte}</span>
                  <div className="section-header-right">
                      <BasicButton
                        textColor={config?.client_config_fe?.button_color}
                        backgroundColor={'#FFFFFF'}
                        size='20px'
                        buttonText={buttonText}
                        className="list-app-btn"
                        onClick={() => {
                          listApps(showNonArchivedApps);
                        }}
                      />
                  <Search
                  placeholder="Search"
                  value={algoliaSearchText}
                  onChange={(e:any) => setAlgoliaSearchText(e.target.value)}
                  />
                  </div>
                </div>
                
                <ProgressLoader loading={loading} size="large" config={config}/>
                <div className='app-actions'>
                { Object.keys(selectedAppBulk).length > 1 && (
                  <div className='bulkupdate-action'>
                  <Select
                        isMulti={false}
                        value={bulkaction}
                        options= {(role === ROLES.RELATIONSHIP_MANAGER) ? appBulkActionRM  : appBulkActionSA}
                        onChange={bulkUpdateAction}
                        placeholder="Bulk Action"
                        isSearchable={false}
                      />
                      </div>
                  )}
                <Pagination
                  current={activePage}
                  hideOnSinglePage={true}
                  pageSize={pageSize}
                  total={totalRecords}
                  showTitle={false}
                  onChange={handlePaginationChange}
                  showSizeChanger={false}
                />
                </div>
                {renderShowUsersTable()}
                <Pagination
                  current={activePage}
                  hideOnSinglePage={true}
                  pageSize={pageSize}
                  total={totalRecords}
                  showTitle={false}
                  onChange={handlePaginationChange}
                  showSizeChanger={false}
                />
                {pageUsers.length === 0 && !loading && (
                  <div className="heading">
                    <span className="heading-center">
                      {algoliaSearchText !== "" ? 'No Data Found' : showNonArchivedApps ? 'No Merchant Apps as of now, please start from Create Link or Start App' : 'No data found in Archived Applications List'}
                    </span>
                  </div>
                )}
              </div>

            </div>
            <div>
              <ModalsStartApp />
            </div>
          </>
        )}
        {(pageUsers.length >= 8 || loading) && (
          <Footer loading={loading} page="listapp" />
        )}
        {pageUsers.length < 8 && !loading && (
          <div className="footerArea">
            <Footer loading={loading} page="listapp" />
          </div>
        )}
      </div>

      {actionConfirmation && (
        <ActionConfirmation
         loading = {loading}
         deactivateModalOpen = {actionConfirmation}
         modalAction = {modalAction}
         selectedApp = {selectedAppBulk}
         handleCancel = {handleCancel}
         handleConfirmAction = {handleConfirmAction}
         statusCount= {statusCount}
         additionalCheckStatus = {additionalCheckStatus}
         config={config}
        />
      )}
      {actionConfirmationforResendlink && (
        <ActionResendLinkConfirmation
         loading = {loading}
         deactivateModalOpen = {actionConfirmationforResendlink}
         modalAction = {modalAction}
         selectedApp = {selectedAppBulk}
         handleCancel = {handleCancel}
         handleConfirmActionResendlink = {handleConfirmActionResendlink}
         statusCount= {statusCount}
         additionalCheckStatus = {additionalCheckStatus}
         setUserMessageResend = {setUserMessageResend}
         userMessageResend = {userMessageResend}
         overlay = {overlay}
         overlayMsg = {overlayMsg}
         config={config}
        />
      )}

    </Layout>
  );
}

export default memo(ListApp);
