import React from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";

interface DeleteModalProps {
    loading: boolean
    selectedUser: any
    deleteModalOpen: boolean
    handleCancel: () => void
    deleteUser: () => Promise<void>
}
const { REACT_APP_BACKEND_MYSQL } = process.env;

const DeleteModal = (props: DeleteModalProps) => {

    const { handleCancel, deleteUser, deleteModalOpen, selectedUser, loading } = props

    return (
        <Modal
            centered
            size='tiny'
            open={deleteModalOpen}
            onClose={handleCancel}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <div className="close-button">
                    <button className="email-activity-close-button" onClick={handleCancel}>X</button>
                </div>
                <ProgressLoader loading={loading} size='small' />
                <span className="word-wrap">{`Are you sure you want to delete user ${REACT_APP_BACKEND_MYSQL ? selectedUser.user_name_first : selectedUser.user_name.first_name} ${REACT_APP_BACKEND_MYSQL ? selectedUser.user_name_last : selectedUser.user_name.last_name} (ID - ${selectedUser.user_id})?`}</span>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleCancel}>CANCEL</Button>
                <Button onClick={deleteUser} color='red'>DELETE</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default DeleteModal;
