import React, { useState, useEffect } from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";

interface WarningModalProps {
    loading: boolean
    warningModalOpenBulk: boolean
    handleCancel: () => void
    officeAdminBulk : any
    config:any
}
const WarningModal = (props: WarningModalProps) => {

    const { handleCancel, warningModalOpenBulk, loading ,officeAdminBulk, config} = props

    return (
        <Modal
            centered
            open={warningModalOpenBulk}
            onClose={handleCancel}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <div className="close-button">
                    <button className="email-activity-close-button" onClick={handleCancel}>X</button>
                </div>
                <ProgressLoader loading={loading} size='small' />
                <p>OfficeAdmin can't perform delete or edit or enable/disable for IRIS reporting on office admins for the following users :&nbsp;
                {officeAdminBulk.map((item: { user_name: any }) => { return item.user_name.toUpperCase() }).join(', ') }. Please recheck your selection</p>
                 
            </Modal.Content>
            <Modal.Actions>
                <button className="save-button button-border ipad-blue"
                    style={{ background: config?.client_config_fe?.button_color }}
                    onClick={handleCancel} >OK</button>
            </Modal.Actions>
        </Modal>
    )
}

export default WarningModal;
