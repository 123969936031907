import React from 'react'
import { Table } from 'semantic-ui-react'
import { ROLES, freeEquipCheckRole } from '../../../Utils/constants'

interface OfficeTableHeaderProps {
    sortFieldOrder: string
    sortField: string
    searchField: string
    searchText: string
    role: string | null
    handleSearchByFieldName: (field_name: string,sort_order: string) => void
    handleSortByFieldName: (field_name: string,sort_order: string) => void
    changeCheckboxStatus: (e: any, id: string) => Promise<void>
    isParentChecked: boolean
    config:any
}



const OfficeTableHeader = (props: OfficeTableHeaderProps) => {

    const { sortFieldOrder, sortField, searchField, searchText, role, handleSearchByFieldName, handleSortByFieldName, changeCheckboxStatus, isParentChecked ,config } = props
    // const getTableHeaderCell = (columnName: string, columnField: string) => (<Table.HeaderCell
    //     style={{ background: config?.client_config_fe?.button_color }}
    //     textAlign="center" className='list-app-table-header' onClick={() => { handleSortByFieldName(columnField, sortDirection === "asc" ? "desc" : "asc") }}>
    //     {columnName}
    //     {sortField === columnField && <i className={`pointer long arrow alternate ${sortDirection === "asc" ? 'up' : 'down'} icon`}></i>}
    //   </Table.HeaderCell>)

    return (
        <Table.Header className='t-header'>
            <Table.Row className={role === ROLES.OFFICE_ADMIN ? 't-row admin' : 't-row'}>
            {
            <>
            <Table.HeaderCell textAlign="center" className='t-data headercheckbox' style={{ background: config?.client_config_fe?.button_color }}>
            <div className="ui fluid category">    
              <div className={`parentcheckbox ${
                  role === ROLES.SUPER_ADMIN  ? "sa-check": " " }`}>
                <input
                  type="checkbox"
                  value={"parent"}
                  onChange={(e) => changeCheckboxStatus(e, "p1")}
                  checked={isParentChecked}
                  className='bulk-check'
                  style={{ background: config?.client_config_fe?.button_color }}
                />     
              </div>
            </div>
            </Table.HeaderCell> 
            </>
             }
                <Table.HeaderCell textAlign='center' className='t-data'  style={{ background: config?.client_config_fe?.button_color }}>
                    {/* OFFICE ID */}
                    <div className='ui fluid category'>
                        <i
                            className={`pointer long arrow alternate ${sortFieldOrder === "asc" && sortField === "office_id" ? "down" : "up"} icon`}
                            onClick={() => handleSortByFieldName("office_id", sortFieldOrder)}></i>
                        <div className='ui icon input search-input'>
                            <input
                                type='text'
                                placeholder='OFFICE ID'
                                value={searchField === "office_id" ? searchText : ""}
                                onChange={(e) => handleSearchByFieldName("office_id", e.target.value)}
                            />
                        </div>
                    </div>
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center' className='t-data'  style={{ background: config?.client_config_fe?.button_color }}>
                    {/* OFFICE NAME */}
                    <div className='ui fluid category'>
                        <i
                            className={`pointer long arrow alternate ${sortFieldOrder === "asc" && sortField === "office_name" ? "down" : "up"} icon`}
                            onClick={() => handleSortByFieldName("office_name", sortFieldOrder)}></i>
                        <div className='ui icon input search-input'>
                            <input
                                type='text'
                                placeholder='OFFICE NAME'
                                value={searchField === "office_name" ? searchText : ""}
                                onChange={(e) => handleSearchByFieldName("office_name", e.target.value)}
                            />
                        </div>
                    </div>
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center' className='t-data'  style={{ background: config?.client_config_fe?.button_color }}>
                    {/* PARENT OFFICE */}
                    <div className='ui fluid category'>
                        <i
                            className={`pointer long arrow alternate ${sortFieldOrder === "asc" && sortField === "parent_office_name" ? "down" : "up"} icon`}
                            onClick={() => handleSortByFieldName("parent_office_name", sortFieldOrder)}></i>
                        <div className='ui icon input search-input'>
                            <input
                                type='text'
                                placeholder='PARENT OFFICE'
                                value={searchField === "parent_office_name" ? searchText : ""}
                                onChange={(e) => handleSearchByFieldName("parent_office_name", e.target.value)}
                            />
                        </div>
                    </div>
                </Table.HeaderCell>
                {/* {role !== ROLES.SUPER_ADMIN &&
                    <Table.HeaderCell textAlign='center' className='t-data'>
                        <div className='ui fluid category'>
                            <i
                                className={`pointer long arrow alternate ${sortFieldOrder === "asc" && sortField === "office_manager_names" ? "down" : "up"} icon`}
                                onClick={() => handleSortByFieldName("office_manager_names", sortFieldOrder)}></i>
                            <div className='ui icon input search-input'>
                                <input
                                    type='text'
                                    placeholder='OFFICE MANAGERS'
                                    value={searchField === "office_manager_names" ? searchText : ""}
                                    onChange={(e) => handleSearchByFieldName("office_manager_names", e.target.value)}
                                />
                            </div>
                        </div>
                    </Table.HeaderCell>
                } */}
                {role !== ROLES.OFFICE_ADMIN && <Table.HeaderCell textAlign='center' className='t-data'  style={{ background: config?.client_config_fe?.button_color }}>
                    {/* OFFICE ADMINS */}
                    <div className='ui fluid category'>
                        <i
                            className={`pointer long arrow alternate ${sortFieldOrder === "asc" && sortField === "office_admin_names" ? "down" : "up"} icon`}
                            onClick={() => handleSortByFieldName("office_admin_names", sortFieldOrder)}></i>
                        <div className='ui icon input search-input'>
                            <input
                                type='text'
                                placeholder='OFFICE ADMINS'
                                value={searchField === "office_admin_names" ? searchText : ""}
                                onChange={(e) => handleSearchByFieldName("office_admin_names", e.target.value)}
                            />
                        </div>
                    </div>
                </Table.HeaderCell>}
                <Table.HeaderCell textAlign='center' className='t-data'  style={{ background: config?.client_config_fe?.button_color }}>
                    {/* RM's */}
                    <div className='ui fluid category'>
                        <i
                            className={`pointer long arrow alternate ${sortFieldOrder === "asc" && sortField === "allowed_RMs" ? "down" : "up"} icon`}
                            onClick={() => handleSortByFieldName("allowed_RMs", sortFieldOrder)}></i>
                        <div className='ui icon input search-input'>
                            <input
                                type='text'
                                placeholder="RM's"
                                value={searchField === "allowed_RMs" ? searchText : ""}
                                onChange={(e) => handleSearchByFieldName("allowed_RMs", e.target.value)}
                            />
                        </div>
                    </div>
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center' className='t-data'  style={{ background: config?.client_config_fe?.button_color }}>
                    {/* LOW RISK BANKS */}
                    <div className='ui fluid category'>
                        <i
                            className={`pointer long arrow alternate ${sortFieldOrder === "asc" && sortField === "allowed_low_risk_processors" ? "down" : "up"} icon`}
                            onClick={() => handleSortByFieldName("allowed_low_risk_processors", sortFieldOrder)}></i>
                        <div className='ui icon input search-input'>
                            <input
                                type='text'
                                placeholder='LOW RISK BANKS'
                                value={searchField === "allowed_low_risk_processors" ? searchText : ""}
                                onChange={(e) => handleSearchByFieldName("allowed_low_risk_processors", e.target.value)}
                            />
                        </div>
                    </div>
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center' className='t-data'  style={{ background: config?.client_config_fe?.button_color }}>
                    {/* HIGH RISK BANKS */}
                    <div className='ui fluid category'>
                        <i
                            className={`pointer long arrow alternate ${sortFieldOrder === "asc" && sortField === "allowed_high_risk_processors" ? "down" : "up"} icon`}
                            onClick={() => handleSortByFieldName("allowed_high_risk_processors", sortFieldOrder)}></i>
                        <div className='ui icon input search-input'>
                            <input
                                type='text'
                                placeholder='HIGH RISK BANKS'
                                value={searchField === "allowed_high_risk_processors" ? searchText : ""}
                                onChange={(e) => handleSearchByFieldName("allowed_high_risk_processors", e.target.value)}
                            />
                        </div>
                    </div>
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center' className='t-data'  style={{ background: config?.client_config_fe?.button_color }}>
                    {/* RISK LEVELS */}
                    <div className='ui fluid category'>
                        <i
                            className={`pointer long arrow alternate ${sortFieldOrder === "asc" && sortField === "allowed_risk_levels" ? "down" : "up"} icon`}
                            onClick={() => handleSortByFieldName("allowed_risk_levels", sortFieldOrder)}></i>
                        <div className='ui icon input search-input'>
                            <input
                                type='text'
                                placeholder='RISK LEVELS'
                                value={searchField === "allowed_risk_levels" ? searchText : ""}
                                onChange={(e) => handleSearchByFieldName("allowed_risk_levels", e.target.value)}
                            />
                        </div>
                    </div>
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center' className='t-data' style={{ background: config?.client_config_fe?.button_color }}>
                    {/* PAPER APPS */}
                    <div className='ui fluid category'>
                        <i
                            className={`pointer long arrow alternate ${sortFieldOrder === "asc" && sortField === "paper_apps" ? "down" : "up"} icon`}
                            onClick={() => handleSortByFieldName("paper_apps", sortFieldOrder)}></i>
                        <div className='ui icon input search-input'>
                            <input
                                type='text'
                                placeholder='PAPER APPS'
                                value={searchField === "paper_apps" ? searchText : ""}
                                readOnly
                            />
                        </div>
                    </div>
                </Table.HeaderCell>
                
                {role !== null && freeEquipCheckRole.includes(role as ROLES) &&
                    <Table.HeaderCell textAlign='center' className='t-data' style={{ background: config?.client_config_fe?.button_color }}>
                        {/* Free Equipment Placement */}
                        <div className='ui fluid category'>
                            <i
                                className={`pointer long arrow alternate ${sortFieldOrder === "asc" && sortField === "free_equipment_placement" ? "down" : "up"} icon`}
                                onClick={() => handleSortByFieldName("free_equipment_placement", sortFieldOrder)}></i>
                            <div className='ui icon input search-input'>
                                <input
                                    type='text'
                                    placeholder='FREE EQUIPMENT'
                                    value={searchField === "free_equipment_placement" ? searchText : ""}
                                    readOnly
                                />
                            </div>
                        </div>
                    </Table.HeaderCell>
                }
            
                <Table.HeaderCell textAlign='center' className='t-data' style={{ background: config?.client_config_fe?.button_color }}>
                    {/* OFFICE STATUS */}
                    <div className='ui fluid category'>
                        <i
                            className={`pointer long arrow alternate ${sortFieldOrder === "asc" && sortField === "deactivate_status" ? "down" : "up"} icon`}
                            onClick={() => handleSortByFieldName("deactivate_status", sortFieldOrder)}></i>
                        <div className='ui icon input search-input'>
                            <input
                                type='text'
                                placeholder='OFFICE STATUS'
                                value={searchField === "deactivate_status" ? searchText : ""}
                                readOnly
                            />
                        </div>
                    </div>
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center' className='t-data' style={{ background: config?.client_config_fe?.button_color }}>ACTIONS</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    )
}

export default OfficeTableHeader