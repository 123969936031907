import React, { useEffect, useState } from 'react'
import { Layout, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import { onboardingContentStyle } from '../StyleConstants'
import ClientCard from './ClientCard';
import OnboardingForm from './Form';
import api from '../../../../Service/Api';
import backend from '../../../../Service/Backend';


const PlatformAdminOnboarding :React.FC = () => {
  const { Content } = Layout;
  const [clientsList, setClientsList] = useState([]);
  const [currentClient, setCurrentClient] = useState({});
  const [loading, setLoading] = useState<boolean>(true);

  const getClientsList = async() => {
    setLoading(true)
    const data: any = {
      api: api.platformAdmin.getClients
    }

    const clientsListData = await backend.fetch(data);

    setClientsList(clientsListData);
    if (clientsListData) {
      setLoading(false);
    }
  }

  const handleClientEdit = (id: string) => {
    const currentSelectedClient: any = clientsList.find((client: any) => client.id === id);

    setCurrentClient(currentSelectedClient);
  }

  useEffect(() => {
    getClientsList();
  }, []);

  const renderClientsList = () => {
    return clientsList && clientsList.length && clientsList.map((client : any) => !client.deleted && (
      <ClientCard
        data={client}
        handleClientEdit={handleClientEdit}
        successCallback={getClientsList}
      />
    ))
  }

  return (
    <Content style={onboardingContentStyle}>
      <OnboardingForm
        successCallback={getClientsList}
        currentClient={currentClient}
      />
      <p className='section-title'>List of Clients</p>
      <div className='clients-list'> 
        {/* <Search placeholder="Search your onboarded company" className='search-box' /> */}
        {loading ? <div className="clients-list-loading-overlay"><Spin indicator={<LoadingOutlined rev={undefined} className="clients-list-loader" />} /></div> : renderClientsList()}
      </div>
    </Content>
  )
}
export default PlatformAdminOnboarding
