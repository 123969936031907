import React from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";
import BasicButton from '../../Common/Button';

interface ActionConfirmationProps {
    loading: boolean
    selectedUser: any
    deactivateModalOpen: boolean
    handleCloseModal: () => void
    modalAction: any
    countforAction : any
    handleActivateBulk : any
    config?:any
    //handleActivateDeActivate: (user_guid: any, user_id: any, user_email: any, deactivate: boolean, role_code: string) => Promise<void>
  
}
const ActionConfirmation = (props: ActionConfirmationProps) => {
    const { loading, deactivateModalOpen, handleCloseModal, selectedUser, modalAction, countforAction,handleActivateBulk,config} = props
    return (
        <Modal
            centered
            size='tiny'
            open={deactivateModalOpen}
            onClose={handleCloseModal}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <div className="close-button">
                    <button className="email-activity-close-button" onClick={handleCloseModal}>X</button>
                </div>
                <ProgressLoader loading={loading} size='small' />
                <div className="word-wrap">
                {countforAction > 0 ?
                <span className="word-wrap">{`${countforAction } ${countforAction > 1 ? 'app links' : 'app link'} already in ${modalAction} state . Please recheck your selection`}</span> 
                : 
                <span className="word-wrap">{`Are you sure you want to ${modalAction} ${selectedUser.length } ${selectedUser.length > 1 ? 'app links' : 'app link'} ?`}</span> 
                }
                </div>
            </Modal.Content>
            <Modal.Actions>
                <BasicButton
                    textColor={config?.client_config_fe?.button_color}
                    backgroundColor={'#FFFFFF'}
                    size='18px'
                    buttonText={countforAction === 0 ? 'CANCEL' : "OK"}
                    className="save-button button-border"
                    onClick={handleCloseModal}
                />
                {countforAction === 0 && (
                    <button
                        className="save-button button-border ipad-blue"
                        style={{ background: config?.client_config_fe?.button_color }}
                        onClick={() => { handleActivateBulk(modalAction) }}>YES</button>

                )}
                
            </Modal.Actions>
        </Modal>
    )
}

export default ActionConfirmation;
