// User Roles
export enum ROLES {
  PARTNER = 'PARTNER',
  RELATIONSHIP_MANAGER = 'RM',
  SUPER_ADMIN = 'SUPERADMIN',
  OFFICE_ADMIN = 'OFFICEADMIN',
  OFFICE_MANAGER = 'OFFICEMANAGER',
  VIEW_ONLY = 'VIEWONLY',
  UNDER_WRITER = 'UNDERWRITER',
  SALES_LIMITED = 'REFERRALPARTNER'
}

// Status
export enum STATUS {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  DELETED = 'Deleted'
}

// Page Name
export enum PAGES {
  LOGIN_PAGE = 'LOGIN_PAGE',
  FORGOT_PASSWORD_PAGE = 'FORGOT_PASSWORD_PAGE',
  NOT_FOUND_PAGE = 'NOT_FOUND_PAGE',
  UNAUTHORISED_PAGE = 'UNAUTHORISED_PAGE',
  THANKYOU_PAGE = 'THANKYOU_PAGE'
}

export enum MERCHANT_APP_STATUS {
  STARTED = 'started',
  SIGNED = 'signed',
  DOC_UPLOAD = 'docs uploaded',
  WITHDRAWN = 'withdrawn',
  SENT_TO_UW = 'sent to uw',
  SENT_TO_MERC = 'sent to merchant',
  PARTIALLY_SIGNED = 'partially signed',
  DELETED = 'deleted'
}

export enum RISK_LEVELS {
  LOW_RISK = 'Low Risk' ,
  HIGH_RISK = 'High Risk'
}

// Field Info
export const FIELDS_INFO = {
  "Pre-Screening Information":
  {
    'businessCategory':
      { 'helperText': 'select business category', 'label': "Business Category", filled: false },
    'businessType':
      { 'helperText': 'select business type', 'label': "Business Type", filled: false },
    'riskLevel':
      { 'helperText': 'select risk level', 'label': "Risk Level", filled: false },
    'selectedSellTime':
      { 'helperText': 'select method of sales', 'label': "Method of Sales", filled: false },
    'selectedBankOption':
      { 'helperText': 'select acquiring bank', 'label': "Acquiring Bank", filled: false }
  },
  "Pricing":
  {
    'pricingType':
      { 'helperText': '', 'label': "Pricing Type", filled: false },
    'pricingCardType':
      { 'helperText': '', 'label': "Card Type", filled: false },
    'tieredQualDiscount':
      { 'helperText': '', 'label': "Qual Discount", filled: false },
    'tieredMidDiscount':
      { 'helperText': '', 'label': "Mid Discount", filled: false },
    'tieredNonDiscount':
      { 'helperText': '', 'label': "Non Discount", filled: false },
    'tieredDebitDiscount':
      { 'helperText': '', 'label': "Debit", filled: false },
    'amexQual':
      { 'helperText': '', 'label': "Amex Qual", filled: false },
    'amexMidQual':
      { 'helperText': '', 'label': "Amex Mid Qual", filled: false },
    'amexNonQual':
      { 'helperText': '', 'label': "Amex Non Qual", filled: false },
    'avs':
      { 'helperText': '', 'label': "AVS", filled: false },
    'batchFee':
      { 'helperText': '', 'label': "Batch Fee", filled: false },
    'Visa/Disc Auth':
      { 'helperText': '', 'label': "Visa/Disc Auth", filled: false },
    'annualFee':
      { 'helperText': '', 'label': "Annual Fee", filled: false },
    'chargeBackFee':
      { 'helperText': '', 'label': "Chargeback Fee", filled: false },
    'earlyTerminationFee':
      { 'helperText': '', 'label': "Early Termination Fee", filled: false },
    'other':
      { 'helperText': '', 'label': "Other", filled: false },
    'monthlyFee':
      { 'helperText': '', 'label': "Monthly Fee", filled: false },
    'monthlyMinimum':
      { 'helperText': '', 'label': "Monthly Minimum", filled: false },
    'billed':
      { 'helperText': '', 'label': "Billed", filled: false }
  },
  "Corp Information":
  {
    'legalName':
      { 'helperText': 'Business name as listed on EIN/SS4/TIN document filed with the IRS.', 'label': "Corp Name", filled: false },
    'legalAddress':
      { 'helperText': '', 'label': "Corp Address", filled: false },
    'legalAddressCity':
      { 'helperText': '', 'label': "City", filled: false },
    'selectedLegalAddressState':
      { 'helperText': '', 'label': "State", filled: false },
    'legalAddressPostalCode':
      { 'helperText': '', 'label': "Zip Code", filled: false },
    'legalBusinessContact':
      { 'helperText': '', 'label': "Business Contact", filled: false },
    'legalBusinessContactTitle':
      { 'helperText': '', 'label': "Business Contact Title", filled: false },
    'legalBusinessPhone':
      { 'helperText': '', 'label': "Business Phone", filled: false },
    'legalBusinessFax':
      { 'helperText': '', 'label': "Business Fax", filled: false },
    'fedTaxID':
      { 'helperText': '', 'label': "Fed Tax Id ( xx-xxxxxxx  /  xxxxxxxxx )", filled: false },
    'selectedTaxType':
      { 'helperText': '', 'label': "Tax Type", filled: false },
    'businessstartdate':
      { 'helperText': '', 'label': "Business Start Date", filled: false },
    'lengthOfOwnership':
      { 'helperText': '', 'label': "Length of current Ownership", filled: false },
    'selectedLegalEntityType':
      { 'helperText': '', 'label': "Legal Entity Type", filled: false },
    'selectedStateIncorporated':
      { 'helperText': '', 'label': "State Incorporated", filled: false },
    'isPriorSecurityBreach':
      { 'helperText': '', 'label': "Prior Security Breach ?", filled: false },
  },
  "DBA Information":
  {
    'dbaName':
      { 'helperText': 'A DBA (doing business as) name allows a business to operate under a name different from its Legal Name.', 'label': "DBA Name", filled: false },
    'dbaAddress':
      { 'helperText': 'Where the business operates/physical location of the business (not allowed to be a virtual office or P.O. Box).', 'label': "DBA Address", filled: false },
    'dbaCity':
      { 'helperText': '', 'label': "City", filled: false },
    'selectedDBAState':
      { 'helperText': '', 'label': "State", filled: false },
    'dbaZipCode':
      { 'helperText': '', 'label': "Zip Code", filled: false },
    'dbaBusinessEmail':
      { 'helperText': 'Will be used for all correspondence on your account.', 'label': "Primary Contact Email", filled: false },
    'dbaCustomerServicePhone':
      { 'helperText': '', 'label': "Customer Service Phone", filled: false },
    'dbaWebsiteURL':
      { 'helperText': '', 'label': "Website Url", filled: false },
    'selectedDBAMultipleLocationOption':
      { 'helperText': '', 'label': "Multiple Location?", filled: false },
    'bankRoutingNo':
      { 'helperText': '', 'label': "Routing #", filled: false },
    'bankName':
      { 'helperText': '', 'label': "Bank Name", filled: false },
    'bankAccountNo':
      { 'helperText': '', 'label': "Account #", filled: false },
    'confirmBankAccountNo':
      { 'helperText': '', 'label': "Confirm Account #", filled: false },
    'bankPhone':
      { 'helperText': '', 'label': "Bank Phone", filled: false },
    'ACHmethod':
      { 'helperText': '', 'label': "ACH Method", filled: false },
  },
  "Owner / Officer Information":
  {
    'firstName':
      { 'helperText': '', 'label': "First Name", filled: false },
    'lastName':
      { 'helperText': '', 'label': "Last Name", filled: false },
    'control_prong':
      { 'helperText': 'Owner, Officer, or Manager resposible for controlling or managing the business.', 'label': "Control Owner ?", filled: false },
    'ownerTitle':
      { 'helperText': '', 'label': "Owner1 Title", filled: false },
    'ownerAddress':
      { 'helperText': '', 'label': "Home Address", filled: false },
    'city':
      { 'helperText': '', 'label': "City", filled: false },
    'state':
      { 'helperText': '', 'label': "State", filled: false },
    'zip':
      { 'helperText': '', 'label': "Zip Code", filled: false },
    'yearsThisAddress':
      { 'helperText': '', 'label': "Years At This Address", filled: false },
    'mobileNo':
      { 'helperText': '', 'label': "Mobile Number", filled: false },
    'dl_Id':
      { 'helperText': '', 'label': "DL/ID #", filled: false },
    'documentType':
      { 'helperText': '', 'label': "Document Type", filled: false },
    'issueState':
      { 'helperText': '', 'label': "DL/ID Issue State", filled: false },
    'issueDate':
      { 'helperText': '', 'label': "DL/ID Issue Date", filled: false },
    'expireDate':
      { 'helperText': '', 'label': "DL/ID Expire Date", filled: false },
    'ssn':
      { 'helperText': '', 'label': "SSN", filled: false },
    'dob':
      { 'helperText': '', 'label': "Date Of Birth", filled: false },
    'ownerEmail':
      { 'helperText': '', 'label': "Owner Email", filled: false },
    'ownership':
      { 'helperText': '', 'label': "% Of Ownership", filled: false },
    'priorBankrupties':
      { 'helperText': '', 'label': "Prior Bankrupties", filled: false },
  },
  "Business Details":
  {
    'isRetailLocation':
      { 'helperText': '', 'label': "Do you have a retail location?l", filled: false },
    'businessLocation':
      { 'helperText': '', 'label': "Business Location", filled: false },
    'numberOfLocations':
      { 'helperText': '', 'label': "Number of locations", filled: false },
    'isSeasonalMerchant':
      { 'helperText': '', 'label': "Are you a seasonal Merchant?", filled: false },
    'isExported':
      { 'helperText': '', 'label': "Will Product(s) or Service(s)Be Sold Outside of the U.S.?", filled: false },
    'isOwnInventory':
      { 'helperText': '', 'label': "Do you own product/inventory?", filled: false },
    'vendorNames':
      { 'helperText': '', 'label': "Name and address of Manufacturer/Vendor from which supplies are purchased", filled: false },
    'isInventoryStoredatRetailLocation':
      { 'helperText': '', 'label': "Is the product stored at the physical address above?", filled: false },
    'selectedSellNationalLocal':
      { 'helperText': '', 'label': "Do you sell nationally or locally?", filled: false },
    'clymDataPrivacyCompliance':
      { 'helperText': "Website data privacy compliance service.",'label': "Clym Data Privacy Compliance", filled: false },
    'internetSalesPct':
      { 'helperText': 'Internet Sales: Sales transaction is keyed by the customer on the business website checkout page.', 'label': "Percentage of internet sales", filled: false },
    'telephoneSalesPct':
      { 'helperText': 'Telephone Sales: Sales transaction is keyed by the merchant and provided by the customer over the phone, by mail, fax, etc.', 'label': "Percentage of telephone sales", filled: false },
    'retailSalesPct':
      { 'helperText': 'Retail Sales: Purchase is made with the card present and inserted, tapped, or swiped into the payment terminal or POS.', 'label': "Percentage of retail sales", filled: false },
    'mailOrderSalesPct':
      { 'helperText': '', 'label': "Percentage of mail order sales", filled: false },
    'billingMethod':
      { 'helperText': '', 'label': "Billing Method", filled: false },
    'advertisingMethods':
      { 'helperText': '', 'label': "How do you advertise?", filled: false },
    'salesProcessDesc':
      { 'helperText': '', 'label': "Describe the sales process from start to finish", filled: false },
    'selectedChargeCustomerMethod':
      { 'helperText': '', 'label': "When do you charge the customer", filled: false },
    'daysToDelivery':
      { 'helperText': '', 'label': "How many days to delivery?", filled: false },
    'returnPolicyDesc':
      { 'helperText': '', 'label': "Description of Return/Refund Policy", filled: false },
    'isPrevProcessorTerminated':
      { 'helperText': '', 'label': "Has the merchant/owner been terminated from a previous processor?", filled: false },
    'selectedSendChargeback_AddressType':
      { 'helperText': '', 'label': "Where should we send your Chargeback & Retrieval information?", filled: false },
    'fulfillmentCompanyName ':
      { 'helperText': '', 'label': "Fulfillment Company Name", filled: false },
    'crmCompanyName':
      { 'helperText': '', 'label': "CRM Company Name", filled: false },
    'chargeBackCompanyName':
      { 'helperText': '', 'label': "Chargeback Company Name", filled: false },
    'affliateMarketingPartnerName':
      { 'helperText': '', 'label': "Affiliate Marketing Partner Name", filled: false },
    'callCenterName':
      { 'helperText': '', 'label': "Call Center Name", filled: false },
    'RDRPreferredVendor':
      { 'helperText': '', 'label': "RDR Preferred Vendor", filled: false },
    'total':
      { 'helperText': 'How often you charge the customer’s card.', 'label': "", filled: false },
  },
  "Transaction Information":
  {
    'monthAvgSales':
      { 'helperText': '', 'label': "Avg Monthly Volume (VS/MC/Disc)", filled: false },
    'avgTicketAmount':
      { 'helperText': '', 'label': "Avg Ticket Amount", filled: false },
    'highestTicketAmount':
      { 'helperText': '', 'label': "Highest Ticket Amount", filled: false },
    'publicSalesPCT':
      { 'helperText': '', 'label': "Percentage of sales Business to Consumer", filled: false },
    'businessTransPCT':
      { 'helperText': '', 'label': "Percentage of sales Business to Business", filled: false },
  },
  "Equipment Information":
  {
    'equipment_type':
      { 'helperText': '', 'label': "Type", filled: false },
    'deployment':
      { 'helperText': '', 'label': "Deployment", filled: false },
    'product':
      { 'helperText': '', 'label': "Product", filled: false },
    'billTo':
      { 'helperText': '', 'label': "Bill To", filled: false },
    'platform':
      { 'helperText': '', 'label': "Platform", filled: false },
    'quantity':
      { 'helperText': '', 'label': "Equipment Quantity", filled: false },
    'application':
      { 'helperText': '', 'label': "Application", filled: false },
    'connection_type':
      { 'helperText': '', 'label': "Connection Type", filled: false },
    'auto_close':
      { 'helperText': '', 'label': "Auto Close (CST)", filled: false },
    'sn_man':
      { 'helperText': '', 'label': "SN/MAN", filled: false },
    'sim_esn':
      { 'helperText': '', 'label': "SIM/ESN", filled: false },
    'special_instructions':
      { 'helperText': '', 'label': "Special Instructions", filled: false },
  }
}

export const AppListWithdrawInactiveStatus = [
  "signed",
  "sent to uw",
  "docs uploaded",
];

export enum BANK_CODES {
  AXIOM = 'AXIOM',
  BBVA = 'BBVA',
  CFSB = 'CFSB',
  CFSB_CD = 'CFSB CD',
  DART = 'DART',
  ESQUIRE = 'ESQUIRE',
  ESQUIRE_A = 'ESQUIRE_A',
  EVOLVE = 'EVOLVE',
  MERRICK = 'MERRICK',
  SYNOVUS = 'SYNOVUS',
  ESQUIRE_CD = 'ESQUIRE CD',
  HARRIS = 'HARRIS',
  CENTRAL = 'CENTRAL',
}
export enum EQUIPMENT_TYPE {
  PHYSICAL_TERMINAL = 'Physical Terminal' ,
  VIRTUAL_TERMINAL = 'Virtual Terminal/Gateway',
  PIN_PAD = 'Pin Pad',
  POS='POS',
  CHECK_READER='Check Reader'
}
export enum APPLICATIN_TYPE{
  FULL_GATEAY= 'Full Gateway',
  MOBILE_ONLY='Mobile Only'
}
export enum CLYM_FEE_ACCEPT_BANKS {
  BBVA = 'BBVA',
  ESQUIRE = 'ESQUIRE',
  ESQUIRE_A = 'ESQUIRE_A',
  EVOLVE = 'EVOLVE',
  MERRICK = 'MERRICK',
  SYNOVUS = 'SYNOVUS',
  HARRIS = 'HARRIS',
  CENTRAL = 'CENTRAL'
}

export const auto_close_default: any = {
  [BANK_CODES.AXIOM]:'20:50',
  [BANK_CODES.SYNOVUS]:'20:50',
  [BANK_CODES.EVOLVE]:'20:50',
  [BANK_CODES.ESQUIRE]:'22:50',
  [BANK_CODES.ESQUIRE_A]:'22:50',
  [BANK_CODES.CFSB]:'19:50',
  [BANK_CODES.DART]:'23:50',
  [BANK_CODES.MERRICK]:'21:50',
  [BANK_CODES.CFSB_CD]:'19:50',
  [BANK_CODES.ESQUIRE_CD]:'22:50',
  [BANK_CODES.HARRIS]:'19:50',
  [BANK_CODES.CENTRAL]:'22:50',
}

export const equipment_deployment = {
  [EQUIPMENT_TYPE.PHYSICAL_TERMINAL]:[{ text: 'New', value: 'New' }],
  [EQUIPMENT_TYPE.POS]:[{ text: 'VAR Only', value: 'VAR Only' }],
  [EQUIPMENT_TYPE.VIRTUAL_TERMINAL]:[{ text: 'New', value: 'New' },{ text: 'VAR Only', value: 'VAR Only' }],
  [EQUIPMENT_TYPE.PIN_PAD]:[{ text: 'New', value: 'New' }],
}

export const equipment_deployment_default = {
  [EQUIPMENT_TYPE.PHYSICAL_TERMINAL]:'New',
  [EQUIPMENT_TYPE.POS]:'VAR Only',
  [EQUIPMENT_TYPE.PIN_PAD]:'New',
  [EQUIPMENT_TYPE.VIRTUAL_TERMINAL]:'New',
}

export const equipment_application = {
  [EQUIPMENT_TYPE.PHYSICAL_TERMINAL]: [
    { text: 'Retail', value: 'Retail' },
    { text: 'Restaurant', value: 'Restaurant' },
    { text: 'Lodging', value: 'Lodging' },
    { text: 'Petroleum', value: 'Petroleum' },
    { text: 'Quick Service (QSR)', value: 'Quick Service (QSR)' }
  ],
  [EQUIPMENT_TYPE.PIN_PAD]: [
    { text: 'Restaurant', value: 'Restaurant' },
    { text: 'Lodging', value: 'Lodging' },
    { text: 'Petroleum', value: 'Petroleum' },
    { text: 'Quick Service (QSR)', value: 'Quick Service (QSR)' }
  ],
  [EQUIPMENT_TYPE.POS]: [
    { text: 'Restaurant', value: 'Restaurant' },
    { text: 'Lodging', value: 'Lodging' },
    { text: 'Petroleum', value: 'Petroleum' },
    { text: 'Quick Service (QSR)', value: 'Quick Service (QSR)' }
  ],
  [EQUIPMENT_TYPE.VIRTUAL_TERMINAL]: [
    { text: 'Full Gateway', value: 'Full Gateway' },
    // { text: 'Mobile Only', value: 'Mobile Only' }
  ]
}
export const equipment_application_CFSB_High_Risk = {
  [EQUIPMENT_TYPE.PHYSICAL_TERMINAL]: [
    { text: 'Retail', value: 'Retail' },
  ],
  [EQUIPMENT_TYPE.VIRTUAL_TERMINAL]: [
    { text: 'Full Gateway', value: 'Full Gateway' },
    // { text: 'Mobile Only', value: 'Mobile Only' }
  ]
}

export const connection_type_physical = [
  { text: 'Ethernet(Internet)', value: 'Ethernet(Internet)' },
  { text: 'WiFi', value: 'WiFi' },
  { text: 'Wireless (4G)', value: 'Wireless (4G)' }
]

export const option_yesno = [
  { text: "No", value: "No" },
  { text: "Yes", value: "Yes" },
];

export const Pricing_Type = [
  { id: "1", text: "Tiered", value: "Tiered" },
  { id: "2", text: "Interchange", value: "Interchange" },
  { id: "3", text: "Err", value: "Err" },
  { id: "4", text: "Flat Rate", value: "Flat Rate" },
];

export const Card_Type = [
  { id: '1', text: 'Visa', value: 'Visa', ischecked: false },
  { id: '2', text: 'MC', value: 'MC', ischecked: false },
  { id: '3', text: 'Discover', value: 'Discover', ischecked: false },
  { id: '4', text: 'AMEX', value: 'AMEX', ischecked: false }
];

export const billedOptions = [
  {
    text: "One-Time",
    value: "One-Time",
  },
  {
    text: "Monthly",
    value: "Monthly",
  },
  {
    text: "Annually",
    value: "Annually",
  },
];

export const equipment_terminal = [
  { text: 'Virtual Terminal', value: 'Virtual Terminal' },
  { text: 'Physical Terminal', value: 'Physical Terminal' },
  { text: 'Pin Pad', value: 'Pin Pad' },
  { text: 'POS', value: 'POS' },
  { text: 'Check Reader', value: 'Check Reader' }
];

export const equipment_billTo = [
  { text: 'Merchant', value: 'Merchant' },
  { text: 'Sales Rep/Agent Office', value: 'Sales Rep/Agent Office' },
  { text: 'Trinity', value: 'Trinity' },
];

export const mailing_address = [
  { text: 'Corp Address', value: 'Legal Address' },
  { text: 'DBA Address', value: 'DBA Address' },
  { text: 'Owner 1 Home Address', value: 'Owner 1 Home Address' }
]

export const connection_type = [
  { text: "Ethernet(Internet)", value: "Ethernet(Internet)" },
  { text: "Dial(Phone Cable)", value: "Dial(Phone Cable)" },
];

export const equipment_quantity = [
  { text: "1", value: "1" },
  { text: "2", value: "2" },
  { text: "3", value: "3" },
  { text: "4", value: "4" },
  { text: "5", value: "5" },
  { text: "6", value: "6" },
  { text: "7", value: "7" },
  { text: "8", value: "8" },
  { text: "9", value: "9" },
  { text: "10", value: "10" },
];

export const ticketSizeOptions = [
  { text: 'Above $20', value: 'Above $20' },
  { text: 'Below $20', value: 'Below $20' }
];

export const discountPercentageOptions = [
  { text: "3.00%", value: "3.00%" },
  { text: "3.50%", value: "3.50%" },
  { text: "3.95%", value: "3.95%" },
  { text: "4.00%", value: "4.00%" },
];

export const serviceFeeOptions = [
  { text: "$0.59", value: "$0.59" },
  { text: "$0.69", value: "$0.69" },
  { text: "$0.79", value: "$0.79" }
];

export const cdBanks = [
  BANK_CODES.CFSB_CD,
  BANK_CODES.ESQUIRE_CD
];

export const cdBankOptions = [
  { text: "CFSB CD", value: BANK_CODES.CFSB_CD },
  { text: "ESQUIRE CD", value: BANK_CODES.ESQUIRE_CD }
];

export const riskLevelValue: any = [
  RISK_LEVELS.HIGH_RISK
];

export const monthAvgSalesHighRisk = [
  { key: BANK_CODES.DART, values: "60000" },
  { key: BANK_CODES.ESQUIRE, values: "50000" },
  { key: BANK_CODES.SYNOVUS, values: "50000" },
  { key: BANK_CODES.ESQUIRE_A, values: "50000" },
  { key: BANK_CODES.EVOLVE, values: "50000" },
  { key: BANK_CODES.CFSB, values: "50000" },
  { key: BANK_CODES.BBVA, values: "50000" },
  { key: BANK_CODES.AXIOM, values: "50000" },
  { key: BANK_CODES.MERRICK, values: "50000" },
  { key: BANK_CODES.HARRIS, values: "50000" },
  { key: BANK_CODES.CENTRAL, values: "50000" },
];

export const AllowedRolesListByRole:any = {
  [ROLES.SUPER_ADMIN]:[
    { value: ROLES.RELATIONSHIP_MANAGER, label: ROLES.RELATIONSHIP_MANAGER },
    { value: ROLES.OFFICE_ADMIN, label: ROLES.OFFICE_ADMIN },
    { value: ROLES.PARTNER, label: 'SALES USER' },
    { value: ROLES.OFFICE_MANAGER, label: ROLES.OFFICE_MANAGER },
    { value: ROLES.VIEW_ONLY, label: ROLES.VIEW_ONLY },
    { value: ROLES.UNDER_WRITER, label: ROLES.UNDER_WRITER },
    { value: ROLES.SALES_LIMITED, label: ROLES.SALES_LIMITED }
  ],
  [ROLES.OFFICE_ADMIN]:[
    { value: ROLES.PARTNER, label: 'SALES USER' },
    { value: ROLES.OFFICE_MANAGER, label: ROLES.OFFICE_MANAGER },
    { value: ROLES.VIEW_ONLY, label: ROLES.VIEW_ONLY },
    { value: ROLES.SALES_LIMITED, label: ROLES.SALES_LIMITED },
  ],
  [ROLES.OFFICE_MANAGER]:[
    { value: ROLES.PARTNER, label: 'SALES USER' },
  ],
  [ROLES.RELATIONSHIP_MANAGER]:[
    { value: ROLES.PARTNER, label: 'SALES USER' },
    { value: ROLES.OFFICE_ADMIN, label: ROLES.OFFICE_ADMIN },
    { value: ROLES.OFFICE_MANAGER, label: ROLES.OFFICE_MANAGER },
    { value: ROLES.VIEW_ONLY, label: ROLES.VIEW_ONLY },
    { value: ROLES.SALES_LIMITED, label: ROLES.SALES_LIMITED }
  ]
}

export const AllowedRolesListByRoleInTenant:any = {
  [ROLES.SUPER_ADMIN]:[
    { value: ROLES.OFFICE_ADMIN, label: ROLES.OFFICE_ADMIN },
    { value: ROLES.PARTNER, label: 'SALES USER' },
    { value: ROLES.OFFICE_MANAGER, label: ROLES.OFFICE_MANAGER },
    { value: ROLES.VIEW_ONLY, label: ROLES.VIEW_ONLY },
    { value: ROLES.SALES_LIMITED, label: ROLES.SALES_LIMITED }
  ],
  [ROLES.OFFICE_ADMIN]:[
    { value: ROLES.PARTNER, label: 'SALES USER' },
    { value: ROLES.OFFICE_MANAGER, label: ROLES.OFFICE_MANAGER },
    { value: ROLES.VIEW_ONLY, label: ROLES.VIEW_ONLY },
    { value: ROLES.SALES_LIMITED, label: ROLES.SALES_LIMITED }
  ],
  [ROLES.OFFICE_MANAGER]:[
    { value: ROLES.PARTNER, label: 'SALES USER' },
  ],
  [ROLES.RELATIONSHIP_MANAGER]:[
    { value: ROLES.PARTNER, label: 'SALES USER' },
    { value: ROLES.OFFICE_ADMIN, label: ROLES.OFFICE_ADMIN },
    { value: ROLES.OFFICE_MANAGER, label: ROLES.OFFICE_MANAGER },
    { value: ROLES.VIEW_ONLY, label: ROLES.VIEW_ONLY },
    { value: ROLES.SALES_LIMITED, label: ROLES.SALES_LIMITED }
  ]
}
export const AllowedRolesListByOfficeAdmin:any = [
  { value: ROLES.OFFICE_ADMIN, label: ROLES.OFFICE_ADMIN },
  { value: ROLES.PARTNER, label: 'SALES USER' },
  { value: ROLES.OFFICE_MANAGER, label: ROLES.OFFICE_MANAGER },
  { value: ROLES.VIEW_ONLY, label: ROLES.VIEW_ONLY },
  { value: ROLES.SALES_LIMITED, label: ROLES.SALES_LIMITED }
]
export const AllowedRolesListBySuperuserOfficeManager:any= [
  { value: ROLES.OFFICE_ADMIN, label: ROLES.OFFICE_ADMIN },
  { value: ROLES.PARTNER, label: 'SALES USER' },
  { value: ROLES.OFFICE_MANAGER, label: ROLES.OFFICE_MANAGER },
  { value: ROLES.VIEW_ONLY, label: ROLES.VIEW_ONLY },
  { value: ROLES.SALES_LIMITED, label: ROLES.SALES_LIMITED }
]
export const freeEquipCheckRole = [
  ROLES.SUPER_ADMIN,
  ROLES.RELATIONSHIP_MANAGER
];

export const monthOptions = [
  {
    key: 0,
    text: '0',
    value: '0',
  },
  {
    key: 1,
    text: '1',
    value: '1',
  },
  {
    key: 2,
    text: '2',
    value: '2',
  },
  {
    key: 3,
    text: '3',
    value: '3',
  },
  {
    key: 4,
    text: '4',
    value: '4',
  },
  {
    key: 5,
    text: '5',
    value: '5',
  },
  {
    key: 6,
    text: '6',
    value: '6',
  },
  {
    key: 7,
    text: '7',
    value: '7',
  },
  {
    key: 8,
    text: '8',
    value: '8',
  },
  {
    key: 9,
    text: '9',
    value: '9',
  },
  {
    key: 10,
    text: '10',
    value: '10',
  },
  {
    key: 11,
    text: '11',
    value: '11',
  },
];

export const otherFees = {
  pciNonCompliance: '19.95',
  chargeBackFee: '15',
}
// pci-non-complaice for cfsb and cfsb-cd banks 
export const otherFees_cfsb = {
  pciNonCompliance: '9.99',
}


export const application_type_nmi = [
  { text: 'Full Gateway', value: 'Full Gateway' },
  { text: 'Mobile Only', value: 'Mobile Only' }
]
