import React, { useState } from 'react';
import { Button, Switch, Tooltip, Spin, Modal } from 'antd';
import { EditOutlined, DeleteFilled, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import api from '../../../../Service/Api';
import backend from '../../../../Service/Backend';

interface ClientCardProps {
  data: any,
  handleClientEdit: (id: string) => void,
  successCallback: () => void,
}

const ClientCard = (props: ClientCardProps) => {
  const { data, handleClientEdit, successCallback } = props;
  const [ cardLoading, setCardLoading ] = useState<boolean>(false);
  const [ toggleActivation, setToggleActivation ] = useState<boolean>(!data.deactivate);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Are you sure you want to delete this client');

  const handleActivateDeactivateClient = async(id: any, checked: boolean) => {
    setToggleActivation(checked);
    setCardLoading(true);
    let data = {
      api: {},
      payLoad: JSON.stringify({
        "client_id": id,
      })
    };

    if (checked) {
      data.api = api.platformAdmin.activateClient
    } else {
      data.api = api.platformAdmin.deactivateClient
    }

    const response = await backend.save(data);
    if (response) {
      setCardLoading(false);
    }
  }

  const handleCancel = () => {
    setOpen(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleClientDelete = async(id: any) => {
    setCardLoading(true)

    const data: any = {
      api: api.platformAdmin.deleteClient,
      urlParam: id,
    }

    const response = await backend.remove(data);

    if (response) {
      setCardLoading(false)
      successCallback();
    }
  }

  return (
    <div className='client-card' key={`${data.id}-${data.client_name}`}>
      { cardLoading && <div className="card-overlay"><Spin /></div> }
      <img src={data.client_config_fe.header_logo} className='company-logo' />
      <div className="company-details">
        <p>{data.client_name}</p>
        <a href={data.client_config_be?.client_url} target='_blank'>{data.client_config_be?.client_url || 'Not Available'}</a>
      </div>
      <div className="theme-color" style={{ backgroundColor: data.client_config_fe.header_bg_color }}></div>
      <Button icon={<EditOutlined rev={undefined} />} className='edit-button' onClick={() => handleClientEdit(data.id)}/>
      <Tooltip placement="top" title={toggleActivation ? 'Deactivate' : 'Activate'}>
        <Switch
          id={data.id}
          checkedChildren={<CheckOutlined rev={undefined} />}
          unCheckedChildren={<CloseOutlined rev={undefined} />}
          checked={toggleActivation}
          onChange={(checked: boolean) => handleActivateDeactivateClient(data.id, checked)}
          size="small"
        />
      </Tooltip>
      <Tooltip placement="top" title={'Delete'}>
        {/* <Button
          icon={<DeleteFilled rev={undefined} />}
          className='edit-button'
          onClick={() => handleClientDelete(data.id)}
        /> */}
        <Button type="primary" className='delete-button' onClick={showModal} icon={<DeleteFilled rev={undefined} />}>
      </Button>
      <Modal
        title="Confirm Delete"
        open={open}
        onOk={() => handleClientDelete(data.id)}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Delete"
      >
        <p>{modalText}</p>
      </Modal>
      </Tooltip>
    </div>
  )
}

export default ClientCard;
