import React from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";
import BasicButton from '../../Common/Button';
interface BulkActionPaperAppsProps {
    loading: boolean
    selectedOffice: any
    officePaperAppsBulkConfirmation: boolean
    handleCancel: () => void
    modalAction: any
    handleConfirmActionPaperApps: any
    countforAction: any
    config?:any
}
const PaperAppsActionConfirmation = (props: BulkActionPaperAppsProps) => {
    const { loading, handleCancel, selectedOffice, modalAction , handleConfirmActionPaperApps, officePaperAppsBulkConfirmation, countforAction,config} = props
    return (
        <Modal
            centered
            size='tiny'
            open={officePaperAppsBulkConfirmation}
            onClose={handleCancel}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <div className="close-button">
                    <button className="email-activity-close-button" onClick={handleCancel}>X</button>
                </div>
                <ProgressLoader loading={loading} size='small' />
                <div className="word-wrap">
                {countforAction > 0 ?
                <span className="word-wrap">{`${countforAction } ${countforAction > 1 ? 'offices' : 'office'} already in ${modalAction === "Enable Paper Apps"? "Enable" : "Disable"} state . Please recheck your selection`}</span>
                :
                <span className="word-wrap">{`Are you sure you want to ${modalAction} for ${selectedOffice.length } ${selectedOffice.length > 1 ?'offices' : 'office'} ?`}</span>
                }
                </div>
            </Modal.Content>
            <Modal.Actions>
                <BasicButton
                    textColor={config?.client_config_fe?.button_color}
                    backgroundColor={'#FFFFFF'}
                    size='18px'
                    buttonText={countforAction === 0 ? 'CANCEL' : "OK"}
                    className="save-button button-border"
                    onClick={handleCancel}
                />
                {countforAction === 0 && (
                    <button
                        className="save-button button-border ipad-blue"
                        style={{ background: config?.client_config_fe?.button_color }}
                        onClick={() => { handleConfirmActionPaperApps(modalAction) }}>YES</button>

                )}
            </Modal.Actions>
        </Modal>
    )
}
export default PaperAppsActionConfirmation;