import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import Value from "./Value";
import "./ValueList.css";

const { REACT_APP_DEFAULT_CLIENT_ID } = process.env;

interface valueListProps {
  selectedValues: any;
  isOptionSelected: boolean;
  handleAdd: (newValue: string) => Promise<void>;
  handleDelete: (deleteValue: string) => Promise<void>;
  handleUpdate: (currentvalue: string, updatedvalue: string) => Promise<void>;
  isEquipmentSelected:boolean;
  addField: boolean;
  setAddField: React.Dispatch<React.SetStateAction<boolean>>
  showAdd: boolean;
  setShowAdd: React.Dispatch<React.SetStateAction<boolean>>
  isUpdate:boolean
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>
  selectedEquipmentType:any;
  productPricesValidation:any;
  setProductPricesValidation:React.Dispatch<React.SetStateAction<string>>;
  selectedClientId: string
}

const ValueList = (props: valueListProps) => {
  const { 
    selectedValues,
    addField,
    setAddField,
    showAdd,
    setShowAdd,
    isUpdate,
    setIsUpdate,
    selectedEquipmentType,
    setProductPricesValidation,
    productPricesValidation,
    selectedClientId
  } = props;
  console.log("Valuelist:", selectedValues);
  
  //const [addField, setAddField] = useState(false);
  const [newValue, setNewValue] = useState("");
  const handleAddValue = async () => {
    let addStatus= true
    if(newValue){
      setProductPricesValidation("")
      if(selectedEquipmentType === 'Physical Terminal' || selectedEquipmentType === 'Cash Discount Physical Terminal'){
        var regexTest = /(?=.)\$(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(\.[0-9]{1,2})?/;
        if(newValue.split("__$")[1] && regexTest.test(newValue)){
          addStatus= true
        }else{
           addStatus= false
           setProductPricesValidation("Product name should end with product price and need to be in following format Ex: MAGTEK Mini Micr__$258.00")
        }
      }
      else{
         addStatus= true
      }
      if(addStatus){
          setProductPricesValidation("")
          await props.handleAdd(newValue);
          setAddField(false);
          setShowAdd(true);
          setNewValue("");
      }
    }
  };

  const handleChange = (data: string) => {
    if(props.isEquipmentSelected){
      setNewValue(data);
    }else{
      setNewValue(data.toUpperCase());
    }
  };
  return (
    <div>
      <div className="valuelist">
        {selectedValues?.map((value: any, index: any) => {
          return (
            <div>
              <Value
                key={index}
                value={value}
                handleDelete={props.handleDelete}
                handleupdate={props.handleUpdate}
                isEquipmentSelected={props.isEquipmentSelected}
                setIsUpdate={setIsUpdate}
                isUpdate={isUpdate}
                selectedEquipmentType={selectedEquipmentType}
                selectedClientId={selectedClientId}
              />
              <div className="valuelist-box-line"></div>
            </div>
          );
        })}
      </div>
      {addField && (
        <div>
          <div className="Value-box mobile-value-box">
            <div className="full-width">
              <input
                type="text"
                value={newValue}
                className="popup-input"
                onChange={(e) => handleChange(e.target.value)}
              ></input>
            </div>
            <div className="actions-value-box">
              <div className="actions-value">
              <Button color='blue' className=" mobile-add-field" disabled={!!newValue?false:true} onClick={handleAddValue}>Add</Button>
              <Button 
                onClick={() => {
                  setAddField(false);
                  setShowAdd(true);
                  setNewValue("");
                  setProductPricesValidation("")
                }}
                className="cancel-mob-field"
              >
                Cancel
              </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.isOptionSelected && showAdd && REACT_APP_DEFAULT_CLIENT_ID == selectedClientId && (
        <Button
          className="valuelist-button"
          onClick={() => {
            setAddField(true);
            setShowAdd(false);
            setNewValue("")
          }}
        >
          + Add
        </Button>
      )}
      {productPricesValidation && (
         <div className="Value-box">
          <div className="price-validation warning-text">{productPricesValidation}</div>
          </div>
        )}
    </div>
  );
};

export default ValueList;
