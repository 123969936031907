import * as React from "react";
import * as Sentry from "@sentry/react";
import Header from "./Header";
import { LayoutProps } from "./structs";
// import "./layout.css";
import "../../App.css";
import "../../Assets/css/font-face.css";

export const Layout: React.FunctionComponent<LayoutProps> = (props) => {
  const FallbackComponent = () => {
    return <div className="not-found">Sorry there was a problem !</div>;
  };
  const myFallback = <FallbackComponent />;

  return (
    
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      <div className="container">
        <Header page={props.page} profile_image_url={props.profile_image_url}  listType={props.listType}/>
        {props.children}
     
      </div>
    </Sentry.ErrorBoundary>
  );

 
};
