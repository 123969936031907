import React from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";
import BasicButton from '../../Common/Button'

interface DeactivateModalProps {
    deactivateModalOpen: boolean;
    loading: boolean;
    editOfficeDetails: any;
    handleCancel: () => void
    handleDeactivateActivate: (office_id: any,deactivate: boolean) => Promise<void>
    config?:any
}

const DeactivateModal = (props: DeactivateModalProps) => {

    const { deactivateModalOpen, handleCancel, editOfficeDetails, handleDeactivateActivate, loading, config } = props
    return (
        <Modal
            centered
            size='tiny'
            open={deactivateModalOpen}
            onClose={handleCancel}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <div className="close-button">
                    <button className="email-activity-close-button" onClick={handleCancel}>X</button>
                </div>
                <ProgressLoader loading={loading} size='small' />
                <div className="word-wrap">
                {editOfficeDetails.deactivate ? `Are you sure you want to activate office ${editOfficeDetails.office_name} ( ID - ${editOfficeDetails.office_id} )?` : `Are you sure you want to deactivate office ${editOfficeDetails.office_name} ( ID - ${editOfficeDetails.office_id} )?`}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <BasicButton
                    textColor={config?.client_config_fe?.button_color}
                    backgroundColor={'#FFFFFF'}
                    size='18px'
                    buttonText='CANCEL'
                    className="save-button button-border"
                    onClick={handleCancel}
                />
                <button
                    style={{ background: config?.client_config_fe?.button_color }}
                    onClick={() => handleDeactivateActivate(editOfficeDetails.office_id, editOfficeDetails.deactivate ? false : true)}
                    className="ipad-blue save-button button-border"
                >  {editOfficeDetails.deactivate ? "ACTIVATE" : "DEACTIVATE"}
                </button>
            </Modal.Actions>
        </Modal>
    )
}

export default DeactivateModal;