import React, { useState, useEffect, useContext } from 'react'
import { Button } from 'semantic-ui-react'
import { useHistory, useLocation } from 'react-router-dom'
import { Footer } from '../PageLayout/Footer'
import Dropzone from 'react-dropzone'
import api from '../../Service/Api'
import backend from '../../Service/Backend'
import ProgressLoader from '../Common/ProgressLoader'
import { auth } from '../../Firebase'
import { Layout } from '../PageLayout/Layout'
import { MERCHANT_APP_STATUS, RISK_LEVELS, ROLES } from '../../Utils/constants'
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen'
import { storage } from "../../Firebase/firebase";
import { ref, uploadBytesResumable } from 'firebase/storage'
import { get } from '../../Utils/helpers'
import { AppContext } from '../../context/appContext'
import BasicButton from '../Common/Button'

const { REACT_APP_BACKEND_MYSQL } = process.env

interface IProps {
  selectedUser: any;
}

const DocUpload = (props: IProps) => {
  const { config, setConfig }: any = useContext(AppContext);
  const history = useHistory()
  const location = useLocation<any>()
  const tokenID: any = location?.state?.tokenID

  const [bankName, setBankName] = useState('')
  const [dbaName, setDbaName] = useState('')
  const [selectedUser, setSelectedUser] = useState({} as any)
  const [docFiles, setDocFiles] = useState([] as any)
  const [allDocuments, setAllDocuments] = useState([] as any)
  const [merchant_app_id, setMerchantAppId] = useState('')
  const [merchant_app_code, setMerchantAppCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingMsg, setLoadingMsg] = useState('')
  const [document_paths, setDocumentPaths] = useState([] as any)
  const [docFlags, setDocFlags] = useState([] as any)
  const [updateMerchantAppDocumentsPayload, setUpdateMerchantAppDocumentsPayload] = useState({} as any)
  const [updateMerchantAppDocumentsPayloadNew, setUpdateMerchantAppDocumentsPayloadNew] = useState({} as any)
  const [overlay, setOverlay] = useState({ style: { display: 'none' } })
  const [overlayMsg, setOverlaymsg] = useState('')
  const [uploadButtonActive, setUploadButtonActive] = useState(false)
  const [finishButtonActive, setFinishButtonActive] = useState(false)
  const [Merchant_app_id, setMerchant_app_id] = useState('')
  const [signedDocFiles, setSignedDocFiles] = useState([] as any)
  const [offlineApp, setOfflineApp] = useState(false)
  const [signedDocFileUploaded, setSignedDocFileUploaded] = useState([] as any)
  const [signedDocsPaths, setSignedDocsPaths] = useState([] as any)
  const [deletedSignedDocsPaths,setDeletedSignedDocsPaths] = useState([] as any)
  const [isHighRiskApp, setIsHighRiskApp] = useState(false)
  const [secondSignerEmail, setSecondSignerEmail] = useState('')
  const [secondSignerName, setSecondSignerName] = useState('')
  const [merchantAPpStatus, setMerchantAPpStatus] = useState('')
  const [selectedProcessor, setSelectedProcessor] = useState('')
  const [noOfOwners, setNoOfOwners] = useState(0)
  const [partnerId, setPartnerId] = useState(null)

  const isMobile = useCheckMobileScreen();

  const user_id = sessionStorage.getItem('user_id')
  const role = sessionStorage.getItem('role')
  const [fileDelete, setFileDelete] = useState(false)

  const getMerchantAppById = async () => {
    const url = window.location.href
    console.log("tokenID>>>>>",tokenID)
    let  token: any
    if(tokenID) {
      await auth.signInWithCustomToken(tokenID)
      token = await auth.getToken();
    }

    
    const query_param_id = url.split('=')[1]
    const role = sessionStorage.getItem('role')
    const data: any = {
      queryParam: { merchant_app_id: query_param_id }
    }
    if (role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.PARTNER || role === ROLES.OFFICE_ADMIN || role === ROLES.OFFICE_MANAGER || role === ROLES.UNDER_WRITER || role === ROLES.SUPER_ADMIN || role === ROLES.SALES_LIMITED) {
      data.api = api.merchantAppData.getMerchantAppById
    } else {
      data.api = api.merchant.getMerchantAppById
    }
    setMerchant_app_id(query_param_id)
    const merchant_app: any = await backend.fetch(data, token || null)
    let signed_app_document_paths =  merchant_app?.signed_app_document_paths && Array.isArray(merchant_app.signed_app_document_paths)?merchant_app.signed_app_document_paths:[]
    console.log('merchant app : ', merchant_app)
    setIsHighRiskApp(merchant_app?.merchant_prescreen?.price === RISK_LEVELS.HIGH_RISK || false)
    setOfflineApp(merchant_app?.offline_app_status)
    setSignedDocsPaths(signed_app_document_paths)
    setSelectedProcessor(merchant_app.merchant_prescreen.processor_name)
    setMerchantAppId(merchant_app.merchant_app_id)
    setPartnerId(merchant_app.partner_id)
    if(REACT_APP_BACKEND_MYSQL) setMerchantAppCode(merchant_app.merchant_app_code)
    setNoOfOwners(merchant_app?.app_data_sections?.owner_section.length)
    if(merchant_app?.app_data_sections?.owner_section.length > 1){
      setSecondSignerName(merchant_app?.app_data_sections?.owner_section[1]?.firstName+" "+merchant_app?.app_data_sections?.owner_section[1]?.lastName)
      setSecondSignerEmail(merchant_app?.app_data_sections?.owner_section[1]?.ownerEmail)
    }
    return merchant_app
  }

  useEffect(() => {
    setFileDelete(false)
    console.log('second life cycle hook')
    const fetchProcessorsData = async () => {
      let  token: any
      if(tokenID) {
        await auth.signInWithCustomToken(tokenID)
        token = await auth.getToken();
      }
      const data = {
        api: api.processorData.getProcessors
      }
      const response = await backend.fetch(data, token || null)
      console.log('response :>> ', response)
      return response
    }

    setLoading(true)
    Promise.all([getMerchantAppById(), fetchProcessorsData()]).then(data => {
      setLoading(false)
      const app = data[0]
      let processor_name = app?.merchant_prescreen?.processor_name
      if (!processor_name) {
        processor_name = app?.app_data_sections?.bank_section?.bank_name
      }
      const processor_index = data[1].findIndex(
        (processor: any) => (REACT_APP_BACKEND_MYSQL ? processor.processor_internal_id : processor.processor_code) === processor_name
      )
      const processor: any = data[1][processor_index]
      const documents = processor?.processor_req_docs || []

      const state: any = { ...app, required_documents: documents }

      if (state) {
        setSelectedUser(state)
        setBankName(state?.merchant_prescreen?.processor_name)
        setDbaName(state?.app_data_sections?.dba_section?.dba_name)
      }

      const required_documents = Object.values(state?.required_documents).filter((document: any) => !!(get(document, 'name', null))).map((document: any) => {
        return { id: document.name, text: document.name, value: document.name }
      })

      if (app?.app_data_sections?.misc_section?.is_cbd_form_downloaded) {
        required_documents.push({ id: 'CBD_Form', text: 'CBD_Form', value: 'CBD_Form' })
      }
      if (app?.app_data_sections?.misc_section?.is_mc_form_downloaded) {
        required_documents.push({ id: 'MC_Registration', text: 'MC_Registration', value: 'MC_Registration' })
      }
      if (app?.app_data_sections?.misc_section?.is_esquire_form_downloaded) {
        required_documents.push({ id: 'Esquire_Attestation', text: 'Esquire_Attestation', value: 'Esquire_Attestation' })
      }

      required_documents.sort(function (a, b) {
        if (a.id > b.id) {
            return 1;
        }
        if (b.id > a.id) {
            return -1;
        }
        return 0;
      })

      const merchant_app_id = state?.merchant_app_id
      setMerchantAppId(merchant_app_id)
      setDocumentPaths(state?.document_paths || [])

      const document_paths_payload: any = {}
      for (const file of required_documents) {
        const file_key = file?.value
        document_paths_payload[file_key] = []
      }

      const index = 0
      const docFilesTemp: any = []
      const document_paths_temp = state?.document_paths || []
      let file_index = 1
      required_documents.map(function (file: any, index) {
        docFilesTemp.push([])
        docFlags.push([])
        const file_key = file?.value
        const file_paths: any = document_paths_temp[file_key]
        file_paths?.map((path: string, index1: number) => {
          document_paths_payload[file_key]?.push(path)
          docFilesTemp[index].push({ name: `${file_key}${index1 + 1}.pdf` })
          docFlags[index].push(false)
        })
        file_index = file_index + 1
      })

      setUpdateMerchantAppDocumentsPayload({
        merchant_app_id: merchant_app_id,
        document_paths: document_paths_payload,
        merchant_app_code: merchant_app_code
      })
      setDocFiles(docFilesTemp)
      setAllDocuments(required_documents)
    }).catch(err => {
      console.log('err', err)
    })
  }, [docFlags, role, user_id])

  const handleDrop = (acceptedFiles: any, index: number) => {
    let validDoc = true
    if((acceptedFiles && acceptedFiles.length > 0 && acceptedFiles[0].type !== 'application/pdf') || (acceptedFiles && acceptedFiles.length <= 0 ) ){
      window.scroll({ top: 0, left: 0 });
      setOverlaymsg('Only PDF files are accepted')
      showOverlayForThreeSeconds()
      validDoc = false
    }
    if(acceptedFiles && acceptedFiles.length > 0 && acceptedFiles[0].size > 20971520){
      window.scroll({ top: 0, left: 0 });
      setOverlaymsg('File size must not be more than 20MB')
      showOverlayForThreeSeconds()
      validDoc = false
    }
    if(validDoc){
      const docFlagsTemp = docFlags
      const file_names = acceptedFiles.map((file: any) => {
        docFlagsTemp[index]?.push(true)
        return file
      })
      const docFilesTemp = docFiles
      docFilesTemp[index] = [...docFiles[index], ...file_names]
      setDocFiles([...docFilesTemp])
      let check = false
      docFilesTemp.forEach((item: any[]) => {
        if (item.length > 0) check = true
      })
      setUploadButtonActive(check)
      setDocFlags(docFlagsTemp)

    }
  }

  const handleDropSignedDoc = (acceptedFiles: any) => {
    let validDoc = true
    if((acceptedFiles && acceptedFiles.length > 0 && acceptedFiles[0].type !== 'application/pdf') || (acceptedFiles && acceptedFiles.length <= 0 ) ){
      window.scroll({ top: 0, left: 0 });
      setOverlaymsg('Only PDF files are accepted')
      showOverlayForThreeSeconds()
      validDoc = false
    }
    if(acceptedFiles && acceptedFiles.length > 0 && acceptedFiles[0].size > 20971520){
      window.scroll({ top: 0, left: 0 });
      setOverlaymsg('File size must be not more than 20MB')
      showOverlayForThreeSeconds()
      validDoc = false
    }
    if(validDoc){
      setSignedDocFiles([...signedDocFiles,...acceptedFiles])
      setUploadButtonActive(true)
    }
  }

  const deleteSignedDocFile = (file: any, index: number) => {
    const filteredData = signedDocFiles?.filter((item: any) => item?.name !== file?.name)
    setSignedDocFiles(filteredData)
    if( offlineApp && filteredData?.length <= 0) setUploadButtonActive(false)
  }

  const deleteUploadedSignedDocFile = (index:number) => {
    setSignedDocsPaths([])
  }

  const handleUploadCancel = () => {
    console.log('handleCancel')
    setDocFiles([])
    history.replace('/listapp');
  }
  const handleUploadFinish = async () => {
    try {
      setLoading(true)
      console.log('UploadFinish signout')
      const secondOwnerSigningBanks = ['AXIOM','SYNOVUS','ESQUIRE','CFSB','DART', 'CFSB CD','HARRIS','CENTRAL']
      if(secondOwnerSigningBanks.includes(selectedProcessor) && noOfOwners > 1){
        history.replace('/thankspage', { tokenID: sessionStorage.merchant_access_token,Merchant_app_id: merchant_app_id, sent_to_owner_two: true, ownerTwoEmail: secondSignerEmail, ownerTwoName: secondSignerName })
      }else{
        history.replace('/thankspage',{ tokenID: sessionStorage.merchant_access_token , Merchant_app_id: Merchant_app_id })
      }
      //sending signed status to check 2nd owners signed or not.
      //If signed is true, second owner signed or else single owner signed
      // history.replace('/thankspage',{ tokenID: sessionStorage.merchant_access_token , Merchant_app_id: Merchant_app_id, signed: tokenID ? true : false })
      await auth.doSignOut()
      const clientId = sessionStorage.getItem("clientId")
      sessionStorage.clear();
      if (!!clientId) {
        sessionStorage.setItem("clientId", clientId)
      }
      localStorage.clear()
      setConfig({
        ...config,
        accessible_clients: [],
        user_roles_id : null,
        selectedClientId: config?.id
      })
      setLoading(false)
    } catch (err: any) {
      console.log(err)
    }
  }
  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds))
  }

  const showOverlayForThreeSeconds = async () => {
    const sstyle: any = { style: { display: 'block', position: 'fixed' } }
    console.log('Show')
    setOverlay(sstyle)

    await sleep(3000)

    const hstyle: any = { style: { display: 'none' } }
    console.log('hide')
    setOverlay(hstyle)
  }

  const sendMailtoRMPartner = async (merchant_app_id: string) => {
    const send_mail_data: any = {
      api: api.merchant.sendMailToRMPartner,
      payLoad: JSON.stringify({
        merchant_app_id: merchant_app_id,
        partner_id: partnerId
      })
    }
    await backend.save(send_mail_data)
  }

  const updateStatusOfMerchantApp = async (merchantApp_id: string, update_status: string, offline_app: boolean) => {
    const payload = {
      merchant_app_id: merchantApp_id,
      status: update_status
    }
    const data = {
      api: offline_app ? api.merchantAppData.updateOfflineAppStatus :
        (role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.PARTNER || role === ROLES.OFFICE_ADMIN || role === ROLES.OFFICE_MANAGER || role === ROLES.UNDER_WRITER) ? api.merchantAppData.updateMerchantAppStatus : api.merchant.updateMerchantAppStatus,
      payLoad: { ...payload }
    }

    setLoading(true)
    const response = await backend.save(data)
    console.log('response : ', response)
  }

  const uploadFileToStorege = async (selectedFile:any,file_name:string) => {
    try {
      let path = ''
      const file: any = selectedFile
      const uploadRef = ref(storage, `MerchantFolders/${merchant_app_id}/${file_name}`);
      const uploadTask = uploadBytesResumable(uploadRef, file);

      await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapShot) => {
            path = snapShot.ref.fullPath;
          },
          (err) => {
            reject(err);
          },
          async () => {
            resolve(path);
          }
        );
      });
      return path
    } catch (err) {
      console.log('UPLOADerr :>> ', err);
      throw err
    }
  }

  const handleUpload = async () => {
    try {
      setLoading(true)
      setLoadingMsg('Document upload in progress, Please do not refresh the page.')
      let apiInfo: any = {}
      if (role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.PARTNER || role === ROLES.OFFICE_ADMIN || role === ROLES.OFFICE_MANAGER || role === ROLES.UNDER_WRITER || role === ROLES.SUPER_ADMIN || role === ROLES.SALES_LIMITED) {
        apiInfo = api.merchantAppData.uploadDocument
      } else {
        apiInfo = api.merchant.uploadDocument
      }

      const data: any = {
        api: {
          ...apiInfo
        },
        payLoad: {
          merchant_app_id: merchant_app_id,
          merchant_app_code: merchant_app_code,
          file: ''
        }
      }

      //uploading offline signed document
      if(offlineApp){
        let signed_file_paths = [...signedDocsPaths]
        for (let index1 = 0; index1 < signedDocFiles?.length; index1++) {
          const path = await uploadFileToStorege(signedDocFiles[index1],signedDocFiles[index1]?.name)
          data.payLoad = {
            merchant_app_id: merchant_app_id,
            merchant_app_code: merchant_app_code,
            file_path: path,
            file_name: signedDocFiles[index1]?.name
          }
          const response = await backend.save(data)
          const file_path = response?.file_path
          signed_file_paths.push(file_path)
          let tempData = signedDocFileUploaded
          tempData[index1] = {...tempData[index1],uploaded: true}
          setSignedDocFileUploaded(tempData)
        }
        const updateData: any = {
          api: api.merchantAppData.updateSignedAppDocs,
          payLoad: JSON.stringify({
            document_paths: signed_file_paths,
            merchant_app_id: merchant_app_id
          })
        }
  
        if(signedDocFiles && signedDocFiles.length > 0){
          await backend.save(updateData)
          await updateStatusOfMerchantApp(merchant_app_id, MERCHANT_APP_STATUS.SIGNED, true);
          await sendMailtoRMPartner(merchant_app_id)
        }

        setSignedDocFiles([])
      }

      let current_document = allDocuments[0]?.value
      let file_path =''
      for (let index1 = 0; index1 < docFiles?.length; index1++) {
        current_document = allDocuments[index1]?.value
        for (let index2 = 0; index2 < docFiles[index1]?.length; index2++) {
          if (docFlags[index1][index2]) {
            const path = await uploadFileToStorege(docFiles[index1][index2],docFiles[index1][index2]?.name)
            data.payLoad = {
              merchant_app_id: merchant_app_id,
              merchant_app_code: merchant_app_code,
              file_path: path,
              file_name: docFiles[index1][index2]?.name
            }
            const response = await backend.save(data)
            file_path = response?.file_path
            if(fileDelete){
              updateMerchantAppDocumentsPayloadNew?.document_paths[current_document]?.push(file_path)
            }else{
              updateMerchantAppDocumentsPayload?.document_paths[current_document]?.push(file_path)
            }
            docFlags[index1][index2] = false
          }
        }
      }
      

      setUploadButtonActive(false)
      console.log('update documents payload : ', updateMerchantAppDocumentsPayload)
      let updateApiInfo: any = {}
      if (role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.PARTNER || role === ROLES.OFFICE_ADMIN || role === ROLES.OFFICE_MANAGER || role === ROLES.UNDER_WRITER || role === ROLES.SUPER_ADMIN || role === ROLES.SALES_LIMITED) {
        updateApiInfo = api.merchantAppData.updateMerchantAppDocuments
      } else {
        updateApiInfo = api.merchant.updateMerchantAppDocuments
      }

      const payloadvariable = fileDelete ? updateMerchantAppDocumentsPayloadNew :  updateMerchantAppDocumentsPayload
      const updateData: any = {
        api: { ...updateApiInfo },
        payLoad: JSON.stringify(payloadvariable)
      }

      await backend.save(updateData)
      setFinishButtonActive(true)
      

      // Change status to docs uploaded here - SarojK
      // Changing Status to docs uploaded only when app status is already signed
      const merchantAppData: any = await getMerchantAppById()
      if(merchantAppData?.merchant_app_status === "signed"){
        await updateStatusOfMerchantApp(merchant_app_id, MERCHANT_APP_STATUS.DOC_UPLOAD, false);
      }
      setLoading(false)
      setLoadingMsg('')
      window.scroll({ top: 0, left: 0 });
      setOverlaymsg('Files Uploaded Successfully')
      showOverlayForThreeSeconds()
    } catch (err: any) {
      setLoading(false)
      showOverlayForThreeSeconds()
      setOverlaymsg(err.message || err.errMessage || 'Error occured while uploading the files')
      
      console.log('err : ', err)
    }
  }

  const updateMerchantAppDocuments = async (payload?: any) => {
    setLoading(false)
    const updateData: any = {
      api: api.merchantAppData.updateMerchantAppDocuments,
      payLoad: JSON.stringify(payload)
    }

    await backend.save(updateData)
    delete payload.deleted_document_paths
    setUpdateMerchantAppDocumentsPayload(payload)
  }

  // Delete files
  const deleteDoc1files = (file: any, index1: number, index2: number) => {

    setFileDelete(true)
    console.log('index1 : ', index1)
    const splitted_array = file?.name.split('.')
    // let index = splitted_array[0][splitted_array[0]?.length - 1];
    const key = splitted_array[0]?.substr(0, splitted_array[0]?.length - 1)
    const document_paths_temp = document_paths
    console.log(document_paths)
    let deleted_document_paths: any = []
    if (document_paths_temp && document_paths_temp[key]) {
      deleted_document_paths = document_paths_temp[key]?.splice(index2 - 1, 1)
    }
    setDocumentPaths(document_paths_temp)

    setUpdateMerchantAppDocumentsPayloadNew(updateMerchantAppDocumentsPayload)
    // document_paths[key]?.splice(index-1, 1);
    setUpdateMerchantAppDocumentsPayload({
      merchant_app_id: merchant_app_id,
      document_paths: document_paths_temp,
      merchant_app_code: merchant_app_code
    })
    const docFilesTemp = docFiles
    docFilesTemp[index1] = docFilesTemp[index1]?.filter((item: any) => item?.name !== file?.name)
    setDocFiles(docFilesTemp)
    console.log('docFiles : ', docFiles)

    if (docFlags[index1] && !docFlags[index1][index2 - 1]) {
      updateMerchantAppDocuments({
        merchant_app_id: merchant_app_id,
        document_paths: document_paths_temp,
        deleted_document_paths: deleted_document_paths,
        merchant_app_code: merchant_app_code
      })
    }
    docFlags[index1].splice(index2 - 1, 1)
    let check = false
    docFilesTemp.forEach((item: any[]) => {
      if (item.length > 0) check = true
    })
    setUploadButtonActive(check)
  }


  const renderSignedCopyUpload = () => {
    return (
      <div className='doc-contain-area'>
          <div className='dropzone-title'>UPLOAD SIGNED APPLICATION</div>
          <div className='drop-container'>
            <div className='docupload-icon' />
            <div className={signedDocFiles?.length > 0 || signedDocsPaths?.length > 0  ? 'drop-message pointer-none' : 'drop-message'}>
              <Dropzone
                onDrop={(file: any) => handleDropSignedDoc(file)}
                multiple
                disabled={ (signedDocFiles?.length > 0 || signedDocsPaths?.length > 0 ) ? true : false }
                accept='application/pdf'
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: 'dropzone' })} style={{ color: config?.client_config_fe?.button_color, border:'1px solid' }}>
                    <input {...getInputProps()} />
                    {isMobile ? <p>Choose file to Upload</p> : <p>
                      Drag files and Drop Here
                      <br />
                      or Choose file to Upload
                    </p>}
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
          <div className='file-display-container'>
            {!!signedDocFiles && signedDocFiles.length > 0 && signedDocFiles.map((file: any, index: number) => (
              <div className='file-status-bar' key={index}>
                <span className='pdfIcon' />
                <span className='modal-text'>{file.name}</span>
                {!!signedDocFileUploaded[index] && signedDocFileUploaded[index].uploaded && (
                  <span style={{ cursor: 'pointer' }}>
                    <i className='tickicon' />
                  </span>
                )}
                <span style={{ cursor: 'pointer' }}>
                  <i
                    className='crossicon'
                    onClick={() => deleteSignedDocFile(file,index)}
                  />
                </span>
              </div>
            ))}
            {!!signedDocsPaths && signedDocsPaths.length > 0 && signedDocsPaths.map((file_path: any, index: number) => (
              <div className='file-status-bar'>
                <span className='pdfIcon' />
                <span className='modal-text'>{file_path.split('/')[file_path.split('/').length-1]}</span>
                  <span style={{ cursor: 'pointer' }}>
                    <i className='tickicon' />
                  </span>
                <span style={{ cursor: 'pointer' }}>
                  <i
                    className='crossicon'
                    onClick={() => deleteUploadedSignedDocFile(index)}
                  />
                </span>
              </div>
            ))}
          </div>
        </div>
    )
  }

  const renderDocFiles = () => {
    return allDocuments.map((item: any, i: number) => {
      return (
        <div className='doc-contain-area' key={i}>
          <div className='dropzone-title'>{item.value}</div>
          <div className='drop-container'>
            <div className='docupload-icon' />
            <div className='drop-message'>
              <Dropzone
                onDrop={(file: any) => handleDrop(file, i)}
                multiple
                accept='application/pdf'
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: 'dropzone' })} style={{ color: config?.client_config_fe?.button_color, border:'1px solid' }}>
                    <input {...getInputProps()} />
                    {isMobile ? <p>Choose file to Upload</p> : <p>
                      Drag files and Drop Here
                      <br />
                      or Choose file to Upload
                    </p>}
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
          <div className='file-display-container'>
            {!!docFiles[i] && docFiles[i].map((file: any, index2: number) => (
              <div className='file-status-bar'>
                {/* {(file1.length && (file1.substring(file1.lastIndexOf('.')+1 )) === 'pdf') && (
                      <span className="pdfIcon"></span>
                    )}
                    {(file1.length && (file1.split('.').pop() === 'png' || file1.split('.').pop() === 'jpg')) && (
                      <span className="imageIcon"></span>
                    )} */}
                <span className='pdfIcon' />
                <span className='modal-text'>{file.name}</span>
                {docFlags[i][index2] === false && (
                  <span style={{ cursor: 'pointer' }}>
                    <i className='tickicon' />
                  </span>
                )}
                <span style={{ cursor: 'pointer' }}>
                  <i
                    className='crossicon'
                    onClick={() => deleteDoc1files(file, i, index2 + 1)}
                  />
                </span>
              </div>
            ))}
          </div>
        </div>
      )
    })
  }
  return (
    <Layout page='docupload'>

      <div className='ui form center'>
        <div className='top-container'  style={{ background: config?.client_config_fe?.button_color }}>
          <div className='top-content'>
            <div className='inline fields'>
              <div className='four wide field'>
                <label className='top-content-label'>
                  Merchant Name
                  {/* <span className="margin-left-25">:</span> */}
                </label>
              </div>
              <div className='twelve wide field'>
                <input
                  type='text'
                  className='form-input'
                  name='business_name'
                  value={selectedUser.merchant_name ? selectedUser.merchant_name : ""}
                  disabled
                  style={{ color: '#9E9E9E' }}
                />
              </div>
            </div>
            <div className='inline fields'>
              <div className='four wide field'>
                <label className='top-content-label'>
                  DBA Name
                  {/* <span className="margin-left-25">:</span> */}
                </label>
              </div>
              <div className='twelve wide field'>
                <input
                  type='text'
                  className='form-input'
                  name='business_name'
                  value={dbaName}
                  disabled
                  style={{ color: '#9E9E9E' }}
                />
              </div>
            </div>
            <div className='inline fields'>
              <div className='four wide field'>
                <label className='top-content-label'>
                   Acquiring Bank
                  {/* <span className="margin-left-25">:</span> */}
                </label>
              </div>
              <div className='twelve wide field'>
                <input
                  type='text'
                  className='form-input'
                  name='business_name'
                  value={bankName}
                  disabled
                  style={{ color: '#9E9E9E' }}
                />
              </div>
            </div>
            <div className='inline fields'>
              <div className='four wide field'>
                <label className='top-content-label'>
                  Application Status
                  {/* <span className="margin-left-25">:</span> */}
                </label>
              </div>
              <div className='twelve wide field'>
                <input
                  type='text'
                  className='form-input'
                  name='business_name'
                  value={selectedUser.merchant_app_status ? selectedUser.merchant_app_status : ""}
                  disabled
                  style={{ color: '#9E9E9E' }}
                />
              </div>
            </div>
            <div className='inline fields'>
              <div className='four wide field'>
                <label className='top-content-label'>
                  AppID
                </label>
              </div>
              <div className='twelve wide field'>
                <input
                  type='text'
                  className='form-input'
                  name='business_name'
                  value={merchant_app_id}
                  disabled
                  style={{ color: '#9E9E9E' }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='body-content'>
          <div className='heading-text-left docs-upload-ipad'>Upload Documents</div>

          <div className='upload-section'>
            <div className='top-section'>
              <div className='left-heading'>List of Required Documents (max limit size 20mb per document)</div>
              <div><b>Note:</b> Please upload only pdf files.</div>
            </div>
            { offlineApp && 
              <div>
                {renderSignedCopyUpload()}
              </div>
            }
            <div className='dropzone-content'>{ isHighRiskApp && renderDocFiles()}</div>

            <div className='sixteen wide field margin-bottom-30 mob-upload'>
            <BasicButton
                  textColor={config?.client_config_fe?.button_color}
                  backgroundColor={'#FFFFFF'}
                  size='15px'
                  buttonText='UPLOAD'
                  className={offlineApp ? ((signedDocFiles?.length > 0 || signedDocsPaths?.length > 0 ) && uploadButtonActive) ? ' rightAlign save-button button-border ' : 'rightAlign save-button button-border not-active' : !uploadButtonActive ? 'rightAlign save-button button-border not-active' : ' rightAlign save-button button-border'}
                  onClick={handleUpload}
                />
              {/* <button
                 style={{ background: config?.client_config_fe?.button_color, border:'1px' }}
                
                className={offlineApp ? ((signedDocFiles?.length > 0 || signedDocsPaths?.length > 0 ) && uploadButtonActive) ? ' rightAlign save-button margin-right-0 ' : 'rightAlign save-button margin-right-0 not-active' : !uploadButtonActive ? 'rightAlign save-button margin-right-0 not-active' : ' rightAlign save-button margin-right-0'}
                // className='rightAlign save-button margin-right-0'
                onClick={handleUpload}
              >
                UPLOAD
              </button> */}
              {finishButtonActive && (
                <BasicButton
                  textColor={config?.client_config_fe?.button_color}
                  backgroundColor={'#FFFFFF'}
                  size='15px'
                  buttonText='FINISH'
                  className="rightAlign save-button button-border"
                  onClick={(role !== ROLES.SUPER_ADMIN && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.OFFICE_MANAGER && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED) ? handleUploadFinish : handleUploadCancel}

                />
              )}
              <BasicButton
                textColor={config?.client_config_fe?.button_color}
                backgroundColor={'#FFFFFF'}
                size='15px'
                buttonText='CANCEL'
                className="rightAlign save-button button-border"
                onClick={(role !== ROLES.SUPER_ADMIN && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.OFFICE_MANAGER && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED) ? handleUploadFinish : handleUploadCancel}
              />              
            </div>
          </div>
        </div>
        <ProgressLoader loading={loading} size='large' message={loadingMsg} config={config}/>
        <div className='overlay' style={overlay.style}>
          <div className='overlay-image'>
            <span className='overlay-text' style={{ color: config?.client_config_fe?.button_color }}>{overlayMsg}</span>
          </div>
        </div>
      </div>
      <Footer page="docupload"/>
    </Layout>
  )
}
export default DocUpload
