import { auth } from './firebase'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  UserCredential,
  IdTokenResult,
} from 'firebase/auth'

// Sign Up
export const doCreateUserWithEmailAndPassword = (email: string, password: string) : Promise<UserCredential> => {
  return createUserWithEmailAndPassword(auth, email, password)
}

// Sign In
export const doSignInWithEmailAndPassword = (email: string, password: string, tenantId: string) : Promise<UserCredential> => {
  if(!!tenantId) {
    auth.tenantId = tenantId
  }
  return signInWithEmailAndPassword(auth, email, password)
}

// Sign out
export const doSignOut = () : Promise<void> => {
  return auth.signOut()
}

export const signInWithCustomToken = (token: any) : Promise<UserCredential> => {
  return signInWithCustomToken(token)
}

// Get Id token
export const getToken = async (tenantId?: string): Promise<string> => {
  if(!!tenantId) {
    auth.tenantId = tenantId
  }
  if (auth.currentUser) {
    return auth.currentUser.getIdToken(true)
  }
  return Promise.reject('No auth.currentUser!')
}

// Get Id token result
export const getTokenResult = async (): Promise<IdTokenResult> => {
  if (auth.currentUser) {
    return auth.currentUser.getIdTokenResult()
  }
  return Promise.reject('No auth.currentUser!')
}

// Get UID
export const getUserId = (): string => {
  return auth.currentUser ? auth.currentUser.uid : ''
}

// Get User Email
export const getUserEmail = (): string => {
  return (auth.currentUser && auth.currentUser.email) ? auth.currentUser.email : ''
}

// Check if user is logged in
export const getUser = (): any => {
  return auth.currentUser ? auth.currentUser : null
}

// IsloggedIn
export const isAuthenticated = (): boolean => {
  let userId = sessionStorage.getItem('user_name') || null;

  const user: any = getUser()
  if (user && userId) {
    return true
  }
  return false
}

export const isInitialised = async () => {
  return new Promise(resolve => {
    auth.onAuthStateChanged(resolve)
  })
}
