import React from "react";
import { Button, Modal } from "semantic-ui-react";

interface SearchResultModalProps {
    noArchivedDataAlert: boolean
    handleCancel: () => void
}
const ArchivedResultModal = (props: SearchResultModalProps) => {
    const { handleCancel, noArchivedDataAlert} = props
    return (
        <Modal
            size='mini'
            open={noArchivedDataAlert}
            onClose={handleCancel}
        >
            <Modal.Header>
                <div className="email-activity-header">
                   <h3>Archived App</h3>
                    <button className="email-activity-close-button" onClick={handleCancel}>X</button>
                </div>
            </Modal.Header>
            <Modal.Content>
                <div className="word-wrap">
                   <p>No data found in Archived Applications list</p>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button color='blue' onClick={handleCancel}>
                    OK
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ArchivedResultModal;
