import React from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";

interface DeactivateModalProps {
    loading: boolean
    deactivateModalOpen: boolean
    uncheckparentcheckbox: any
    countforAction: any
}
const DeactivateModal = (props: DeactivateModalProps) => {
    const { loading, deactivateModalOpen ,uncheckparentcheckbox , countforAction} = props
    return (
        <Modal
            centered
            size='tiny'
            open={deactivateModalOpen}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <div className="close-button">
                    <button className="email-activity-close-button" onClick={() => { uncheckparentcheckbox() }}>X</button>
                </div>
                <ProgressLoader loading={loading} size='small' />
                <div className="word-wrap">
                <span className="word-wrap user-status">{`Office deactivation is preventing ${countforAction.length > 1 ? 'users' : 'user'} ${countforAction} activation. Please recheck your selection`}</span>
                </div>
            </Modal.Content>
            <Modal.Actions>            
                <Button color='blue' onClick={() => { uncheckparentcheckbox() }}  >OK</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default DeactivateModal;

