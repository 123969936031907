import React from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";
import BasicButton from '../../Common/Button'

interface SignModalOpenPageProps {
    signModalOpen: boolean;
    setSignModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    business_name: string;
    business_email: string;
    setMerchantMessage: (event: any) => Promise<void>;
    userMessage: string;
    setUserMessage: React.Dispatch<React.SetStateAction<string>>;
    handleSendApp: (event: any) => Promise<void>;
    loading: boolean;
    business_contact: string;
    config:any;
}
const SignModalOpenPage = (props: SignModalOpenPageProps) => {
    const { signModalOpen, setSignModalOpen, loading, business_name, business_contact, business_email, setMerchantMessage, userMessage, setUserMessage, handleSendApp ,config} = props
    return (
        <Modal
            centered
            open={signModalOpen}
            onClose={() => setSignModalOpen(false)}
            onOpen={() => setSignModalOpen(true)}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <ProgressLoader loading={loading} size="small" />
                <Modal.Description>
                    <div className="body-content min-height-0">
                        <div className="ui form center">
                            <div className="inline fields">
                                <div className="three wide field">
                                    <label>Merchant Name :</label>
                                </div>
                                <div className="eight wide field">
                                    <input
                                        type="text"
                                        className="form-input-grey"
                                        name="business_contact"
                                        value={business_contact}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="inline fields">
                                <div className="three wide field">
                                    <label>Merchant Email :</label>
                                </div>
                                <div className="eight wide field">
                                    <input
                                        type="text"
                                        className="form-input-grey"
                                        name="business_email"
                                        value={business_email}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="inline fields">
                            <div className="three wide field">
                                <label> DBA Name:  </label>
                            </div>
                            <div className="eight wide field">
                                <input
                                    type="text"
                                    className="form-input-grey"
                                    name="business_name"
                                    value={business_name}
                                    readOnly
                                />
                            </div>
                        </div>
                            <div className="inline fields">
                                <div className="three wide field">
                                    <label>Personal Message :</label>
                                </div>
                                <div className="eight wide field">
                                    <textarea
                                        name="personal_message"
                                        onChange={setMerchantMessage}
                                        value={userMessage}
                                        rows={5}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <BasicButton
                    textColor={config?.client_config_fe?.button_color}
                    backgroundColor={'#FFFFFF'}
                    size='18px'
                    buttonText='CANCEL'
                    className="save-button button-border"
                    onClick={() => {
                        setSignModalOpen(false);
                        setUserMessage('');
                    }}
                />
                <BasicButton
                    textColor={'#FFFFFF'}
                    backgroundColor={config?.client_config_fe?.button_color}
                    size='18px'
                    buttonText='SEND EMAIL'
                    className="save-button button-border"
                    onClick={(event:any) => handleSendApp(event)}
                />  
            </Modal.Actions>
        </Modal>
    )
}

export default SignModalOpenPage;