import React, { useState } from 'react';
import { Link } from "react-router-dom";

const HeaderMenuButton = (props: any) => {
    const { pageName, currentPage, pageDisplayName, config } = props
    const [hovered, setHovered] = useState(false);

    const handleMouseOver = () => {
        setHovered(true);
    };

    const handleMouseOut = () => {
        setHovered(false);
    };

    //const active = currentPage === pageName

    const getHeaderBtnColor = (active: boolean) => {
        return active
            ? config?.client_config_fe?.header_button_active_color
            : hovered
                ? config?.client_config_fe?.header_button_active_color
                : config?.client_config_fe?.header_button_bg_color === "none" ? "transparent" : config?.client_config_fe?.header_button_bg_color;
    }

    return (
            <Link
                to={`/${pageName}`}
                className={(currentPage === pageName) ? "active" : ""}
                style={{ backgroundColor: getHeaderBtnColor(currentPage === pageName) }}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                {pageDisplayName}
            </Link>
    );
};

export default HeaderMenuButton;