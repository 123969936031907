import React, { memo } from 'react'
import {
  Dropdown,
  Button,
  Radio,
  Checkbox,
  CheckboxProps,
  Icon,
  Modal,
  Confirm,
  Table
} from 'semantic-ui-react'
import { RISK_LEVELS, ROLES,BANK_CODES } from '../../../Utils/constants'

interface PreScreeningSectionProps {
  isRetail: boolean
  handleRetail: (data: any) => void
  isMailOrder: boolean
  handleMailOrder: (data: any) => void
  isEcommerce: boolean
  handleEcommerce: (data: any) => void
  businessCategoryConditionalOptions: {
    text: string;
    value: string;
  }[]
  businessCategory: string
  handleBusinessCategory: (e: any, data: any) => void
  businessTypeConditionalOptions: {
    text: string;
    value: string;
  }[]
  handleBusinessTypeChange: (e: any, data: any) => void
  businessCategoryFreeFormText: string
  setBusinessCategoryFreeFormText: React.Dispatch<React.SetStateAction<string>>
  businessType: string
  businessTypeFreeFormText: string
  setBusinessTypeFreeFormText: React.Dispatch<React.SetStateAction<string>>
  businessCategoryFreeFormErrorMessage: string
  businessTypeFreeFormErrorMessage: string
  role: string | null
  showRiskLevels: any
  riskLevel: string
  handleRiskLevel: (e: any, data: any) => void
  selectedSellTime: string
  handleChangeSellTime: (e: any, data: any) => void
  getLink: boolean
  showProcessors: any
  selectedBankOption: string
  handleChangeBankOption: (e: any, data: any) => void
  merchantToken: string
  showLowRiskProcessors: any
  showHighRiskProcessors: any
  isMobile: boolean
  isTab: boolean
  showCashDiscount:boolean
  disableSalesMethod:boolean
  cdBankOptionsForCD: string[]
}

const PreScreeningSection = (props: PreScreeningSectionProps) => {

  const {
    isRetail,
    handleRetail,
    isMailOrder,
    handleMailOrder,
    isEcommerce,
    handleEcommerce,
    businessCategoryConditionalOptions,
    businessCategory,
    handleBusinessCategory,
    businessTypeConditionalOptions,
    handleBusinessTypeChange,
    businessCategoryFreeFormText,
    setBusinessCategoryFreeFormText,
    businessType,
    businessTypeFreeFormText,
    setBusinessTypeFreeFormText,
    businessCategoryFreeFormErrorMessage,
    businessTypeFreeFormErrorMessage,
    role,
    showRiskLevels,
    riskLevel,
    handleRiskLevel,
    selectedSellTime,
    handleChangeSellTime,
    getLink,
    showLowRiskProcessors,
    showHighRiskProcessors,
    selectedBankOption,
    handleChangeBankOption,
    merchantToken,
    isMobile,
    isTab,
    showCashDiscount,
    disableSalesMethod,
    cdBankOptionsForCD
  } = props;

  const getAquiringBanks = () => {
    if(selectedSellTime === "Cash Discount"){
      return cdBankOptionsForCD
    }
    else{
      return riskLevel !== '' ? riskLevel === RISK_LEVELS.LOW_RISK ? showLowRiskProcessors.filter((banks: any) => !(banks.value === BANK_CODES.CFSB_CD || banks.value === BANK_CODES.ESQUIRE_CD)) : showHighRiskProcessors : []
    }
  }

  return (
    <>
      <div className='form-fields pre-screening-info'>
        <form name='' className='ui form'>
          <div className='inline fields flex-container mb-14 custom-prescreen-props'>
            <div className='five wide field'>
              <label>Business is Conducted<span className="required-text"> *</span></label>
            </div>
            <div className='four wide field'>
              <Checkbox
                label='Retail'
                name='Retail'
                value='Retail'
                checked={isRetail || false}
                onChange={(event, data: CheckboxProps) => {
                  handleRetail(data)
                }}
              />
            </div>
            <div className='four wide field'>
              <Checkbox
                label='Mail Order/Tel'
                name='Mail Order/Tel'
                value='Mail Order/Tel'
                checked={isMailOrder || false}
                onChange={(event, data: CheckboxProps) => {
                  handleMailOrder(data)
                }}
              />
            </div>
            <div className='four wide field'>
              <Checkbox
                label='E-Commerce'
                name='E-Commerce'
                value='E-Commerce'
                checked={isEcommerce || false}
                onChange={(event, data: CheckboxProps) => {
                  handleEcommerce(data)
                }}
              />
            </div>
          </div>
          <div className='fields MD-FDC'>
            <div className={`${isTab ? 'twelve' : 'eight'} wide field`}>
              <label className='d-none-sm'>Business Category<span className="required-text"> *</span></label>
              <Dropdown
                placeholder={!!isMobile ? 'Business Category' : 'Choose One'}
                name='business_category'
                className={ !businessCategory ? 'not-filled form-conditional' :'form-conditional'}
                fluid
                search
                selection
                options={businessCategoryConditionalOptions}
                value={businessCategory}
                onChange={handleBusinessCategory}
              />
            </div>
            <div className={`${isTab ? 'twelve' : 'eight'} wide field`}>
              <label className='d-none-sm'>Business Type<span className="required-text"> *</span></label>
              <Dropdown
                placeholder={!!isMobile ? 'Business Type' : 'Choose One'}
                name='business_type'
                className={ !businessType ? 'not-filled form-conditional' :'form-conditional'}
                fluid
                search
                selection
                options={businessTypeConditionalOptions}
                value={businessType}
                onChange={handleBusinessTypeChange}
              />
            </div>

          </div>
          <div className='fields'>
            {businessCategory.toLowerCase() === 'other' && (
              <div className='eight wide field'>
                <label>Business Category Free Form<span className="required-text"> *</span></label>
                <input
                  type='text'
                  name='business_category_free_form'
                  className={ !businessCategoryFreeFormText ? 'not-filled' :'form-input'}
                  placeholder=''
                  value={businessCategoryFreeFormText}
                  autoComplete="new-password"
                  onChange={(e) => setBusinessCategoryFreeFormText(e.target.value)}
                />
              </div>
            )}
            {(businessCategory.toLowerCase() === 'other' && businessType.toLowerCase() === 'other') && (
              <div className='eight wide field'>
                <label>Business Type Free Form<span className="required-text"> *</span></label>
                <input
                  type='text'
                  name='business_type_free_form'
                  className={ !businessTypeFreeFormText ? 'not-filled' :'form-input'}
                  placeholder=''
                  value={businessTypeFreeFormText}
                  autoComplete="new-password"
                  onChange={(e) => setBusinessTypeFreeFormText(e.target.value)}
                />
              </div>
            )}
          </div>
          {(businessCategoryFreeFormErrorMessage || businessTypeFreeFormErrorMessage) && (<div className='fields'>
            {businessCategoryFreeFormErrorMessage && (<div className='eight wide field'>
              <div className='warning-text'>
                {businessCategoryFreeFormErrorMessage}
              </div>
            </div>)}
            {!businessCategoryFreeFormErrorMessage && (<div className='eight wide field'>
              <div className='warning-text'>
              </div>
            </div>)}
            {businessTypeFreeFormErrorMessage && (<div className='eight wide fields'>
              <div className='warning-text' style={{ paddingLeft: "20px" }}>
                {businessTypeFreeFormErrorMessage}
              </div>
            </div>)}
          </div>)}
          {(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED) && (<div className='inline fields'>
            <div className='four wide field'>
              <label>Risk Level<span className="required-text"> *</span></label>
            </div>
            {showRiskLevels.length === 0 && role !== ROLES.PARTNER && role !== ROLES.UNDER_WRITER && <div className="eight wide field"><span style={{ color: '#80000d' }}> Select sales user to proceed*</span></div>}
            {showRiskLevels.includes("Low Risk") && <div className='four wide field'>
              <Radio
                label='Low Risk'
                name='radioGroup'
                value='Low Risk'
                checked={riskLevel === 'Low Risk'}
                onChange={handleRiskLevel}
              />
            </div>}
            {showRiskLevels.includes("High Risk") && <div className='four wide field'>
              <div className='radio_risk_level'>
              <Radio
                label='High Risk'
                name='radioGroup'
                value='High Risk'
                checked={riskLevel === 'High Risk'}
                onChange={handleRiskLevel}
              />
              </div>
            </div>}
          </div>
          )}
          <div className='inline fields'>
            <div className='method-of-sales wide field'>
              <label>Method of Sales <span className="required-text"> *</span></label>
            </div>
            <div className='four wide field'>
              <Radio
                label='One-Time Sale'
                name='selltime'
                value='One time'
                checked={selectedSellTime === 'One time'}
                onChange={handleChangeSellTime}
                disabled={!!disableSalesMethod}
              />
            </div>
            <div className='four wide field'>
              <Radio
                label='Recurring Billing'
                name='selltime'
                value='Recurring'
                checked={selectedSellTime === 'Recurring'}
                onChange={handleChangeSellTime}
                disabled={!!disableSalesMethod}
              />
            </div>
            <div className='three wide field'>
              <Radio
                label='Trial Offer'
                name='selltime'
                value='Trial'
                checked={selectedSellTime === 'Trial'}
                onChange={handleChangeSellTime}
                disabled={!!disableSalesMethod}
              />
            </div>
            {!!showCashDiscount &&(
            <div className='four wide field'>
              <Radio
                label='Cash Discount'
                name='selltime'
                value='Cash Discount'
                checked={selectedSellTime === 'Cash Discount'}
                onChange={handleChangeSellTime}
                disabled={!!disableSalesMethod}
              />
            </div>
            )}
          </div>
          <div className={getLink ? 'disableddiv fields' : 'fields'}>
            <div className={`${isTab ? 'twelve' : 'eight'} wide field`}>
              <label className='d-none-sm'>Acquiring Bank<span className="required-text"> *</span></label>
              <Dropdown
                placeholder={!!isMobile ? 'Acquiring Bank' : 'Choose One'}
                name='bank_name'
                className={ !selectedBankOption ? 'not-filled form-conditional' :'form-conditional'}
                fluid
                search
                selection
                options={getAquiringBanks()}
                value={selectedBankOption}
                onChange={handleChangeBankOption}
                disabled={merchantToken !== ''}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default memo(PreScreeningSection)