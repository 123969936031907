import React from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";

interface WarningModalProps {
    warningModalOpen: boolean;
    loading: boolean;
    handleCancel: () => void
    config?:any
}

const WarningModal = (props: WarningModalProps) => {
    const { warningModalOpen, handleCancel, loading, config } = props
    return (
        <Modal
            centered
            open={warningModalOpen}
            onClose={handleCancel}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <div className="close-button">
                    <button className="email-activity-close-button" onClick={handleCancel}>X</button>
                </div>
                <ProgressLoader loading={loading} size='small' />
                {`OfficeAdmin can't perform delete or edit operations on logged in office`}
            </Modal.Content>
            <Modal.Actions>
                <button
                    className="save-button button-border ipad-blue"
                    style={{ background: config?.client_config_fe?.button_color }}
                    onClick={handleCancel}>OK</button>
            </Modal.Actions>
        </Modal>
    )
}

export default WarningModal;
