import React from "react";
import { Table } from "semantic-ui-react";
import { ROLES } from "../../../Utils/constants";
const { REACT_APP_BACKEND_MYSQL } = process.env;

interface SendAppTableRowProps {
    partner_name: string;
    app_link_id: string;
    createdTS: string;
    app_link_status: string;
    updatedTS: string;
    no_of_clicks: any;
    no_of_apps: any;
    openViewLinkDetailsModal: (index: number, customData?: any) => void
    index: number;
    handleCopy: (tiny_url: string) => Promise<void>;
    link_name: string;
    tiny_url: any;
    handleEditButtonClick: (e: any, app_link_id: string, duplicated: boolean, duplicate_link_data?: any) => void
    handleActivate: (save_link_id: any, status: any) => Promise<void>;
    handleDuplicate: (e: any, save_link_id: any) => Promise<void>;
    handleDelete: (save_link_id: any, status: any) => Promise<void>;
    setDeleteAppLinkId: React.Dispatch<React.SetStateAction<string>>;
    setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    setCancelAlertOpen: React.Dispatch<React.SetStateAction<boolean>>;
    clearForm: () => void;
    role: string | null
    saveLinkId: string
    isEditMode: boolean
    isParentChecked : any
    changeCheckboxStatus : any
    user: any;
    selectedAppBulk: any[];
    app_link_code:string;
    processor_name:string
}
const SendAppTableRow = (props: SendAppTableRowProps) => {
    const { partner_name, app_link_id, createdTS, app_link_status, updatedTS, no_of_clicks, no_of_apps, openViewLinkDetailsModal, index, handleCopy
        ,link_name, tiny_url, handleEditButtonClick, handleActivate, handleDuplicate, setDeleteAppLinkId, setDeleteModalOpen,setIsEditMode,setCancelAlertOpen,clearForm, role,
        saveLinkId, isEditMode,isParentChecked, changeCheckboxStatus, user, selectedAppBulk ,app_link_code,processor_name} = props

    return (
        <Table.Row key={index} active={(isEditMode && (saveLinkId === app_link_id)) ? true : false}>
             {(role !== ROLES.VIEW_ONLY && role !== ROLES.SALES_LIMITED) && (
            <Table.Cell className='t-data checkbox-action'>
            <>
            <div className="parentcheckbox">
              <input type="checkbox" checked={user?.isChecked} value={app_link_id} onChange={(e) => changeCheckboxStatus(e, app_link_id)}
              />
            </div>
            </>
            </Table.Cell>
          )}
            <Table.Cell>{REACT_APP_BACKEND_MYSQL ?  app_link_code : app_link_id}</Table.Cell>
            <Table.Cell>{partner_name}</Table.Cell>
            <Table.Cell className="send-link-user-table-URL">
                {link_name}
            </Table.Cell>
            <Table.Cell>{processor_name}</Table.Cell>
            <Table.Cell className="send-link-user-table-URL">
                {tiny_url}
            </Table.Cell>
            <Table.Cell>{createdTS}</Table.Cell>
            <Table.Cell>{updatedTS}</Table.Cell>
            <Table.Cell>{app_link_status}</Table.Cell>
            <Table.Cell>{no_of_clicks}</Table.Cell>
            <Table.Cell>{no_of_apps}</Table.Cell>
            <Table.Cell style={{padding:"2px"}}>
              <div className={role === ROLES.VIEW_ONLY  || role === ROLES.SALES_LIMITED?"sendapp-actions":""}><span
                    className={
                        app_link_status === "inactive" || selectedAppBulk.length > 1
                            ? "not-active view-icon small-icon"
                            : "view-icon small-icon"
                    }
                    data-tooltip="View"
                    onClick={() => openViewLinkDetailsModal(index)}
                />
                <span
                    className={
                        app_link_status === "inactive" || selectedAppBulk.length > 1
                            ? "not-active duplicate-icon small-icon"
                            : "duplicate-icon small-icon"
                    }
                    data-tooltip="Copy"
                    onClick={() => handleCopy(tiny_url)}
                /></div>
               {role !== ROLES.VIEW_ONLY && role !== ROLES.SALES_LIMITED && <span
                    className={selectedAppBulk.length > 1 
                          ?"not-active withdraw-icon small-icon" 
                          : "withdraw-icon small-icon"}
                    data-tooltip={
                        app_link_status === "inactive" ? "Activate" : "Deactivate"
                    }
                    onClick={() => {
                        handleActivate(app_link_id, app_link_status)
                    }}
                />}
              {role !== ROLES.VIEW_ONLY && role !== ROLES.SALES_LIMITED &&  <span
                    className={
                        app_link_status === "inactive" || selectedAppBulk.length > 1
                            ? "not-active edit-icon small-icon"
                            : "edit-icon small-icon"
                    }
                    data-tooltip="Edit"
                    onClick={(e: any) => handleEditButtonClick(e, app_link_id, false)}
                />}
                {role !== ROLES.VIEW_ONLY && role !== ROLES.SALES_LIMITED && <span
                    className={
                        app_link_status === "inactive" || selectedAppBulk.length > 1
                            ? "not-active clone-icon small-icon"
                            : "clone-icon small-icon"
                    }
                    data-tooltip="Duplicate"
                    onClick={(e: any) => {
                        handleDuplicate(e, app_link_id)
                    }}
                />}
                {role !== ROLES.VIEW_ONLY && role !== ROLES.SALES_LIMITED && <span
                    className={
                        app_link_status === "inactive" || selectedAppBulk.length > 1
                            ? "not-active delete-icon small-icon"
                            : "delete-icon small-icon"
                    }
                    data-tooltip="Delete"
                    onClick={() => {
                      setDeleteAppLinkId(app_link_id)
                      setDeleteModalOpen(true)
                      setIsEditMode(false);
                      setCancelAlertOpen(false);
                    }}
                />}
            </Table.Cell>
        </Table.Row>
    )
}
export default SendAppTableRow;