import React from "react";
import { Checkbox, CheckboxProps, Table } from "semantic-ui-react";
import { ROLES, freeEquipCheckRole } from "../../../Utils/constants";
import { firstBy } from 'thenby'
import { getParsedList } from "../../../Utils/helpers";

const { REACT_APP_BACKEND_MYSQL } = process.env;

const office_id: any = sessionStorage.getItem('office_id')
let officeId: number = Number(office_id);

interface OfficeTableRowProps {
    office: any
    role: string | null
    index: any
    allProcessors: any[]
    handleEditOffice: (id: number) => void
    handleDeleteModal: (id: number) => void
    handlePaperAppsChecked: (data: any, user_id: string) => Promise<void>
    handleDeactivateModal: (office_id: string) => Promise<void>
    changeCheckboxStatus: (e: any, id: string) => Promise<void>
    selectedOfficeBulk: any[],
    handleFreeEquipmentPlacementChecked: (data: any, user_id: string) => Promise<void>
    config:any
}
const OfficeTableRow = (props: OfficeTableRowProps) => {

    const { office, index, role, handleDeleteModal, handleEditOffice, handlePaperAppsChecked, handleDeactivateModal, changeCheckboxStatus, selectedOfficeBulk, handleFreeEquipmentPlacementChecked,config } = props

    const bulkCheckbox = role

    let rmNames = office.allowed_RMs?.map((rm: any) => { return `${rm?.user_name?.first_name} ${rm?.user_name?.last_name}` })

    
    let low_risk_processors: any = getParsedList(office?.allowed_low_risk_processors)
    let high_risk_processors: any = getParsedList(office?.allowed_high_risk_processors)
    let allowed_risk_levels: any = getParsedList(office?.allowed_risk_levels)

    let warningModal=(office.office_id === officeId && role === ROLES.OFFICE_ADMIN)
    
    low_risk_processors.sort(
        firstBy(function (option: any) {
        return option?.toLowerCase();
        })
    )
    high_risk_processors.sort(
        firstBy(function (option: any) {
        return option?.toLowerCase();
        })
    )

    return (
        <Table.Row key={index} className={role === ROLES.OFFICE_ADMIN ? 't-row admin' : 't-row'}>
            {(bulkCheckbox) && <Table.Cell className='t-data checkbox-action'>
      <>
      <div className={bulkCheckbox? "parentcheckbox" : ""}>
        <input type="checkbox" checked={office.isChecked} value={office.office_id} className="bulk-check" style={{ background: config?.client_config_fe?.button_color }} onChange={(e) => changeCheckboxStatus(e, office.office_id)}
        />
      </div>
      </>
        </Table.Cell>}
            <Table.Cell className='t-data'>{REACT_APP_BACKEND_MYSQL ? office.office_code : office.office_id}</Table.Cell>
            <Table.Cell className='t-data'>{office.office_name}</Table.Cell>
            <Table.Cell className='t-data'>{office.parent_office_name}</Table.Cell>
            {/* <Table.Cell className='t-data'>{getParsedList(office.office_manager_names).join(', ')}</Table.Cell> */}
            {role !== ROLES.OFFICE_ADMIN && <Table.Cell>{getParsedList(office.office_admin_names).join(', ')}</Table.Cell>}
            <Table.Cell className='t-data'>{rmNames?.join(', ')}</Table.Cell>
            <Table.Cell className='t-data'>{low_risk_processors.join(', ')}</Table.Cell>
            <Table.Cell className='t-data'>{high_risk_processors.join(', ')}</Table.Cell>
            <Table.Cell className='t-data'>{allowed_risk_levels.join(', ')}</Table.Cell>
            <Table.Cell className='t-data'>
            <Checkbox
                    name="enablePaperApps"
                    checked={office.paper_apps_enabled || false}
                    onChange={(e, data: CheckboxProps) => {
                        handlePaperAppsChecked(data, office.office_id);
                    }}
                    disabled= {selectedOfficeBulk.length >1 || warningModal}
                />
            </Table.Cell>
            {role !== null && freeEquipCheckRole.includes(role as ROLES) && 
            <Table.Cell className='t-data'>
                <Checkbox
                    name="enableFreeEquipmentPlacement"
                    checked={office.free_equipment_placement_enabled || false}
                    onChange={(e, data: CheckboxProps) => {
                        handleFreeEquipmentPlacementChecked(data, office.office_id);
                    }}
                    disabled={warningModal}
                    />
            </Table.Cell>}
            <Table.Cell className='t-data'>{office.deactivate? "INACTIVE" : "ACTIVE"}</Table.Cell>
            <Table.Cell disabled={selectedOfficeBulk.length > 1 || warningModal} className='t-data'>
            <div className="action-items">
                    {(role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.SUPER_ADMIN || role === ROLES.OFFICE_MANAGER) && 
                    < span className={selectedOfficeBulk.length> 1 || warningModal? "deactivate-icon not-active-userList" : "deactivate-icon userList"} 
                    data-tooltip={office.deactivate ? "Activate" : "Deactivate"} 
                    onClick={() => handleDeactivateModal(office.office_id)}/>}
                    <span className={selectedOfficeBulk.length>1 || warningModal ? "edit-icon not-active-userList" : "edit-icon userList"} data-tooltip="Edit" onClick={() => handleEditOffice(office.office_id)}/>
                    < span className={selectedOfficeBulk.length>1 || warningModal? "delete-icon not-active-userList" : "delete-icon userList"} data-tooltip="Delete" onClick={() => handleDeleteModal(office.office_id)}/>
                </div>
            </Table.Cell>
        </Table.Row>
    )
}

export default OfficeTableRow;
