import React from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";
import BasicButton from '../../Common/Button';
interface EnableFeenavigatorProps {
  loading: boolean;
  selectedUser: any;
  openEnableFeenavigatorModal: boolean;
  handleCancel: () => void;
  updateFeenavigatorEnabledStatus: () => Promise<void>;
  feenavigatorEnabled: boolean;
  config?:any
}

const { REACT_APP_BACKEND_MYSQL } = process.env;

const EnableFeenavigatorModal = (props: EnableFeenavigatorProps) => {
  const {
    handleCancel,
    updateFeenavigatorEnabledStatus,
    openEnableFeenavigatorModal,
    selectedUser,
    feenavigatorEnabled,
    loading,
    config
  } = props;

  return (
    <Modal
      centered
      open={openEnableFeenavigatorModal}
      onClose={handleCancel}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <div className="close-button">
          <button
            className="email-activity-close-button"
            onClick={handleCancel}
          >
            X
          </button>
        </div>
        <ProgressLoader loading={loading} size="small" />
        <div className="word-wrap">
        {`Are you sure you want to ${
          feenavigatorEnabled ? "enable" : "disable"
        } Fee Navigator for ${REACT_APP_BACKEND_MYSQL ? selectedUser.user_name_first : selectedUser.user_name.first_name} ${REACT_APP_BACKEND_MYSQL ? selectedUser.user_name_last : selectedUser.user_name.last_name} ( ID - ${selectedUser.user_id} )?`}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <BasicButton
          textColor={config?.client_config_fe?.button_color}
          backgroundColor={'#FFFFFF'}
          size='18px'
          buttonText='CANCEL'
          className="save-button button-border"
          onClick={handleCancel}
        />
        <button
          className="save-button button-border ipad-blue"
          style={{ background: config?.client_config_fe?.button_color }}
          onClick={updateFeenavigatorEnabledStatus}>YES</button>
      </Modal.Actions>
    </Modal>
  );
};

export default EnableFeenavigatorModal;
