import React, { useEffect, useState } from "react";
import { Button, Modal, Select } from "antd";
import type { SelectProps } from "antd";
import { firstBy } from 'thenby';
import classNames from 'classnames';
import api from "../../../../Service/Api";
import backend from "../../../../Service/Backend";

interface SuperUserFormProps {
  data: any,
  config:any,
  successCallback: () => void,
}
const SuperUserForm = (props: SuperUserFormProps) => {
  const { successCallback, data, config } = props;
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedUser, setSelectedUser] = useState([]);

  const [clientsList, setClientsList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [formError, setFormError] = useState<boolean>(false)

  const roleOptions: SelectProps["options"] = [
    {
      label: "Relationship Manager",
      value: "RM",
    },
    {
      label: "Office Manager",
      value: "OFFICEMANAGER",
    },
  ];
  const countDown = () => {
    let secondsToGo = 5;

    const instance = Modal.success({
      title: 'Super User Access Added Successfully',
      content: `This notification will auto close after ${secondsToGo} seconds.`,
    });

    const timer = setInterval(() => {
      secondsToGo -= 1;
      instance.update({
        content: `This notification will auto close after ${secondsToGo} seconds.`,
      });
    }, 1000);

    setTimeout(() => {
      successCallback();
      clearInterval(timer);
      instance.destroy();
      resetForm()
    }, secondsToGo * 1000);
  };

  const getClientsList = async () => {
    setLoading(true);
    const data: any = {
      api: api.platformAdmin.getClients,
    };

    let clientsListData = await backend.fetch(data);

    const clientsListArray: any = [];
    clientsListData.filter((item: any) => {
      if (!item.deleted && item.client_code !== config.client_code) {
        clientsListArray.push({
          key: item.id,
          value: item.id,
          label: item.client_name,
        })
      }
    });
    clientsListArray.sort(firstBy((option: any) => option.label.toLowerCase()) )
    setClientsList(clientsListArray);

    if (clientsListData) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getClientsList();
  }, []);

  const getFilteredUsers = (users: any) => {
    let filteredUsers = users.filter((user: any) => user.role_code === selectedRole)
    filteredUsers.sort(firstBy((option: any) => option.label.toLowerCase()) )
     setUsersList(filteredUsers);
  }

  const handleChangeRole = (value: any) => {
    setSelectedRole(value);
  };
  const handleChangeClient = (value: any) => {
    setSelectedClient(value);
  };

  const handleChangeUser = (value: any) => {
    console.log(value)
    setSelectedUser(value);
  };
  const getUserList = async () => {
    setLoading(true);
    const data: any = {
      api: api.platformAdmin.getUsersList,
      payLoad: JSON.stringify({
        "destination_client_id": selectedClient,
      })
    };

    let usersListData = await backend.save(data);
    const usersListArray: any = [];

    usersListData.forEach((item: any) => {
      usersListArray.push({
        key: item.user_id,
        value: item.user_roles_id,
        label: `${item.user_name_first} ${item.user_name_last}`,
        role_code: item.role_code,
      })
    });
    getFilteredUsers(usersListArray);

    if (usersListData) {
      setLoading(false);
    }
  }
  useEffect(() => {
    if(!!selectedClient || !!selectedRole){
      getUserList();
    }
  }, [selectedClient, selectedRole]);

  const addSuperUserAccess = async () => {
    if (isFormValid()) {
      setFormError(false)
      setLoading(true);
      const data: any = {
        api: api.platformAdmin.addSuperUserAccess,
        payLoad: JSON.stringify({
          "user_role_ids": selectedUser,
          "client_id": selectedClient
        })
      };

      let response = await backend.save(data);
      if (response) {
        countDown();
        setLoading(false);
        successCallback()
      }
    } else {
      setFormError(true)
    }
  }

  const resetForm = () => {
    setSelectedRole(null);
    setSelectedClient(null);
    setSelectedUser([]);
  }

  const isFormValid = () => {
    if (
      selectedRole
      && selectedClient
      && selectedUser.length
    ) {
      return true;
    }

    return false;
  }

  return (
    <div className="onboarding-form">
      <div className="form-content-left">
        <p className="form-title">Enter below configuration details</p>
        <div className="form-content">
          <div className={classNames('form-field', {
            'error-field': formError && selectedClient === null
          })}>
            <p className="form-label">
              Select Client <sup>*</sup>
            </p>
            <Select
              style={{ width: "100%" }}
              placeholder="Select a Client"
              onChange={handleChangeClient}
              options={clientsList}
              value={selectedClient}
              optionLabelProp="label"
              filterOption={(input, option:any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </div>
          <div className={classNames('form-field', {
            'error-field': formError && selectedRole === null
          })}>
            <p className="form-label">
              Select Role<sup>*</sup>
            </p>
            <Select
              style={{ width: "100%" }}
              placeholder="Select a Role"
              onChange={handleChangeRole}
              options={roleOptions}
              value={selectedRole}
            />
          </div>
        </div>
      </div>
      <div className="form-content-right">
        <div className="form-content">
          <div className={classNames('form-field', {
            'error-field': formError && selectedUser.length === 0
          })}>
            <p className="form-label">
              Select User <sup>*</sup>
            </p>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select Users"
              onChange={handleChangeUser}
              options={usersList}
              value={selectedUser}
              optionLabelProp="label"
              filterOption={(input, option:any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </div>
        </div>
      </div>
      <div className="button-container">
        <Button onClick={() => addSuperUserAccess()}>Add As Super User</Button>
      </div>
    </div>
  );
};

export default SuperUserForm;
