import React, { useState, useEffect, useRef, useContext } from "react";
import { Table , Button, Input, Space , ConfigProvider, theme} from 'antd';
import { Footer } from '../../PageLayout/Footer';
import { Layout } from '../../PageLayout/Layout';
import api from '../../../Service/Api';
import backend from '../../../Service/Backend';
import './index.css';
import ProgressLoader from "../../Common/ProgressLoader";
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import type { ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import useCheckMobileScreen, { useCheckTabScreen } from "../../../hooks/useCheckMobileScreen";
import TableComponent from "../../TableComponent";
import { sleep, formatDate } from "../../../Utils/helpers";
import { AppContext } from "../../../context/appContext";

const APIIntegrations = (props: any) => {
    const { config }: any = useContext(AppContext);
    const role = sessionStorage.getItem('role')

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([] as any)
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    const [overlay, setOverlay] = useState({ style: { display: 'none' } })
    const [overlayMsg, setOverlaymsg] = useState('')

    useEffect(() => {
        const fetchApiIntegrationsData = async () => {
            try {
                setLoading(true)
                const data: any = {
                    api: api.integrations.getIntegrations,
                };
                let response: any = await backend.fetch(data);
                console.log('response', response)
                response = response.map((item: any) => {
                    return {
                        key: item.id,
                        id: item.id,
                        apiType: item.api_request_data.apiType.split('.')[0],
                        apiroute: item.api_request_data.apiroute,
                        status: item.status || "",
                        appID: item.merchant_app_code,
                        merchant_app_id: item.app_id,
                        createdTS: formatDate(item.created_ts),
                        updatedTS: formatDate(item.updated_ts),
                        retried: item.retried ? 'Retried' : '',
                    }
                });
                setData(response)
                setOverlaymsg("API Integrations Data fetched successfully!")
            } catch (err) {
                console.log('err', err)
            } finally {
                showOverlayForThreeSeconds()
                setLoading(false)
            }
        }

        fetchApiIntegrationsData()
    }, [config.selectedClientId])

    const showOverlayForThreeSeconds = async () => {
        const sstyle: any = { style: { display: 'block', position: 'fixed' } }
        setOverlay(sstyle)
        await sleep(1000)
        const hstyle: any = { style: { display: 'none' } }
        setOverlay(hstyle)
    }

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: any,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void,
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: any,) => {
        clearFilters();
        setSearchText('');
        confirm();
        setSearchedColumn(dataIndex);
    };

    const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block', color:config?.client_config_fe?.button_color,border:'1px solid' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined rev={undefined} />}
                        size="small"
                        style={{ width: 90 , background:config?.client_config_fe?.button_color}}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
                        size="small"
                        style={{ width: 90 ,color:config?.client_config_fe?.button_color,border:'1px solid'}}
                    >
                        Reset
                    </Button>
                    <Button
                        style={{ width: 90 ,color:config?.client_config_fe?.button_color,border:'1px solid'}}
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? config?.client_config_fe?.button_color : undefined }} rev={undefined} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffffff', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const retryIntegration = async (record: any) => {
        console.log('record', record)
        setLoading(true)
        try {
            const payload: any = {
                payLoad: JSON.stringify({
                    merchantAppID: record.merchant_app_id,
                    docID: record.id
                })
            }

            switch (record.apiroute) {
                case 'verify_customer':
                    payload.api = api.integrations.validateCustomerDetails
                    break;
                case "validate_bank_details":
                    payload.api = api.integrations.validateBankDetails
                    break;
                case "credit-report-xml":
                    payload.api = api.integrations.getCreditReportXML
                    break;
                case "credit-report-pdf":
                    payload.api = api.integrations.getCreditReportPDF
                    break;
                case "create_apptivo":
                    payload.api = api.integrations.createApptivo
                    break;
                default:
                    return;
            }
            console.log('payload', payload)
            if (!!payload.api) {
                await backend.save(payload);
                let updatedData = data?.map((item: any) => {
                    if (record.id === item.id) {
                        return { ...item, retried: 'Retried' }
                    } else {
                        return item
                    }
                })
                setData(updatedData)
            }
            setLoading(false)
        } catch (err: any) {
            console.log(err)
            showOverlayForThreeSeconds()
            setOverlaymsg(err.errMessage || 'Something went wrong. Please try again')
        } finally {
            setLoading(false)
        }

    }

    const columns: any = [
        {
            title: 'App ID',
            dataIndex: 'appID',
            key: 'appID',
            ...getColumnSearchProps('appID'),
        },
        {
            title: 'API Type',
            dataIndex: 'apiType',
            key: 'apiType',
            ...getColumnSearchProps('apiType'),
        },
        {
            title: 'API Route',
            dataIndex: 'apiroute',
            key: 'apiroute',
            ...getColumnSearchProps('apiroute'),
        },
        {
            title: 'Created Date',
            dataIndex: 'createdTS',
            key: 'createdTS',
            ...getColumnSearchProps('createdTS'),
        },
        {
            title: 'Updated Date',
            dataIndex: 'updatedTS',
            key: 'updatedTS',
            ...getColumnSearchProps('updatedTS'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            ...getColumnSearchProps('status'),
        },
        {
            title: 'Retry Status',
            dataIndex: 'retried',
            key: 'retried',
            ...getColumnSearchProps('retried'),
        },
        {
            title: "Actions",
            key: 'action',
            render: (record: any) => {
                return (
                    <>
                        {record.status !== 'failed' || record.retried ? <></> :
                            <Button type="link" onClick={() => { retryIntegration(record) }}>
                                Retry
                            </Button>
                        }
                    </>
                )
            }
        }
    ]

    const getTableKeys = (
        isMobile: boolean,
        isTablet: boolean,
        role: string | null
    ) => {
        let headerKeys: any = [];
        let rowKeys: any = [];
        if (isMobile) {
            headerKeys = [
                { label: "App ID", key: "appID" },
                { label: "API Type", key: "apiType" },
                { label: "Status", key: "status" },
            ];

            rowKeys = [
                { key: "apiroute", label: "API Route" },
                { key: "createdTS", label: "Created Date" },
                { key: "updatedTS", label: "Updated Date" },
                { key: "retried", label: "Retry Status" },
            ];
        } else if (isTablet) {
            if (window.innerWidth < window.innerHeight) {
                headerKeys = [
                    { label: "App ID", key: "appID" },
                    { label: "API Type", key: "apiType" },
                    { label: "Status", key: "status" },
                    { key: "apiroute", label: "API Route" },
                    { key: "createdTS", label: "Created Date" },
                    { key: "updatedTS", label: "Updated Date" },
                ];

                rowKeys = [{ key: "retried", label: "Retry Status" }];
            } else {
                headerKeys = [
                    { label: "App ID", key: "appID" },
                    { label: "API Type", key: "apiType" },
                    { label: "Status", key: "status" },
                    { key: "apiroute", label: "API Route" },
                    { key: "createdTS", label: "Created Date" },
                    { key: "updatedTS", label: "Updated Date" },
                ];

                rowKeys = [{ key: "retried", label: "Retry Status" }];
            }
        }

        return {
            headerKeys,
            rowKeys,
        };
    };

    const isMobile = useCheckMobileScreen();
    const isTablet = useCheckTabScreen();
    const tableKeys = getTableKeys(isMobile, isTablet, role);

    // logic for handling the retry option in mobile and ipad
    const handleReTry = (record: any) => {
        retryIntegration(record)
    }

    return (

        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        headerBg: config?.client_config_fe?.button_color,
                    },
                },
            }}
            >
            <Layout page="apiintegrations">
                {!(isMobile || isTablet) ? <> <div className={(window.innerWidth > 1640) ? 'body-content' : 'listapp-body-content'}>
                    <div className='heading flex-spacebetween'>
                        <span className='heading-text-left'>API INTEGRATIONS</span>

                    </div>
                    <Table
                        className="api-integrations"
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        rowClassName={(record:any, index:any) => index % 2 === 0 ? '' : 'antd-table-row-dark'}
                    />
                </div></> : <TableComponent
                    headerList={tableKeys.headerKeys}
                    rowKeys={tableKeys.rowKeys}
                    tableData={data}
                    heading={"API INTEGRATIONS"}
                    uniqueId={"id"}
                    className={'mobile-table-max'}
                    noAppMessage="NO DATA FOUND"
                    actionButton={false}
                    dynamicActions={(record: any) => {
                        return <Button
                            onClick={() => handleReTry(record)}
                            icon={<ReloadOutlined rev={undefined} />}
                            style={{ backgroundColor: 'transparent' }}
                            disabled={record.status !== 'failed' || record.retried}
                        />
                    }
                    }
                />}

                <ProgressLoader loading={loading} size='large' />
                <div className='overlay' style={overlay.style}>
                    <div className='overlay-image'>
                        <span className='overlay-text' style={{ color: config?.client_config_fe?.button_color }}>{overlayMsg}</span>
                    </div>
                </div>
                <Footer page='apiintegrations' />
            </Layout>
        </ConfigProvider>
    )
}

export default APIIntegrations
