import jwt from 'jsonwebtoken';

const get = (source: any, path: string, defaultValue: any) => {
  if (!!source && !!path) {
    const parts = path.split(".");
    const length = parts.length;
    let result = source;
    for (let i = 0; i < length; i++) {
      let item = result[parts[i]];
      if (item === null || item === undefined) {
        return item || defaultValue;
      }
      result = item;
    }
    return result;
  }
  return defaultValue;
};

const generatePassword = (length: number) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789@#$%{}[]()/\~<>'
  const charactersLength = characters.length
  for (let index = 0; index < length; index++) {
    result = result + characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const isEmptyObject = (obj: any) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

const getParsedList = (value: any) => {
  if (isEmptyObject(value)) {
    return []
  } else {
    if (Array.isArray(value)) {
      return value
    } else {
      // Replace single quotes with double quotes to make it valid JSON
      const array = !!value ? JSON.parse(value) : [];
      return array
    }
  }
}

const getDecodedToken = async (token:string) => {
  // Decode the JWT token
  const decoded = jwt.decode(token);
  if (decoded) {
    console.log('Decoded JWT:', decoded);
    return decoded;
  } else {
    console.error('Invalid JWT');
    return null;
  }
}

const formatDate = (date: any) => {
  if(!!date){
    var d = new Date(date)
    var month = '' + (d.getMonth() + 1)
    var day = '' + d.getDate()
    var year = d.getFullYear()
  
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
  
    return [month, day, year].join('-')
  }
  return ''
}

const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export { get, generatePassword, getParsedList, isEmptyObject, getDecodedToken, formatDate, sleep };
