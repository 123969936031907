import React from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";
import BasicButton from '../../Common/Button';

interface ActionModalConfirmationProps {
    loading: boolean
    selectedApp: any
    deactivateModalOpen: boolean
    handleCancel: () => void
    modalAction: any
    handleConfirmAction: any
    statusCount:any
    additionalCheckStatus:any
    config?:any
}

const { REACT_APP_BACKEND_MYSQL } = process.env;

const ActionModal = (props: ActionModalConfirmationProps) => {
    const { loading, deactivateModalOpen, handleCancel, selectedApp, modalAction ,handleConfirmAction, statusCount , additionalCheckStatus,config} = props
    return (
        <Modal
            centered
            size='tiny'
            open={deactivateModalOpen}
            onClose={handleCancel}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <div className="close-button">
                    <button className="email-activity-close-button" onClick={handleCancel}>X</button>
                </div>
                <ProgressLoader loading={loading} size='small' />
                <div className="word-wraps">
                    {additionalCheckStatus.length > 0 ?
                        <span className="word-wrap"> {`Couldn't perform ${modalAction} action for the following Apps : ${additionalCheckStatus.map((item: { app_id: any, app_code: any }) => { return (REACT_APP_BACKEND_MYSQL && item.app_code) ? item.app_code : item.app_id }).join(',') }. Please recheck your selection`}
                        </span>
                    :
                    statusCount === 0 ? 
                    <span className="word-wrap">{`Are you sure you want to ${modalAction === 'SEND TO UW' ? 'perform' : ''} ${modalAction} ${modalAction === 'SEND TO UW' || modalAction === 'Resend Link' ? 'for' : ''} ${selectedApp.length} ${selectedApp.length > 1 ? ' apps' : ' app'} ?`}</span> 
                    : 
                    <span className="word-wrap">{`${statusCount } ${statusCount > 1 ? 'apps' : 'app'} already in ${modalAction} state . Please recheck your selection`}</span>    
                    }            
                </div>
            </Modal.Content>
            <Modal.Actions>
            <BasicButton
                    textColor={config?.client_config_fe?.button_color}
                    backgroundColor={'#FFFFFF'}
                    size='18px'
                    buttonText='CANCEL'
                    className="save-button button-border"
                    onClick={handleCancel}
                />
                {statusCount === 0 && additionalCheckStatus.length === 0 && (
                    <button
                        className="save-button button-border ipad-blue"
                        style={{ background: config?.client_config_fe?.button_color }}
                        onClick={() => { handleConfirmAction(modalAction) }} >YES</button>

                )}
                </Modal.Actions>
        </Modal>
    )
}

export default ActionModal;
