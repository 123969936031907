// Auth Routes
export enum NON_AUTH_ROUTES {
  login = '/',
  startapp = '/startapp',
  listapp='/listapp',
  sendapp = '/createlink',
  merchantapp = '/merchantapp',
  docupload = '/docupload',
  docusign_code = '/auth/docusign/callback',
  docusign_return = '/ds-return',
  thankspage = '/thankspage',
  fieldlist='/fieldlist',
  users = '/users',
  offices = '/offices',
  audit = '/audit',
  updateSigningStatus='/update_signing_status',
  api_integrations = '/apiintegrations'
}

// Non Auth Routes
export enum AUTH_ROUTES {
  resetpassword='/resetpassword',
  otp = '/otp',
  unauthorised = '/unauthorised',
  profile='/profile',
  maintenanceMessage = '/',
  downtime= '/'
}
