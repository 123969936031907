import { AES, enc } from "crypto-js"
const { REACT_APP_FF_KEY = '' } = process.env

export const encrypt = (plainJson: any) => {
  return AES.encrypt(JSON.stringify(plainJson), REACT_APP_FF_KEY).toString();
}

export const decrypt = (encStr: string) => {
  const bytes = AES.decrypt(encStr, REACT_APP_FF_KEY);
  return JSON.parse(bytes.toString(enc.Utf8));
}