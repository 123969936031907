
import React from "react";
import { Button, Modal } from "semantic-ui-react";

interface ShowSaveLinkModalProps {
    showSaveLinkModal: boolean;
    linkName: string;
    handleChangeTextInput: (e: any) => false | undefined;
    isEditMode: boolean;
    handleSaveLinkApp: (event: any) => Promise<void>;
    handleUpdateLinkApp: (event: any) => Promise<void>;
    setShowSaveLinkModal: React.Dispatch<React.SetStateAction<boolean>>;
    config:any;
}
const ShowSaveLinkModal = (props: ShowSaveLinkModalProps) => {
    const {showSaveLinkModal, linkName, handleChangeTextInput, isEditMode, handleSaveLinkApp, handleUpdateLinkApp, setShowSaveLinkModal,config } = props

    return (
        <Modal
            centered
            open={showSaveLinkModal}
            onClose={() => setShowSaveLinkModal(false)}
            onOpen={() => setShowSaveLinkModal(true)}
            size="small"
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <Modal.Description>
                    <div className="eight wide field">
                        <label>Link Name</label>
                        <input
                            type="text"
                            name="link_name"
                            className="form-input margin-left-10 padding-left-5"
                            value={linkName}
                            onChange={handleChangeTextInput}
                        />
                    </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <button
                    className="save-button button-border"
                    style={{ background: config?.client_config_fe?.button_color }}
                    onClick={isEditMode ? handleUpdateLinkApp : handleSaveLinkApp}
                >
                    {isEditMode ? "UPDATE" : "SAVE"}
                </button>
                <Button onClick={() => setShowSaveLinkModal(false)}>CANCEL</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ShowSaveLinkModal;