import React from "react";
import { Button, Modal, Table } from "semantic-ui-react";
import TableComponent from "../../TableComponent";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
import { formatDate, get } from "../../../Utils/helpers";

const { REACT_APP_BACKEND_MYSQL } = process.env;

interface AuditOfficesDataModalProps {
    selectedOfficeData: any;
    auditOfficesDataModal: boolean;
    handleCancel: (refresh_data: boolean) => void
    createdTS: any;
    config:any
}

const AuditOfficesDataModal = (props: AuditOfficesDataModalProps) => {
    const {
        selectedOfficeData,
        auditOfficesDataModal,
        handleCancel,
        createdTS,
        config
    } = props
    const headerKeys = [
        { label: 'Field', key: 'field' },
        { label: 'After update', key: 'newV' },
        { label: 'Before update', key: 'oldV' },
    ];
    const tableMeta = [
        {
            field: 'Office ID',
            newV: REACT_APP_BACKEND_MYSQL ? get(selectedOfficeData, "new_data.office_code", "") : get(selectedOfficeData, "new_data.office_id", ""),
            oldV: REACT_APP_BACKEND_MYSQL ? get(selectedOfficeData, "old_data.office_code", "") : get(selectedOfficeData, "old_data.office_id", "")
        },
        {
            field: 'Office admin IDs',
            newV: get(selectedOfficeData, "new_data.office_admin_ids", []).join(", "),
            oldV: get(selectedOfficeData, "old_data.office_admin_ids", []).join(", ")
        },
        {
            field: 'Office manager IDs',
            newV: get(selectedOfficeData, "new_data.office_manager_ids", []).join(", "),
            oldV: get(selectedOfficeData, "old_data.office_manager_ids", []).join(", ")
        },
        {
            field: 'Office level',
            newV: get(selectedOfficeData, "new_data.office_level", ""),
            oldV: get(selectedOfficeData, "old_data.office_level", "")
        },
        {
            field: 'Office name',
            newV: get(selectedOfficeData, "new_data.office_name", ""),
            oldV: get(selectedOfficeData, "old_data.office_name", "")
        },
        {
            field: 'Status',
            newV: Boolean(get(selectedOfficeData, "new_data.deactivate", false)) ? "INACTIVE" : "ACTIVE",
            oldV: !!selectedOfficeData.old_data ? Boolean(get(selectedOfficeData, "old_data.deactivate", false)) ? "INACTIVE" : "ACTIVE" : ''
        },
        {
            field: 'Paper apps',
            newV: Boolean(get(selectedOfficeData, "new_data.paper_apps_enabled", false)) ? "ENABLED" : "DISABLED",
            oldV: !!selectedOfficeData.old_data ? Boolean(get(selectedOfficeData, "old_data.paper_apps_enabled", false)) ? "ENABLED" : "DISABLED" : ''
        },
        {
            field: 'Parent office id',
            newV: get(selectedOfficeData, "new_data.parent_office_id", ""),
            oldV: get(selectedOfficeData, "old_data.parent_office_id", "")
        },
        {
            field: 'Created Date',
            newV: REACT_APP_BACKEND_MYSQL ? formatDate(get(selectedOfficeData, "new_data.updated_ts", "")) : createdTS(get(selectedOfficeData, "new_data.created_ts._seconds", ""), get(selectedOfficeData, "new_data.created_ts._nanoseconds", "")),
            oldV: REACT_APP_BACKEND_MYSQL ? formatDate(get(selectedOfficeData, "old_data.updated_ts", "")) : createdTS(get(selectedOfficeData, "old_data.created_ts._seconds", ""), get(selectedOfficeData, "old_data.created_ts._nanoseconds", ""))
        },
        {
            field: 'High risk banks',
            newV: get(selectedOfficeData, "new_data.allowed_high_risk_processors", []).join(", "),
            oldV: get(selectedOfficeData, "old_data.allowed_high_risk_processors", []).join(", ")
        },
        {
            field: 'Low risk banks',
            newV: get(selectedOfficeData, "new_data.allowed_low_risk_processors", []).join(", "),
            oldV: get(selectedOfficeData, "old_data.allowed_low_risk_processors", []).join(", ")
        },
        {
            field: 'Risk levels',
            newV: get(selectedOfficeData, "new_data.allowed_risk_levels", []).join(", "),
            oldV: get(selectedOfficeData, "old_data.allowed_risk_levels", []).join(", ")
        },
        {
            field: 'RMs',
            newV: get(selectedOfficeData, "new_data.allowed_rm_ids", []).join(", "), 
            oldV: get(selectedOfficeData, "old_data.allowed_rm_ids", []).join(", ")
        }
    ];

    const MobileModel = () => (<TableComponent
        className="user-data-model-table"
        headerList={headerKeys}
        rowKeys={[]}
        tableData={tableMeta}
        hasAction={false}
        uniqueId={'objectID'}
        displayFooter={false} />);

    const isMobile = useCheckMobileScreen();
    if (isMobile) return <>
        <MobileModel />;
        <Button color='blue' onClick={() => handleCancel(false)}>
            Close
        </Button>
    </>
    return (<>
        <Modal
            closeIcon
            centered
            open={auditOfficesDataModal}
            onClose={() => handleCancel(false)}
            size="small"
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content scrolling>
                <Modal.Description>
                    {!isMobile && (<Table textAlign="center" celled striped structured>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>Fields</Table.HeaderCell>
                                <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>After Updating</Table.HeaderCell>
                                <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>Before Updating</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {tableMeta.map((t: any) => (<Table.Row>
                                <Table.Cell>{t.field}</Table.Cell>
                                <Table.Cell>{t.newV}</Table.Cell>
                                <Table.Cell>{t.oldV}</Table.Cell>
                            </Table.Row>))}
                        </Table.Body>
                    </Table>)}
                    {isMobile && <MobileModel />}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='blue' style={{ background: config?.client_config_fe?.button_color }} onClick={() => handleCancel(false)}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    </>)
}
export default AuditOfficesDataModal;
