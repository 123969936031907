import React, { useEffect, useState } from "react";
import { MoreOutlined } from "@ant-design/icons";
import "./index.css";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import { useCheckTabScreen } from "../../hooks/useCheckMobileScreen";
import { ROLES } from '../../Utils/constants'

interface HeaderObject {
  label: string;
  key: string;
}

interface rowKeysObject {
  key: string;
  label: string;
  type?: "checkbox" | string;
  default?: boolean;
  display?: boolean;
  change?: (data: any, user_id: string) => Promise<void>;
  checkProperty?: string | any;
  name?: string;
}

interface tableComponentProps {
  hasAction?: boolean;
  tableData: any;
  uniqueId: string | number;
  headerList: HeaderObject[];
  rowKeys: rowKeysObject[];
  saveLinkId?: string
  isEditMode?: boolean
  heading?: string | any;
  noAppMessage?: string;
  overrideActions?: boolean;
  displayFooter?: boolean;
  className?: string;
  loading?: boolean;
  actionButton?: React.ReactNode;
  dynamicActions?: (record: any) => React.ReactNode;
  changeCheckboxStatus?: (e: any, id: string) => void,
  isParentChecked?: boolean
  role?: any,
  bulkId?: string,
  viewOptions? : boolean,
  selectedBulkCount? : any
}

// custom table especially for mobile view
const TableComponent = (props: tableComponentProps) => {
  let {
    hasAction = true,
    tableData: tableDataList,
    uniqueId = "",
    headerList,
    rowKeys,
    loading = false,
    heading = false,
    noAppMessage = false,
    overrideActions = false,
    className = "",
    actionButton = undefined,
    dynamicActions,
    saveLinkId, 
    isEditMode,
    changeCheckboxStatus,
    isParentChecked,
    role,
    bulkId,
    viewOptions,
    selectedBulkCount
  } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const srch = urlParams.get('searchTerm')?.toLowerCase();
  if (srch) {
    const headerKeyValues = headerList.map(x => x.key);
    const rowKeyValues = rowKeys.map(x=>x.key);
    const recordHasString = (rec:any) => {
      let str='#';
      [...headerKeyValues,...rowKeyValues].forEach( k => str = `${str}#${rec[k] || ''}`);
      return str.toLowerCase().indexOf(srch) >=0;
    }
    tableDataList = tableDataList.filter(recordHasString);
  }
  // state which holds unique id for selection
  const [selectedRowId, setSelectedRowId] = useState("" as any);
  const [actionOpened, setactionOpened] = useState<Boolean>(false);
  const [actionRecordSelected, setactionRecordSelected] = useState<String>();

  const isTablet = useCheckTabScreen();

  useEffect(()=>{
      if(actionOpened){
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    return () => {
      if(document.body.style.overflow && document.body.style?.overflow == 'hidden'){
        document.body.style.removeProperty('overflow');
      }
    }
  },[actionOpened])

  // when the user clicks perticular row, then this function returns the relevent details
  const renderDetails = (user: any) => {
    // all these things returns details of the table row
    return (
      <tr className="row-details-table">
        <td colSpan={isTablet ? 3 : headerList.length + 1}>
          {rowKeys.map((row) => {
            return (
              <>
                {!row.display ? (
                  <div>
                    <span>{row.label}</span>
                    <span
                      className={row.label === "Link URL" ? "link_url" : ""}
                    >
                      {!!row.type && row.type === "checkbox" ? (
                        <>
                          <Checkbox
                            label=""
                            name={row.name}
                            disabled={selectedBulkCount > 1}
                            checked={
                              row.checkProperty
                                ? !!user[row.checkProperty]
                                : false
                            }
                            onChange={(e, data: CheckboxProps) => {
                              if (row.change) row.change(data, user[uniqueId]);
                            }}
                          />
                        </>
                      ) : (
                        user[row.key]
                      )}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </td>
      </tr>
    );
  };

  // handling selection while selecting row
  const handleSelect = (user: any) => {
    console.log(user, user[uniqueId], selectedRowId);
    if (user[uniqueId] === selectedRowId) {
      setSelectedRowId("");
    } else {
      setSelectedRowId(user[uniqueId]);
    }
  };

  const handleSelectAction = (user: any) => {
    const sameRecord = user[props.uniqueId] === actionRecordSelected;
    if (!sameRecord) {
      setactionRecordSelected(user[props.uniqueId]);
      if (!actionOpened) {
        setactionOpened(true);
      }
    } else {
      if (!actionRecordSelected) setactionRecordSelected(user[props.uniqueId]);
      else setactionRecordSelected("");
      setactionOpened(!actionOpened);
    }
    if (user.onClickActions) user.onClickActions();
  };

  const getBulkId = (user:any, id:any) => {
    const idMappings = [
      { key: "user_email", value: user.user_email },
      { key: "app_id", value: user.merchant_app_id },
      { key: "link_id", value: user.app_link_id },
      { key: "office_id", value: user.office_id }
    ];
  
    const mapping = idMappings.find(mapping => mapping.key === id) || { value: null };
  
    return mapping.value;
  };
  // rendering the rows of the table
  const renderRow = () => {
    return tableDataList.map((user: any) => {
      return (
        <>
          <tr
            id={
              !!actionRecordSelected && user[uniqueId] === actionRecordSelected
                ? "highlight"
                : ""
            }
            onClick={() => handleSelect(user)}
            className= {(isEditMode && (saveLinkId === user.app_link_id)) ? "active-link" : ""}
          >
          {( viewOptions && isParentChecked!== null ) && (
          <td><div className="parentcheckbox">
            <input type="checkbox" checked={user?.isChecked} value={bulkId === "user_email"? user.user_email : user.office_id} onClick={e => e.stopPropagation()}
            onChange={(e) =>changeCheckboxStatus ? changeCheckboxStatus(e, getBulkId(user, bulkId)) : ""}
             />
         </div></td>
           )}
        {headerList.map((header) => {
              return <td>{user[header.key]}</td>;
            })}
            {hasAction && (
              <td onClick={(e) => e.stopPropagation()}>
                {actionButton ? <div onClick={(e) => handleSelectAction(user)}>{actionButton}</div> : dynamicActions ? <>
                  {dynamicActions(user)}
                </> : (
                <MoreOutlined
                  id={
                    !!actionRecordSelected &&
                    user[uniqueId] === actionRecordSelected
                      ? ""
                      : ""
                  }
                  onClick={(e) => handleSelectAction(user)}
                  className="more-action-button"
                  rev={undefined}
                />)}
              </td>
            )}
          </tr>
          {user[uniqueId] && user[uniqueId] === selectedRowId ? (
            renderDetails(user)
          ) : (
            <></>
          )}
        </>
      );
    });
  };
  const populateActions = () => {
    const selectedApp = props.tableData.find(
      (app: any) => app[props.uniqueId] === actionRecordSelected
    );
    const actions = selectedApp?.uiActions || [];
    return (
      <div
        className="table-actions-wrapper"
        onClick={() => {
          setactionRecordSelected("");
          setactionOpened(false);
        }}
      >
        <div className="table-actions-mob">
          {actions.map(
            (act: any, index: number) =>
              (act.display === undefined || !!act.display) && (
                <button
                  disabled={
                    !!act.className
                      .split(" ")
                      .find((x: any) => x === "not-active")
                  }
                  className={
                    (!!act.className
                      .split(" ")
                      .find((x: any) => x === "not-active")
                      ? "app-not-active"
                      : "")+
                    (index === 0 ? " top-radius" : "")+
                    (index === actions.length - 1 ? " bottom-radius" : "")
                  }
                  onClick={() => {
                    act.action();
                    setactionRecordSelected("");
                    setactionOpened(false);
                  }}
                >
                  {act.label}
                </button>
              )
          )}
        </div>
      </div>
    );
  };

  const renderMessage = () => {
    if (srch) {
      return <div className="mobile-table-list">No Data Found</div>
    }
    return <div className="mobile-table-list">{noAppMessage}</div>;
  };


  return (
    <>
      <div className={"mobile-table-componnet " + className}>
        {heading ? (
          <div className="table-heading-container">
            {heading && typeof heading === "string" ? (
              <h3>
                <b>{heading}</b>
              </h3>
            ) : (
              heading
            )}
          </div>
        ) : (
          <></>
        )}
        {!loading &&
        tableDataList &&
        tableDataList.length === 0 &&
        noAppMessage ? (
          renderMessage()
        ) : (
          !loading && tableDataList && tableDataList.length ? <table className="mobile-table">
          <thead>
            <tr>
            {( viewOptions && isParentChecked!== null ) && (
              <th className="parentcheckbox headercheckbox"><input
                  type="checkbox"
                  value={"parent"}
                  onChange={(e) =>changeCheckboxStatus ? changeCheckboxStatus(e, "p1") : ""}
                  checked={isParentChecked}
                /></th>
            )}

              {headerList.map((head) => (
                <th>{head.label}</th>
              ))}
              {hasAction && (isTablet ? <th>Actions</th> : <th />)}
            </tr>
          </thead>
          <tbody
            onClick={(e) => {
              if (actionOpened) {
                setactionOpened(false);
                setactionRecordSelected("");
              }
              e.stopPropagation();
            }}
          >
            {!loading && tableDataList && tableDataList.length ? (
              renderRow()
            ) : (
              <></>
            )}
          </tbody>
        </table> : !loading && <div className='body-content'>
            <div className='heading'>
                <span className='heading-center'>No Data Found!!</span>
            </div>
        </div>
        )}
        {actionOpened && !overrideActions && populateActions()}
      </div>
    </>
  );
};

export default TableComponent;
