import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { AppstoreOutlined, PlayCircleOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps['items'] = [
  // getItem('Dashboard', '/platformadmin', <AppstoreOutlined rev={undefined} />, [
  //   getItem('Menu 1', '/platformadmin'),
  // ]),

  getItem('Client Onboarding', '/platformadmin/onboarding', <PlayCircleOutlined rev={undefined} />, [
    getItem('Add/Edit Client', '/platformadmin/onboarding'),
  ]),

  { type: 'divider' },

  // getItem('Super User', '/platformadmin/superuser', <UsergroupAddOutlined rev={undefined} />, [
  //   getItem('Add/Edit Super User', '/platformadmin/superuser'),
  // ]),
];

const PlatformAdminSidebar :React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const onClick: MenuProps['onClick'] = (e) => {
    history.push(e.keyPath[1]);
  };

  return (
    <div className="platform-admin-sidebar">
      <Menu
        onClick={onClick}
        style={{ width: "220px" }}
        defaultSelectedKeys={[location.pathname]}
        defaultOpenKeys={[location.pathname]}
        mode="inline"
        items={items}
      />
    </div>
  )
}
export default PlatformAdminSidebar
