import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import api from "../../../Service/Api";
import backend from "../../../Service/Backend";
import ProgressLoader from "../../Common/ProgressLoader";
import "./Value.css";

const { REACT_APP_DEFAULT_CLIENT_ID } = process.env;

interface valueProps {
  value: any;
  handleDelete: (deleteValue: string) => Promise<void>;
  handleupdate: (currentvalue: string, updatedvalue: string) => Promise<void>;
  isEquipmentSelected:boolean;
  isUpdate:boolean
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>
  selectedEquipmentType:any;
  selectedClientId: string
}

const Value = (props: valueProps) => {
  const { 
    isUpdate,
    setIsUpdate,
    selectedEquipmentType,
    selectedClientId
  }= props;
  const [valueState, setValueState] = useState(props.value);
  const [loading, setLoading] = useState(false);
  const [IsEditable, SetIsEditable] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [productPricesUpdateValidation, setProductPricesUpdateValidation] = useState("");

  const handleEdit = () => {
    setProductPricesUpdateValidation("")
    SetIsEditable(true);
    setValueState(props.value);
    setIsUpdate(true);
  };

  const handleChange = (data: string) => {
    if(props.isEquipmentSelected){
      setValueState(data);
    }else{
      setValueState(data.toUpperCase());
    }
  };

  const handleCancel = () => {
    setValueState(props.value);
    SetIsEditable(false);
    setProductPricesUpdateValidation("")
  };
  const handleDelete = async () => {
    setProductPricesUpdateValidation("")
    setDeleteModalOpen(false);
    await props.handleDelete(props.value);
  };

  const handleupdateValue = async () => {
    if(valueState){
      let addStatus= true
      if(selectedEquipmentType === 'Physical Terminal'){
        var regexTest = /(?=.)\$(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(\.[0-9]{1,2})?/;
        if(valueState.split("__$")[1] && regexTest.test(valueState)){
          addStatus= true
        }else{
           addStatus= false
           setProductPricesUpdateValidation("Product name should end with product price and need to be in following format Ex: MAGTEK Mini Micr__$258.00")
        }
      }
      else{
         addStatus= true
      }
      if(addStatus){
        await props.handleupdate(props.value, valueState);
        SetIsEditable(false);
        setProductPricesUpdateValidation("")
      }
    }
  };
 
  return (
    <div>
      <ProgressLoader loading={loading} size="large" />
      <div className="Value-box new-box">
        <div className="fill-width">
          {(!IsEditable) || (IsEditable && !isUpdate)? (
            <p key={props.value} className="value">
              {props.value}
            </p>
          ) : (
            <input
              type="text"
              value={valueState}
              className="popup-input"
              onChange={(e) => handleChange(e.target.value)}
            ></input>
          )}
        </div>
        {REACT_APP_DEFAULT_CLIENT_ID == selectedClientId && <div className="actions-value-box">
          {(!IsEditable) || (IsEditable && !isUpdate) ? (
            <div className="actions-value">
              <p className="value-edit" onClick={handleEdit}>
                EDIT
              </p>
              <p
                className="value-delete"
                onClick={() => setDeleteModalOpen(true)}
              >
                DELETE
              </p>
            </div>
          ) : (
            <><div className="actions-value">
            <p className="value-update" onClick={handleupdateValue}>
              UPDATE
            </p>
           <p className="value-cancel" onClick={handleCancel}>
              CANCEL
            </p>
          </div>
          </>
          )}
        </div>}
      </div>
      {productPricesUpdateValidation && isUpdate && selectedEquipmentType === 'Physical Terminal' &&  (
          <div className="Value-box">
              <div className="price-validation warning-text">{productPricesUpdateValidation}</div>
          </div>
       )}
      {deleteModalOpen && (
        <Modal
          centered
          size="mini"
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onOpen={() => setDeleteModalOpen(true)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <div className="close-button">
              <button className="email-activity-close-button" onClick={() => setDeleteModalOpen(false) }>X</button>
            </div>
            <ProgressLoader loading={loading} size="small" />
            {`Are you sure you want to delete ${props.value}?`}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setDeleteModalOpen(false)} >
              CANCEL
            </Button>
            <Button onClick={handleDelete} color="red">
              DELETE
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </div>
    
  );
};

export default Value;
