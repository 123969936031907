import React from "react";
import { Button, Modal } from "semantic-ui-react";

interface SearchResultModalProps {
    noSearchResultModal: boolean
    searchText: string
    handleCancel: () => void
    config?:any
}
const SearchResultModal = (props: SearchResultModalProps) => {

    const { handleCancel, noSearchResultModal, searchText ,config} = props

    return (
        <Modal
            size='mini'
            closeOnDimmerClick={false}
            open={noSearchResultModal}
            onClose={handleCancel}
        >
            <Modal.Header>
                <div className="email-activity-header">
                    <h3> Search Result</h3>
                    <button className="email-activity-close-button" onClick={handleCancel}>X</button>
                </div>
            </Modal.Header>
            <Modal.Content>
                <div className="word-wrap">
                    <p>No data found with the search text <b>{searchText}</b></p>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button  style={{ background: config?.client_config_fe?.button_color, color:'#FFFFFF' }} onClick={handleCancel}>
                    OK
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default SearchResultModal;
