import React, { memo } from "react";    
import './index.css';

const MaintenanceMessage = (props: any) => {

    return (
        <body>
        <div className="maintenancePage">
            <div className="maintenance-content">
                <h1>We'll Be back soon!</h1>
                <p className="errorMessage">We're very sorry for the inconvenience but we're performing maintenance. Please check back soon.</p>
                <p className="errorMessage">- Trinity Payment Solutions</p>
            </div>
        </div>
        </body>
    )
};

export default memo(MaintenanceMessage);