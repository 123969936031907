import React, { useState, useEffect, useContext } from "react";
import IdleTimer from 'react-idle-timer';
import { useHistory, useLocation } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";
import { auth } from "../../Firebase";
import { AppContext } from "../../context/appContext";

const SessionHandler = () => {
    const { config, setConfig }: any = useContext(AppContext);
    //Session time should be in minutes 
    const REACT_APP_SESSION_TIME = 5; 

    const [modalOpen, setModalOpen] = useState(false);
    const [timer, setTimer] = useState(false); 
    const [counterFunction, setCounterFun] = useState({
        counter: 0
    } as any); 
    const [modal, setModal] = useState({
        header: "You Have Been Idle!",
        msg: `You are about to be logged out in 60 seconds due to inactivity. Are you still here?`,
        actions: [
            {
                name: "Yes",
                color: "green",
                onClick: () => resetTimer()
            } 
        ]
    });
    const location = useLocation();
    const history = useHistory();
    
    useEffect(() => { 
        counterFunction.counter = 0;
        if (timer) {
            const timerInterval = setInterval(() => {
                counterFunction.counter++;
                if (counterFunction.counter === 60) {
                    clearInterval(timerInterval);
                    signOut(); 
                    setModal({
                        header: "Session Timeout",
                        msg: `You are logged out due to inactivity for more than ${REACT_APP_SESSION_TIME} minutes`,
                        actions: [
                            {
                                name: "CLOSE",
                                color: "red",
                                onClick: () => {
                                    setTimer(false);
                                    setModalOpen(false)
                                }
                            }
                        ]
                    })
                    counterFunction.counter = 0;
                } else {
                    setModal({
                        header: "You Have Been Idle!",
                        msg: `You are about to be logged out in ${60 - counterFunction.counter} seconds due to inactivity. Are you still here?`,
                        actions: [
                            {
                                name: "Yes",
                                color: "green",
                                onClick: () => resetTimer()
                            } 
                        ]
                    })
                }
            }, 1000);
            counterFunction.timerInterval = timerInterval;
            
        } else {
            const {timerInterval} = counterFunction;
            if(!!timerInterval) {
                clearInterval(timerInterval);
                counterFunction.timerInterval = null;
                counterFunction.counter = 0; 
            }
        }
    }, [counterFunction, modalOpen, timer]);

    const resetTimer = () => {
        clearInterval(counterFunction.timerInterval);
        counterFunction.timerInterval = null;
        counterFunction.counter = 0; 
        setTimer(false);
        setModalOpen(false);
    }

    const signOut = async () => {
        if(auth.isAuthenticated()) {
            try {
                await auth.doSignOut();
            } catch (err: any) {
                console.log(err);
            } finally {
                const clientId = sessionStorage.getItem("clientId")
                sessionStorage.clear();
                if(!!clientId){
                    sessionStorage.setItem("clientId", clientId)
                }
                localStorage.clear();
                setConfig({
                    ...config,
                    accessible_clients: [],
                    user_roles_id : null,
                    selectedClientId: config?.id,
                    client_config_fe: config.default_config_fe || config.client_config_fe
                  })
                history.push("/");
                setModalOpen(true);
            }
        }
    } 

    const onIdle = async (event: any) => {
        if (!timer && !!auth.isAuthenticated()) { 
            setModalOpen(true);
            setTimer(true);
        } 
    }; 
    return (
        <div> 
            <Modal
                open={modalOpen}
                size={"small"}
                onClick={()=> setModalOpen(false)}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                className="modal-content"
            >
                <Modal.Header>
                    {/* Session Timeout */}
                    {/* You Have Been Idle! */}
                    {modal.header}
                </Modal.Header>
                <Modal.Content>   
                    {/* You are logged out due to inactivity for more than {REACT_APP_SESSION_TIME} minutes */}
                    {/* You are about to be logged out due to inactivity. Are you still here? */}
                    {modal.msg}
                </Modal.Content> 
                <Modal.Actions>
                    {modal.actions.map((btn:any, i) => (
                        <Button key={i} color={btn.color}  onClick={btn.onClick} >
                            {btn.name} 
                        </Button>
                    ))}
                    
                </Modal.Actions>
            </Modal>
            <IdleTimer
                element={document}
                onIdle={onIdle}
                timeout={1000 * 60 * Number(REACT_APP_SESSION_TIME)}
            />
        </div>
      );

}

export default SessionHandler;
