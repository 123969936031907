import React from "react";
import SendAppTableRow from "./SendAppTableRow";
const { REACT_APP_BACKEND_MYSQL } = process.env;

interface SendAppTableBodyProps {
    sortAndSearchResult: (data: any) => any;
    sendLinkUsers: any[];
    openViewLinkDetailsModal: (index: number, customData?: any) => void
    handleCopy: (tiny_url: string) => Promise<void>;
    handleEditButtonClick: (e: any, app_link_id: string, duplicated: boolean, duplicate_link_data?: any) => void
    handleActivate: (save_link_id: any, status: any) => Promise<void>;
    handleDuplicate: (e: any, save_link_id: any) => Promise<void>;
    handleDelete: (save_link_id: any, status: any) => Promise<void>;
    clearForm: () => void;
    formatDate: (date: any) => string
    setDeleteAppLinkId: React.Dispatch<React.SetStateAction<string>>;
    setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    setCancelAlertOpen: React.Dispatch<React.SetStateAction<boolean>>;
    role: string | null
    saveLinkId: string
    isEditMode: boolean
    isParentChecked : any
    changeCheckboxStatus : any
    selectedAppBulk: any[]
}

const SendAppTableBody = (props: SendAppTableBodyProps) => {
    const { sortAndSearchResult, sendLinkUsers,openViewLinkDetailsModal, handleCopy, handleEditButtonClick, handleActivate, handleDuplicate,
        handleDelete, formatDate,clearForm,setDeleteAppLinkId,setDeleteModalOpen,setIsEditMode,setCancelAlertOpen, role,
        saveLinkId, isEditMode,isParentChecked, changeCheckboxStatus, selectedAppBulk} = props

    return (
        sortAndSearchResult(sendLinkUsers)
            .map((user: any, index: number) => {
                const createdTS = REACT_APP_BACKEND_MYSQL ? formatDate(new Date(user.created_ts)) : formatDate(new Date(user.created_ts._seconds * 1000 + user.created_ts._nanoseconds / 1000000).toDateString());
                const updatedTS = REACT_APP_BACKEND_MYSQL ? formatDate(new Date(user.updated_ts)) : formatDate(new Date(user?.updated_ts?._seconds * 1000 + user?.updated_ts?._nanoseconds / 1000000).toDateString());
                
                return (
                    <><SendAppTableRow
                        partner_name={user.partner_name}
                        app_link_id={user.app_link_id}
                        createdTS={createdTS}
                        app_link_status={user.app_link_status}
                        updatedTS={updatedTS}
                        no_of_clicks={user.no_of_clicks}
                        no_of_apps={user.no_of_apps}
                        openViewLinkDetailsModal={openViewLinkDetailsModal}
                        index={index}
                        handleCopy={handleCopy}
                        link_name={user.link_name}
                        tiny_url={user.tiny_url}
                        handleEditButtonClick={handleEditButtonClick}
                        handleActivate={handleActivate}
                        handleDuplicate={handleDuplicate}
                        handleDelete={handleDelete}
                        setDeleteAppLinkId={setDeleteAppLinkId}
                        setDeleteModalOpen={setDeleteModalOpen}
                        setIsEditMode={setIsEditMode}
                        setCancelAlertOpen={setCancelAlertOpen}
                        clearForm={clearForm}
                        role={role}
                        saveLinkId={saveLinkId}
                        isEditMode={isEditMode}   
                        isParentChecked = {isParentChecked}
                        changeCheckboxStatus = {changeCheckboxStatus}  
                        user ={user}
                        selectedAppBulk={selectedAppBulk}
                        app_link_code = {user.app_link_code}
                        processor_name = {user.merchant_prescreen.processor_name}
                    />
                    </>
                )
            }))
}

export default SendAppTableBody;