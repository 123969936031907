import React, { memo, useState } from 'react'
import { ROLES,RISK_LEVELS } from '../../../Utils/constants'

interface BankDetailsSectionProps {
  bankRoutingNo: string
  bankInfoAPI: () => Promise<void>
  allowOnlyNumber: (e: any) => void
  setBankRoutingNo: React.Dispatch<React.SetStateAction<string>>
  bankName: string
  setBankName: React.Dispatch<React.SetStateAction<string>>
  updateBankSectionStatus: () => void
  role: string | null
  bankAccountNo: string
  handleChangeTextInput: (e: any) => false | undefined
  handleCopy: (e: any) => void
  handlePaste: (e: any) => boolean
  confirmBankAccountNo: string
  handleConfirmBankAccountNo: (event: any) => void
  bankPhone: string
  bankRoutingNumberErrMessage: string
  errorMessage1: string
  alertCopyMsg: string
  successMessage1: string
  isMobile: boolean
  riskLevel:string
  errorMessage2:string
}


const BankDetailsSection = (props: BankDetailsSectionProps) => {
  const {
    bankRoutingNo,
    bankInfoAPI,
    allowOnlyNumber,
    setBankRoutingNo,
    bankName,
    setBankName,
    updateBankSectionStatus,
    role,
    bankAccountNo,
    handleChangeTextInput,
    handleCopy,
    handlePaste,
    confirmBankAccountNo,
    handleConfirmBankAccountNo,
    bankPhone,
    bankRoutingNumberErrMessage,
    errorMessage1,
    alertCopyMsg,
    successMessage1,
    isMobile,
    riskLevel,
    errorMessage2
  } = props

  const [toolTipHover,toolTipHoverHandler] = useState(false);

  return (
    <div className='form-fields bank-info-section'>
      <div className='ui form'>
        <div className='fields'>
          <div className='eight wide field'>
            <label 
              >
                Routing # {(riskLevel==RISK_LEVELS.HIGH_RISK)
                  &&<span className={ 'info1-icon'} 
                  data-tooltip={"Online banks are not supported, including Green Dot, Azlo, NorthOne, Radius, Novo, and others."}></span>}
                  <span className="required-text"> *</span> 
            </label>
            <input
              type='text'
              className={ !bankRoutingNo ? 'not-filled' :'form-input'}
              placeholder='Routing #'
              name='bankRoutingNo'
              value={bankRoutingNo}
              autoComplete="new-password"
              onChange={(e) => setBankRoutingNo(e.target.value)}
              onBlur={bankInfoAPI}
              onKeyPress={allowOnlyNumber}

            />
          </div>
          <div className='eight wide field'>
            <label>Bank Name <span className="required-text"> *</span> </label>
            <input
              type='text'
              className={ !bankName ? 'not-filled' :'form-input'}
              placeholder='Bank Name'
              name='bankName'
              value={bankName}
              readOnly
              autoComplete="new-password"
              onChange={(e) => setBankName(e.target.value)}
              onBlur={updateBankSectionStatus}
            />
          </div>
        </div>

        <div className='fields'>
          <div className='eight wide field'>
            <label>Bank Account # <span className="required-text"> *</span> </label>
            <input
              type={(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.SALES_LIMITED) ? "password" : "text"}
              className={ `${((!bankAccountNo || (bankAccountNo !== confirmBankAccountNo)) && role !== ROLES.SUPER_ADMIN ) ? 'not-filled' :'form-input'} placeholder-visible`}
              name='bankAccountNo'
              value={bankAccountNo}
              autoComplete="new-password"
              onChange={handleChangeTextInput}
              onBlur={updateBankSectionStatus}
              onCopy={handleCopy}
              onPaste={handlePaste}
              disabled={!!(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.SALES_LIMITED)}
              placeholder={(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.SALES_LIMITED) ? !isMobile? 'Must be completed by merchant' : 'Bank Account # ': !isMobile? '' : 'Bank Account # '}
            />
          </div>
          <div className='eight wide field'>
            <label>Confirm Bank Account # <span className="required-text"> *</span> </label>
            <input
              type={(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.SALES_LIMITED) ? "password" : "text"}
              className={ `${((!confirmBankAccountNo || (bankAccountNo !== confirmBankAccountNo))  && role !== ROLES.SUPER_ADMIN) ? 'not-filled' :'form-input'} placeholder-visible`}
              placeholder={(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.SALES_LIMITED) ? !isMobile? 'Must be completed by merchant' : 'Confirm Bank Account # ': !isMobile? '' : 'Confirm Bank Account # '}
              name='confirmBankAccountNo'
              value={confirmBankAccountNo}
              autoComplete="new-password"
              onChange={handleConfirmBankAccountNo}
              onBlur={updateBankSectionStatus}
              onCopy={handleCopy}
              onPaste={handlePaste}
              disabled={!!(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.SALES_LIMITED)}
            />
          </div>
        </div>

        <div className='fields'>
          <div className='eight wide field'>
            <label>Bank Phone <span className="required-text"> *</span> </label>
            <input
              type='text'
              className={ !bankPhone ? 'not-filled' :'form-input'}
              placeholder='Bank Phone'
              name='bankPhone'
              readOnly
              value={bankPhone}
              autoComplete="new-password"
              onChange={handleChangeTextInput}
              onBlur={updateBankSectionStatus}
            />
          </div>
          {/* <div className='eight wide field'>
                <label>ACH Method <span className="required-text"> *</span> </label>
                <Dropdown
                  placeholder='Choose One'
                  className='form-input'
                  name=''
                  fluid
                  search
                  selection
                  options={ACHmethod}
                  value={selectedBankACHMethod}
                  onChange={handleChangeBankACHMethod}
                />
              </div> */}
        </div>
        <div className='form-fields'>
          {bankRoutingNumberErrMessage && (
            <div className='warning-text'>
              {bankRoutingNumberErrMessage}
            </div>
          )}
        </div>
        <div>
          <div className="warning-text">{errorMessage1}</div>
          <div className="warning-text">{alertCopyMsg}</div>
          <div className="warning-text">{successMessage1}</div>
          <div className="warning-text">{errorMessage2}</div>
        </div>
      </div>
    </div>
  )
}

export default memo(BankDetailsSection)