import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { ConfigProvider, Layout } from 'antd';
import { auth } from "../../../Firebase";
import { AppContext } from "../../../context/appContext";
import AccountMenu from '../../PageLayout/AccountMenu';

const PlatformAdminHeader :React.FC = () => {
  const { Header } = Layout;
  const history = useHistory();
  const { config, setConfig }: any = useContext(AppContext);
  let user_name = sessionStorage.getItem('user_name');
  const role = sessionStorage.getItem('role');

  const signOut = async () => {
    try {
      await auth.doSignOut();
      const clientId = sessionStorage.getItem("clientId")
      sessionStorage.clear();
      if (!!clientId) {
        sessionStorage.setItem("clientId", clientId)
      }
      localStorage.clear();
      setConfig({
        ...config,
        accessible_clients: [],
        user_roles_id : null,
        selectedClientId: config?.id,
        client_config_fe: config.default_config_fe || config.client_config_fe
      })
    } catch (err: any) {
      console.log(err);
    } finally {
      history.replace("/");
    }
  };

  return (
    <ConfigProvider
      theme={{
          components: {
              Layout: {
                headerBg: config?.client_config_fe?.header_bg_color,
              },
          },
      }}
    >
      <Header className="platform-admin-header">
        <img src={config?.client_config_fe?.header_logo} onClick={() => history.replace("/listapp")} />
        <div className="user-details">
          <AccountMenu
            name={user_name || ''}
            signOut={signOut}
            config={config}
            role={role}
          />
        </div>
      </Header>
    </ConfigProvider>
  )
}
export default PlatformAdminHeader
