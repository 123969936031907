import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.css'
import App from './Components/App/Index'
import * as serviceWorker from './serviceWorker';
import './Assets/css/responsive/responsive.css';
import './Assets/css/responsive/ipad-responsive.css';
import { AppProvider } from './context/appContext';

// const { REACT_APP_SENTRY_DSN } = process.env

// Sentry.init({
//   dsn: REACT_APP_SENTRY_DSN,
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(<AppProvider><App /></AppProvider>, document.getElementById('root'))

serviceWorker.unregister()
