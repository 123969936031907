import React, { memo } from 'react'
import { Checkbox, CheckboxProps, Dropdown, Radio } from 'semantic-ui-react'
import { FIELDS_INFO, ROLES } from '../../../Utils/constants'

interface DbaInformationSectionProps {
  dbaInfoAddressValid: boolean
  dbaInfoAddressErrMessage: string
  handleUseLegalAddress: (data: CheckboxProps) => void
  dbaName: string
  updatedbaSectionStatus: () => void
  handleChangeTextInput: (e: any) => false | undefined
  dbaAddress: string
  useLegalAddress: boolean
  validateDBASectionAddress: () => Promise<void>
  sendMailAdditionalLocation: () => Promise<void>
  dbaCity: string
  selectedDBAState: string
  states: {
    text: string;
    value: string;
  }[]
  dbaZipCode: string
  dbaBusinessEmail: string
  isDbaBusinessEmailValid: boolean
  dbaCustomerServicePhone: string
  riskLevel: string
  dbaWebsiteURL: string
  option_yesno: {
    text: string;
    value: string;
  }[]
  selectedDBAMultipleLocationOption: string
  handleChangeDBAMultipleLocationOption: (e: any, data: any) => void
  setSelectedDBAState: React.Dispatch<React.SetStateAction<string>>
  role: string | null
  isMobile: boolean
  isTab: boolean
}
const DbaInformationSection = (props: DbaInformationSectionProps) => {
  const {
    dbaInfoAddressValid,
    dbaInfoAddressErrMessage,
    handleUseLegalAddress,
    dbaName,
    updatedbaSectionStatus,
    handleChangeTextInput,
    dbaAddress,
    useLegalAddress,
    validateDBASectionAddress,
    sendMailAdditionalLocation,
    dbaCity,
    selectedDBAState,
    states,
    dbaZipCode,
    dbaBusinessEmail,
    isDbaBusinessEmailValid,
    dbaCustomerServicePhone,
    riskLevel,
    dbaWebsiteURL,
    option_yesno,
    selectedDBAMultipleLocationOption,
    handleChangeDBAMultipleLocationOption,
    setSelectedDBAState,
    role,
    isMobile,
    isTab
  } = props

  const CommonRadioSection = (props: any) => {
    
    return (
      <div className="inline fields">
        <label>{props.placeholder}</label>
        {!isTab ? <div className="two wide field"></div> : <></>}
        <div>
          {props.options.map((value: any) => {
            return (
              <div className="checkbox-inline">
                <Radio
                  label={value.text}
                  name=""
                  value={value.value}
                  checked={!!props.value && props.value === value.value}
                  onChange={props.handler}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  
  const handleChangeDBAState = (e: any, data: any) => {
    setSelectedDBAState(data.value)
  }
  return (
    <div className='form-fields dba-info-section'>
      {!dbaInfoAddressValid && (
        <div className='warning-text'>{dbaInfoAddressErrMessage}</div>
      )}
      <div className='ui form'>
        <div className='useLegalAddress'>
          {/* <Checkbox
            label='Use Corp Address'
            name='options'
            value="useLegalAddress"
            checked={useLegalAddress || false}
            onChange={(event, data: CheckboxProps) => {
              handleUseLegalAddress(data)
            }}
          /> */}
        </div>
        <div className='fields'>
          <div className='eight wide field'>
            <label>DBA Name <span className={(role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN) ? 'info1-icon' : 'info1-icon-merchant'}
              data-tooltip={FIELDS_INFO['DBA Information'].dbaName.helperText}></span><span className="required-text"> *</span> </label>
            <input
              type='text'
              name='dbaName'
              className={ !dbaName ? 'not-filled' :'form-input'}
              placeholder='DBA Name'
              value={dbaName}
              onBlur={()=>{updatedbaSectionStatus();  sendMailAdditionalLocation() }}
              autoComplete="new-password"
              onChange={handleChangeTextInput}
            />
          </div>
          <div className='eight wide field'>
            <label>DBA Address (Physical Location – no P.O. Box)<span className={(role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN) ? 'info1-icon' : 'info1-icon-merchant'}
              data-tooltip={FIELDS_INFO['DBA Information'].dbaAddress.helperText}></span><span className="required-text"> *</span></label>
            <input
              className={ !dbaAddress ? 'not-filled' :'form-input'}
              placeholder='DBA Address '
              name='dbaAddress'
              value={dbaAddress}
              readOnly={useLegalAddress}
              autoComplete="new-password"
              onChange={handleChangeTextInput}
              onBlur={validateDBASectionAddress}
            />
          </div>
        </div>
        <div className='fields'>
          <div className='eight wide field'>
            <label>City <span className="required-text"> *</span> </label>
            <input
              type='text'
              name='dbaCity'
              className={ !dbaCity ? 'not-filled' :'form-input'}
              placeholder='City'
              value={dbaCity}
              readOnly={useLegalAddress}
              autoComplete="new-password"
              onChange={handleChangeTextInput}
              onBlur={validateDBASectionAddress}
            />
          </div>
          <div className='eight wide field'>
            <label>State <span className="required-text"> *</span> </label>
            <Dropdown
              placeholder={isMobile? 'State' : 'Choose One'}
              className={ !selectedDBAState ? 'not-filled' :'form-input'}
              fluid
              search
              selection
              value={selectedDBAState}
              disabled={useLegalAddress}
              options={states}
              onChange={handleChangeDBAState}
              onBlur={validateDBASectionAddress}
            />
          </div>
        </div>
        <div className='fields'>
          <div className='eight wide field'>
            <label>Zip Code <span className="required-text"> *</span> </label>
            <input
              type='text'
              name='dbaZipCode'
              className={ !dbaZipCode ? 'not-filled' :'form-input'}
              placeholder='Zip Code'
              value={dbaZipCode}
              autoComplete="off"
              onChange={handleChangeTextInput}
              readOnly={useLegalAddress}
              onBlur={validateDBASectionAddress}
            />
          </div>
          <div className='eight wide field'>
            <label>Primary Contact Email <span className={(role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN) ? 'info1-icon' : 'info1-icon-merchant'}
              data-tooltip={FIELDS_INFO['DBA Information'].dbaBusinessEmail.helperText}></span><span className="required-text"> *</span> </label>
            <input
              type='text'
              name='dbaBusinessEmail'
              className={ (!dbaBusinessEmail || !isDbaBusinessEmailValid) ? 'not-filled' :'form-input'}
              placeholder='Primary Contact Email'
              value={dbaBusinessEmail}
              autoComplete="new-password"
              onChange={handleChangeTextInput}
              onBlur={updatedbaSectionStatus}
            />
            {(dbaBusinessEmail || isDbaBusinessEmailValid) && <span className='business-email-validation'>{isDbaBusinessEmailValid?"":"invalid email"}</span>}
          </div>
        </div>
        <div className='fields'>
          <div className='eight wide field'>
            <label>Customer Service Phone<span className="required-text"> *</span> </label>
            <input
              type='text'
              name='dbaCustomerServicePhone'
              className={ !dbaCustomerServicePhone ? 'not-filled' :'form-input'}
              placeholder='Customer Service Phone'
              value={dbaCustomerServicePhone}
              autoComplete="new-password"
              onChange={handleChangeTextInput}
              onBlur={updatedbaSectionStatus}
            />
          </div>
          <div className='eight wide field'>
            <label>Website Url {(riskLevel !== 'Low Risk' && <span className="required-text"> *</span>)} </label>
            <input
              type='text'
              name='dbaWebsiteURL'
              className={ (riskLevel !== 'Low Risk' && !dbaWebsiteURL) ? 'not-filled' :'form-input'}
              placeholder='Website Url'
              value={dbaWebsiteURL}
              autoComplete="new-password"
              onChange={handleChangeTextInput}
              onBlur={updatedbaSectionStatus}
            />
          </div>
        </div>
        <div className='fields'>
          <div className='eight wide field gdt-100'>
           {!isMobile && !isTab? <><label>Multiple Location? <span className="required-text"> *</span> </label>
            <Dropdown
              placeholder={isMobile || isTab ? 'Multiple Location?' : 'Choose One'}
              className={ !selectedDBAMultipleLocationOption ? 'not-filled' :'form-input'}
              fluid
              search
              selection
              options={option_yesno}
              value={selectedDBAMultipleLocationOption}
              onChange={handleChangeDBAMultipleLocationOption}
            /> </> : <CommonRadioSection placeholder={isMobile || isTab ? 'Multiple Location?' : 'Choose One'} options={option_yesno} value={selectedDBAMultipleLocationOption} handler={handleChangeDBAMultipleLocationOption} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(DbaInformationSection)