import * as firebase from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getStorage } from "firebase/storage";

import { config } from './firebase.config'

const app = firebase.initializeApp(config)

export const auth = getAuth(app)
export const storage = getStorage(app)
