import React from 'react'
import { Layout } from 'antd';
import { dashboardContentStyle } from '../StyleConstants'

const PlatformAdminDashboard :React.FC = () => {
  const { Content } = Layout;

  return (
    <Content style={dashboardContentStyle}>
      Platform Admin Dashboard
    </Content>
  )
}
export default PlatformAdminDashboard
