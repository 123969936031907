import React from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";

interface DeleteModalPageProps {
  deleteModalOpen: boolean;
  loading: boolean;
  deleteAppLinkId: string;
  overlay: {
    style: {
      display: string;
    };
  };
  overlayMsg: string;
  handleDelete: (save_link_id: any, status: any) => Promise<void>;
  setDeleteAppLinkId: React.Dispatch<React.SetStateAction<string>>;
  setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const DeleteModalPage = (props: DeleteModalPageProps) => {
  const { deleteModalOpen, loading, deleteAppLinkId, overlay, overlayMsg, handleDelete, setDeleteAppLinkId, setDeleteModalOpen } = props
  return (
    <Modal
      centered
      size="mini"
      open={deleteModalOpen}
      onClose={() => setDeleteModalOpen(false)}
      onOpen={() => setDeleteModalOpen(true)}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <ProgressLoader loading={loading} size='small' />
        <div className="close-button">
          <button className="email-activity-close-button" onClick={() => {
            setDeleteModalOpen(false)
            setDeleteAppLinkId('')
          }
          }>X</button>
        </div>
        {`Are you sure you want to delete ${deleteAppLinkId}?`}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => {
          setDeleteModalOpen(false)
          setDeleteAppLinkId('')
        }} >CANCEL</Button>
        <Button onClick={() => {
          setDeleteModalOpen(false)
          handleDelete(deleteAppLinkId, "true")
        }} color='red'>DELETE
        </Button>
        <div className='overlay' style={overlay.style}>
          <div className='overlay-image'>
            <span className='overlay-text' >{overlayMsg}</span>
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default DeleteModalPage;
