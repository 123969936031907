import React, { useState, useEffect } from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";

interface WarningModalProps {
    loading: boolean
    warningModalOpen: boolean
    handleCancel: () => void
    config?:any
}
const WarningModal = (props: WarningModalProps) => {

    const { handleCancel, warningModalOpen, loading, config } = props

    return (
        <Modal
            centered
            open={warningModalOpen}
            onClose={handleCancel}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <div className="close-button">
                    <button className="email-activity-close-button" onClick={handleCancel}>X</button>
                </div>
                <ProgressLoader loading={loading} size='small' />
                <p>OfficeAdmin can't perform delete or edit or enable/disable for IRIS reporting on office admins</p>
            </Modal.Content>
            <Modal.Actions>
                <Button inverted onClick={handleCancel} color='blue'>OK</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default WarningModal;
