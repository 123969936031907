import React from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";
import BasicButton from '../../Common/Button';

interface EnablePaperAppsProps {
  loading: boolean;
  editOfficeDetails: any;
  openEnablePaperAppsModal: boolean;
  handleCancel: () => void;
  updatePaperAppsEnabledStatus: () => Promise<void>;
  paperAppsEnabled: boolean;
  config?:any
}

const EnablePaperAppsModal = (props: EnablePaperAppsProps) => {
  const {
    handleCancel,
    updatePaperAppsEnabledStatus,
    openEnablePaperAppsModal,
    editOfficeDetails,
    paperAppsEnabled,
    loading,
    config
  } = props;

  return (
    <Modal
      centered
      open={openEnablePaperAppsModal}
      onClose={handleCancel}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <div className="close-button">
          <button
            className="email-activity-close-button"
            onClick={handleCancel}
          >
            X
          </button>
        </div>
        <ProgressLoader loading={loading} size="small" />
        <div className="word-wrap">
        {`Are you sure you want to ${
          paperAppsEnabled ? "ENABLE" : "DISABLE"
        } Paper Apps submission for ${editOfficeDetails.office_name} ( ID - ${editOfficeDetails.office_id} )?`}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <BasicButton
          textColor={config?.client_config_fe?.button_color}
          backgroundColor={'#FFFFFF'}
          size='18px'
          buttonText='CANCEL'
          className="save-button button-border"
          onClick={handleCancel}
        />

        <button
          className="save-button button-border ipad-blue"
          style={{ background: config?.client_config_fe?.button_color }}
          onClick={updatePaperAppsEnabledStatus}>YES</button>
      </Modal.Actions>
    </Modal>
  );
};

export default EnablePaperAppsModal;
