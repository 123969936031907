import React, {useState, useEffect, useContext} from 'react'
import { useHistory } from 'react-router-dom';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import { ROLES } from '../../Utils/constants';
import { FooterProps } from './structs';
import listapp from '../../Images/listapp.svg';
import listappSelected from '../../Images/listapp_selected.svg';
import createlink from '../../Images/createlink.svg';
import createlinkSelected from '../../Images/createlink_selected.svg';
import startapp from '../../Images/startapp.svg';
import startappSelected from '../../Images/startapp_selected.svg';
import { AppContext } from '../../context/appContext';
import { auth } from '../../Firebase'

const MobileFooter = (props: any) => {
  const {role} = props
  const navigate = useHistory();
  

const MOBILE_START_APP_ACCESS_USERS: string[] = [ROLES.OFFICE_MANAGER, ROLES.RELATIONSHIP_MANAGER, ROLES.PARTNER, ROLES.OFFICE_ADMIN, ROLES.UNDER_WRITER]

const MOBILE_SEND_APP_ACCESS_USERS: string[] = [ROLES.OFFICE_MANAGER, ROLES.RELATIONSHIP_MANAGER, ROLES.PARTNER, ROLES.OFFICE_ADMIN, ROLES.VIEW_ONLY, ROLES.SALES_LIMITED]

/*const MOBILE_USERS_PAGE_ACCESS_USERS: string[] = [ROLES.OFFICE_ADMIN, ROLES.SUPER_ADMIN, ROLES.RELATIONSHIP_MANAGER, ROLES.OFFICE_MANAGER]

const MOBILE_FIELDS_PAGE_ACCESS_USERS: string[] = [ROLES.SUPER_ADMIN]

const MOBILE_OFFICES_PAGE_ACCESS_USERS: string[] = [ROLES.OFFICE_ADMIN, ROLES.SUPER_ADMIN, ROLES.RELATIONSHIP_MANAGER]

let fieldListCheck = !!role && MOBILE_FIELDS_PAGE_ACCESS_USERS.includes(role);
let officeListCheck = !!role && MOBILE_OFFICES_PAGE_ACCESS_USERS.includes(role);
let userListCheck = !!role && MOBILE_USERS_PAGE_ACCESS_USERS.includes(role);*/
let createListCheck = !!role && MOBILE_SEND_APP_ACCESS_USERS.includes(role);
let startAppCheck = !!role && MOBILE_START_APP_ACCESS_USERS.includes(role);
let footerClass = role !== ROLES.VIEW_ONLY || role!== ROLES.SALES_LIMITED? 'mob-footer-icons': 'mob-footer-icons-view';
let imageClass = props.page === "fieldlist" ? "mob-footer-image-blue":"mob-footer-image-white";
const loc = window.location.pathname;
const footerContent = [
  /*{href: "/fieldlist", label: "Field List", display: fieldListCheck},
  {href: "/offices", label: "Office List", display: officeListCheck},
  {href: "/users", label: "User List", display: userListCheck},*/
  {href: "/createlink", icon: [createlink, createlinkSelected],
   label: role === ROLES.VIEW_ONLY || role === ROLES.SALES_LIMITED? "View Link" : "Create Link", display: createListCheck},
  {href: "/startapp", icon:[startapp, startappSelected], label: "Start App", display: startAppCheck},
  {href: "/listapp", icon: [listapp,listappSelected], label: "App List", display: true},
  //{href: "/audit", label: "Change log", display: fieldListCheck}
 ];

 const setUrlAndReload = (footerRecord: any) => {
    navigate.push(footerRecord.href);
    window.location.reload();
 };

const footerBlockMobTemplate = () => {
  console.log("loc:",loc, footerContent);
  return footerContent.map((footerRecord:any) =>(footerRecord.display && 
  <div className={footerClass} onClick={() => setUrlAndReload(footerRecord)}>
  {/*<img src={footerRecord.href === loc ? footerRecord.icon[1]: footerRecord.icon[0]}
  className={imageClass} ></img>*/}
            <div
              className={`mob-footer-text ${(footerRecord.href === loc || loc.includes(footerRecord.href)) ? "mob-footer-cur":''}`}
            >
             {footerRecord.label}
            </div>
</div>))};

return(
   <>
      {!!role && <div className='mobile-footer'>
      {footerBlockMobTemplate()}
    </div>}
      </>
)
}

export const Footer = (props: FooterProps) => {
  const history = useHistory()
  const [feVersion, setFeVersion] = useState("");
  const role = sessionStorage.getItem('role');

  const isMobile = useCheckMobileScreen()
  const { loading = false} = props
  const { config }: any = useContext(AppContext);

	useEffect(() => {
    let isMounted = true;
		const getVersionData = async () => {
      try {
        const data = {
          api: api.appVersions.get
        };

        const params = new URLSearchParams(history.location.search)
        const tokenID: any = params.get('token')
        
        let token: any
        if(tokenID) {
          token = await auth.getToken();
        }

        const versionData = !!token ? await backend.fetch(data, token) : await backend.fetch(data);
  
        if (versionData && isMounted) {
          setFeVersion(versionData[0].fe_version_number);
        }

        if(tokenID) {
          await auth.signInWithCustomToken(tokenID)
        }
      } catch (err: any) {
        console.log('No version data found')
      }
		}

    getVersionData();
    return () => {isMounted = false}
	}, []);

  return (
  <div>        
     { <><div className='footerLogoArea' style={{background: config?.client_config_fe?.footer_bg_color}}>
        <div className='blockArea'>
          <div className='footer-logo'>
            <img src={config?.client_config_fe?.header_logo} />
          </div>
          <div className='footer-p' style={{color: config?.client_config_fe?.footer_text_color}}>
            {`At ${config?.client_config_fe?.name}, we know that running a business means doing one
            thing you’re passionate about, and a lot of things you might not be.
            That’s why we work with our customers to provide an all-in-one
            payment solution. Our role is to enhance your customers’ payment
            experience, and help you get back to what‘s really important.`}
          </div>
        </div>
      </div>
      <div className='footerCardArea'>
        <div className='footer-blockArea'>
          <div className='footer-copyright'>
            <span className='footer-text mob-copyright'>
              {config?.client_config_fe?.copy_right_msg?`${config?.client_config_fe?.copy_right_msg} `:`Copyright © 2020 ${config.client_name} `}- All Rights
              Reserved.
            </span>
            <span className='mob-privacy'>
            <span className='footer-text texthover'>
              <a href="https://trinitypaysol.com/privacy-policy/" target="_blank">Privacy Policy</a>
            </span>
            <span className='footer-text texthover'>
              <a href="https://trinitypaysol.com/terms/" target="_blank">Terms of Use</a>
            </span>
            <span className='footer-text texthover'>
              <a>Version : {feVersion}</a>
            </span>
            </span>
          </div>
        </div>
      </div>
      </>}
    </div>
  )
}

export { MobileFooter }
export default React.memo(Footer)
