import React, { memo, useEffect, useState, useContext } from 'react'
import { Button } from 'semantic-ui-react'
import { firstBy } from 'thenby'
import api from '../../../Service/Api'
import backend from '../../../Service/Backend'
import { BANK_CODES, RISK_LEVELS, ROLES, cdBanks } from '../../../Utils/constants'
import ProgressLoader from '../../Common/ProgressLoader'
import { Footer } from '../../PageLayout/Footer'
import { Layout } from '../../PageLayout/Layout'
import './users.css'
import { generatePassword, get, getParsedList } from '../../../Utils/helpers'
import SearchResultModal from '../../Common/SearchResultModal'
import WarningModal from './WarningModal'
import DeleteModal from './DeleteModal'
import CreateUser from './CreateUser'
import UserTable from './UserTable'
import EnableIRISModal from './EnableIRISModal'
import EnableFeenavigatorModal from './EnableFeenavigatorModal'
import ResetPasswordModal from "./ResetPasswordModal";
import DeactivateModal from './DeactivateModal'
import ResetPasswordModalBulk from "./ResetPasswordModalBulk";
import ActionConfirmation from "./ActionConfirmationModal";
import UserActiveDeactiveConfirmation from "./UserActiveDeactiveConfirmationModal";
import UserOfficeStatus from "./UserOfficeStatusActivateModal";
import useCheckMobileScreen, { useCheckTabScreen } from '../../../hooks/useCheckMobileScreen'
import ButtonScrollTop from '../../Common/ButtonScrollTop'
import Select from 'react-select'
import WarningModalBulk from './WarningModalBulk'
import { fetchUsersListData, fetchOfficeListData } from '../../../Service/Algolia'
import { AppContext } from '../../../context/appContext'
import EnableFreeEquipmentPlacementModal from '../../FreeEquipmentPlacement/EnableFreeEquipmentPlacementModal'
import BasicButton from '../../Common/Button'

const PlusSquareOutlined = () => <svg viewBox="64 64 896 896" focusable="false" data-icon="plus-square" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M328 544h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8z"></path><path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656z"></path></svg>

const { REACT_APP_BACKEND_MYSQL } = process.env;

const allRiskLevels: any = [
  { value: RISK_LEVELS.LOW_RISK, label: RISK_LEVELS.LOW_RISK },
  { value: RISK_LEVELS.HIGH_RISK, label: RISK_LEVELS.HIGH_RISK }
]

const superAdminCreateRoles = [
  { value: ROLES.RELATIONSHIP_MANAGER, label: ROLES.RELATIONSHIP_MANAGER },
  { value: ROLES.OFFICE_ADMIN, label: ROLES.OFFICE_ADMIN },
  { value: ROLES.PARTNER, label: 'SALES USER' },
  { value: ROLES.OFFICE_MANAGER, label: ROLES.OFFICE_MANAGER },
  { value: ROLES.VIEW_ONLY, label: ROLES.VIEW_ONLY },
  { value: ROLES.UNDER_WRITER, label: ROLES.UNDER_WRITER },
  { value: ROLES.SALES_LIMITED, label: ROLES.SALES_LIMITED }

]

const officeAdminCreateRoles = [
  { value: ROLES.PARTNER, label: 'SALES USER' },
  { value: ROLES.OFFICE_MANAGER, label: ROLES.OFFICE_MANAGER },
  { value: ROLES.VIEW_ONLY, label: ROLES.VIEW_ONLY },
  { value: ROLES.SALES_LIMITED, label: ROLES.SALES_LIMITED }

]

const officeManagerCreateRoles = [
  { value: ROLES.PARTNER, label: 'SALES USER' },

]

const relationshipManagerCreateRoles = [
  { value: ROLES.PARTNER, label: 'SALES USER' },
  { value: ROLES.OFFICE_ADMIN, label: ROLES.OFFICE_ADMIN },
  { value: ROLES.OFFICE_MANAGER, label: ROLES.OFFICE_MANAGER },
  { value: ROLES.VIEW_ONLY, label: ROLES.VIEW_ONLY },
  { value: ROLES.SALES_LIMITED, label: ROLES.SALES_LIMITED }
]
const relationshipManagerCreateRolesEdit = [
  { value: ROLES.PARTNER, label: 'SALES USER' },
  { value: ROLES.OFFICE_ADMIN, label: ROLES.OFFICE_ADMIN },
  { value: ROLES.OFFICE_MANAGER, label: ROLES.OFFICE_MANAGER },
  { value: ROLES.VIEW_ONLY, label: ROLES.VIEW_ONLY },
  { value: ROLES.SALES_LIMITED, label: ROLES.SALES_LIMITED },
]

const editRoles = [
  { value: ROLES.PARTNER, label: 'SALES USER' },
  { value: ROLES.OFFICE_ADMIN, label: ROLES.OFFICE_ADMIN },
  { value: ROLES.OFFICE_MANAGER, label: ROLES.OFFICE_MANAGER },
  { value: ROLES.VIEW_ONLY, label: ROLES.VIEW_ONLY },
  { value: ROLES.SALES_LIMITED, label: ROLES.SALES_LIMITED },
  { value: ROLES.UNDER_WRITER, label: ROLES.UNDER_WRITER }
]
const userBulkAction: any = [
  { value: 'Reset password', label: 'Reset password' },
  { value: 'Activate user', label: 'Activate' },
  { value: 'Deactivate user', label: 'Deactivate' },
]

const UsersList = () => {
  const { config }: any = useContext(AppContext);

  const role = sessionStorage.getItem('role')
  const office_id = sessionStorage.getItem('office_id')
  const office_level = sessionStorage.getItem('office_level')
  const user_id = sessionStorage.getItem('user_id')
  let client_id: any = Number(sessionStorage.getItem('clientId'))
  const super_user: any = sessionStorage.getItem("super_user") === 'true'
  if (super_user) {
      client_id = Number(sessionStorage.getItem("selectedClientId"))
  }

  const [usersList, setUsersList] = useState([] as any[])
  const [processors, setProcessors] = useState([] as any[])
  const [lowRiskProcessors, setLowRiskProcessors] = useState([] as any[])
  const [highRiskProcessors, setHighRiskProcessors] = useState([] as any[])
  const [allProcessors, setAllProcessors] = useState([] as any[])
  const [riskLevels, setRiskLevels] = useState([] as any[])
  const [rmUsers, setRmUsers] = useState([] as any[])
  const [loading, setLoading] = useState(true)
  const [showCreateUserModal, setShowCreateUserModal] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [emailValid, setEmailValid] = useState(false)
  const [selectedRole, setSelectedRole] = useState({} as any)
  const [selectedOffice, setSelectedOffice] = useState({} as any)
  const [selectedProcessors, setSelectedProcessors] = useState([])
  const [selectedLowRiskProcessors, setSelectedLowRiskProcessors] = useState([] as any[])
  const [selectedHighRiskProcessors, setSelectedHighRiskProcessors] = useState([] as any[])
  const [selectedRiskLevel, setSelectedRiskLevel] = useState([])
  const [selectedRM, setSelectedRM] = useState([])
  const [irisEnabled, setIrisEnabled] = useState(false)
  const [feenavigatorEnabled,setFeenavigatorEnabled] = useState(false)
  // const [enableFreeEquipmentPlacement,setEnableFreeEquipmentPlacement] = useState(false)
  const [openEnableFreeEquipmentPlacementModal, setOpenEnableFreeEquipmentPlacementModal] = useState(false)
  const [freeEquipmentPlacementEnabled, setFreeEquipmentPlacementEnabled] = useState(false)
  const [createUserEnabled, setCreateUserEnabled] = useState(false)
  const [overlay, setOverlay] = useState({ style: { display: "none" } })
  const [overlayMsg, setOverlaymsg] = useState("Users Fetched Successfully!")
  const [edit, setEdit] = useState(false)
  const [editUserDetails, setEditUserDetails] = useState({} as any)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState({} as any)
  const [offices, setOffices] = useState([] as any[])
  const [officesDropDown, setOfficesDropDown] = useState([] as any[])
  const [userCreationError, setUserCreationError] = useState('');
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const [openEnableIRISModal, setOpenEnableIRISModal] = useState(false)
  const [openEnableFeenavigatorModal, setOpenEnableFeenavigatorModal] = useState(false)
  //USEING TO SAVE LEVEL OFFICE DETAILS WHEN LEVEL TWO OFFICE ADMIN LOGGED IN
  const [levelOneOfficeDetails, setLevelOneOfficeDetails] = useState({} as any)
  const [modalForgetpassword,setModalForgetpassword] = useState(false)
  const [userEmail, setUserEmail] = useState({} as any)
  const [modalTitle,setModalTitle] = useState("Reset Password")
  const [generatedPassword,setGeneratedPassword]= useState("")
  const [errResponse,setErrResponse] = useState (false)
  const [errMsgResp,setErrmsgResp] = useState("")
  //Search and Sorting for Users
  const [searchField, setSearchField] = useState('');
  const [searchText, setSearchText] = useState('')

  const [sortField, setSortField] = useState('');
  const [sortFieldOrder, setSortFieldOrder] = useState('desc');
  const [noSearchResultModal, setNoSearchResultModal] = useState(false)
  const [sortAndSearchResultData, setSortAndSearchResultData] = useState([] as any[])

  const [fetchingOffices, setFetchingOffices] = useState(false)
  const [fetchingProcessors, setFetchingProcessors] = useState(false)
  const [fetchingUsers, setFetchingUsers] = useState(true)
  const [fetchingRms, setFetchingRms] = useState(false)
  const isMobile = useCheckMobileScreen();
  const isTablet = useCheckTabScreen();
  const [algoliaLoading, setAlgoliaLoading] = useState(true);
 
  //Bulk Update
  const [usersToUpdate, setUsers] = useState([] as any[])
  const [selectedUserBulk, setSelectedUserBulk] = useState([] as any)
  const [passwordUpdateBulk, setPasswordUpdateBulk] = useState(false)
  const [bulkaction, setBulkaction] = useState('')
  const [modalAction, setModalAction] = useState('')
  const [passwordUpdateConfirmation, setPasswordUpdateConfirmation] = useState(false)
  const [userActiveDeactiveBulkConfirmation, setUserActiveDeactiveBulkConfirmation] = useState(false)
  const [countforAction, setCountforAction] = useState('')
  const [isParentChecked, setIsParentChecked] = useState(false);
  const [showOfficeStatusonActivate, setShowOfficeStatusonActivate] = useState(false)
  const [userNames, setUserNames] = useState(false)
  const [warningModalOpenBulk, setWarningModalOpenBulk] = useState(false)
  const [officeAdminBulk, setOfficeAdminBulk] = useState(false)
  const [details, setDetails] = useState({} as any)

  let viewOptions = true
  if(role === ROLES.VIEW_ONLY){ viewOptions = false }
  useEffect(() => {
    if(role === ROLES.OFFICE_ADMIN && office_level === '1'){
      //ALLOWING LEVEL ONE OFFICE ADMIN TO CREATE OFFICE ADMIN FOR LEVEL TWO OFFICES
      let index = officeAdminCreateRoles.findIndex((roles: any) => roles.value === ROLES.OFFICE_ADMIN)
      if(index < 0) officeAdminCreateRoles.push({ value: ROLES.OFFICE_ADMIN, label: ROLES.OFFICE_ADMIN })
    }
    fetchProcessorsData()
  }, [])
  
  const fetchUserList = async () => {
    try {
      setAlgoliaLoading(true);
      const dataList = await fetchUsersListData();
      setAlgoliaLoading(false);
      if (!super_user) {
        if (role === ROLES.SUPER_ADMIN) {
          let updatedDataList = dataList?.filter((user: any) => user?.role_code !== ROLES.SUPER_ADMIN && !user.deleted && user.client_id == client_id)
          setUsersList(updatedDataList)
        } else if (role === ROLES.OFFICE_ADMIN) {
          let updatedDataList = dataList?.filter((user: any) => {
            const userOfficeId = !!user?.office_id ? (user?.office_id).toString() : ''
            const parentOfficeIds = !!user?.parent_office_ids ? getParsedList(user?.parent_office_ids) : []
            return (
              (parentOfficeIds.includes(office_id) || userOfficeId === office_id) &&
              !(!!user.deleted) &&
              user?.role_code !== ROLES.UNDER_WRITER
            );
          });
          setUsersList(updatedDataList)
        }
        else if (role === ROLES.OFFICE_MANAGER) {
          let updatedDataList = dataList?.filter((user: any) => (user?.role_code === ROLES.PARTNER && (user?.parent_office_ids?.includes(office_id) || user?.office_id == office_id)) && !user.deleted && user?.role_code !== ROLES.UNDER_WRITER && user.client_id == client_id)
          setUsersList(updatedDataList)
        } else if (role === ROLES.RELATIONSHIP_MANAGER) {
          let reducedUsers = dataList?.filter((user: any) => !user.deleted && user.client_id == client_id && user?.role_code !== ROLES.UNDER_WRITER)?.reduce((filteredUsers: any, user: any) => {
            const rm_details = getParsedList(user?.rm_details).filter((rm_user: any) => user_id == rm_user.user_id)
            if (rm_details && rm_details.length > 0) {
              filteredUsers.push(user);
            }
            return filteredUsers;
          }, []);
          setUsersList(reducedUsers)
        }
      } else {
        let updatedDataList = dataList?.filter((user: any) => user?.role_code !== ROLES.SUPER_ADMIN && !user.deleted && user.client_id == client_id)
        setUsersList(updatedDataList)
      }

      setFetchingUsers(false)
      setLoading(false)
    } catch (err) {
      console.log('err', err)
    }
  }

  useEffect(() => {
    const fetchInitialUsersList = async () => {
      await fetchUserList()
      setOverlaymsg("Users Fetched Successfully!")
      showOverlayForThreeSeconds()
    }
    
    fetchInitialUsersList()
  }, [config.selectedClientId])

  const fetchOfficeDetails = async(office_id: string) => {
    const data = {
      api: api.offices.getOfficeDetails,
      queryParam : { office_id: office_id}
    };
    const officeDetails = await backend.fetch(data);
    return officeDetails
  }

  const fetchOffices = async (selectedRole: string) => {
    setFetchingOffices(true)
    try {
      //fetching from Algolia instead of DB to get formatted details.
      const filters: string = (role === ROLES.SUPER_ADMIN || role === ROLES.RELATIONSHIP_MANAGER || super_user) ? "" : `office_id:${office_id} OR parent_office_ids:${office_id}`
      const response = await fetchOfficeListData(filters);
      console.log("office response:::", response)
      // const response = await backend.fetch(data)
      if (response && response.length > 0) {
        if (role === ROLES.SUPER_ADMIN) {
          if (selectedRole === ROLES.OFFICE_ADMIN) {
            let reducedOffices = response?.filter((office: any) => !office.deleted && !office.deactivate)?.reduce((filteredOffices: any, office: any) => {
              if (office.office_level !== '3') {
                let levelOneOffice = { ...office, value: office.office_id, label: office.office_name }
                filteredOffices.push(levelOneOffice);
              }
              return filteredOffices;
            }, []);
            setOfficesDropDown(reducedOffices)
          } else if (selectedRole === ROLES.OFFICE_MANAGER || selectedRole === ROLES.PARTNER || selectedRole === ROLES.VIEW_ONLY || selectedRole === ROLES.SALES_LIMITED || selectedRole === ROLES.SALES_LIMITED || selectedRole === ROLES.UNDER_WRITER) {
            let reducedOffices = response?.filter((office: any) => !office.deleted && !office.deactivate)?.reduce((filteredOffices: any, office: any) => {
              let dummyOffice = { ...office, value: office.office_id, label: office.office_name }
              filteredOffices.push(dummyOffice);
              return filteredOffices;
            }, []);
            setOfficesDropDown(reducedOffices)
          }
        } else if (role === ROLES.OFFICE_ADMIN || role === ROLES.OFFICE_MANAGER) {
          if(super_user){
            let reducedOffices = response?.filter((office: any) => !office.deleted && !office.deactivate)?.reduce((filteredOffices: any, office: any) => {
              if (selectedRole === ROLES.OFFICE_ADMIN) {
                if (office.office_level !== '3') {
                  let levelOneOffice = { ...office, value: office.office_id, label: office.office_name }
                  filteredOffices.push(levelOneOffice);
                }
              } else {
                let dummyOffice = { ...office, value: office.office_id, label: office.office_name }
                filteredOffices.push(dummyOffice);
              }
              return filteredOffices
            }, [])
            setOfficesDropDown(reducedOffices)
          } else {
            let officeDropDown = response?.filter((office: any) => !office.deleted && !office.deactivate)?.map((office: any) => {
              return { ...office, value: office.office_id, label: office.office_name }
            })
            setOfficesDropDown(officeDropDown)
            if (office_level === '1' && selectedRole === ROLES.OFFICE_ADMIN) {
              //SHOWING ONLY LEVEL TWO OFFICES IN DROPDOWN FOR LEVEL ONE OFFICE ADMIN
              let reducedOffices = response?.filter((office: any) => !office.deleted)?.reduce((filteredOffices: any, office: any) => {
                if (office.office_level === '2') {
                  let levelOneOffice = { ...office, value: office.office_id, label: office.office_name }
                  filteredOffices.push(levelOneOffice);
                }
                return filteredOffices;
              }, []);
              setOfficesDropDown(reducedOffices)
            }
            if (office_level === '2') {
              let currentOffice = response?.filter((office: any) => office?.office_id === office_id)
              let levelOneOffice = await fetchOfficeDetails(currentOffice[0].parent_office_id)
              setLevelOneOfficeDetails(levelOneOffice)
            }
          }
        } else if (role === ROLES.RELATIONSHIP_MANAGER) {
          if (selectedRole === ROLES.OFFICE_ADMIN) {
            let reducedOffices = response?.filter((office: any) => !office.deleted && !office.deactivate)?.reduce((filteredOffices: any, office: any) => {
              if(super_user){
                if (office.office_level !== '3') {
                  let levelOneOffice = { ...office, value: office.office_id, label: office.office_name }
                  filteredOffices.push(levelOneOffice);
                }
              } else{
                const rm_details = office?.allowed_RMs?.filter((rm_user: any) => user_id == rm_user.user_id)
                if (rm_details && rm_details.length > 0 && office.office_level !== '3') {
                  let dummyOffice = { ...office, value: office.office_id, label: office.office_name }
                  filteredOffices.push(dummyOffice);
                }
              }
              return filteredOffices;
            }, []);
            setOfficesDropDown(reducedOffices)
          } else if (selectedRole === ROLES.OFFICE_MANAGER || selectedRole === ROLES.VIEW_ONLY || selectedRole === ROLES.PARTNER || selectedRole === ROLES.SALES_LIMITED) {
            let reducedOffices = response?.filter((office: any) => !office.deleted && !office.deactivate)?.reduce((filteredOffices: any, office: any) => {
              if(super_user){
                let dummyOffice = { ...office, value: office.office_id, label: office.office_name }
                filteredOffices.push(dummyOffice);
              } else {
                const rm_details = office?.allowed_RMs?.filter((rm_user: any) => user_id == rm_user.user_id)
                if (rm_details && rm_details.length > 0) {
                  let dummyOffice = { ...office, value: office.office_id, label: office.office_name }
                  filteredOffices.push(dummyOffice);
                }
              }
              return filteredOffices;
            }, []);
            setOfficesDropDown(reducedOffices)
          }
        }
        setOffices(response?.filter((office: any) => !office.deleted))
      }
    } catch (error) {
      console.log(error)
      setOverlaymsg('Error while fetching offices')
    }
    finally {
      setFetchingOffices(false)
    }
  }

  const fetchProcessorsData = async () => {
    setFetchingProcessors(true)
    const data = {
      api: api.processorData.getProcessors
    }
    try{
      const response = await backend.fetch(data)
      if (response) {
        response.sort(
          firstBy(function (option: any) {
            return option?.processor_name?.toLowerCase();
          })
        )
        const options = response?.map((item: any) => {
          const dummy = {
            value: REACT_APP_BACKEND_MYSQL ? item.processor_internal_id : item.processor_code,
            label: item.processor_name
          }
          return dummy
        })
        setAllProcessors(options)
      }
    }catch(err){
      console.log(err)
      setOverlaymsg('Error while fetching processors')
    }finally{
      setFetchingProcessors(false)
    }
  }

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const showOverlayForThreeSeconds = async () => {
    const sstyle: any = { style: { display: 'block', position: 'fixed' } };
    setOverlay(sstyle);
    await sleep(2000);
    const hstyle: any = { style: { display: "none" } };
    setOverlay(hstyle);
  };

  useEffect(() => {
    if (!!firstName && !!lastName && !!emailValid && !!selectedRole.value && (selectedRole.value !== ROLES.RELATIONSHIP_MANAGER ? selectedOffice?.value : true)) {
        if( role === ROLES.OFFICE_ADMIN && editUserDetails.role_code === ROLES.OFFICE_ADMIN ) {
          setUserCreationError("OfficeAdmin can't perform edit operations on OfficeAdmin")
          setCreateUserEnabled(false)
        }else{
          setCreateUserEnabled(true)
        }
    } else {
      setCreateUserEnabled(false)
    }
  }, [firstName, lastName, emailValid, selectedRole, selectedProcessors, selectedRiskLevel, selectedRM, selectedOffice])

  const formatDate = (date: any) => {
    var d = new Date(date)
    var month = '' + (d.getMonth() + 1)
    var day = '' + d.getDate()
    var year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [month, day, year].join('-')
  }

  const fetchRmUsers = async () => {
    setFetchingRms(true)
    const data: any = {
      api: api.users.getRmUsers,
    }
    try {
      const response = await backend.fetch(data)
      if (response && response.data.length > 0) {
        const rm_users = response.data?.map((item: any) => {
          const dummy = {
            ...item,
            value: item.user_name.first_name + " " + item.user_name.last_name,
            label: item.user_name.first_name + " " + item.user_name.last_name
          }
          return dummy
        })
        setRmUsers(rm_users)
      }
    } catch (error) {
      console.log(error)
    }finally{
      setFetchingRms(false)
    }
  }

  const updateDropDownOptions = (selectedOffice?: any) => {
    let selected_office_level = parseInt(selectedOffice.office_level)
    let dummyOffice: any = selectedOffice
    let dummyLowRiskProcessors: any[] = []
    let dummyHighRiskProcessors: any[] = []
    let dummyRms: any[] = []
    let dummyRiskLevels: any[] = []
    for (let i = selected_office_level; i > 0; i--) {
      if (i < selected_office_level) {
        let currentIndex = offices.findIndex((office: any) => office.office_id === dummyOffice?.parent_office_id)
        dummyOffice = offices[currentIndex]
      }
      if (dummyHighRiskProcessors?.length > 0 && dummyLowRiskProcessors?.length > 0 && dummyRms?.length > 0 && dummyRiskLevels?.length > 0) {
        break;
      } else {
        if (!dummyHighRiskProcessors || dummyHighRiskProcessors?.length === 0) {
          dummyHighRiskProcessors = getParsedList(dummyOffice?.allowed_high_risk_processors)
        }
        if (!dummyLowRiskProcessors || dummyLowRiskProcessors?.length === 0) {
          dummyLowRiskProcessors = getParsedList(dummyOffice?.allowed_low_risk_processors)
        }
        if (!dummyRms || dummyRms?.length === 0) {
          dummyRms = getParsedList(dummyOffice?.allowed_RMs)
        }
        if (!dummyRiskLevels || dummyRiskLevels?.length === 0) {
          dummyRiskLevels = getParsedList(dummyOffice?.allowed_risk_levels)
        }
      }
    }
    //WHEN LEVEL TWO OFFICE LOGGED IN, SERCHING DATA FROM LEVEL ONE OFFICE
    if (( !dummyHighRiskProcessors || dummyHighRiskProcessors?.length === 0) && ( !dummyLowRiskProcessors || dummyLowRiskProcessors?.length === 0) && (!dummyRms || dummyRms?.length === 0) && (!dummyRiskLevels || dummyRiskLevels?.length === 0)) {
      if ( !dummyHighRiskProcessors || dummyHighRiskProcessors?.length === 0) {
        dummyHighRiskProcessors = levelOneOfficeDetails?.allowed_high_risk_processors
      }
      if ( !dummyLowRiskProcessors || dummyLowRiskProcessors?.length === 0) {
        dummyLowRiskProcessors = levelOneOfficeDetails?.allowed_low_risk_processors
      }
      if (!dummyRms || dummyRms?.length === 0) {
          dummyRms = levelOneOfficeDetails?.allowed_RMs
      }
      if (!dummyRiskLevels || dummyRiskLevels?.length === 0) {
          dummyRiskLevels = levelOneOfficeDetails?.allowed_risk_levels
      }
    }
  
    setHighRiskProcessors(allProcessors?.filter((processor: any)=> dummyHighRiskProcessors?.includes(processor.value)&& cdBanks.indexOf(processor.value) === -1))
    setLowRiskProcessors(allProcessors?.filter((processor: any)=> dummyLowRiskProcessors?.includes(processor.value) && processor.value !== BANK_CODES.DART))

    setRmUsers(dummyRms?.map((item: any) => {
      return {
        ...item,
        value: `${item?.user_name?.first_name} ${item?.user_name?.last_name}`,
        label: `${item?.user_name?.first_name} ${item?.user_name?.last_name}`
      }
    }))

    setRiskLevels(dummyRiskLevels?.map((item: any) => {
      return {
        value: item,
        label: item
      }
    }))
    //FETCHING DATA FROM BACK-END
    if (!dummyLowRiskProcessors || dummyLowRiskProcessors?.length === 0) {
      setLowRiskProcessors(allProcessors?.filter((processor: any)=> processor.value !== BANK_CODES.DART))
    }
    if (!dummyHighRiskProcessors || dummyHighRiskProcessors?.length === 0) {
      setHighRiskProcessors(allProcessors?.filter((processor: any)=> !cdBanks.includes(processor.value)))
    }
    if (!dummyRms || dummyRms?.length === 0) {
      fetchRmUsers()
    }
    if (!dummyRiskLevels || dummyRiskLevels?.length === 0) {
      setRiskLevels(allRiskLevels)
    }
  }

  const handleSelectedRole = (item: any) => {
    
    if (item.value !== ROLES.RELATIONSHIP_MANAGER ) {
      setSelectedOffice({})
      setSelectedRiskLevel([])
      setSelectedRM([])
      setSelectedHighRiskProcessors([])
      setSelectedLowRiskProcessors([])
      fetchOffices(item.value)
    }
    setSelectedRole(item);
    setFreeEquipmentPlacementEnabled(false)
  }

  const handleSelectedRM = (item: any) => {
    setSelectedRM(item)
  }

  const handleSelectedOffice = (item: any) => {
    setSelectedOffice(item)
    setSelectedHighRiskProcessors([])
    setSelectedLowRiskProcessors([])
    setSelectedRiskLevel([])
    if (selectedRole.value !== ROLES.RELATIONSHIP_MANAGER) {
      updateDropDownOptions(item)
      setFreeEquipmentPlacementEnabled(false)
    }
  }

  const emailValidation = (value: any) => {
    if (!/^[^\s@]+@[^\s/@]+\.[^\s@]+$/.test(value)) {
      setEmailError('Invalid email id')
      setEmailValid(false)
      setEmail(value)
    } else {
      setEmailError('')
      setEmailValid(true)
      setEmail(value)
    }
  }

  const handleSelectedLowRiskProcessors = (value: any) => {
    setSelectedLowRiskProcessors(value)
  }

  const handleSelectedHighRiskProcessors = (value: any) => {
      setSelectedHighRiskProcessors(value)
  }

  const handleSelectRiskLevel = (selectedRiskLevel: any) => {
    setSelectedRiskLevel(selectedRiskLevel)
    const allowed_risk_levels = selectedRiskLevel.map((item: any) => { return item.value })
    if(edit){
      if(!allowed_risk_levels.includes(RISK_LEVELS.HIGH_RISK)){
        setSelectedHighRiskProcessors([])
      }else{
        const allowed_highrisk_processors = getParsedList(editUserDetails?.allowed_high_risk_processors).map((processor: string) => {
          return {
              value: processor,
              label: processor
          }
        })
        setSelectedHighRiskProcessors(allowed_highrisk_processors)
      }
      if(!allowed_risk_levels.includes(RISK_LEVELS.LOW_RISK)){
        setSelectedLowRiskProcessors([])
      }else{        
        const allowed_lowrisk_processors = getParsedList(editUserDetails?.allowed_low_risk_processors).map((processor: string) => {
          return {
              value: processor,
              label: processor
          }
        })
        setSelectedLowRiskProcessors(allowed_lowrisk_processors)
      }
    }
  }

  const handleUpdateUser = async () => {
    let userDetails: any = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      role_code: selectedRole.value,
      user_id: editUserDetails.user_id,
      guid: editUserDetails.user_guid,
      // free_equipment_placement_enabled: freeEquipmentPlacementEnabled
    }
    if (editUserDetails.partner_id) {
      userDetails.partner_id = editUserDetails.partner_id
    }
    if (selectedRM?.length > 0) {
      userDetails.rmUserDetails = selectedRM
    }
    if (selectedRiskLevel?.length > 0 || (selectedRiskLevel?.length === 0 && editUserDetails.riskLevels?.length > 0)) {
      userDetails.selectedRiskLevel = selectedRiskLevel
    }
    /*
      selectedLowRiskProcessors?.length > 0 --> users is opted for some banks
      (selectedLowRiskProcessors?.length === 0 && editUserDetails.allowedProcessors?.length > 0)  --> removed already opted banks while editing
    */
    if (selectedLowRiskProcessors?.length > 0 || (selectedLowRiskProcessors?.length === 0 && editUserDetails.allowed_low_risk_processors?.length > 0)) {
      userDetails.allowed_low_risk_processors = selectedLowRiskProcessors
    }
    if (selectedHighRiskProcessors?.length > 0 || (selectedHighRiskProcessors?.length === 0 && editUserDetails.allowed_high_risk_processors?.length > 0)) {
      userDetails.allowed_high_risk_processors = selectedHighRiskProcessors
    }
    if (selectedOffice && selectedOffice.value) {
      userDetails.office_id = selectedOffice.office_id
      userDetails.office_name = selectedOffice.office_name
    }
   
    //user update
    const data: any = {
      api: api.users.updateUser,
      payLoad: JSON.stringify(userDetails)
    }
    try {
      const response = await backend.save(data)
      if (response.data) {
        setOverlaymsg('User updated successfully!')
        handleCancel()
        setEdit(false)
        window.scroll({ top: 0, left: 0 });
        showOverlayForThreeSeconds()
        setLoading(false)
        uncheckparentcheckbox()        
        await fetchUserList()
      }else{
        setUserCreationError(response.message || "Something went wrong while updating user!")
        setOverlaymsg("Something went wrong while updating user!")
        showOverlayForThreeSeconds()
        setLoading(false)
      }
    } catch (err: any) {
      setUserCreationError(err.errMessage || "Something went wrong while updating user!")
      setLoading(false)
    }
  }

  const createUser = async () => {
    let userDetails: any = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      role_code: selectedRole.value,
      iris_enabled: irisEnabled,
      feenavigator_enabled: feenavigatorEnabled,
      free_equipment_placement_enabled: freeEquipmentPlacementEnabled
    }
    if (selectedRM?.length > 0) {
      userDetails.rmUserDetails = selectedRM
    }
    if (selectedRiskLevel?.length > 0) {
      userDetails.selectedRiskLevel = selectedRiskLevel
    }
    if (selectedHighRiskProcessors && selectedHighRiskProcessors.length > 0) {
      userDetails.allowed_high_risk_processors = selectedHighRiskProcessors
    }
    if (selectedLowRiskProcessors && selectedLowRiskProcessors.length > 0) {
      userDetails.allowed_low_risk_processors = selectedLowRiskProcessors
    }
    if (selectedOffice && selectedOffice.value) {
      userDetails.office_id = selectedOffice.office_id
      userDetails.office_name = selectedOffice.office_name
    }
    const data: any = {
      api: api.users.createUser,
      payLoad: JSON.stringify(userDetails)
    }
    try {
      const response = await backend.save(data)
      if (response) {
        if (response.data) {
          // if( role === ROLES.RELATIONSHIP_MANAGER ){
          //   const rm_details = response?.data?.rm_details?.filter((rm_user: any) => user_id === rm_user.user_id)
          //     if (rm_details && rm_details.length > 0) {
          //       setUsersList([ response.data, ...usersList])
          //     }   
          // }else{
          //   setUsersList([ response.data, ...usersList])
          // }
          setOverlaymsg(response.message)
          handleCancel()
          window.scroll({ top: 0, left: 0 });
          showOverlayForThreeSeconds()
          fetchUserList()
        }else{
          setUserCreationError(response.message)
        }
        uncheckparentcheckbox()
      } else {
        setOverlaymsg("Something went wrong while creating user!")
        setUserCreationError("Something went wrong while creating user!")
        showOverlayForThreeSeconds()
      }
    }
    catch (err: any) {
      if (err?.code === "auth/email-already-in-use" || err?.errCode === "auth/email-already-exists") {
        setEmailError(err.errMessage)
        setEmailValid(false)
      }else{
        setUserCreationError(err.errMessage || "Something went wrong while creating user!")
      }
    }finally{
      setLoading(false)
    }

  }

  const deleteUser = async () => {
    setDeleteModalOpen(false)
    setLoading(true)
    let userDetails: any = {
      guid: selectedUser.user_guid,
      user_id: selectedUser.user_id,
      role: selectedUser.role_code,
    }
    if (selectedUser.role_code === ROLES.PARTNER || selectedUser.role_code === ROLES.VIEW_ONLY || selectedUser.role_code === ROLES.UNDER_WRITER || selectedUser.role_code === ROLES.SALES_LIMITED) {
      userDetails.partner_id = selectedUser.partner_id
    } else if (selectedUser.role_code === ROLES.RELATIONSHIP_MANAGER) {
      userDetails.rm_id = selectedUser.user_id
    } else if(selectedUser.role_code === ROLES.OFFICE_ADMIN || selectedUser.role_code === ROLES.OFFICE_MANAGER){
      userDetails.office_id = selectedUser.office_id
      userDetails.partner_id = selectedUser?.partner_id
    }
    const data: any = {
      api: api.users.deleteUser,
      payLoad: JSON.stringify(userDetails)
    }

    try {
      const response = await backend.save(data)
      if (response) {
        uncheckparentcheckbox()
        let updatedUsers = usersList?.map((user: any) => {
          if(user.user_id === selectedUser.user_id){
              return { ...user, deleted: true }
          }else{
            //REMOVING DELETED RM's FOR ALL USERS, WHO IS LINKED WITH DELTED RM
            if(user.role_code !== ROLES.RELATIONSHIP_MANAGER && user.rm_details && user.rm_details.length > 0){
              const rm_details = user.rm_details?.filter((rm_user: any) => selectedUser.user_id !== rm_user.user_id)
              return { ...user, rm_details: rm_details }
            }else{
                return user
            }
          }
        })
        setUsersList(updatedUsers)
        window.scroll({ top: 0, left: 0 });
        setOverlaymsg(response.message)
        showOverlayForThreeSeconds()
        handleCancel()
        setLoading(false)
        setSelectedUserBulk([])
      }else{
        handleCancel()
        setOverlaymsg("Something went wrong while deleting user!")
        showOverlayForThreeSeconds()
        setLoading(false)
      }
    }
    catch (err: any) {
      handleCancel()
      setOverlaymsg(err.errMessage || "Something went wrong while deleting user!")
      showOverlayForThreeSeconds()
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setEmail('')
    setFirstName('')
    setLastName('')
    setSelectedRole('')
    setSelectedRiskLevel([])
    setSelectedRM([])
    setSelectedHighRiskProcessors([])
    setSelectedLowRiskProcessors([])
    setSelectedOffice({})
    setShowCreateUserModal(false)
    setEdit(false)
    setEditUserDetails({})
    setUserCreationError('')
    setDeleteModalOpen(false)
    setWarningModalOpen(false)
    setSearchText('')
    setSearchField('')
    setNoSearchResultModal(false)
    setOpenEnableIRISModal(false)
    setIrisEnabled(false)
    setOpenEnableFeenavigatorModal(false)
    setFeenavigatorEnabled(false)
    setFreeEquipmentPlacementEnabled(false)
    setOpenEnableFreeEquipmentPlacementModal (false)
    setModalForgetpassword(false)
    setGeneratedPassword("")
    setErrResponse(false)
    setDeactivateModalOpen(false)
    setSelectedUser({})
    setPasswordUpdateBulk(false)
    setPasswordUpdateConfirmation(false)
    setUserActiveDeactiveBulkConfirmation(false)
    setBulkaction('')
    setWarningModalOpenBulk(false)
    setDetails({})

  }

  const handleSaveUser = async () => {
    setLoading(true)
    if (edit) {
      handleUpdateUser()
    } else {
      createUser()
    }
  }

  useEffect(() => {
    emailValidation(email)
  }, [email])

  const handleDeleteUser = async (user_id: number) => {

    const editUserIndex = usersList.findIndex((user: any) => user.user_id === user_id)

    const current_user_data = usersList[editUserIndex]

    if(role === ROLES.OFFICE_ADMIN && current_user_data.role_code === ROLES.OFFICE_ADMIN ){
      setWarningModalOpen(true)
    }else{
      setSelectedUser(current_user_data)
      setDeleteModalOpen(true)
    }
  }

  useEffect(() => {
    if (edit && officesDropDown) {
      if( selectedRole.value === ROLES.OFFICE_ADMIN ) {
        let parentOfficeIndex = officesDropDown?.findIndex((office: any) => editUserDetails.office_id === office.office_id)
        if (parentOfficeIndex >= 0) {
          handleSelectedOffice({ ...officesDropDown[parentOfficeIndex], value: officesDropDown[parentOfficeIndex].office_name, label: officesDropDown[parentOfficeIndex].office_name })
        }
      }else if( selectedRole.value === ROLES.PARTNER || selectedRole.value === ROLES.OFFICE_MANAGER || selectedRole.value === ROLES.VIEW_ONLY || selectedRole.value === ROLES.SALES_LIMITED || selectedRole.value === ROLES.UNDER_WRITER) {
        let parentOfficeIndex = officesDropDown?.findIndex((office: any) => editUserDetails.office_id === office.office_id)
        if (parentOfficeIndex >= 0) {
          handleSelectedOffice({ ...officesDropDown[parentOfficeIndex], value: officesDropDown[parentOfficeIndex].office_name, label: officesDropDown[parentOfficeIndex].office_name })
        }
      }
    }
  }, [edit, officesDropDown, offices])

  useEffect(() => {
    if (edit) {
      let selected_rms: any = rmUsers?.filter((item: any) => {
        let data = editUserDetails?.rm_details?.filter((rm_details: any) => {
          if (item.user_id === rm_details.user_id) {
            return item
          }
        })
        if (data && data.length > 0) {
          return data[0]
        }
      }) 
      setSelectedRM(selected_rms)
    }
  }, [rmUsers])

  useEffect(() => {
    if (edit && selectedRole.value !== ROLES.VIEW_ONLY) {
      let selected_low_risk_processors = allProcessors?.filter((item: any) => { return editUserDetails?.allowed_low_risk_processors?.includes(item.value) })
      handleSelectedLowRiskProcessors(selected_low_risk_processors)
      let selected_high_risk_processors = allProcessors?.filter((item: any) => { return editUserDetails?.allowed_high_risk_processors?.includes(item.value) })
      handleSelectedHighRiskProcessors(selected_high_risk_processors)
      let priceRiskLevel = riskLevels?.filter((item: any) => { return editUserDetails?.allowed_risk_levels?.includes(item.value) })
      handleSelectRiskLevel(priceRiskLevel)
    }
  }, [ lowRiskProcessors, highRiskProcessors,  riskLevels, selectedRole ])

  const handleEditUser = async (user_id: number) => {

    const editUserIndex = usersList.findIndex((user: any) => user.user_id === user_id)

    const current_user_data = usersList[editUserIndex]

    if(role === ROLES.OFFICE_ADMIN && current_user_data.role_code === ROLES.OFFICE_ADMIN ){
      setWarningModalOpen(true)
    }else{
      setEdit(true)
      setShowCreateUserModal(true)
      setEditUserDetails(current_user_data)
      setEmail(current_user_data.user_email)
      setFirstName(REACT_APP_BACKEND_MYSQL ? current_user_data.user_name_first : current_user_data.user_name.first_name)
      setLastName(REACT_APP_BACKEND_MYSQL ? current_user_data.user_name_last : current_user_data.user_name.last_name)
      setFeenavigatorEnabled(current_user_data.feenavigator_enabled)
      if (current_user_data.role_code === ROLES.RELATIONSHIP_MANAGER) {
        handleSelectedRole(superAdminCreateRoles[0])
      } else if (current_user_data.role_code === ROLES.OFFICE_ADMIN) {
        handleSelectedRole(superAdminCreateRoles[1])
      } else if (current_user_data.role_code === ROLES.PARTNER) {
        handleSelectedRole(officeAdminCreateRoles[0])
      } else if (current_user_data.role_code === ROLES.OFFICE_MANAGER) {
        handleSelectedRole(officeAdminCreateRoles[1])
      } else if (current_user_data.role_code === ROLES.VIEW_ONLY) {
        handleSelectedRole(superAdminCreateRoles[4])
      } else if (current_user_data.role_code === ROLES.UNDER_WRITER) {
        handleSelectedRole(superAdminCreateRoles[5])
      }else if (current_user_data.role_code === ROLES.SALES_LIMITED) {
        handleSelectedRole(superAdminCreateRoles[6])
      }
    }

  }

  const handleResetPassword = async(email: string) => {
       setModalForgetpassword(true)
       setUserEmail(email)
  }
  const handleGeneratePassword = async() => {
    setGeneratedPassword(generatePassword(16)) 
  }
  const setNewPassword = async (email:string,password:string) => {
    setLoading(true)
    try{
        const Data: any = {
        api: api.users.resetUserPassword,
        payLoad: JSON.stringify({
          email:  email,
          new_password: password
          })
        }
        let response = await backend.save(Data);
        setOverlaymsg('Password Reset Successful')
        showOverlayForThreeSeconds()
        setModalForgetpassword(false)
        setGeneratedPassword("")
        setErrResponse(false)
        uncheckparentcheckbox()
      }
     catch ( err : any){
      setErrResponse(true)
      setErrmsgResp(err.errMessage)
     }
    finally{
        setLoading(false)
      } 
  }

  const setNewPasswordBulk = async () => {
    setLoading(true)
    try{
        const Data: any = {
        api: api.users.resetUserPasswordBulk,
        payLoad: JSON.stringify({
          userlist:  selectedUserBulk,
          })
        }
        let response = await backend.save(Data);
        uncheckparentcheckbox()
        setOverlaymsg('Password Reset Successful')
        showOverlayForThreeSeconds()
        setPasswordUpdateConfirmation(false)
        setGeneratedPassword("")
        setErrResponse(false)

      }
     catch ( err : any){
      setErrResponse(true)
      setErrmsgResp(err.errMessage)
     }
    finally{
        setLoading(false)
      } 
  }
  const resetErrResponse = async() => {
    setErrResponse(false)
    setErrmsgResp("")
  }
  const updateIRESEnabledStatus = async () => {
    setLoading(true)
      const payload: any = {
        api: api.users.enableIRISAccess,
        payLoad: JSON.stringify({
          guid: REACT_APP_BACKEND_MYSQL ? selectedUser.user_id : selectedUser.user_guid,
          iris_enabled: irisEnabled || false
        })
      }
      try {
        const response = await backend.save(payload, true)
        if (response && response.data) {
          // setUsersList(usersList.map((user:any)=>{
          //   if(user.user_id === selectedUser.user_id){
          //     return {...user, iris_enabled:irisEnabled}
          //   }else{
          //     return user
          //   }
          // }))
          // setSortAndSearchResultData(sortAndSearchResultData.map((user:any)=>{
          //   if(user.user_id === selectedUser.user_id){
          //     return {...user, iris_enabled:irisEnabled}
          //   }else{
          //     return user
          //   }
          // }))
          setOverlaymsg(response.message)
          handleCancel()
          window.scroll({ top: 0, left: 0 });
          showOverlayForThreeSeconds()
          await fetchUserList()
        } else {
          setOverlaymsg(response.message || 'Something went wrong while updating user!')
          showOverlayForThreeSeconds()
        }
      }
      catch (err: any) {
        console.log(err)
        setOverlaymsg('Something went wrong while updating user!')
        showOverlayForThreeSeconds()
      }finally{
        setLoading(false)
      }
  }
  const uncheckparentcheckbox = async()=>{
    setBulkaction('')
    let myUsers = [...usersList];
    setIsParentChecked(false)
    myUsers.map((user) => {
      user.isChecked = false
      return user;
    });
    setSelectedUserBulk([])
    setShowOfficeStatusonActivate(false)
  }

  let finalarray : any[] = [];
  const changeCheckboxStatus = async(e:any, id:string) => {
   setBulkaction('')
   let removeDeletedUser  = usersList.filter((user: any) => user.deleted !== 1)
   const myUsers = [...removeDeletedUser];
    const { checked } = e.target;
    if (id === "p1" ) {
      finalarray = [] 
      setSelectedUserBulk([])
    }
    myUsers.map((user) => {
      if (id === "p1") {
        setIsParentChecked(checked);
        user.isChecked = checked;
        if(e.target.checked){
         finalarray.push({'user_id':user.user_id,'user_guid':user.user_guid, 'user_email' : user.user_email, 'user_status' : Boolean(user.deactivate) ,
         'role_code' : user.role_code,'office_id' : user.office_id , 'user_name' : user.user_name_first+' ' +user.user_name_last, 'client_id': user.client_id})
         setSelectedUserBulk(finalarray)
        }
      } else {
        if (user.user_email === id) {
          user.isChecked = checked;
          let checkID =  selectedUserBulk.findIndex((user: any) => user.user_email === id)
          if(checkID === -1){
            selectedUserBulk.push({'user_id':user.user_id,'user_guid':user.user_guid,'user_email' : user.user_email, 'user_status' : Boolean(user.deactivate), 
            'role_code' : user.role_code, 'office_id' : user.office_id, 'user_name' : user.user_name_first+' ' +user.user_name_last, 'client_id': user.client_id})
            setSelectedUserBulk(selectedUserBulk)
          }
          else{
            let secondRemoved  = selectedUserBulk.filter((user: any) => user.user_email !== id)
            setSelectedUserBulk(secondRemoved)
          }
        }
        const isAllChildsChecked = myUsers.every(
          (user) => user.isChecked === true
        );
        if (isAllChildsChecked) {
          setIsParentChecked(checked);
        } else {
          setIsParentChecked(false);
        }
      }
      return user;
    });
   
    setUsers([...myUsers]);
  };

  const handleDeactivateModal = async (user_id:number)=>{
    const editUserIndex = usersList.findIndex((user: any) => user.user_id === user_id)
    const current_user_data = usersList[editUserIndex]
      if(role === ROLES.OFFICE_ADMIN && current_user_data.role_code === ROLES.OFFICE_ADMIN ){
        setWarningModalOpen(true)
      }else{
        setSelectedUser(current_user_data)
        setDeactivateModalOpen(true)
      }
  }

  const handleActivateDeActivate = async (user_guid: any, user_id: any, user_email: any, deactivate: boolean, role_code: string) => {
    setLoading(true)
    const userDetails: any = {
      guid: user_guid,
      user_id: user_id,
      user_email: user_email,
      role_code: role_code
    }
    console.log(user_id, deactivate)
    const data: any = {
      api:deactivate?  api.users.deactivateUser : api.users.activateUser,
      payLoad: JSON.stringify(userDetails)
    };
    try {
      const response = await backend.save(data)
      if (response && response.data) {
        uncheckparentcheckbox()
        let deactivatedUsers = usersList?.map((user: any) => {
          if (user.user_id === selectedUser.user_id) {
            return { ...user, deactivate:deactivate }
          } else {
            return user
          }
        })
        
        setUsersList([])
        setUsersList(deactivatedUsers)
        setOverlaymsg(deactivate? "User deactivated successfully" : "User activated successfully" );
        showOverlayForThreeSeconds()
        setDeactivateModalOpen(false)
      }
    }
    catch (err: any) {
      setDeactivateModalOpen(false)
      console.log(err)
      setOverlaymsg(err.errMessage || "Error occured while deactivating user.");
      showOverlayForThreeSeconds()
    } finally {
      window.scroll({ top: 0, left: 0 });
              setLoading(false)
    }
  }

  const handleActivateDeActivateBulk = async (deactivate: boolean) => {
    setLoading(true)
    console.log(user_id, deactivate)
    let officestatus = false
    let newUsers
    const forMessage = [...selectedUserBulk]
    if(!deactivate){
      let checkforRM = selectedUserBulk.filter((item: any) => item.role_code !== "RM");
      
      if(checkforRM.length === 0){
        officestatus = false
        newUsers = selectedUserBulk;
      }
      else{
        newUsers = checkforRM;
      }
      
     if(checkforRM.length > 0) {
        const data: any = {
          api: api.users.getOfficeDetails,
          payLoad: JSON.stringify({userlist:  newUsers})
        };
        const response = await backend.save(data)
        console.log(response.length)
        if(response.length > 0){
          uncheckparentcheckbox()
          setShowOfficeStatusonActivate(true)
          setUserNames(response)
          officestatus = true
          setUserActiveDeactiveBulkConfirmation(false)
          let count = response.length > 1 ? "users" : "user"
          let message = "Cannot activate the " + count + response + " of deactivated office."
          console.log(message)
          setLoading(false)
        }
     }
   }
   if(!officestatus || deactivate){
      const data: any = {
        api:deactivate?  api.users.deactivateUserBulk : api.users.activateUserBulk,
        payLoad: JSON.stringify({userlist:  selectedUserBulk})
      };
      try {
        const response = await backend.save(data)
        setUsersList([])
        console.log(response.length)
        if (response && response.length) {
        uncheckparentcheckbox()
        let deactivatedUsers = usersList?.map((user: any) => {
            if(selectedUserBulk.findIndex((bulkuser: any) => bulkuser.user_id === user.user_id) > -1){
            return  { ...user, deactivate:deactivate}
            }else{
              return user
            }
          })
          setUsersList(deactivatedUsers)
          if(deactivate){
            setOverlaymsg(forMessage.length > 1? "Users deactivated successfully" : "User deactivated successfully" );
          }else{
            setOverlaymsg(forMessage.length > 1? "Users activated successfully" : "User activated successfully" );
          }
          
          showOverlayForThreeSeconds()
          setUserActiveDeactiveBulkConfirmation(false)
        }
      }
      catch (err: any) {
        setUserActiveDeactiveBulkConfirmation(false)
        console.log(err)
        setOverlaymsg(err.errMessage || "Error occured while deactivating user.");
        showOverlayForThreeSeconds()
      } finally {window.scroll({ top: 0, left: 0 }); setLoading(false) } 

    }
  
  }

  const handleIRISChecked = async(data: any, user_id: string) => {

    const editUserIndex = usersList.findIndex((user: any) => user.user_id === user_id)

    const current_user_data = usersList[editUserIndex]
    setIrisEnabled(data.checked)

    if(role === ROLES.OFFICE_ADMIN && current_user_data.role_code === ROLES.OFFICE_ADMIN ){
      setWarningModalOpen(true)
    }else{
      setSelectedUser(current_user_data)
      setOpenEnableIRISModal(true)
    }
  }
// Feenavigator

  const updateFeenavigatorEnabledStatus = async () => {
    try {
      console.log('selectedUser', selectedUser)
      setLoading(true);
      const payload: any = {
        api: api.users.enableFeenavigatorAccess,
        payLoad: JSON.stringify({
          guid: REACT_APP_BACKEND_MYSQL ? selectedUser.user_id : selectedUser.user_guid,
          feenavigator_enabled: feenavigatorEnabled || false,
        }),
      };
      const response = await backend.save(payload, true);
      if (response && response.data) {
        // setUsersList(
        //   usersList.map((user: any) => {
        //     if (user.user_id === selectedUser.user_id) {
        //       return { ...user, feenavigator_enabled: feenavigatorEnabled };
        //     } else {
        //       return user;
        //     }
        //   })
        // );
        // setSortAndSearchResultData(
        //   sortAndSearchResultData.map((user: any) => {
        //     if (user.user_id === selectedUser.user_id) {
        //       return { ...user, feenavigator_enabled: feenavigatorEnabled };
        //     } else {
        //       return user;
        //     }
        //   })
        // );
        setOverlaymsg(response.message);
        handleCancel();
        window.scroll({ top: 0, left: 0 });
        showOverlayForThreeSeconds();
        await fetchUserList()
      } else {
        setOverlaymsg(
          response.message || "Something went wrong while updating user!"
        );
        showOverlayForThreeSeconds();
      }
    } catch (err: any) {
      console.log(err);
      setOverlaymsg("Something went wrong while updating user!");
      showOverlayForThreeSeconds();
    } finally {
      setLoading(false);
    }
  };
  const updateFreeEquipmentPlacementStatus = async () => {
 
    try {
      setLoading(true);
      const payload: any = {
        api: api.users.enableFreeEquipmentPlacementSubmission,
        payLoad: JSON.stringify({
          guid: REACT_APP_BACKEND_MYSQL?selectedUser.user_id:selectedUser.user_guid,
          free_equipment_placement_enabled: freeEquipmentPlacementEnabled || false,
        }),
      };
      const response = await backend.save(payload, true);
      if (response && response.data) {
        setUsersList(
          usersList.map((user: any) => {
            if (user.user_id === selectedUser.user_id) {
              return { ...user, free_equipment_placement_enabled: freeEquipmentPlacementEnabled };
            } else {
              return user;
            }
          })
        );
        setSortAndSearchResultData(
          sortAndSearchResultData.map((user: any) => {
            if (user.user_id === selectedUser.user_id) {
              return { ...user, free_equipment_placement_enabled: freeEquipmentPlacementEnabled };
            } else {
              return user;
            }
          })
        );
        setOverlaymsg(response.message);
        handleCancel();
        window.scroll({ top: 0, left: 0 });
        showOverlayForThreeSeconds();
      } else {
        setOverlaymsg(
          response.message || "Something went wrong while updating user!"
        );
        showOverlayForThreeSeconds();
      }
      setOpenEnableFreeEquipmentPlacementModal(false);
    } catch (err: any) {
      console.log(err);
      setOverlaymsg("Something went wrong while updating user!");
      showOverlayForThreeSeconds();
      setOpenEnableFreeEquipmentPlacementModal(false);
    } finally {
      setLoading(false);
    }
  };
  

  const handleFeenavigatorChecked = async (data: any, user_id: string) => {
    const editUserIndex = usersList.findIndex((user: any) => user.user_id === user_id)
    const current_user_data = usersList[editUserIndex]
    setFeenavigatorEnabled(data.checked)
    if (role === ROLES.OFFICE_ADMIN && current_user_data.role_code === ROLES.OFFICE_ADMIN) {
      setWarningModalOpen(true)
    } else {
      setSelectedUser(current_user_data)
      setOpenEnableFeenavigatorModal(true)
    }
  }
  const handleFreeEquipmentPlacementChecked = async (data: any, id: string) => {
    const editUserIndex = usersList.findIndex((user: any) => user.user_id === id)
    const current_user_data = usersList[editUserIndex]
    setSelectedUser(current_user_data)

    setFreeEquipmentPlacementEnabled(data.checked)
    if (role === ROLES.OFFICE_ADMIN && current_user_data.role_code === ROLES.OFFICE_ADMIN) {
      setWarningModalOpen(true)
    } else {
      setSelectedUser(current_user_data)
      setOpenEnableFreeEquipmentPlacementModal(true)
    }
}  
  //Searching
  const handleSearchByFieldName = async (field: string, value: string) => {
    if(!noSearchResultModal){
      if (!!value) {
        setSearchField(field);
      } else {
        setSearchField("");
      }
      setSearchText(value);
    }
  }

  //Bulk Update
  const bulkUpdateAction  = (item: any) => {
    setBulkaction(item)
    setModalAction(item.label)
    console.log(selectedUserBulk)
    console.log('role',role)

    if(item.value === 'Activate user' || item.value === 'Deactivate user'){
      let checkOfficeAdmin = selectedUserBulk.filter((user: any) => user.role_code === 'OFFICEADMIN')
      setOfficeAdminBulk(checkOfficeAdmin)
      if(role === ROLES.OFFICE_ADMIN && checkOfficeAdmin.length >0){
        setWarningModalOpenBulk(true)
      }else{
          setWarningModalOpen(false)
          let userStatus = item.value === 'Activate user'  ? false : true
          let checkUserStatus = selectedUserBulk.filter((item: any) => item.user_status === userStatus);
          const userStatusCount = checkUserStatus.length
          console.log(userStatusCount)
          setCountforAction(userStatusCount)
          setUserActiveDeactiveBulkConfirmation(true)
      }
    }
    else{
      setPasswordUpdateConfirmation(true)
    }
  }


  const handleConfirmAction = (action :any) =>{
    setPasswordUpdateConfirmation(false)
    if(action === 'Reset password'){
      setNewPasswordBulk()
    }
    else if(action === 'Activate' || action === 'Deactivate'){
      let userAction =  action === 'Deactivate' ? true : false
      handleActivateDeActivateBulk(userAction)
    }
  }

  //Sorting
  const handleSortByFieldName = async (field: string, order: string) => {
    setSortFieldOrder(sortFieldOrder === "desc" ? "asc" : "desc")
    setSortField(field);
    let usersTemp = sortAndSearchResultData;

    usersTemp.sort(
    firstBy(function (user: any) {
      switch (field) {
        case 'user_name':
          if(REACT_APP_BACKEND_MYSQL)
            return user?.user_name_first?.toLowerCase()+" "+user?.user_name_last?.toLowerCase();
          else{
            return user?.user_name.first_name.toLowerCase()+" "+user?.user_name.last_name?.toLowerCase();
          }
        case 'user_email':
          return user?.user_email?.toLowerCase();
        case 'role_code':
          return user?.role_code !== ROLES.PARTNER ? user?.role_code?.toLowerCase() : 'SALES USER'.toLowerCase();
        case 'office_name':
          return user?.office_name?.toLowerCase() || '';
        case 'parent_office_name':
          return user?.parent_office_name?.toLowerCase() || '';
        case 'rm_details':
          return user?.rm_details?.length > 0 ?
          getParsedList(user?.rm_details)?.map((rm: any) => {
            const user_firstName = REACT_APP_BACKEND_MYSQL ? rm.user_name_first : rm.user_name.first_name
            const user_lastName = REACT_APP_BACKEND_MYSQL ? rm.user_name_last : rm.user_name.last_name
            return `${user_firstName} ${user_lastName}`.toLowerCase() }).join(', ')
            : ''
        case 'allowed_low_risk_processors':
          return user?.allowed_low_risk_processors?.length > 0 ?
          user?.allowed_low_risk_processors?.map((processor: any) => { return processor.toLowerCase() }).join(', ')
            : ''
        case 'allowed_high_risk_processors':
            return user?.allowed_high_risk_processors?.length > 0 ?
            user?.allowed_high_risk_processors?.map((processor: any) => { return processor.toLowerCase() }).join(', ')
              : ''
        case 'allowed_risk_levels':
          return user?.allowed_risk_levels?.length > 0 ?
          user?.allowed_risk_levels?.map((risk_level: any) => { return risk_level.toLowerCase() }).join(', ')
            : ''
        case 'reports_dashboard':
          return user?.iris_enabled || false;
        case 'FEENAVIGATOR':
          return user?.feenavigator_enabled || false;
        case 'deactivate_status':
          return user?.deactivate || false;
        default:
          return user?.user_id?.toLowerCase();
      }
    },{ direction: order === 'desc' ? 'asc' : 'desc' })
    );
    setSortAndSearchResultData(usersTemp);
  }

  useEffect(() => {
    const sortAndSearchResult = (data: any) => {
      const usersTemp: any = data;
      let usersSearchResult: any = usersTemp;

      if (!!searchText && !!searchField) {
        usersSearchResult = usersTemp?.filter((item: any) => {
          let searched_Field = "";
          if (searchField === "role_code" && !!item[searchField]) {
            searched_Field = item[searchField] === 'PARTNER' ? 'SALES USER' : item[searchField]
          } else if (searchField === "user_name") {
            if (REACT_APP_BACKEND_MYSQL) {
              searched_Field = `${item.user_name_first} ${item.user_name_last}`
            } else {
              searched_Field = `${item[searchField].first_name} ${item[searchField].first_name}`
            }
          } else if (searchField === "allowed_high_risk_processors" || searchField === "allowed_low_risk_processors" || searchField === "allowed_risk_levels") {
            searched_Field = item[searchField]?.length > 0 ? item[searchField]?.join(', ') : ''
          } else if (searchField === "rm_details") {
            searched_Field = item[searchField]?.length > 0 ?
              getParsedList(item[searchField])?.map((rm: any) => {
                const user_firstName = REACT_APP_BACKEND_MYSQL ? rm.user_name_first : rm.user_name.first_name
                const user_lastName = REACT_APP_BACKEND_MYSQL ? rm.user_name_last : rm.user_name.last_name
                return `${user_firstName} ${user_lastName}`
              }).join(', ')
              : ''
          } else {
            if (item[searchField]) {
              searched_Field = item[searchField]
            };
          }
          return searched_Field.toLowerCase().indexOf(searchText.toLowerCase()) > -1
        })
      }
  
      const sortFieldOrderTemp: any = sortFieldOrder === "desc" ? -1 : 1;
  
      usersSearchResult.sort(
        firstBy((user: any) => {
          if (sortField === "user_name") {
            if(REACT_APP_BACKEND_MYSQL){
              return `${user.user_name_first} ${user.user_name_last}`
            }else{
              return `${user[sortField]?.first_name} ${user[sortField]?.last_name}`
            }
          } else if (sortField === "role_code" && !!user[sortField]) {
            return user[sortField] === 'PARTNER' ? 'SALES USER' : user[sortField]
          } else if (sortField === "allowedProcessors" || sortField === "allowed_risk_levels") {
            return user[sortField]?.length > 0 ? user[sortField]?.join(', ') : ''
          } else if (sortField === "rm_details") {
            return user[sortField]?.length > 0 ?
            getParsedList(user[sortField])?.map((rm: any) => { 
              const user_firstName = REACT_APP_BACKEND_MYSQL ? rm.user_name_first : rm.user_name.first_name
              const user_lastName = REACT_APP_BACKEND_MYSQL ? rm.user_name_last : rm.user_name.last_name
                return `${user_firstName} ${user_lastName}` }).join(', ')
              : ''
          }
          return get(user, sortField, "").toLowerCase();
        }, { direction: sortFieldOrderTemp })
      );
  
      return usersSearchResult;
    }
    if(!algoliaLoading && usersList.length > 0){
      const sortedUsers = sortAndSearchResult(usersList)
      if(sortedUsers && sortedUsers.length > 0){
        setSortAndSearchResultData(sortedUsers)
      }else{
        setNoSearchResultModal(true)
      }
    } else if (!algoliaLoading && usersList.length === 0) {
      setSortAndSearchResultData([])
    }
  }, [ searchText, searchField, usersList ])
  

  const renderShowUsersTable = () => {
    return (
      <>
        <UserTable
          sortAndSearchResultData={sortAndSearchResultData}
          role={role}
          sortFieldOrder={sortFieldOrder}
          sortField={sortField}
          searchField={searchField}
          searchText={searchText}
          heading={<>
            <h3><b>{'USERS LIST'}</b></h3>
            <span onClick={() => setShowCreateUserModal(true)}>
              <PlusSquareOutlined />
              Create user
            </span>
          </>}
          handleSearchByFieldName={handleSearchByFieldName}
          handleSortByFieldName={handleSortByFieldName}
          handleEditUser={handleEditUser}
          handleDeleteUser={handleDeleteUser}
          handleIRISChecked={handleIRISChecked}
          handleFeenavigatorChecked={handleFeenavigatorChecked}
          handleFreeEquipmentPlacementChecked = {handleFreeEquipmentPlacementChecked}
          handleResetPassword={handleResetPassword}
          handleDeactivateModal={handleDeactivateModal}
          loading={loading}
          algoliaLoading={algoliaLoading}
          changeCheckboxStatus={changeCheckboxStatus}
          isParentChecked = {isParentChecked}
          viewOptions = {viewOptions}
          selectedUserBulk={selectedUserBulk}
          config={config}
        />
        {usersList?.length === 0 && !loading && (
          <div className="body-content mobile-user-list-body">
            <div className="heading">
              <span className="heading-center mobile-user-list">No Users Found!!</span>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <Layout page="users">
      <div className="userslist-container">
        <div
          className={
            window.innerWidth > 1640 ? "body-content" : "listapp-body-content"
          }
        >
          {isMobile || isTablet ? <></> : <div className="heading flex-spacebetween">
            <span className="heading-text-left">Users List</span>
            <BasicButton
              textColor={config?.client_config_fe?.button_color}
              backgroundColor={'#FFFFFF'}
              size='20px'
              buttonText="CREATE USER"
              className="list-app-btn"
              onClick={() => setShowCreateUserModal(true)}
            />
            {/* <Button
              inverted
              color="blue"
              size="massive"
              onClick={() => setShowCreateUserModal(true)}
            >
              CREATE USER
            </Button> */}
          </div>}
          <div>
   </div>

  { Object.keys(selectedUserBulk).length > 1 && (
   <div className='bulkupdate-action'>
   <Select
        isMulti={false}
        value={bulkaction}
        options={userBulkAction}
        onChange={ bulkUpdateAction}
        placeholder="Bulk Action"
        isSearchable={false}
      />
      </div>
  )}
          {renderShowUsersTable()}
          <ButtonScrollTop />
        </div>
      
        <ProgressLoader loading={loading} size="large" />
      </div>
      <div className="overlay" style={overlay.style}>
        <div className="overlay-image">
          <span className="overlay-text" style={{ color: config?.client_config_fe?.button_color }}>{overlayMsg}</span>
        </div>
      </div>
      {showCreateUserModal && (
        <CreateUser
          handleCancel={handleCancel}
          showCreateUserModal={showCreateUserModal}
          firstName={firstName}
          lastName={lastName}
          email={email}
          emailValid={emailValid}
          emailError={emailError}
          selectedRole={selectedRole}
          irisEnabled={irisEnabled}
          feenavigatorEnabled={feenavigatorEnabled}
          role={role}
          officeAdminCreateRoles={officeAdminCreateRoles}
          officeManagerCreateRoles={officeManagerCreateRoles}
          loading={loading}
          superAdminCreateRoles={superAdminCreateRoles}
          edit={edit}
          handleSelectedRole={handleSelectedRole}
          editRoles={editRoles}
          selectedRM={selectedRM}
          selectedOffice={selectedOffice}
          officesDropDown={officesDropDown}
          handleSelectedOffice={handleSelectedOffice}
          handleSelectedRM={handleSelectedRM}
          rmUsers={rmUsers}
          fetchingOffices={fetchingOffices}
          fetchingProcessors={fetchingProcessors}
          fetchingRms={fetchingRms}
          handleSelectRiskLevel={handleSelectRiskLevel}
          selectedRiskLevel={selectedRiskLevel}
          riskLevels={riskLevels}
          userCreationError={userCreationError}
          createUserEnabled={createUserEnabled}
          handleSaveUser={handleSaveUser}
          setLastName={setLastName}
          emailValidation={emailValidation}
          setFirstName={setFirstName}
          setIrisEnabled={setIrisEnabled}
          setFeenavigatorEnabled={setFeenavigatorEnabled}
          handleSelectedLowRiskProcessors={handleSelectedLowRiskProcessors}
          handleSelectedHighRiskProcessors={handleSelectedHighRiskProcessors}
          selectedLowRiskProcessors={selectedLowRiskProcessors}
          selectedHighRiskProcessors={selectedHighRiskProcessors}
          highRiskProcessors={highRiskProcessors}
          lowRiskProcessors={lowRiskProcessors}     
          relationshipManagerCreateRoles={relationshipManagerCreateRoles}
          relationshipManagerCreateRolesEdit={relationshipManagerCreateRolesEdit}
          freeEquipmentPlacementEnabled={freeEquipmentPlacementEnabled}
          setFreeEquipmentPlacementEnabled={setFreeEquipmentPlacementEnabled}
          config={config}
        />    
      )}
      {deleteModalOpen && (
        <DeleteModal
          handleCancel={handleCancel}
          deleteUser={deleteUser}
          loading={loading}
          deleteModalOpen={deleteModalOpen}
          selectedUser={selectedUser}
        />
      )}
      {deactivateModalOpen && (
        <DeactivateModal
          selectedUser={selectedUser}
          loading={loading}
          deactivateModalOpen={deactivateModalOpen}
          handleCancel={handleCancel}
          handleActivateDeActivate={handleActivateDeActivate}
          config={config}
        />
      )}
      {warningModalOpen && (
        <WarningModal
          loading={loading}
          warningModalOpen={warningModalOpen}
          handleCancel={handleCancel}
          config={config}
        />
      )}
      {noSearchResultModal && (
        <SearchResultModal
          handleCancel={handleCancel}
          noSearchResultModal={noSearchResultModal}
          searchText={searchText}
          config={config}
        />
      )}
      {openEnableIRISModal && (
        <EnableIRISModal
          handleCancel={handleCancel}
          updateIRESEnabledStatus={updateIRESEnabledStatus}
          loading={loading}
          openEnableIRISModal={openEnableIRISModal}
          selectedUser={selectedUser}
          irisEnabled={irisEnabled}
          config={config}
        />
      )}
      {openEnableFeenavigatorModal && (
        <EnableFeenavigatorModal
          handleCancel={handleCancel}
          updateFeenavigatorEnabledStatus={updateFeenavigatorEnabledStatus}
          loading={loading}
          openEnableFeenavigatorModal={openEnableFeenavigatorModal}
          selectedUser={selectedUser}
          feenavigatorEnabled={feenavigatorEnabled}
          config={config}
        />
      )}
      {openEnableFreeEquipmentPlacementModal && (
        <EnableFreeEquipmentPlacementModal
          handleCancel={handleCancel}
          updateFreeEquipmentPlacementStatus={updateFreeEquipmentPlacementStatus}
          loading={loading}
          editName={REACT_APP_BACKEND_MYSQL ? selectedUser.user_name_first : selectedUser.user_name.first_name}
          editId={selectedUser?.user_id}
          openEnableFreeEquipmentPlacementModal ={openEnableFreeEquipmentPlacementModal}
          freeEquipmentPlacementEnabled={freeEquipmentPlacementEnabled}
        />
      )}
      {modalForgetpassword  && 
        <ResetPasswordModal
          loading={loading} 
          modalForgetpassword={modalForgetpassword} 
          handleCancel={handleCancel}
          business_email={userEmail}
          setNewPassword={setNewPassword}
          modalTitle={modalTitle}
          handleGeneratePassword={handleGeneratePassword}
          generatedPassword={generatedPassword}
          errMsgResp={errMsgResp}
          errResponse={errResponse}
          resetErrResponse={resetErrResponse}
        />
        }

        {passwordUpdateConfirmation && (
        <ActionConfirmation
         selectedUser={selectedUserBulk}
         loading={loading}
         deactivateModalOpen={passwordUpdateConfirmation}
         handleCancel={handleCancel}
         handleActivateDeActivate={handleActivateDeActivate}
         modalAction = {modalAction}
         handleConfirmAction = {handleConfirmAction}
         config={config}
      />
      )}       
        {userActiveDeactiveBulkConfirmation && (
        <UserActiveDeactiveConfirmation
         selectedUser={selectedUserBulk}
         loading={loading}
         deactivateModalOpen={userActiveDeactiveBulkConfirmation}
         handleCancel={handleCancel}
         handleActivateDeActivate={handleActivateDeActivate}
         modalAction = {modalAction}
         handleConfirmAction = {handleConfirmAction}
         countforAction = {countforAction}
         config={config}
      />
      )}
      {showOfficeStatusonActivate && (
        <UserOfficeStatus
         loading={loading}
         deactivateModalOpen={showOfficeStatusonActivate}
         uncheckparentcheckbox = {uncheckparentcheckbox}
         countforAction = {userNames}
      />
      )}
      
        {warningModalOpenBulk && (
        <WarningModalBulk
          loading={loading}
          warningModalOpenBulk={warningModalOpenBulk}
          handleCancel={handleCancel}
          officeAdminBulk = {officeAdminBulk}
          config={config}

        />
      )}
      <Footer loading={loading} page = 'users'/>
    </Layout>
  );
}

export default memo(UsersList)

