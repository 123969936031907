import React, { useEffect, useState } from "react";

// this is a custom hooks for detect the screen width is relevent to mobile
const useCheckMobileScreen = () => {

    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => setWidth(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);

    return (width <= 750);
}

export default useCheckMobileScreen;

// this is a custom hooks for detect the screen width is relevent to mobile
export const useCheckTabScreen = () => {

    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => setWidth(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);

    return (width >= 750 && width <= 1200);
}
