import React, { memo, useEffect, useState } from 'react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { Dropdown, Icon, Radio } from 'semantic-ui-react'
import { FIELDS_INFO, ROLES, monthOptions } from '../../../Utils/constants'
import isEditFlow from '../StartApp'

interface CorpInformationSectionProps {
  legalInfoAddressValid: boolean
  legalInfoAddressErrMessage: string
  legalName: string
  legalAddress: string
  legalAddressCity: string
  selectedLegalAddressState: string
  legalAddressPostalCode: string
  legalBusinessFax: string
  legalBusinessContact: string
  handleLegalState: (e: any, data: any) => void
  validateLegalSectionAddress: () => Promise<void>
  setLegalBusinessContact: React.Dispatch<React.SetStateAction<string>>
  legalBusinessContactTitle: string
  setLegalBusinessContactTitle: React.Dispatch<React.SetStateAction<string>>
  role: string | null
  legalBusinessPhone: string
  handleChangeTextInput: (e: any) => false | undefined
  updatelegalSectionStatus: () => void
  updatebusinessSectionStatus: () => void
  fedexTaxIdShown: boolean
  fedTaxID: string
  fedTaxIdValid: boolean
  setFedTaxIdValid: React.Dispatch<React.SetStateAction<boolean>>
  setFedTaxID: React.Dispatch<React.SetStateAction<string>>
  validateFedTaxId: (fedTaxId: string) => boolean
  allowOnlyNumberIncludingHyphen: (e: any) => void
  eyeIconFedexTaxId: boolean
  togglefedexTaxIdVisiblity: () => void
  tax_type: {
    text: string;
    value: string;
  }[]
  selectedTaxType: string
  handleChangeTaxType: (e: any, data: any) => void
  businessstartdate: any
  setBusinessStartDate: React.Dispatch<React.SetStateAction<null>>
  lengthOfOwnership: string
  setLengthOfOwnership: React.Dispatch<React.SetStateAction<string>>
  lengthOfOwnershipYears: string
  setLengthOfOwnershipYears: React.Dispatch<React.SetStateAction<string>>
  allowNumberText: (e: any) => void
  legalentity: {
    text: string;
    value: string;
  }[]
  selectedLegalEntityType: string
  handleChangeLegalEntityType: (e: any, data: any) => void
  states: {
    text: string;
    value: string;
  }[]
  handleStateIncorporated: (e: any, data: any) => void
  selectedStateIncorporated: string
  isPriorSecurityBreach: string
  option_yesno: {
    text: string;
    value: string;
  }[]
  handleChangePriorSecurityBreach: (e: any, data: any) => void
  setLegalName: React.Dispatch<React.SetStateAction<string>>
  isMobile: boolean
  isTab: boolean
}
const CorpInformationSection = (props: CorpInformationSectionProps) => {
  const {
    legalInfoAddressValid,
    legalInfoAddressErrMessage,
    legalName,
    legalAddress,
    legalAddressCity,
    selectedLegalAddressState,
    handleLegalState,
    legalAddressPostalCode,
    validateLegalSectionAddress,
    legalBusinessContact,
    setLegalBusinessContact,
    legalBusinessContactTitle,
    setLegalBusinessContactTitle,
    legalBusinessPhone,
    handleChangeTextInput,
    updatelegalSectionStatus,
    legalBusinessFax,
    updatebusinessSectionStatus,
    fedexTaxIdShown,
    role,
    fedTaxID,
    fedTaxIdValid,
    setFedTaxIdValid,
    setFedTaxID,
    validateFedTaxId,
    allowOnlyNumberIncludingHyphen,
    eyeIconFedexTaxId,
    togglefedexTaxIdVisiblity,
    tax_type,
    selectedTaxType,
    handleChangeTaxType,
    businessstartdate,
    setBusinessStartDate,
    lengthOfOwnership,
    setLengthOfOwnership,
    lengthOfOwnershipYears,
    setLengthOfOwnershipYears,
    allowNumberText,
    legalentity,
    selectedLegalEntityType,
    handleChangeLegalEntityType,
    states,
    handleStateIncorporated,
    selectedStateIncorporated,
    isPriorSecurityBreach,
    option_yesno,
    handleChangePriorSecurityBreach,
    setLegalName,
    isMobile,
    isTab
  } = props

  
  const [lengthOfOwnershipMonths, setLengthOfOwnershipMonths] = useState('');

  const handleLengthOfOwnership = (e: any) => {
    const { value } = e.target
    const onlyNumbers = /^[0-9]*$/;
    if (!onlyNumbers.test(value)) {
      return;
    }
    setLengthOfOwnershipYears(value);
    const currentLengthOfOwnership = `${value}.${lengthOfOwnershipMonths}`;
    if ((value === '' || value === '0') && lengthOfOwnershipMonths === '0') {
      setLengthOfOwnership('');
    } else {
      setLengthOfOwnership(currentLengthOfOwnership);
    }
  }

  const handleLengthOfOwnershipMonths = (e: any, data: any) => {
    setLengthOfOwnershipMonths(data.value);
    
    const currentLengthOfOwnership = `${lengthOfOwnershipYears}.${data.value}`;
    if(!isEditFlow){
      setLengthOfOwnership('');
    }
    if ((lengthOfOwnershipYears === '' || lengthOfOwnershipYears === '0') && data.value === '0') {
      setLengthOfOwnership('');
    } else {
      setLengthOfOwnership(currentLengthOfOwnership);
    }
  }

  useEffect(() => {
    const lengthOfOwnershipValue = lengthOfOwnership.split('.');
    setLengthOfOwnershipYears(lengthOfOwnershipValue[0]);
    if (lengthOfOwnershipValue[1]) {
      setLengthOfOwnershipMonths(lengthOfOwnershipValue[1]);
    } else {
      setLengthOfOwnershipMonths('');
      setLengthOfOwnership(`${lengthOfOwnershipValue[0]}.0`);
    }
  }, [lengthOfOwnership])

  const CommonRadioSection = (props: any) => {
    return (
      <div className="inline fields">
        <span>{props.placeholder}</span>
        <div className="two wide field"></div>
        {props.options.map((value: any) => {
          return (
            <div className="checkbox-inline">
              <Radio
                label={value.text}
                name=""
                value={value.value}
                checked={!!props.value && props.value === value.value}
                onChange={props.handler}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className='form-fields corp-info-section'>
      {!legalInfoAddressValid && (
        <div className='warning-text'>{legalInfoAddressErrMessage}</div>
      )}
      <div className='ui form'>
        <div className='fields'>
          <div className='eight wide field'>
            <label>Corp Name <span className={(role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN) ? 'info1-icon' : 'info1-icon-merchant'}
              data-tooltip={FIELDS_INFO['Corp Information'].legalName.helperText}></span><span className="required-text"> *</span> </label>
            <input
              type='text'
              name='legalName'
              className={ !legalName ? 'not-filled' :'form-input'}
              placeholder='Corp Name'
              value={legalName}
              autoComplete="new-password"
              onChange={handleChangeTextInput}
              onBlur={updatelegalSectionStatus}
            />
          </div>
          <div className='eight wide field'>
            <label>Corp Address <span className="required-text"> *</span> </label>
            <input
              type='text'
              className={ (!legalAddress || !legalInfoAddressValid) ? 'not-filled' :'form-input'}
              name='legalAddress'
              value={legalAddress}
              placeholder='Corp Address'
              autoComplete="new-password"
              onChange={handleChangeTextInput}
              onBlur={validateLegalSectionAddress}
            />
          </div>
        </div>
        <div className='fields'>
          <div className='eight wide field'>
            <label>City <span className="required-text"> *</span> </label>
            <input
              type='text'
              name='legalAddressCity'
              className={ (!legalAddressCity || !legalInfoAddressValid) ? 'not-filled' :'form-input'}
              value={legalAddressCity}
              placeholder='City'
              autoComplete="new-password"
              onChange={handleChangeTextInput}
              onBlur={validateLegalSectionAddress}
            />
          </div>
          <div className='eight wide field'>
            <label>State <span className="required-text"> *</span> </label>
            <Dropdown
              placeholder={!!isMobile ? 'State' : 'Choose One'}
              className={ (!selectedLegalAddressState || !legalInfoAddressValid) ? 'not-filled' :'form-input'}
              fluid
              search
              selection
              options={states}
              value={selectedLegalAddressState}
              onChange={handleLegalState}
            />
          </div>
        </div>
        <div className='fields'>
          <div className='eight wide field'>
            <label>Zip Code <span className="required-text"> *</span> </label>
            <input
              type='text'
              name='legalAddressPostalCode'
              className={ (!legalAddressPostalCode || !legalInfoAddressValid) ? 'not-filled' :'form-input'}
              value={legalAddressPostalCode}
              autoComplete="new-password"
              onChange={handleChangeTextInput}
              placeholder='Zip Code'
              onBlur={validateLegalSectionAddress}
            />
          </div>
          <div className='eight wide field'>
            <label>Business Contact <span className="required-text"> *</span> </label>
            <input
              type='text'
              name='legalBusinessContact'
              className={ !legalBusinessContact ? 'not-filled' :'form-input'}
              value={legalBusinessContact}
              autoComplete="off"
              placeholder='Business Contact'
              onChange={(e) => setLegalBusinessContact(e.target.value)}
              onBlur={updatelegalSectionStatus}
            />
          </div>
        </div>
        <div className='fields'>
          <div className='eight wide field'>
            <label>Business Contact Title<span className="required-text"> *</span> </label>
            <input
              type='text'
              name='legalBusinessContactTitle'
              className={ !legalBusinessContactTitle ? 'not-filled' :'form-input'}
              value={legalBusinessContactTitle}
              autoComplete="off"
              onChange={(e) => setLegalBusinessContactTitle(e.target.value)}
              placeholder='Business Contact Title'
              onBlur={updatelegalSectionStatus}
            />
          </div>
          <div className='eight wide field'>
            <label>Business Phone <span className="required-text"> *</span> </label>
            <input
              type='text'
              name='legalBusinessPhone'
              className={ !legalBusinessPhone ? 'not-filled' :'form-input'}
              value={legalBusinessPhone}
              autoComplete="new-password"
              onChange={handleChangeTextInput}
              placeholder='Business Phone'
              onBlur={updatelegalSectionStatus}
            />
          </div>
        </div>
        <div className='fields'>
          <div className='eight wide field'>
            <label>Business Fax</label>
            <input
              type='text'
              name='legalBusinessFax'
              className='form-input'
              value={legalBusinessFax}
              autoComplete="new-password"
              onChange={handleChangeTextInput}
              placeholder='Business Fax'
              onBlur={updatebusinessSectionStatus}
            />
          </div>
          <div className='eight wide field'>
            <label>Fed Tax Id {!isTab ? '( xx-xxxxxxx  /  xxxxxxxxx )' : ''}<span className="required-text"> *</span>  </label>
            <input
              type={fedexTaxIdShown ? 'text' : 'password'}
              placeholder={(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN  || role === ROLES.SALES_LIMITED) ? !isMobile ? 'Must be completed by merchant' : 'Fed Tax Id ( xx-xxxxxxx  /  xxxxxxxxx )': !isMobile? '' : 'Fed Tax Id ( xx-xxxxxxx  /  xxxxxxxxx )'}
              name='fedTaxID'
              className={ `${((!fedTaxID || !fedTaxIdValid) && role !== ROLES.SUPER_ADMIN) ? 'not-filled' : 'form-input'} placeholder-visible`}
              value={fedTaxID}
              autoComplete="new-password"
              onChange={(e) => {
                setFedTaxIdValid(validateFedTaxId(e.target.value.replace(/[^\d -]/g,'')))
                setFedTaxID(e.target.value.replace(/[^\d -]/g,''))
              }
              }
              onBlur={updatelegalSectionStatus}
              onKeyPress={allowOnlyNumberIncludingHyphen}
              disabled={!!(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.SALES_LIMITED)}
              minLength={9}
              maxLength={(fedTaxID.search('-') >= 0) ? 10 : 9}
              onMouseLeave={updatelegalSectionStatus}
            />
            <Icon
              name={eyeIconFedexTaxId ? 'eye slash' : 'eye'}
              size='large'
              className='eye-icon-owner-dlId ipad-eye-icon'
              onClick={togglefedexTaxIdVisiblity}
              data-content="text"
              disabled={!!(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.SALES_LIMITED)}
            />
          </div>
        </div>
        <div className=' fields'>
          <div className='eight wide field'>
            <label>Tax Type <span className="required-text"> *</span> </label>
            <Dropdown
              placeholder={!!isMobile ? 'Tax Type' : 'Choose One'}
              className={ !selectedTaxType ? 'not-filled' :'form-input'}
              fluid
              search
              selection
              options={tax_type}
              value={selectedTaxType}
              onChange={handleChangeTaxType}
            />
          </div>
          <div className='eight wide field  semantic-date-input'>
            <label>Business Start Date<span className="required-text"> *</span> </label>
            <SemanticDatepicker
              placeholder='Business Start Date (MM-DD-YYYY)'
              format='MM-DD-YYYY'
              name='businessStartDate'
              autoComplete='new-password'
              datePickerOnly={false}
              className={ !businessstartdate ? 'not-filled datepicker-width' :'datepicker-width'}
              value={businessstartdate ? new Date(businessstartdate) : null}
              clearable={true}
              onChange={(event, { value }: any) => { setBusinessStartDate(value) }}

            />
          </div>
        </div>
        <div className='fields'>
          <div className='eight wide field'>
            <label>Length of current Ownership <span className="required-text"> *</span> </label>
            <div className='input-wrapper'>
              <div className='eight wide field'>
                <label>Years <span className="required-text"> *</span></label>
                <input type='text'
                  name='lengthOfOwnershipYears'
                  className={ !lengthOfOwnershipYears ? 'not-filled' :'form-input'}
                  value={lengthOfOwnershipYears}
                  autoComplete="new-password"
                  onChange={handleLengthOfOwnership}
                  onBlur={updatelegalSectionStatus}
                  onKeyPress={allowNumberText}
                  placeholder='Length of current Ownership'
                  maxLength={3}
                />
              </div>
              <div className='eight wide field'>
                <label>Months <span className="required-text"> *</span></label>
                <Dropdown
                  placeholder="Choose Months"
                  className={ !lengthOfOwnership ? 'not-filled' :'form-input'}
                  fluid
                  search
                  selection
                  options={monthOptions}
                  onChange={handleLengthOfOwnershipMonths}
                  value={lengthOfOwnershipMonths}
                />
              </div>
            </div>
          </div>
          <div className='eight wide field'>
            <label>Legal Entity Type <span className="required-text"> *</span></label>
            <Dropdown
              placeholder={!!isMobile ? 'Legal Entity Type' : 'Choose One'}
              className={ !selectedLegalEntityType ? 'mt-35 not-filled' : 'mt-35 form-input'}
              fluid
              search
              selection
              options={legalentity}
              value={selectedLegalEntityType}
              onChange={handleChangeLegalEntityType}
            />
          </div>
        </div>
        <div className='fields'>
          <div className='eight wide field'>
            <label>State Incorporated <span className="required-text"> *</span> </label>
            <Dropdown
              placeholder={!!isMobile ? 'State Incorporated' : 'Choose One'}
              className={ !selectedStateIncorporated ? 'not-filled' :'form-input'}
              fluid
              search
              selection
              options={states}
              onChange={handleStateIncorporated}
              value={selectedStateIncorporated}
            />
          </div>
          <div className='eight wide field'>
           {!isMobile? <><label>Prior Security Breach ?<span className="required-text"> *</span> </label>
            <Dropdown
              placeholder='Prior Security Breach ?'
              className={ !isPriorSecurityBreach ? 'not-filled' :'form-input'}
              fluid
              search
              selection
              value={isPriorSecurityBreach}
              options={option_yesno}
              onChange={handleChangePriorSecurityBreach}
            /></> : <CommonRadioSection placeholder={isMobile? 'Prior Security Breach ?' : 'Choose One'} options={option_yesno} value={isPriorSecurityBreach} handler={handleChangePriorSecurityBreach} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(CorpInformationSection)
