import React, { useEffect, useState } from 'react';
import { Upload, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  getStorage,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";

interface UploadImageProps {
  handleSetImageUrl: any,
  value: string,
}

const UploadImage = (props: UploadImageProps) => {
  const { handleSetImageUrl, value } = props;
  const [imageUrl, setImageUrl] = useState<string>(value);
  const [loading, setLoading] = useState(false);

  const beforeUpload = (file: any) => {
    const isImage = file.type.indexOf('image/') === 0;
    if (!isImage) {
      Modal.error({
        title: 'Error',
        content: 'You can only upload image file!',
      });
    }
    
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      Modal.error({
        title: 'Error',
        content: 'Image must smaller than 5MB!',
      });
    }
    return isImage && isLt5M;
  };

  const customUpload = async ({ file }: any) => {
    setLoading(true);

    const storage = getStorage();
    const storageRef = ref(storage, `platform-admin/${file.name}`);

    uploadBytes(storageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then(downloadUrl => {
        setImageUrl(downloadUrl)
        handleSetImageUrl(downloadUrl)
      })
      setLoading(false);
    });
  };

  useEffect(() => {
    setImageUrl(value)
  }, [value])

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined rev={undefined} /> : <PlusOutlined rev={undefined} />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Upload
      name="Company Logo"
      listType="picture-card"
      className="logo-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      customRequest={customUpload}
    >
      {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '50%' }} /> : uploadButton}
    </Upload>
  )
}

export default UploadImage;
