import React from "react";
import { Button, Modal, Table } from "semantic-ui-react";
import { ROLES } from "../../../Utils/constants";
import TableComponent from "../../TableComponent";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
import { formatDate, get } from "../../../Utils/helpers";

interface AuditUsersDataModalProps {
    selectedUserData: any;
    auditUsersDataModal: boolean;
    handleCancel: (refresh_data: boolean) => void,
    createdTS: any;
    config?:any
}

const { REACT_APP_BACKEND_MYSQL } = process.env;

const AuditUsersDataModal = (props: AuditUsersDataModalProps) => {
    const {
        selectedUserData,
        auditUsersDataModal,
        handleCancel,
        createdTS,
        config
    } = props;
    const display = get(selectedUserData, "new_data.role_code", "") !== ROLES.RELATIONSHIP_MANAGER;
    const headerKeys = [
        { label: 'Field', key: 'field' },
        { label: 'After update', key: 'newV' },
        { label: 'Before update', key: 'oldV' },
    ];

    const tableMeta = [
        {
            display: true, field: 'User name',
            newV: `${REACT_APP_BACKEND_MYSQL ? get(selectedUserData, "new_data.user_name_first", "") : get(selectedUserData, "new_data.user_name.first_name", "")} ${REACT_APP_BACKEND_MYSQL ? get(selectedUserData, "new_data.user_name_last", "") : get(selectedUserData, "new_data.user_name.last_name", "")}`,
            oldV: `${REACT_APP_BACKEND_MYSQL ? get(selectedUserData, "old_data.user_name_first", "") : get(selectedUserData, "old_data.user_name.first_name", "")} ${REACT_APP_BACKEND_MYSQL ? get(selectedUserData, "old_data.user_name_last", "") : get(selectedUserData, "old_data.user_name.last_name", "")}`
        },
        {
            display: true, field: 'Email',
            newV: get(selectedUserData, "new_data.user_email", ""),
            oldV: get(selectedUserData, "old_data.user_email", "")
        },
        {
            display: true, field: 'User ID',
            newV: REACT_APP_BACKEND_MYSQL ? get(selectedUserData, "new_data.user_code", "") : get(selectedUserData, "new_data.user_id", ""),
            oldV: REACT_APP_BACKEND_MYSQL ? get(selectedUserData, "old_data.user_code", "") : get(selectedUserData, "old_data.user_id", "")
        },
        {
            display: true, field: 'Status',
            newV: Boolean(get(selectedUserData, "new_data.deactivate", false)) ? "INACTIVE" : "ACTIVE",
            oldV: !!selectedUserData.old_data ? Boolean(get(selectedUserData, "old_data.deactivate", false)) ? "INACTIVE" : "ACTIVE" : ''
        },
        {
            display, field: 'High risk banks',
            newV: get(selectedUserData, "new_data.allowed_high_risk_processors", []).join(", "),
            oldV: get(selectedUserData, "old_data.allowed_high_risk_processors", []).join(", ")
        },
        {
            display, field: 'Low risk banks',
            newV: get(selectedUserData, "new_data.allowed_low_risk_processors", []).join(", "),
            oldV: get(selectedUserData, "old_data.allowed_low_risk_processors", []).join(", ")
        },
        {
            display, field: 'Risk levels',
            newV: get(selectedUserData, "new_data.allowed_risk_levels", []).join(", "),
            oldV: get(selectedUserData, "old_data.allowed_risk_levels", []).join(", ")
        },
        {
            //TODO
            display, field: 'RMs',
            newV: "",
            oldV: ""
        },
        {
            display: true, field: 'Updated Date',
            newV: REACT_APP_BACKEND_MYSQL ? formatDate(get(selectedUserData, "new_data.updated_ts", "")) : createdTS(get(selectedUserData, "new_data.created_ts._seconds", ""), get(selectedUserData, "new_data.created_ts._nanoseconds", "")),
            oldV: REACT_APP_BACKEND_MYSQL ? formatDate(get(selectedUserData, "old_data.updated_ts", "")) : createdTS(get(selectedUserData, "old_data.created_ts._seconds", ""), get(selectedUserData, "old_data.created_ts._nanoseconds", ""))
        },
        {
            display, field: 'Office ID',
            newV: get(selectedUserData, "new_data.office_id", ""),
            oldV: get(selectedUserData, "old_data.office_id", "")
        },
        {
            display, field: 'SalesUser ID',
            newV: get(selectedUserData, "new_data.partner_id", ""),
            oldV: get(selectedUserData, "old_data.partner_id", "")
        },
        {
            display: true, field: 'Role',
            newV: get(selectedUserData, "new_data.role_code", "") === ROLES.PARTNER ? 'SALES USER' : get(selectedUserData, "new_data.role_code", ""),
            oldV: get(selectedUserData, "old_data.role_code", "") === ROLES.PARTNER ? 'SALES USER' : get(selectedUserData, "old_data.role_code", "")
        },
        {
            display: true, field: 'Reports Dashboard',
            newV: get(selectedUserData, "new_data.iris_enabled", "") === 1 ? "ENABLED" : get(selectedUserData, "new_data.iris_enabled", "") === 0 ? "DISABLED" : "",
            oldV: get(selectedUserData, "old_data.iris_enabled", "") === 1 ? "ENABLED" : get(selectedUserData, "old_data.iris_enabled", "") === 0 ? "DISABLED" : ""
        },
        {
            display: true, field: 'Fee Navigator',
            newV: get(selectedUserData, "new_data.feenavigator_enabled", "") === 1 ? "ENABLED" : get(selectedUserData, "new_data.feenavigator_enabled", "") === 0 ? "DISABLED" : "",
            oldV: get(selectedUserData, "old_data.feenavigator_enabled", "") === 1 ? "ENABLED" : get(selectedUserData, "old_data.feenavigator_enabled", "") === 0 ? "DISABLED" : ""
        }
    ]
    const MobileModel = () => (<TableComponent
        className="user-data-model-table"
        headerList={headerKeys}
        rowKeys={[]}
        hasAction={false}
        tableData={tableMeta}
        uniqueId={'objectID'}
        displayFooter={false} />);

    const isMobile = useCheckMobileScreen();
    if (isMobile) return <>
        <MobileModel />;
        <Button color='blue' onClick={() => handleCancel(false)}>
            Close
        </Button>
    </>
    return (<>
        {isMobile && <MobileModel />}
        <Modal
            closeIcon
            centered
            open={auditUsersDataModal}
            onClose={() => handleCancel(false)}
            size="small"
            closeOnEscape={false}
            closeOnDimmerClick={false}
            className='user-data-model'
        >
            <Modal.Content scrolling>
                <Modal.Description>
                    {!isMobile && <Table textAlign="center" celled striped structured>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>Fields</Table.HeaderCell>
                                <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>After Updating</Table.HeaderCell>
                                <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>Before Updating</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {tableMeta.map(t => t?.display && (<Table.Row>
                                <Table.Cell>{t.field}</Table.Cell>
                                <Table.Cell>{t.newV}</Table.Cell>
                                <Table.Cell>{t.oldV}</Table.Cell>
                            </Table.Row>))}
                        </Table.Body>
                    </Table>}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='blue' style={{ background: config?.client_config_fe?.button_color }} onClick={() => handleCancel(false)}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    </>)
}
export default AuditUsersDataModal;
