import React, { ReactNode } from 'react';
import { Layout } from 'antd';
import { layoutStyle } from '../Pages/PlatformAdmin/StyleConstants';

interface AppLayoutProps {
  children: ReactNode
}

const AppLayout = ({
  children
}: AppLayoutProps) => {
  return (
    <Layout style={layoutStyle}>
      { children }
    </Layout>
  )
}

export default AppLayout;
