import React, { useState, useEffect } from "react";
import UserTableRow from "./UserTableRow";

interface UserTableBodyProps {
    sortAndSearchResultData: any[]
    role: string | null
    handleDeleteUser: (user_id: number) => Promise<void>
    handleEditUser: (user_id: number) => Promise<void>
    handleIRISChecked: (data: any, user_id: string) => Promise<void>
    handleFeenavigatorChecked: (data: any, user_id: string) => Promise<void>
    handleResetPassword: (email:string) => Promise<void>
    handleFreeEquipmentPlacementChecked: (data: any, user_id: string) => Promise<void>
    handleDeactivateModal: (user_id: number) => Promise<void>
    changeCheckboxStatus: (e: any, id: string) => void
    selectedUserBulk: any[]

}
const UserTableBody = (props: UserTableBodyProps) => {

    const {
      sortAndSearchResultData,
      handleEditUser,
      handleDeleteUser,
      role,
      handleIRISChecked,
      handleFeenavigatorChecked,
      handleResetPassword,
      handleDeactivateModal,
      changeCheckboxStatus,
      handleFreeEquipmentPlacementChecked,
      selectedUserBulk
    } = props;

    return (
        <>
        {sortAndSearchResultData?.filter((user: any) => !user.deleted)?.map((user: any, index: any) => {
            return (
              <UserTableRow
                key={index}
                handleEditUser={handleEditUser}
                handleDeleteUser={handleDeleteUser}
                index={index}
                user={user}
                role={role}
                handleIRISChecked={handleIRISChecked}
                handleResetPassword={handleResetPassword}
                handleFeenavigatorChecked={handleFeenavigatorChecked}
                handleDeactivateModal={handleDeactivateModal}
                changeCheckboxStatus={changeCheckboxStatus}
                selectedUserBulk={selectedUserBulk}
                handleFreeEquipmentPlacementChecked={handleFreeEquipmentPlacementChecked}
              />
            );
            })
        }
        </>
    )
}

export default UserTableBody;
