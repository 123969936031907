
import React, { useState, useContext } from 'react';
import { Table , Button, ConfigProvider, Modal} from 'antd';
import api from '../../../../Service/Api';
import backend from '../../../../Service/Backend';

interface SuperUserTableProps {
  data: any,
  config:any,
  successCallback: () => void,
}
const SuperUserTable = (props:SuperUserTableProps) => {
  const { data, config, successCallback} = props;  
  const [loading, setLoading] = useState(true); 
  const [deleteModalOpen,setDeleteModalOpen] = useState(false)


  const deleteSuperUserAccess = async (record: any) => {
    try {
      setLoading(true)
      const data: any = {
        api: api.platformAdmin.removeSuperUser,
        payLoad: {
          "user_roles_id": record.user_roles_id,
          "access_client_id": record.access_client_id,
          "auth_user_id": record.auth_user_id,
          "user_client_id": record.user_client_id
        }
      }
      const response = await backend.save(data)
      if(response){
        setLoading(false)
        successCallback()
      }
    } catch (err) {
      console.log('err', err)
    } finally {
      setLoading(false)
    }
  }
  const openDeleteModal = (record: any) => {

    Modal.confirm({
      open: deleteModalOpen,
      title: 'DELETE SUPER USER ?',
      content: 'This will delete the super user selected.',
      okText: 'Delete',
      onOk() {
        deleteSuperUserAccess(record)
      },
      cancelText: 'Cancel',
      onCancel() {
        setDeleteModalOpen(false);
      }
    });
  };

  const columns: any = [
    {
        title: 'ROLE',
        dataIndex: 'role',
        key: 'role',
    },
    {
        title: 'USER NAME',
        dataIndex: 'superUserName',
        key: 'superUserName',
    },    
    {
        title: 'CLIENT NAME',
        dataIndex: 'clientName',
        key: 'clientName',
    },
    {
      title: 'CLIENT URL',
      dataIndex: 'clientUrl',
      key: 'clientUrl',
  },
    {
      title: "Actions",
      key: 'action',
      render: (record: any) => {
        return (

          <Button type="link" onClick={() => { openDeleteModal(record); }}>
            Delete
          </Button>

        )
      }
    }
]
  return (

    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: config?.client_config_fe?.button_color,
            
          },
        },
      }}
    >
      <Table
        className="superuser-table"
        columns={columns}
        dataSource={data}
        rowClassName={(record: any, index: any) => index % 2 === 0 ? '' : 'antd-table-row-dark'}
      />
    </ConfigProvider>
  )
}

export default SuperUserTable;
