import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import { ROLES } from "../../Utils/constants";

const role = sessionStorage.getItem('role')

function RenderBlocksDesktop(props: any) {
  const { onOpenBlock, activesection, title, status, 
    display = true, block, desktopClasses} = props;
  if (!display) return null;

  const evalClass1 = (status === 'filled' ? 'green-arrow-icon' : 
  activesection === block ? 'yellow-arrow-icon' : 'grey-arrow-icon');

  return (<li className="vertical-list" onClick={onOpenBlock}>
    <span
      className={ desktopClasses?.class1 || evalClass1}/>
    <span className={desktopClasses?.class2 || (activesection === block ? 'section-active-status-bar' : 'section-status-bar')} />
    <span className={`${desktopClasses?.class3 || (activesection === block ? 'section-active-text section-name' : 'section-name')} ${status === 'filled' ? 'text-success' : ''}`}
    >{title}
    </span>
  </li>);
}

function RenderBlocksMobile(props: any) {
  const { onOpenBlock, activesection, title,
     renderSections, display = true, block, handleSaveApp, 
     handleSaveAppMerchant, status, disableControl, disableSave = false } = props;
  const [open, setOpen] = useState<Boolean>(false);
  if (!display) return null;
  let statusBar = "red";
  if (status === 'filled'){
    statusBar = "green"
  }
  if(block === 'message') {
    statusBar = 'gray'
  }

  console.log(activesection)

  if (block !== activesection && open) {
    setOpen(false);
  }


  let rolesCheck = role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN;

  function onClickBlock(block:string) {
    let override = window.sessionStorage.getItem('autoSwitchNextBlock');
    setOpen(!!override || !open);
    window.sessionStorage.setItem('autoSwitchNextBlock', '');
    onOpenBlock(block);
  }

  function getNextBlock(currentBlock: string) {
    const currentBlockElement = document.getElementById(currentBlock);
    currentBlockElement?.click();
    const nextSibling = currentBlockElement?.nextElementSibling;
    const nextSiblingId = nextSibling?.id;
    if (nextSiblingId && nextSiblingId !== '') {
      window.sessionStorage.setItem('autoSwitchNextBlock', "open");
      document.getElementById(nextSiblingId || '')?.click();
      setTimeout(() => {nextSibling?.scrollIntoView();}, 0);
    }
  }

  const class1 = status === 'filled' ? 'section-active-status-bar-green' : activesection === block ? '' : 'grey-arrow-icon';
  return (<li key={block} id={block} className="vertical-list vertical-list-mob-block" onClick={() => {
    onClickBlock(block);
    setTimeout(() => {document.getElementById(block)?.scrollIntoView();}, 100);
  }}>
    <div onClick={e=>{if(open) setOpen(false);}} className={activesection === block ? `mobile-rsp-active mob-status-bar-${statusBar}` : `mobile-rsp mob-status-bar-${statusBar}`}>
      <span
        className={(status==='NA' && props.desktopclassNames?.class1) || class1 } />
      <span className={activesection === block ? 'mob-section-active-status-bar' : 'mob-section-status-bar'} />
      <span className={activesection === block ? `mob-section-name-active` : `mob-section-name`}
      >{title}
      </span>
    </div>
    <div onClick={e => { console.log("div above render"); e.stopPropagation() }} className={activesection === block && open ? "mobile-form-section-open mobile-form-section" : "mobile-form-section-closed mobile-form-section"}>
    <div className="sections-mobile" >{activesection === block && renderSections()}</div>
    {activesection === block && open && !disableSave &&
    <div className='mob-center-buttons'>
      <Button
      onClick={(event) => {
        rolesCheck? handleSaveAppMerchant(event, false) : handleSaveApp(false, event, false);
        getNextBlock(block);
      }}
       className='mob-save-button'
       disabled={!(statusBar === 'green' || !disableControl)}
    >SAVE</Button></div>}
    {activesection === block && <h4 className="warning-text">*  Fields are required</h4>}
    </div>
  </li>);
}

function RenderBlocks(props: any) {
  const isMobile = useCheckMobileScreen();
  return !isMobile ? <RenderBlocksDesktop {...props} /> : <RenderBlocksMobile {...props} />;
}
export default RenderBlocks;
