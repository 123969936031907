import React from "react";
import { Checkbox, CheckboxProps, Table } from "semantic-ui-react";
import { ROLES } from '../../../Utils/constants'
import { firstBy } from 'thenby'
import { getParsedList } from "../../../Utils/helpers";

interface UserTableRowProps {
  role: string | null
  index: any
  user: any
  handleDeleteUser: (user_id: number) => Promise<void>
  handleEditUser: (user_id: number) => Promise<void>
  handleIRISChecked: (data: any, user_id: string) => Promise<void>
  handleResetPassword: (email: string) => Promise<void>
  handleFeenavigatorChecked: (data: any, user_id: string) => Promise<void>
  handleDeactivateModal: (user_id: number) => Promise<void>
  changeCheckboxStatus: (e: any, id: string) => void
  handleFreeEquipmentPlacementChecked: (data: any, user_id: string) => Promise<void>
  selectedUserBulk: any[]
}

const { REACT_APP_BACKEND_MYSQL } = process.env;

const UserTableRow = (props: UserTableRowProps) => {
  const super_user: any = sessionStorage.getItem("super_user") === 'true'
  const {
    index,
    user,
    handleEditUser,
    handleDeleteUser,
    role,
    handleIRISChecked,
    handleFeenavigatorChecked,
    handleResetPassword,
    handleDeactivateModal,
    changeCheckboxStatus,
    selectedUserBulk,
    handleFreeEquipmentPlacementChecked
  } = props;

  let warningModal = (role === ROLES.OFFICE_ADMIN && user.role_code === ROLES.OFFICE_ADMIN)
  let rmNames = getParsedList(user?.rm_details).map((rm: any) => { return `${REACT_APP_BACKEND_MYSQL ? rm.user_name_first : rm.user_name.first_name} ${REACT_APP_BACKEND_MYSQL ? rm.user_name_last : rm.user_name.last_name}` })
  let low_risk_processors: any = getParsedList(user?.allowed_low_risk_processors) || []
  let high_risk_processors: any = getParsedList(user?.allowed_high_risk_processors) || []
  low_risk_processors.sort(
    firstBy(function (option: any) {
      return option?.toLowerCase();
    })
  )
  high_risk_processors.sort(
    firstBy(function (option: any) {
      return option?.toLowerCase();
    })
  )

  const truncateCell = (text: string) => {
    if(text && typeof text === 'string'){
      return (text.length > 15 ? text.slice(0, 15) + '...' : text);
    }
    return '';
  }

  return (
    <Table.Row key={index} className='t-row'>
      <Table.Cell className='t-data checkbox-action'>
      <div className="parentcheckbox">
        <input type="checkbox" checked={user?.isChecked} value={user.user_email} onChange={(e) => changeCheckboxStatus(e, user.user_email)}
        />
      </div>
      </Table.Cell>
      <Table.Cell className='t-data'>{
        REACT_APP_BACKEND_MYSQL ?
          truncateCell(`${user.user_name_first.toUpperCase()} ${user.user_name_last.toUpperCase()}`) :
          truncateCell(`${user.user_name.first_name.toUpperCase()} ${user.user_name.last_name.toUpperCase()}`)
      }
      </Table.Cell>
      <Table.Cell className='t-data'><p className='text-wrap'>{user.user_email}</p></Table.Cell>
      <Table.Cell className='t-data'>{user.role_code === ROLES.PARTNER ? 'SALES USER' : user.role_code}</Table.Cell>
      <Table.Cell className='t-data'>{truncateCell(user.office_name)}</Table.Cell>
      <Table.Cell className='t-data'>{truncateCell(user?.parent_office_name)}</Table.Cell>
      <Table.Cell className='t-data'>
        <div className="multi-select-dropdown">
          {rmNames?.join(', ')}
        </div>
      </Table.Cell>
      <Table.Cell className='t-data'>{low_risk_processors?.join(', ')}</Table.Cell>
      <Table.Cell className='t-data'>{high_risk_processors?.join(', ')}</Table.Cell>
      {/* <Table.Cell className='t-data'>
        <div className="multi-select-dropdown">
          {getParsedList(user?.allowed_risk_levels).join(', ')}
        </div>
      </Table.Cell> */}
      {(role === ROLES.SUPER_ADMIN || (role === ROLES.SUPER_ADMIN && super_user)) && (
        <Table.Cell className='t-data'>
           <Checkbox
            name='enableIRIS'
            checked={!!user.iris_enabled || false}
            onChange={(e, data: CheckboxProps) => {
              handleIRISChecked(data, user.user_id)
            }}
          />
        </Table.Cell>
      )}
      {(role === ROLES.SUPER_ADMIN || (role === ROLES.SUPER_ADMIN && super_user)) && (
        <>
          <Table.Cell className='t-data'>
          <Checkbox
              name="enableFeeNavigator"
              checked={!!user.feenavigator_enabled || false}
              onChange={(e, data: CheckboxProps) => {
                handleFeenavigatorChecked(data, user.user_id);
              }}
            />
          </Table.Cell>
        </>
      )}
      {(role === ROLES.SUPER_ADMIN || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.RELATIONSHIP_MANAGER) && (
        <>
          <Table.Cell className='t-data'>
          {<Checkbox
              name="enableFreeEquipmentPlacement"
              checked={user.free_equipment_placement_enabled || false}
              disabled={warningModal || !user.office_free_equipment_placement_enabled && user.role_code !== 'RM' }
              onChange={(e, data: CheckboxProps) => {
                handleFreeEquipmentPlacementChecked(data, user.user_id);
              }}
            />}
          </Table.Cell>
        </>
      )}
       <Table.Cell className='t-data'>{!!user.deactivate ? "INACTIVE" : "ACTIVE"}</Table.Cell>
      <Table.Cell className='t-data' disabled={selectedUserBulk.length > 1}>
      <div className='action-items'>
          {(role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.SUPER_ADMIN || role === ROLES.OFFICE_MANAGER) && (<span className={selectedUserBulk.length> 1 || warningModal?'deactivate-icon not-active-userList' : 'deactivate-icon userList'}  {...(warningModal ? {} : { "data-tooltip": user.deactivate ? "Activate" : "Deactivate" })} onClick={warningModal ? () => {} : () => handleDeactivateModal(user.user_id)} />)}
          <span className={selectedUserBulk.length>1 || warningModal? "edit-icon not-active-userList" : "edit-icon userList"} {...(warningModal? {} : { "data-tooltip": "Edit" })} onClick={warningModal ? () => {} : () => handleEditUser(user.user_id)} />
          <span className={selectedUserBulk.length>1 ? 'reset-password-icon not-active-userList' : 'reset-password-icon userList'} data-tooltip='Reset Password' onClick={() => handleResetPassword(user.user_email)} />
          {/* {role !== ROLES.RELATIONSHIP_MANAGER &&  */}
          <span className={selectedUserBulk.length>1 || warningModal? "delete-icon not-active-userList" : "delete-icon userList"} {...(warningModal? {} : { "data-tooltip": "Delete" })} onClick={warningModal ? () => {} : () => handleDeleteUser(user.user_id)} />
          {/* } */}
        </div>
      </Table.Cell>
    </Table.Row>
  )
}

export default UserTableRow;
