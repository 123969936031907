import React from "react";
import { Button, Modal } from "semantic-ui-react";
import ProgressLoader from "../../Common/ProgressLoader";

interface ActionConfirmationDeleteProps {
    loading: boolean
    selectedUser: any
    deactivateModalOpen: boolean
    handleCloseModal: () => void
    modalAction: any
    countforAction : any
    handleDeleteBulk : any
    //handleActivateDeActivate: (user_guid: any, user_id: any, user_email: any, deactivate: boolean, role_code: string) => Promise<void>
  
}
const ActionConfirmationDelete = (props: ActionConfirmationDeleteProps) => {
    const { loading, deactivateModalOpen, handleCloseModal, selectedUser, modalAction, countforAction,handleDeleteBulk} = props
    return (
        <Modal
            centered
            size='tiny'
            open={deactivateModalOpen}
            onClose={handleCloseModal}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <div className="close-button">
                    <button className="email-activity-close-button" onClick={handleCloseModal}>X</button>
                </div>
                <ProgressLoader loading={loading} size='small' />
                <div className="word-wraps">
                {countforAction > 0 ?
                <span className="word-wrap">{`Couldn't perform delete action for the deactivated ${countforAction > 1 ? 'app links' : 'app link'} . Please recheck your selection`}</span> 
                : 
                <span className="word-wrap">{`Are you sure you want to ${modalAction} ${selectedUser.length } ${selectedUser.length > 1 ? 'app links' : 'app link'} ?`}</span> 
                }
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleCloseModal}>CANCEL</Button>
                {countforAction === 0 && (
                <Button color='blue' onClick={() => { handleDeleteBulk(modalAction) }}  >YES</Button>
                )}
            </Modal.Actions>
        </Modal>
    )
}

export default ActionConfirmationDelete;
