import React from "react";
import { Button, Modal } from "semantic-ui-react";

interface SentMailModalProps {
    sentMailModalOpen: boolean;
    setSentMailModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSaveLinkError: React.Dispatch<React.SetStateAction<string>>
    config:any
}
const SentMailModal = (props: SentMailModalProps) => {
    const { sentMailModalOpen, setSentMailModalOpen, setSaveLinkError, config } = props
    return (
        <Modal
            centered
            open={sentMailModalOpen}
            onClose={() => setSentMailModalOpen(false)}
            onOpen={() => setSentMailModalOpen(true)}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <Modal.Description>
                    <div className='not-found-text'>
                        Successfully sent e-mail to Merchant!
                    </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <button
                    onClick={() => {
                        setSentMailModalOpen(false);
                        setSaveLinkError("")
                    }}
                    className="save-button button-border"
                    style={{ background: config?.client_config_fe?.button_color,color:"#FFF"}}>OKAY</button>
            </Modal.Actions>
        </Modal>
    )
}

export default SentMailModal;