import React from "react";
import { memo, useState, useEffect, useContext } from "react";
import { Button, Table } from "semantic-ui-react";
import { Footer } from "../../PageLayout/Footer";
import { Layout } from "../../PageLayout/Layout";
import AuditUsersDataModal from "./AuditUsersDataModal";
import AuditOfficesDataModal from "./AuditOfficesDataModal";
import ProgressLoader from "../../Common/ProgressLoader";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import TableComponent from "../../TableComponent";
import useCheckMobileScreen, { useCheckTabScreen } from "../../../hooks/useCheckMobileScreen";
import { sleep, get } from "../../../Utils/helpers";
import api from "../../../Service/Api";
import backend from "../../../Service/Backend";
import { AppContext } from '../../../context/appContext';
import BasicButton from '../../Common/Button'

const { REACT_APP_BACKEND_MYSQL } = process.env;

const ACTIVE_INDEX_KEY = {
  USER_DATA: "users",
  OFFICE_DATA: "offices",
};

const AuditList = () => {
  const { config }: any = useContext(AppContext);
  const [tabIndex, setTabIndex] = useState(ACTIVE_INDEX_KEY.USER_DATA);
  const [loading, setLoading] = useState(true);
  const [activeIndexData, setActiveIndexData] = useState([] as any);
  const [auditUsersDataModal, setAuditUsersDataModal] = useState(false);
  const [auditOfficesDataModal, setAuditOfficesDataModal] = useState(false);
  const [overlay, setOverlay] = useState({ style: { display: "none" } });
  const [overlayMsg, setOverlaymsg] = useState("Audit data Fetched Successfully!");
  const [defaultStartDate, setDefaultStartDate] = useState("");
  const [defaultEndDate, setDefaultEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [selectedUserData, setSelectedUserData] = useState({} as any)
  const [selectedOfficeData, setSelectedOfficeData] = useState({} as any)

  const isMobile = useCheckMobileScreen();
  const isTablet = useCheckTabScreen();

  const getInitialDates = () => {
    const current_date: any = new Date();
    const start_date: any = new Date(
      current_date.getFullYear(),
      current_date.getMonth(),
      current_date.getDate() - 7
    );
    setStartDate(start_date);
    setEndDate(current_date);
    setDefaultStartDate(start_date);
    setDefaultEndDate(current_date);
    return {
      startDate: start_date,
      endDate: current_date
    }
  };

  const formatDate = (date: any) => {
    var d = new Date(date)
    var month = '' + (d.getMonth() + 1)
    var day = '' + d.getDate()
    var year = d.getFullYear()
  
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
  
    return [year, month, day].join('-')
  }

  const getData = async (start_date: any, end_date: any) => {
    try {
      setLoading(true)
      if (!!tabIndex) {
        let auditData: any;
        if (tabIndex === 'users') {
          const data = {
            api: api.audit.getUserAuditHistory,
            queryParam: { startDate: formatDate(start_date), endDate: formatDate(end_date) }
          };
          auditData = await backend.fetch(data);
          setOverlaymsg("User audit data fetched successfully!")
          console.log('userData', auditData)
        } else if (tabIndex === 'offices') {
          const data = {
            api: api.audit.getOfficeAuditHistory,
            queryParam: { startDate: formatDate(start_date), endDate: formatDate(end_date) }
          };
          auditData = await backend.fetch(data);
          console.log('officeData', auditData)
          setOverlaymsg("Office audit data fetched successfully!")
        }
        setActiveIndexData(auditData)
      }
    } catch (err) {
      throw err
    } finally {
      setLoading(false)
      await showOverlayForThreeSeconds();
      setOverlaymsg("")
    }
  }

  const fetchInitialData = async () => {
    const dates: any = await getInitialDates()
    console.log('dates', dates)
    await getData(dates.startDate, dates.endDate)
  }

  useEffect(() => {
    //TO FETCH INITIAL DATA
    fetchInitialData();
  }, []);

  useEffect(() => {
    // Fetch data based on selected client.
    fetchInitialData();
  }, [config.selectedClientId]);

  useEffect(() => {
    if (!!startDate && !!endDate) {
      setActiveIndexData([])
      getData(startDate, endDate)
    }
  }, [tabIndex])

  function handelUserModal(index: any) {
    setAuditUsersDataModal(true);
    const data: any = activeIndexData[index];
    console.log('data', data)
    setSelectedUserData(data)
  }

  function handelOfficeModal(index: any) {
    setAuditOfficesDataModal(true);
    const data: any = activeIndexData[index];
    setSelectedOfficeData(data)
  }

  const createdTS = (sec: any, nano: any) => {
    var date = new Date(sec * 1000 + nano / 1000000).toDateString();
    var d = new Date(date);
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("-");
  };

  const showOverlayForThreeSeconds = async () => {
    const sstyle: any = { style: { display: "block", position: "fixed" } };
    setOverlay(sstyle);
    await sleep(2000);
    const hstyle: any = { style: { display: "none" } };
    setOverlay(hstyle);
  };

  const handleViewClick = (index: number, key: string) => {
    if (key === ACTIVE_INDEX_KEY.USER_DATA) {
      handelUserModal(index);
    } else if (key === ACTIVE_INDEX_KEY.OFFICE_DATA) {
      handelOfficeModal(index);
    }
  };

  const handleCancel = (refresh_data: boolean) => {
    if (refresh_data) {
      setStartDate(defaultStartDate);
      setEndDate(defaultEndDate);
      getData(defaultStartDate, defaultEndDate)
    };
    setAuditUsersDataModal(false);
    setAuditOfficesDataModal(false);
    setSelectedOfficeData({})
    setSelectedUserData({})
    window.scrollTo(0, 0);
  };

  const handleClear = () => {
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    getData(defaultStartDate, defaultEndDate)
  };

  const FilterContainer = () => (
    <div className="flex-spacebetween mob-audit-filter">
      <div className="date-filter">
        <label>Start Date :</label>
        <SemanticDatepicker
          placeholder={!isMobile ? "MM-DD-YYYY" : "Start Date"}
          format="MM-DD-YYYY"
          datePickerOnly={true}
          name="FromDate"
          autoComplete="new-password"
          value={startDate ? new Date(startDate) : null}
          onChange={(event, { value }: any) => {
            setStartDate(value <= new Date() ? value : null);
          }}
          filterDate={(date) => {
            const now = new Date();
            return date <= now;
          }}
        />
      </div>
      <div className="date-filter">
        <label>End Date :</label>
        <SemanticDatepicker
          placeholder={!isMobile ? "MM-DD-YYYY" : "End Date"}
          format="MM-DD-YYYY"
          datePickerOnly={true}
          name="FromDate"
          autoComplete="new-password"
          value={endDate ? new Date(endDate) : null}
          onChange={(event, { value }: any) => {
            setEndDate(value <= new Date() ? value : null);
          }}
          filterDate={(date) => {
            const now = new Date();
            const start_date: any = new Date(startDate);
            return date <= now && date >= start_date;
          }}
        />
      </div>
      {!!startDate && !!endDate ? (
        <div className="button-container">
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText="CLEAR"
            className=" save-button button-border"
            onClick={handleClear}
          />
          <button
            className="save-button button-border"
            style={{ background: config?.client_config_fe?.button_color }}
            onClick={() => getData(startDate, endDate)}
          >
            Search
          </button>
        </div>
      ) : (
        <>
          <div className="button-container gray-disabled-button">
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText="CLEAR"
            className="save-button button-border"
          />
          <button
            className="save-button button-border"
            style={{ background: config?.client_config_fe?.button_color }}
          >
            Search
          </button>
          </div>
        </>
      )}
    </div>
  );

  const TabHeader = () => (
    <div className="audit-btn mobile-tabs-slider">
      <BasicButton
        textColor={
          tabIndex === ACTIVE_INDEX_KEY.USER_DATA
          ? '#FFFFFF' : config?.client_config_fe?.button_color }
        backgroundColor={
          tabIndex === ACTIVE_INDEX_KEY.USER_DATA
          ? config?.client_config_fe?.button_color : '#FFFFFF'}
        size='18px'
        buttonText="Audit Users"
        className="rightAlign save-button button-border"
        onClick={() => setTabIndex(ACTIVE_INDEX_KEY.USER_DATA)}
      />
      <BasicButton
        textColor={
          tabIndex === ACTIVE_INDEX_KEY.OFFICE_DATA
          ? '#FFFFFF' : config?.client_config_fe?.button_color }
        backgroundColor={
          tabIndex === ACTIVE_INDEX_KEY.OFFICE_DATA
          ? config?.client_config_fe?.button_color : '#FFFFFF'}
        size='18px'
        buttonText="Audit Offices"
        className="rightAlign save-button button-border"
        onClick={() => setTabIndex(ACTIVE_INDEX_KEY.OFFICE_DATA)}
      />
        
    </div>
  );

  const getTableKeys = (forUser: boolean) => {
    let rowkeys: any = [];
    let headerKeys: any = [];
    if (isMobile) {
      rowkeys = [
        { label: "Updated date", key: "updateDate" },
        { label: "Updated by User ID", key: "updateId" },
        { label: "Updated by User Name", key: "updateName" },
      ];
      headerKeys = [
        { label: forUser ? "User ID" : "Office ID", key: "id" },
        { label: forUser ? "User Name" : "Office Name", key: "name" },
        { label: "Action Name", key: "action" },
      ];
    } else if (isTablet) {
      if (window.innerHeight > window.innerWidth) {
        rowkeys = [
          { label: "Updated by User ID", key: "updateId" },
          { label: "Updated by User Name", key: "updateName" }
        ];
        headerKeys = [
          { label: forUser ? "User ID" : "Office ID", key: "id" },
          { label: forUser ? "User Name" : "Office Name", key: "name" },
          { label: "Action Name", key: "action" },
          { label: "Updated date", key: "updateDate" }
        ];
      } else {
        rowkeys = [];
        headerKeys = [
          { label: forUser ? "User ID" : "Office ID", key: "id" },
          { label: forUser ? "User Name" : "Office Name", key: "name" },
          { label: "Action Name", key: "action" },
          { label: "Updated date", key: "updateDate" },
          { label: "Updated by User ID", key: "updateId" },
          { label: "Updated by User Name", key: "updateName" }
        ];
      }
    }

    return {
      headerKeys,
      rowkeys
    }

  }

  const TableAuditLogsMobile = () => {
    const forUser = tabIndex === ACTIVE_INDEX_KEY.USER_DATA;

    const tableKeys = getTableKeys(forUser);
    const records = activeIndexData.map((i: any, index: number) => ({    
      ...i,
      action: REACT_APP_BACKEND_MYSQL ? i.transaction_type : i.action_type_code.split("_")[0],
      id: forUser ? REACT_APP_BACKEND_MYSQL ? i.new_data.user_code || i.new_data.user_id : i.new_data.user_id : REACT_APP_BACKEND_MYSQL ? i.new_data.office_code || i.new_data.office_id: i.new_data.office_id,
      updateDate: (formatDate(i.created_ts), formatDate(i.created_ts)),
      updateId: i.created_by_user_details
        ? i.created_by_user_details.user_id
        : " ",
      updateName: `${i.created_by_user_details
        ? i.created_by_user_details.user_name?.first_name
        : " "
        } ${i.created_by_user_details
          ? i.created_by_user_details.user_name.last_name
          : " "
        }`,
      name: forUser
        ? `${i.new_data?.user_name?.first_name} ${i.new_data?.user_name?.last_name}`
        : i.new_data.office_name,
      onClickActions: () => handleViewClick(index, tabIndex),
    }));

    return (
      <TableComponent
        headerList={tableKeys.headerKeys}
        rowKeys={tableKeys.rowkeys}
        tableData={records}
        heading={isMobile ? "Audit List" : ""}
        uniqueId={"objectID"}
        overrideActions={true}
        noAppMessage={
          "No logs available for the selected range"
        }
        actionButton={<Button
          icon="eye"
          style={{ backgroundColor: 'transparent' }}
        />}
        loading={loading}
      />
    );
  };

  const getTransactionType = (i: any) => {
    if(i.transaction_type === "INSERT") {
      return "CREATE"
    } else {
      if(i.new_data?.deleted === 1 && i.old_data?.deleted !== 1) {
        return "DELETE"
      } 
    }
    return i.transaction_type
  }

  const TableAuditLogsDesktop = () => (
    <Table
      style={{ padding: 10 }}
      textAlign="center"
      celled
      striped
      structured
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>
            {tabIndex === ACTIVE_INDEX_KEY.USER_DATA
              ? "User ID"
              : "Office ID"}
          </Table.HeaderCell>
          <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>
            {tabIndex === ACTIVE_INDEX_KEY.USER_DATA
              ? "User Name"
              : "Office Name"}
          </Table.HeaderCell>
          <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>Action Name</Table.HeaderCell>
          <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>Updated Date</Table.HeaderCell>
          <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>Updated By UserID</Table.HeaderCell>
          <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>Updated By UserName</Table.HeaderCell>
          <Table.HeaderCell style={{ background: config?.client_config_fe?.button_color }}>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {activeIndexData.map((i: any, index: any) => {
          return (
            <Table.Row key={index}>
              <Table.Cell>
                {tabIndex === ACTIVE_INDEX_KEY.USER_DATA
                  ? REACT_APP_BACKEND_MYSQL ? i.new_data.user_code ||  i.new_data.user_id : i.new_data.user_id
                  : REACT_APP_BACKEND_MYSQL ? i.new_data.office_code || i.new_data.office_id : i.new_data.office_id}
              </Table.Cell>
              <Table.Cell>
                {tabIndex === ACTIVE_INDEX_KEY.USER_DATA
                  ? REACT_APP_BACKEND_MYSQL ? `${i.new_data?.user_name_first} ${i.new_data?.user_name_last}` : `${i.new_data?.user_name?.first_name} ${i.new_data?.user_name?.last_name}`
                  : i.new_data.office_name}
              </Table.Cell>
              <Table.Cell>{getTransactionType(i)}</Table.Cell>
              <Table.Cell>
                {formatDate(i.created_time)}
              </Table.Cell>
              <Table.Cell>
                {get(i, "updated_user_code", "")}
              </Table.Cell>
              <Table.Cell>
                {get(i, "updated_user_name", "").replace(',', '')}
              </Table.Cell>
              <Table.Cell>
                <Button
                  icon="eye"
                  onClick={() => handleViewClick(index, tabIndex)}
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );

  return (
    <>
      <Layout page="audit">
        {(!auditUsersDataModal && !auditOfficesDataModal) || (!isMobile) ? (
          <div className="userslist-container">
            <div
              className={
                window.innerWidth > 1640
                  ? "body-content"
                  : "listapp-body-content"
              }
            >
              {!isMobile ? (
                <div className="heading flex-spacebetween mob-audit-heading-filter">
                  <span className="heading-text-left" style={{ color: config?.client_config_fe?.button_color }}>Audit List</span>
                  <FilterContainer />
                  <TabHeader />
                </div>
              ) : (
                <>
                  <FilterContainer />
                  <TabHeader />
                </>
              )}
              {isMobile || isTablet ? (
                <TableAuditLogsMobile />
              ) : (
                <TableAuditLogsDesktop />
              )}
              {(activeIndexData?.length === 0) && !loading && (!isMobile && !isTablet) && (
                <div className="body-content">
                  <div className="heading">
                    <span className="heading-center" style={{ color: config?.client_config_fe?.button_color }}>
                      No logs available for the selected range
                    </span>
                  </div>
                </div>)
              }
            </div>
            <ProgressLoader loading={loading} size="large" config={config} />
          </div>
        ) : (
          <></>
        )}
        <div className="overlay" style={overlay.style}>
          <div className="overlay-image">
            <span className="overlay-text" style={{ color: config?.client_config_fe?.button_color }}>{overlayMsg}</span>
          </div>
        </div>
        {auditUsersDataModal && (
          <AuditUsersDataModal
            auditUsersDataModal={auditUsersDataModal}
            selectedUserData={selectedUserData}
            handleCancel={handleCancel}
            createdTS={createdTS}
            config={config}
          />
        )}
        {auditOfficesDataModal && (
          <AuditOfficesDataModal
            selectedOfficeData={selectedOfficeData}
            auditOfficesDataModal={auditOfficesDataModal}
            handleCancel={handleCancel}
            createdTS={createdTS}
            config={config}
          />
        )}
        <Footer page="audit" />
      </Layout>
    </>
  );
};

export default memo(AuditList);
