import React from "react";
import { Table } from "semantic-ui-react";
import SendAppTableBody from "./SendAppTableBody";
import SendAppTableHeader from "./SendAppTableHeader";

interface SendAppTableProps {
    sortField: string;
    handleSortByFieldName: (field: string, order: string) => Promise<void>;
    sortFieldOrder: string;
    searchText: string;
    handleSearchByFieldName: (field: string, value: string) => Promise<void>;
    searchField: string;
    sortAndSearchResult: (data: any) => any;
    sendLinkUsers: any[];
    openViewLinkDetailsModal: (index: number, customData?: any) => void
    handleCopy: (tiny_url: string) => Promise<void>;
    handleEditButtonClick: (e: any, app_link_id: string, duplicated: boolean, duplicate_link_data?: any) => void
    handleActivate: (save_link_id: any, status: any) => Promise<void>;
    handleDuplicate: (e: any, save_link_id: any) => Promise<void>;
    handleDelete: (save_link_id: any, status: any) => Promise<void>;
    clearForm: () => void;
    formatDate: (date: any) => string;
    setDeleteAppLinkId: React.Dispatch<React.SetStateAction<string>>;
    setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    setCancelAlertOpen: React.Dispatch<React.SetStateAction<boolean>>;
    role: string | null
    saveLinkId: string
    isEditMode: boolean
    isParentChecked : any
    changeCheckboxStatus : any
    selectedAppBulk: any[]
    config:any
}
const SendAppTable = (props: SendAppTableProps) => {
    const { sortField,handleSortByFieldName, sortFieldOrder, searchText, handleSearchByFieldName, searchField, sortAndSearchResult,
        sendLinkUsers,openViewLinkDetailsModal, handleCopy, handleEditButtonClick, handleActivate, handleDuplicate, handleDelete,
        clearForm, formatDate,setDeleteAppLinkId,setDeleteModalOpen,setIsEditMode,setCancelAlertOpen, role, saveLinkId, isEditMode, 
        isParentChecked, changeCheckboxStatus, selectedAppBulk, config} = props

    return (
        <Table celled structured textAlign="center" striped>
            <SendAppTableHeader
                sortField={sortField}
                handleSortByFieldName={handleSortByFieldName}
                sortFieldOrder={sortFieldOrder}
                searchText={searchText}
                handleSearchByFieldName={handleSearchByFieldName}
                searchField={searchField}
                isParentChecked = {isParentChecked}
                changeCheckboxStatus = {changeCheckboxStatus}
                role={role}
                config={config}
            />
            <Table.Body>{<SendAppTableBody
                sortAndSearchResult={sortAndSearchResult}
                sendLinkUsers={sendLinkUsers}
                openViewLinkDetailsModal={openViewLinkDetailsModal}
                handleCopy={handleCopy}
                handleEditButtonClick={handleEditButtonClick}
                handleActivate={handleActivate}
                handleDuplicate={handleDuplicate}
                clearForm={clearForm}
                handleDelete={handleDelete}
                formatDate={formatDate}
                setDeleteAppLinkId={setDeleteAppLinkId}
                setDeleteModalOpen={setDeleteModalOpen}
                setIsEditMode={setIsEditMode}
                setCancelAlertOpen ={setCancelAlertOpen}
                role={role}  
                saveLinkId={saveLinkId}
                isEditMode={isEditMode}
                isParentChecked = {isParentChecked}
                changeCheckboxStatus = {changeCheckboxStatus}   
                selectedAppBulk={selectedAppBulk}  
            />}</Table.Body>
        </Table>
    )
}

export default SendAppTable;